import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box,
    Grid,
    Card,
    CardContent,
    Avatar, Container
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useParams, useNavigate } from 'react-router-dom';

const DashboardOrderDetail = () => {
    const { id } = useParams(); // Get the orderId from the URL params
    const [orderDetails, setOrderDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the order details from the API using Axios
        const fetchOrderDetails = async () => {
            const token = sessionStorage.getItem('token'); // Retrieve token from local storage
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}order/order-detail-student/${id}`, {
                    headers: {
                        Authorization: token,
                    },
                });
                if (response.data.success) {
                    setOrderDetails(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        };

        fetchOrderDetails();
    }, [id]);

    if (!orderDetails) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ padding: "40px" }}>
            <Container>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: "40px", pl: "10px" }}>
                    <Icon icon="ep:back" fontSize={"36px"} onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
                    <Box sx={{ display: "flex", flexDirection: "column", pl: "20px" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontSize: "20px", fontWeight: "600" }}>
                                {orderDetails.orderId}
                            </Typography>
                            <Box
                                sx={{
                                    ml: "10px",
                                    padding: '5px 10px',
                                    backgroundColor:
                                        orderDetails?.paymentStatus === 'pending'
                                            ? '#fff1d6'
                                            : orderDetails?.paymentStatus === 'paid'
                                                ? '#dff3ea'
                                                : '#f9d6d6',
                                    borderRadius: '8px',
                                    width:
                                        orderDetails?.paymentStatus === 'pending'
                                            ? '80px'
                                            : orderDetails?.paymentStatus === 'paid'
                                                ? '60px'
                                                : '70px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        textTransform: "capitalize",
                                        color:
                                            orderDetails?.paymentStatus === 'pending'
                                                ? '#dbb06c'
                                                : orderDetails?.paymentStatus === 'paid'
                                                    ? '#499a87'
                                                    : '#f97777',
                                    }}
                                >
                                    {orderDetails?.paymentStatus}
                                </Typography>
                            </Box>

                        </Box>
                        <Typography sx={{ fontSize: "18px", fontWeight: "500", color: "grey" }}>
                            {new Date(orderDetails.dateOfPurchase).toLocaleString()}
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid item md={12} lg={7} xs={12}>
                        <Box sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                                <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: 0, borderRadius: "10px" }}>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                            <TableCell sx={{ borderBottom: 'none' }}>Plan Name</TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }}>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderDetails.plans.map((plan) => (
                                            <TableRow key={plan.planId}>
                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                    <Box>
                                                        <Typography variant="body1">{plan.planName}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 'none' }}>₹{plan.price}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={1} sx={{ borderBottom: 'none', fontWeight: "550", fontSize: "16px" }}>Total Price</TableCell>
                                            <TableCell sx={{ borderBottom: 'none', fontWeight: "550", fontSize: "16px" }}>₹{orderDetails.amount}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={5} xs={12}>
                        <Box sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: "0 20px" }}>
                            <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: 'visible' }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Box component="span" sx={{ px: 1, borderRadius: 1, mr: 1 }}>
                                            Student Info
                                        </Box>
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Avatar sx={{ bgcolor: '#ffcc00', width: 56, height: 56, mr: 2, color: "#000" }}>
                                            <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                                                {orderDetails.createdBy.name[0].toUpperCase()}
                                            </Typography>
                                        </Avatar>
                                        <Box>
                                            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>{orderDetails.createdBy.name}</Typography>
                                            <Typography variant="body2" fontSize={{ md: "13px" }} color="textSecondary">Email: {orderDetails.createdBy.email}</Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default DashboardOrderDetail;
