import React, { useEffect } from "react";

import Navbar from "./Navbar";
import Footer from "./Footer";
import LetsGetStarted from "./LetsGetStarted";

import WhyChoose from "src/TestPages/WhyChoose";
import OurTest from "src/TestPages/OurTests";
import TestMainBanner from "src/TestPages/TestBannerMain";
import Testimonial from "./Testimonial";
import { Helmet } from "react-helmet";

const Test = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Best Test Preparation Services | Taksheela Institute</title>
        <meta
          name="description"
          content="Excel in your exams with Taksheela Institute's expert test preparation services. Get comprehensive study materials, personalized coaching, and practice tests."
        />
        <meta
          name="keywords"
          content="Best place for TOEFL Preparation, TOEFL Preparation instityute Kolkata, Best place for IELTS Exam Prep, best test Preparation Services center Delhi,"
        />
      </Helmet>
      <Navbar />
      <TestMainBanner />
      <OurTest />
      <WhyChoose />
      <Testimonial />
      <LetsGetStarted />
      <Footer />
    </>
  );
};

export default Test;
