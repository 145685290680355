import React, { useState } from "react";
import {
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const UniversityList = () => {
  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  // Example options for the dropdown
  const options = [
    "Massachusetts Institute of Technology (MIT)",
    "Imperial College London",
    "University of Cambridge",
    "Harvard University",
    "University of Cambridge",
    "Massachusetts Institute of Technology",
    "California Institute of Technology",
    "University of Chicago",
    "University of California, Berkeley",
    "University of Toronto",
    "University of Edinburgh",
    "National University of Singapore",
    "University of Melbourne",
    "University College London",
    "University of British Columbia",
    "University of Pennsylvania",
    "University of Sydney",
    "University of California, Los Angeles",
    "Hong Kong University of Science and Technology",
    "University of Queensland",
    "University of Copenhagen",
    "University of California, Los Angeles",
    "University of Hong Kong",
    "University of British Columbia",
  ];

  const handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setQuery(value);

    // Filter options based on the query
    const matches = options.filter((option) =>
      option.toLowerCase().includes(value)
    );
    setFilteredOptions(matches);
  };

  // Determine options to display
  const displayOptions = query ? filteredOptions : options;

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      {/* Input Field */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
          variant="outlined"
          sx={{
            mb: 2,
            ml: 4,
            width: "90%",
            "& .MuiOutlinedInput-root": {
              // borderRadius: '4px',
              height: "auto",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* Options List */}
        <List>
          {displayOptions.length > 0 ? (
            displayOptions.map((option, index) => (
              <ListItem key={index} button>
                <ListItemText primary={option} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No options found" />
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
};

export default UniversityList;
