import { Typography } from "@mui/material";
import React from "react";

const Header = ({ color, textAlign, content, fontSize, content1 }) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontSize: fontSize ?? { xs: "24px", sm: "26px", md: "36px", lg: "36px" },
        fontFamily: "Poppins",
        color: color ?? "#00999E",
        fontWeight: "600",
        textAlign: textAlign,
      }}
    >
      ~{content}~
    </Typography>
  );
};

export default Header;
