const EngineeringLevel = {
    heading: "Level’s of Engineering",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate",
                "Duration: 3-4 years",
                "Curriculum: Core engineering",
                "Opportunities: Entry-level",
                "Admission Requirements: Diploma",
                "Career Path: Engineer"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate",
                "Duration: 1-2 years",
                "Curriculum: Advanced coursework",
                "Opportunities: Career Advancement",
                "Admission Requirements: Bachelor's degree",
                "Career Path: Engineering Manager"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Postgraduate",
                "Duration: 3-5+ years",
                "Curriculum: Dissertation or research project",
                "Opportunities: Academic research",
                "Admission Requirements: Master's degree",
                "Career Path: Professor"
            ]
        }
    ]

};

const CSITLevel = {
    heading: "Levels of CSIT",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree",
                "Duration: 3-4 years",
                "Curriculum: Fundamental, Specialization",
                "Opportunities: Entry-level positions",
                "Admission Requirements: Diploma,Test scores",
                "Career Path: Developer, IT technician"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level program",
                "Duration: 1-2 years",
                "Curriculum: Advanced coursework thesis",
                "Opportunities: Career advancement, Specialization",
                "Admission Requirements: Bachelor's, GRE/GMAT",
                "Career Path: Senior software engineer, IT manager,"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-5 years",
                "Curriculum: In-depth research dissertation.",
                "Opportunities:  Advanced research, Teaching positions",
                "Admission Requirements: Master's degree, GRE/GMAT",
                "Career Path: Research Scientist, Professor"
            ]
        }
    ]


};
const HsmLevel = {
    heading: "Levels of Health Sciences and Medicine",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree program",
                "Duration: 3-4 years",
                "Curriculum: Foundational, Specializations",
                "Opportunities: Entry-level healthcare",
                "Admission Requirements: Diploma, Test scores",
                "Career Path: Assistant, Technician, Administrator"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level program",
                "Duration: 1-2 years",
                "Curriculum: Advanced coursework research",
                "Opportunities: Career advancement, Leadership",
                "Admission Requirements: Bachelor's degree, GRE/MCAT",
                "Career Path: Physician, Specialist, Manager"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-6 years",
                "Curriculum: In-depth research, Dissertation.",
                "Opportunities: Advanced research, Teaching",
                "Admission Requirements: Master's degree, GRE/MCAT Research",
                "Career Path: Scientist, Professor, Executive"
            ]
        }
    ]

};
const SocialLevel = {
    heading: "Levels of social science",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Social science disciplines",
                "Duration: 3-4 years",
                "Curriculum: Foundational varied",
                "Opportunities: Entry-level",
                "Admission Requirements: High school ",
                "Career Path: Social worker, researcher"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Specialized coursework",
                "Duration: 1-2 years",
                "Curriculum: Advanced, specialized",
                "Opportunities: Career advancement",
                "Admission Requirements: Bachelor's degree, GRE",
                "Career Path: Sociologist, psychologist"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research",
                "Duration: 3-5 years",
                "Curriculum: In-depth research",
                "Opportunities: Advanced research, teaching",
                "Admission Requirements: Master's degree, GRE, research experience",
                "Career Path: Research scientist, professor"
            ]
        }
    ]

};
const BusinessLevel = {
    heading: "Levels of Business and Management ",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree",
                "Duration: 3-4 years",
                "Curriculum: Business fundamentals ",
                "Opportunities: Entry-level  management.",
                "Admission Requirements: High school diploma /SAT, ACT",
                "Career Path: Analyst, coordinator, manager"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level program.",
                "Duration: 1-2 years",
                "Curriculum: Advanced specialization",
                "Opportunities:  Career advancement",
                "Admission Requirements: Bachelor's degree in Business Management, /GMAT/GRE",
                "Career Path: Consultant, manager, analyst"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-5 years",
                "Curriculum: In-depth research and a dissertation.",
                "Opportunities: Advanced research, teaching",
                "Admission Requirements: Master's degree in Business, Management, GMAT/GRE",
                "Career Path: Researcher, professor, executive"
            ]
        }
    ]

};
const PlsLevel = {
    heading: "Levels of Physical and life sciences",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree program",
                "Duration: 3-4 years",
                "Curriculum: Basic sciences foundation",
                "Opportunities: Entry-level position",
                "Admission Requirements: High school diploma /SAT, ACT",
                "Career Path: Technician, educator, specialist"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level program",
                "Duration: 1-2 years",
                "Curriculum: Advanced specialization",
                "Opportunities: Specialization, leadership",
                "Admission Requirements: Bachelor's degree/GRE scores ",
                "Career Path: Scientist, manager, consultant."
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-5 years",
                "Curriculum: In-depth research",
                "Opportunities: Independent research, teaching",
                "Admission Requirements: Master's degree/ GRE scores",
                "Career Path: Researcher, professor, director"
            ]
        }
    ]

};
const FaaLevel = {
    heading: "Levels of Fine and Applied Art",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree",
                "Duration: 3-4 years",
                "Curriculum: Foundation courses, electives",
                "Opportunities: Entry-level position",
                "Admission Requirements: High school diploma portfolio",
                "Career Path: Artist, designer, curator"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level",
                "Duration: 1-2 years",
                "Curriculum: Advanced specialization",
                "Opportunities: Specialization networking",
                "Admission Requirements: Bachelor's degree, portfolio",
                "Career Path: Artist, director, educator"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-5 years",
                "Curriculum: In-depth research",
                "Opportunities: Independent research, teaching",
                "Admission Requirements: Master's degree, portfolio",
                "Career Path: Researcher, professor, director"
            ]
        }
    ]

};
const CJLevel = {
    heading: "Levels of Communication and Journalism",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree program",
                "Duration: 3-4 years",
                "Curriculum: Communication foundation",
                "Opportunities: Entry-level positions",
                "Admission Requirements: High school diploma /SAT, ACT",
                "Career Path: Journalist, PR specialist"]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level program",
                "Duration: 1-2 years",
                "Curriculum: Advanced specialization",
                "Opportunities: Specialization, networking",
                "Admission Requirements: Bachelor's degree/GRE scores",
                "Career Path: Senior journalist, director"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-5 years",
                "Curriculum: In-depth research",
                "Opportunities: Independent research, teaching",
                "Admission Requirements: Master's degree /GRE scores",
                "Career Path: Professor, consultant, director"
            ]
        }
    ]

};
const DesigningLevel = {
    heading: "Levels of Designing",
    item: [
        {
            "title": "Bachelor's Level",
            "topColor": "#F59E0B",
            "listitem": [
                "Education: Undergraduate degree program",
                "Duration: 3-4 years",
                "Curriculum: Design fundamentals",
                "Opportunities: Entry-level positions",
                "Admission Requirements: High school diploma",
                "Career Path: Designer, specialist"
            ]
        },
        {
            "title": "Master's Level",
            "topColor": "#10B981",
            "listitem": [
                "Education: Graduate-level program",
                "Duration: 1-2 years",
                "Curriculum: Advanced specialization",
                "Opportunities: specialization, networking ",
                "Admission Requirements: Bachelor's degree, portfolio",
                "Career Path: Senior designer, director"
            ]
        },
        {
            "title": "Doctorate Level",
            "topColor": "#9B1DFF",
            "listitem": [
                "Education: Advanced research program",
                "Duration: 3-5 years",
                "Curriculum: In-depth research",
                "Opportunities: Independent research, teaching",
                "Admission Requirements: Master's degree, portfolio",
                "Career Path: Educator, researcher, consultant"
            ]
        }
    ]

};



export { EngineeringLevel, CSITLevel, HsmLevel, SocialLevel, BusinessLevel, PlsLevel, FaaLevel, CJLevel, DesigningLevel }