
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const eng1 =imageBaseUrl + 'courseuniversity/engineering/eng1.png'
const eng2 =imageBaseUrl + 'courseuniversity/engineering/en2.png'
const eng3 =imageBaseUrl + 'courseuniversity/engineering/en3.png'
const eng4 =imageBaseUrl + 'courseuniversity/engineering/eng4.png'
const eng5 =imageBaseUrl + 'courseuniversity/engineering/eng5.png'
const eng6 =imageBaseUrl + 'courseuniversity/engineering/eng6.png'
const eng7 =imageBaseUrl + 'courseuniversity/engineering/eng7.png'
const eng8 =imageBaseUrl + 'courseuniversity/engineering/eng8.png'
const eng9 =imageBaseUrl + 'courseuniversity/engineering/eng9.png'
const eng10 =imageBaseUrl + 'courseuniversity/engineering/eng10.png'
const cs1 =imageBaseUrl + 'courseuniversity/csit/cs1.png'
const cs2 =imageBaseUrl + 'courseuniversity/csit/cs2.png'
const cs3 =imageBaseUrl + 'courseuniversity/csit/cs3.png'
const cs4 =imageBaseUrl + 'courseuniversity/csit/cs4.png'
const cs5 =imageBaseUrl + 'courseuniversity/csit/cs5.png'
const cs6 =imageBaseUrl + 'courseuniversity/csit/cs6.png'
const cs7 =imageBaseUrl + 'courseuniversity/csit/cs7.png'
const cs8 =imageBaseUrl + 'courseuniversity/csit/cs8.png'
const cs9 =imageBaseUrl + 'courseuniversity/csit/cs9.png'
const cs10 =imageBaseUrl + 'courseuniversity/csit/cs10.png'
const h1 =imageBaseUrl + 'courseuniversity/hsm/h1.png'
const h2 =imageBaseUrl + 'courseuniversity/hsm/h2.png'
const h3 =imageBaseUrl + 'courseuniversity/hsm/h3.png'
const h4 =imageBaseUrl + 'courseuniversity/hsm/h4.png'
const h5 =imageBaseUrl + 'courseuniversity/hsm/h5.png'
const h6 =imageBaseUrl + 'courseuniversity/hsm/h6.png'
const h7 =imageBaseUrl + 'courseuniversity/hsm/h7.png'
const h8 =imageBaseUrl + 'courseuniversity/hsm/h8.png'
const h9 =imageBaseUrl + 'courseuniversity/hsm/h9.png'
const h10 =imageBaseUrl + 'courseuniversity/hsm/h10.png'
const s1 =imageBaseUrl + 'courseuniversity/social/s1.png'
const s2 =imageBaseUrl + 'courseuniversity/social/s2.png'
const s3 =imageBaseUrl + 'courseuniversity/social/s3.png'
const s4 =imageBaseUrl + 'courseuniversity/social/s4.png'
const s5 =imageBaseUrl + 'courseuniversity/social/s5.png'
const s6 =imageBaseUrl + 'courseuniversity/social/s6.png'
const s7 =imageBaseUrl + 'courseuniversity/social/s7.png'
const s8 =imageBaseUrl + 'courseuniversity/social/s8.png'
const s9 =imageBaseUrl + 'courseuniversity/social/s9.png'
const s10 =imageBaseUrl + 'courseuniversity/social/s10.png'
const b1 =imageBaseUrl + 'courseuniversity/bussiness/b1.png'
const b2 =imageBaseUrl + 'courseuniversity/bussiness/b2.png'
const b3 =imageBaseUrl + 'courseuniversity/bussiness/b3.png'
const b4 =imageBaseUrl + 'courseuniversity/bussiness/b4.png'
const b5 =imageBaseUrl + 'courseuniversity/bussiness/b5.png'
const b6 =imageBaseUrl + 'courseuniversity/bussiness/b6.png'
const b7 =imageBaseUrl + 'courseuniversity/bussiness/b7.png'
const b8 =imageBaseUrl + 'courseuniversity/bussiness/b8.png'
const b9 =imageBaseUrl + 'courseuniversity/bussiness/b9.png'
const b10 =imageBaseUrl + 'courseuniversity/bussiness/b10.png'
const p1 =imageBaseUrl + 'courseuniversity/physical/p1.png'
const p2 =imageBaseUrl + 'courseuniversity/physical/p2.png'
const p3 =imageBaseUrl + 'courseuniversity/physical/p3.png'
const p4 =imageBaseUrl + 'courseuniversity/physical/p4.png'
const p5 =imageBaseUrl + 'courseuniversity/physical/p5.png'
const p6 =imageBaseUrl + 'courseuniversity/physical/p6.png'
const p7 =imageBaseUrl + 'courseuniversity/physical/p7.png'
const p8 =imageBaseUrl + 'courseuniversity/physical/p8.png'
const p9 =imageBaseUrl + 'courseuniversity/physical/p9.png'
const p10 =imageBaseUrl + 'courseuniversity/physical/p10.png'
const f1 =imageBaseUrl + 'courseuniversity/fineart/fa1.png'
const f2 =imageBaseUrl + 'courseuniversity/fineart/fa2.png'
const f3 =imageBaseUrl + 'courseuniversity/fineart/fa3.png'
const f4 =imageBaseUrl + 'courseuniversity/fineart/fa4.png'
const f5 =imageBaseUrl + 'courseuniversity/fineart/fa5.png'
const f6 =imageBaseUrl + 'courseuniversity/fineart/fa6.png'
const f7 =imageBaseUrl + 'courseuniversity/fineart/fa7.png'
const f8 =imageBaseUrl + 'courseuniversity/fineart/fa8.png'
const f9 =imageBaseUrl + 'courseuniversity/fineart/fa9.png'
const f10 =imageBaseUrl + 'courseuniversity/fineart/fa10.png'
const j1 =imageBaseUrl + 'courseuniversity/journalism/j1.png'
const j2 =imageBaseUrl + 'courseuniversity/journalism/j2.png'
const j3 =imageBaseUrl + 'courseuniversity/journalism/j3.png'
const j4 =imageBaseUrl + 'courseuniversity/journalism/j4.png'
const j5 =imageBaseUrl + 'courseuniversity/journalism/j5.png'
const j6 =imageBaseUrl + 'courseuniversity/journalism/j6.png'
const j7 =imageBaseUrl + 'courseuniversity/journalism/j7.png'
const j8 =imageBaseUrl + 'courseuniversity/journalism/j8.png'
const j9 =imageBaseUrl + 'courseuniversity/journalism/j9.png'
const j10 =imageBaseUrl + 'courseuniversity/journalism/j10.png'
const d1 =imageBaseUrl + 'courseuniversity/designing/d1.png'
const d2 =imageBaseUrl + 'courseuniversity/designing/d2.png'
const d3 =imageBaseUrl + 'courseuniversity/designing/d3.png'
const d4 =imageBaseUrl + 'courseuniversity/designing/d4.png'
const d5 =imageBaseUrl + 'courseuniversity/designing/d5.png'
const d6 =imageBaseUrl + 'courseuniversity/designing/d6.png'
const d7 =imageBaseUrl + 'courseuniversity/designing/d7.png'
const d8 =imageBaseUrl + 'courseuniversity/designing/d8.png'
const d9 =imageBaseUrl + 'courseuniversity/designing/d9.png'
const d10 =imageBaseUrl + 'courseuniversity/designing/d10.png'

const EngineeringUniversity = [
    {
        "title": "Massachusetts Institute of Technology (MIT)",
        "items": [
            "Location: Cambridge, USA",
            "Tuition Fee (INR): ₹40,65,644"
        ],
        "Image": eng1,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Tuition Fee (INR): ₹41,99,319"
        ],
        "Image": eng2,
    },
    {
        "title": "University of California, Berkeley",
        "items": [
            "Location: Berkeley, USA",
            "Tuition Fee (INR): ₹33,30,123"
        ],
        "Image": eng3,
    },
    {
        "title": "California Institute of Technology (Caltech)",
        "items": [
            "Location: Pasadena, USA",
            "Tuition Fee (INR): ₹43,05,382"
        ],
        "Image": eng4,
    },
    {
        "title": "ETH Zurich - Swiss Federal Institute of Tech",
        "items": [
            "Location: Zurich, Switzerland",
            "Tuition Fee (INR): ₹10,92,927"
        ],
        "Image": eng5,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Tuition Fee (INR): ₹41,23,600"
        ],
        "Image": eng6,
    },
    {
        "title": "Imperial College London",
        "items": [
            "Location: London, UK",
            "Tuition Fee (INR): ₹37,16,400 - ₹47,48,200"
        ],
        "Image": eng7,
    },
    {
        "title": "National University of Singapore (NUS)",
        "items": [
            "Location: Singapore",
            "Tuition Fee (INR): ₹17,95,125"
        ],
        "Image": eng8,
    },
    {
        "title": "Tsinghua University",
        "items": [
            "Location: Beijing, China",
            "Tuition Fee (INR): ₹7,73,202 - ₹8,71,778"
        ],
        "Image": eng9,
    },
    {
        "title": "University of Toronto",
        "items": [
            "Location: Toronto, Canada",
            "Tuition Fee (INR): ₹34,99,685 - ₹44,35,140"
        ],
        "Image": eng10,
    }
]
    ;
const CSITUniversity = [
    {
        "title": "Massachusetts Institute of Technology (MIT)",
        "items": [
            "Location: Cambridge, USA",
            "Tuition Fee (INR): ₹44,00,000 - ₹48,00,000"
        ],
        "Image": cs1,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Tuition Fee (INR): ₹46,00,000 - ₹50,00,000"
        ],
        "Image": cs2,
    },
    {
        "title": "Carnegie Mellon University",
        "items": [
            "Location: Pittsburgh, USA",
            "Tuition Fee (INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": cs3,
    },
    {
        "title": "University of California, Berkeley (UC Berkeley)",
        "items": [
            "Location: Berkeley, USA",
            "Tuition Fee (INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": cs4,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Tuition Fee (INR): ₹32,00,000 - ₹36,00,000"
        ],
        "Image": cs5,
    },
    {
        "title": "ETH Zurich - Swiss Federal Institute of Technology Zurich",
        "items": [
            "Location: Zurich, Switzerland",
            "Tuition Fee (INR): ₹12,00,000 - ₹16,00,000"
        ],
        "Image": cs6,
    },
    {
        "title": "University of Oxford",
        "items": [
            "Location: Oxford, UK",
            "Tuition Fee (INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": cs7,
    },
    {
        "title": "California Institute of Technology (Caltech)",
        "items": [
            "Location: Pasadena, USA",
            "Tuition Fee (INR): ₹44,00,000 - ₹48,00,000"
        ],
        "Image": cs8,
    },
    {
        "title": "Harvard University",
        "items": [
            "Location: Cambridge, USA",
            "Tuition Fee (INR): ₹46,00,000 - ₹50,00,000"
        ],
        "Image": cs9,
    },
    {
        "title": "National University of Singapore (NUS)",
        "items": [
            "Location: Singapore",
            "Tuition Fee (INR): ₹20,00,000 - ₹24,00,000"
        ],
        "Image": cs10,
    }
]
    ;
const HsmUniversity = [
    {
        "title": "Harvard University",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": h1,
    },
    {
        "title": "University of Oxford",
        "items": [
            "Location: Oxford, UK",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": h2,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Annual Fees (Approx. INR): ₹46,00,000 - ₹50,00,000"
        ],
        "Image": h3,
    },
    {
        "title": "Johns Hopkins University",
        "items": [
            "Location: Baltimore, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹44,00,000"
        ],
        "Image": h4,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹36,00,000"
        ],
        "Image": h5,
    },
    {
        "title": "University of California, Los Angeles (UCLA)",
        "items": [
            "Location: Los Angeles, USA",
            "Annual Fees (Approx. INR): ₹34,00,000 - ₹38,00,000"
        ],
        "Image": h6,
    },
    {
        "title": "University of California, San Francisco (UCSF)",
        "items": [
            "Location: San Francisco, USA",
            "Annual Fees (Approx. INR): ₹42,00,000 - ₹46,00,000"
        ],
        "Image": h7,
    },
    {
        "title": "Yale University",
        "items": [
            "Location: New Haven, USA",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": h8,
    },
    {
        "title": "University College London (UCL)",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": h9,
    },
    {
        "title": "Columbia University",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹44,00,000"
        ],
        "Image": h10,
    }
]
    ;
const SocialUniversity = [
    {
        "title": "Harvard University",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": s1,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Annual Fees (Approx. INR): ₹46,00,000 - ₹50,00,000"
        ],
        "Image": s2,
    },
    {
        "title": "Massachusetts Institute of Technology (MIT)",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹44,00,000 - ₹48,00,000"
        ],
        "Image": s3,
    },
    {
        "title": "University of Oxford",
        "items": [
            "Location: Oxford, UK",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": s4,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹36,00,000"
        ],
        "Image": s5,
    },
    {
        "title": "University of California, Berkeley (UC Berkeley)",
        "items": [
            "Location: Berkeley, USA",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": s6,
    },
    {
        "title": "London School of Economics and Political Science (LSE)",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹44,00,000"
        ],
        "Image": s7,
    },
    {
        "title": "Yale University",
        "items": [
            "Location: New Haven, USA",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": s8,
    },
    {
        "title": "University of Chicago",
        "items": [
            "Location: Chicago, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": s9,
    },
    {
        "title": "Princeton University",
        "items": [
            "Location: Princeton, USA",
            "Annual Fees (Approx. INR): ₹44,00,000 - ₹48,00,000"
        ],
        "Image": s10,
    }
]
    ;
const BusinessUniversity = [
    {
        "title": "Harvard University",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": b1,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Annual Fees (Approx. INR): ₹46,00,000 - ₹50,00,000"
        ],
        "Image": b2,
    },
    {
        "title": "Massachusetts Institute of Technology (MIT)",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹44,00,000 - ₹48,00,000"
        ],
        "Image": b3,
    },
    {
        "title": "University of Oxford",
        "items": [
            "Location: Oxford, UK",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": b4,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹36,00,000"
        ],
        "Image": b5,
    },
    {
        "title": "London Business School",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹44,00,000"
        ],
        "Image": b6,
    },
    {
        "title": "University of Chicago (Booth)",
        "items": [
            "Location: Chicago, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": b7,
    },
    {
        "title": "INSEAD",
        "items": [
            "Location: Fontainebleau, France",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": b8,
    },
    {
        "title": "University of Pennsylvania (Wharton)",
        "items": [
            "Location: Philadelphia, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹44,00,000"
        ],
        "Image": b9,
    },
    {
        "title": "Northwestern University (Kellogg)",
        "items": [
            "Location: Evanston, USA",
            "Annual Fees (Approx. INR): ₹42,00,000 - ₹46,00,000"
        ],
        "Image": b10,
    }
]

    ;
const PlsUniversity = [
    {
        "title": "Massachusetts Institute of Technology (MIT)",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹44,00,000 - ₹48,00,000"
        ],
        "Image": p1,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Annual Fees (Approx. INR): ₹46,00,000 - ₹50,00,000"
        ],
        "Image": p2,
    },
    {
        "title": "Harvard University",
        "items": [
            "Location: Cambridge, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": p3,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹36,00,000"
        ],
        "Image": p4,
    },
    {
        "title": "University of Oxford",
        "items": [
            "Location: Oxford, UK",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹40,00,000"
        ],
        "Image": p5,
    },
    {
        "title": "California Institute of Technology (Caltech)",
        "items": [
            "Location: Pasadena, USA",
            "Annual Fees (Approx. INR): ₹48,00,000 - ₹52,00,000"
        ],
        "Image": p6,
    },
    {
        "title": "Princeton University",
        "items": [
            "Location: Princeton, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹44,00,000"
        ],
        "Image": p7,
    },
    {
        "title": "University of California, Berkeley",
        "items": [
            "Location: Berkeley, USA",
            "Annual Fees (Approx. INR): ₹42,00,000 - ₹46,00,000"
        ],
        "Image": p8,
    },
    {
        "title": "ETH Zurich - Swiss Federal Institute of Technology",
        "items": [
            "Location: Zurich, Switzerland",
            "Annual Fees (Approx. INR): ₹20,00,000 - ₹24,00,000"
        ],
        "Image": p9,
    },
    {
        "title": "University of Chicago",
        "items": [
            "Location: Chicago, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹42,00,000"
        ],
        "Image": p10,
    }
]
    ;
const FaaUniversity = [
    {
        "title": "Royal College of Art",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹25,00,000 - ₹30,00,000"
        ],
        "Image": f1,
    },
    {
        "title": "Rhode Island School of Design (RISD)",
        "items": [
            "Location: Providence, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹45,00,000"
        ],
        "Image": f2,
    },
    {
        "title": "Central Saint Martins, University of the Arts London",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹28,00,000 - ₹33,00,000"
        ],
        "Image": f3,
    },
    {
        "title": "Parsons School of Design, The New School",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹35,00,000 - ₹40,00,000"
        ],
        "Image": f4,
    },
    {
        "title": "School of the Art Institute of Chicago (SAIC)",
        "items": [
            "Location: Chicago, USA",
            "Annual Fees (Approx. INR): ₹30,00,000 - ₹35,00,000"
        ],
        "Image": f5,
    },
    {
        "title": "Pratt Institute",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹37,00,000"
        ],
        "Image": f6,
    },
    {
        "title": "University of the Arts London (UAL)",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹26,00,000 - ₹31,00,000"
        ],
        "Image": f7,
    },
    {
        "title": "Goldsmiths, University of London",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹27,00,000 - ₹32,00,000"
        ],
        "Image": f8,
    },
    {
        "title": "California Institute of the Arts (CalArts)",
        "items": [
            "Location: Valencia, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹43,00,000"
        ],
        "Image": f9,
    },
    {
        "title": "London College of Fashion, University of the Arts London",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹25,00,000 - ₹30,00,000"
        ],
        "Image": f10,
    }
]

    ;
const CJUniversity = [
    {
        "title": "University of Southern California (USC)",
        "items": [
            "Location: Los Angeles, USA",
            "Annual Fees (Approx. INR): ₹30,00,000 - ₹35,00,000"
        ],
        "Image": j1,
    },
    {
        "title": "Columbia University",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹45,00,000"
        ],
        "Image": j2,
    },
    {
        "title": "University of California, Berkeley",
        "items": [
            "Location: Berkeley, USA",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹37,00,000"
        ],
        "Image": j3,
    },
    {
        "title": "University of Pennsylvania (UPenn)",
        "items": [
            "Location: Philadelphia, USA",
            "Annual Fees (Approx. INR): ₹35,00,000 - ₹40,00,000"
        ],
        "Image": j4,
    },
    {
        "title": "Northwestern University",
        "items": [
            "Location: Evanston, USA",
            "Annual Fees (Approx. INR): ₹36,00,000 - ₹41,00,000"
        ],
        "Image": j5,
    },
    {
        "title": "London School of Economics and Political Science (LSE)",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹27,00,000 - ₹32,00,000"
        ],
        "Image": j6,
    },
    {
        "title": "New York University (NYU)",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹43,00,000"
        ],
        "Image": j7,
    },
    {
        "title": "University of Oxford",
        "items": [
            "Location: Oxford, UK",
            "Annual Fees (Approx. INR): ₹30,00,000 - ₹35,00,000"
        ],
        "Image": j8,
    },
    {
        "title": "Stanford University",
        "items": [
            "Location: Stanford, USA",
            "Annual Fees (Approx. INR): ₹42,00,000 - ₹47,00,000"
        ],
        "Image": j9,
    },
    {
        "title": "University of Cambridge",
        "items": [
            "Location: Cambridge, UK",
            "Annual Fees (Approx. INR): ₹28,00,000 - ₹33,00,000"
        ],
        "Image": j10,
    }
]

    ;
const DesigningUniversity = [

    {
        "title": "Royal College of Art",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹25,00,000 - ₹30,00,000"
        ],
        "Image": d1,
    },
    {
        "title": "Parsons School of Design, The New School",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹35,00,000 - ₹40,00,000"
        ],
        "Image": d2,
    },
    {
        "title": "Central Saint Martins, University of the Arts London",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹28,00,000 - ₹33,00,000"
        ],
        "Image": d3,
    },
    {
        "title": "Rhode Island School of Design (RISD)",
        "items": [
            "Location: Providence, USA",
            "Annual Fees (Approx. INR): ₹40,00,000 - ₹45,00,000"
        ],
        "Image": d4,
    },
    {
        "title": "Pratt Institute",
        "items": [
            "Location: New York City, USA",
            "Annual Fees (Approx. INR): ₹32,00,000 - ₹37,00,000"
        ],
        "Image": d5,
    },
    {
        "title": "School of the Art Institute of Chicago (SAIC)",
        "items": [
            "Location: Chicago, USA",
            "Annual Fees (Approx. INR): ₹30,00,000 - ₹35,00,000"
        ],
        "Image": d6,
    },
    {
        "title": "California Institute of the Arts (CalArts)",
        "items": [
            "Location: Valencia, USA",
            "Annual Fees (Approx. INR): ₹38,00,000 - ₹43,00,000"
        ],
        "Image": d7,
    },
    {
        "title": "University of the Arts London (UAL)",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹26,00,000 - ₹31,00,000"
        ],
        "Image": d8,
    },
    {
        "title": "Goldsmiths, University of London",
        "items": [
            "Location: London, UK",
            "Annual Fees (Approx. INR): ₹27,00,000 - ₹32,00,000"
        ],
        "Image": d9,
    },
    {
        "title": "Savannah College of Art and Design (SCAD)",
        "items": [
            "Location: Savannah, USA",
            "Annual Fees (Approx. INR): ₹33,00,000 - ₹38,00,000"
        ],
        "Image": d10,
    }
]

    ;


export { EngineeringUniversity, CSITUniversity, HsmUniversity, SocialUniversity, BusinessUniversity, PlsUniversity, FaaUniversity, CJUniversity, DesigningUniversity }