import { Box, Button, Typography } from "@mui/material";
import React from "react";
import MainHeader from "src/Component/content-component/MainHeader";
import calendarImg from "../../../../asset/TestPrepDashboard/calendartop.png";
import calendaricon from "../../../../asset/TestPrepDashboard/calendaricon.png";
import videoicon from "../../../../asset/TestPrepDashboard/video.png";

// Function to format date and time
const formatDateTime = (date, duration) => {
  const startTime = new Date(date);
  const endTime = new Date(startTime.getTime() + duration * 60000); // Duration in minutes

  const formattedDate = startTime.toLocaleDateString("default", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });

  const formattedStartTime = startTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });

  const formattedEndTime = endTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });

  return `${formattedDate}, ${formattedStartTime} - ${formattedEndTime}`;
};

// Function to determine if the class can be joined (15 minutes before and after the start time)
const isJoinEnabled = (date) => {
  const currentTime = new Date();
  const classTime = new Date(date);
  const fifteenMinutesBefore = new Date(classTime.getTime() - 15 * 60000);
  const fifteenMinutesAfter = new Date(classTime.getTime() + 15 * 60000);

  return (
    currentTime >= fifteenMinutesBefore && currentTime <= fifteenMinutesAfter
  );
};

const Schedule = ({ upcomingClasses, upcomingMockTestData }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", mb: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <MainHeader
          content={"Exam Date"}
          fontWeight={"500"}
          fontSize={"24px"}
        />
        <Box
          sx={{
            mt: "20px",
            width: "320px",
            height: "320px",
            border: "1px solid #B9B9B9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "186px",
              margin: "auto "
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "60px",
                backgroundImage: `url(${calendarImg})`
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                {upcomingMockTestData && upcomingMockTestData.length > 0
                  ? new Date(upcomingMockTestData[0].date).toLocaleString(
                      "default",
                      { month: "short" }
                    )
                  : "Month"}
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid #BDBDBD",
                height: "100px",
                margin: "0 3px 3px 3px",
                borderRadius: "0px 0px 10px 10px"
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "52px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                {upcomingMockTestData && upcomingMockTestData.length > 0
                  ? new Date(upcomingMockTestData[0].date).getDate()
                  : "--"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #B9B9B9",
              mt: "auto",
              height: "40px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "10px"
            }}
          >
            <img src={calendaricon} alt="" />
            <Typography
              sx={{ color: "#00999E", fontWeight: "500", pl: "15px" }}
            >
              {upcomingMockTestData && upcomingMockTestData.length > 0
                ? Math.ceil(
                    (new Date(upcomingMockTestData[0].date) - new Date()) /
                      (1000 * 60 * 60 * 24)
                  ) + " days"
                : "N/A"}
            </Typography>
            <Typography sx={{ color: "#777777", fontWeight: "500", pl: "5px" }}>
              {" "}
              to go {">>>>"}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          pl: "20px",
          flexDirection: "column"
        }}
      >
        <MainHeader
          content={"Upcoming Classes"}
          fontWeight={"500"}
          fontSize={"24px"}
        />

        <Box
          sx={{
            mt: "20px",
            width: "100%",
            height: "330px",
            border: "1px solid #B9B9B9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          {upcomingClasses && upcomingClasses.length > 0 ? (
            upcomingClasses.map((classItem) => (
              <Box
                key={classItem._id}
                sx={{
                  width: "100%",
                  height: "110px",
                  border: "1px solid #B9B9B9",
                  display: "flex",
                  flexDirection: "row",
                  padding: "0 20px",
                  alignItems: "center"
                }}
              >
                <img src={videoicon} alt="Class Icon" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 20px"
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#4C4C4C", fontWeight: "500" }}
                  >
                    {classItem.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "#838383", fontWeight: "500" }}
                  >
                    {formatDateTime(classItem.date, classItem.duration)}
                  </Typography>
                </Box>
                <Box sx={{ ml: "auto" }}>
                  <Button
                    width={"150px"}
                    fontWeight={"550"}
                    sx={{
                      bgcolor: !isJoinEnabled(classItem.date)
                        ? "grey"
                        : "#00999E",
                      color: !isJoinEnabled(classItem.date)
                        ? "#ffffff"
                        : "#ffffff"
                    }}
                    disabled={!isJoinEnabled(classItem.date)}
                    onClick={() => window.open(classItem.meetingLink, "_blank")}
                  >
                    Join
                  </Button>
                </Box>
              </Box>
            ))
          ) : (
            <Typography>No upcoming classes available.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Schedule;
