const GmatMainHeading = 
    {
        title: "GMAT",
        content: "Welcome to Our GMAT Focus Edition Preparation Hub",
    };

const IeltsMainHeading = 
    {
        title: "IELTS",
        content: "Unlock Your Global Opportunities with IELTS",
    };

const PteMainHeading = 
    {
        title: "PTE",
        content: "Your Passport to Language Proficiency and Global Opportunities.",
    }
    
const GreMainHeading = 
    {
        title: "GRE",
        content: "Elevate Your Future: Your Path to GRE Success Starts Here",
    };

const ToeflMainHeading = 
    {
        title: "TOEFL",
        content: "Unlock your potential with TOEFL success, paving the way to boundless opportunities."
        ,
    };

const SatMainHeading = 
    {
        title: "SAT",
        content: "Empowering Futures: Master the SAT and Open Doors to Success!",
    };


export { IeltsMainHeading, GmatMainHeading, PteMainHeading, GreMainHeading, ToeflMainHeading, SatMainHeading };