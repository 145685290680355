import food from "../../../asset/food.png";
import accomodation from "../../../asset/accomodation.png";
import transport from "../../../asset/transportation.png";
import Miscellaneous from "../../../asset/Miscellaneous.png";
import tution from "../../../asset/tution.svg";

const USACost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹2,42,959"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹1,00,000 - ₹2,00,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹40,000 - ₹60,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹20,000 - ₹40,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹10,000 - ₹20,000"
  }
];

const UKCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹76,526- ₹82,782"
  },
  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹40,000 - ₹60,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹25,000 - ₹35,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹15,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹7,000 - ₹10,000"
  }
];
const IrelandCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹93,750 - ₹2,18,750"
  },
  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹30,000 - ₹60,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹20,000 - ₹30,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹15,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹5,000 - ₹10,000"
  }
];
const CanadaCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹1,25,000 - ₹2,50,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹30,000 - ₹70,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹15,000 - ₹25,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹15,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹5,000 - ₹10,000"
  }
];
const GermanyCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹1,25,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹30,000 - ₹70,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹15,000 - ₹25,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹15,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹5,000 - ₹10,000"
  }
];
const AustraliaCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹1,21,670 - ₹ 2,73,750"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹50,000 - ₹1,00,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹25,000 - ₹40,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹15,000 - ₹25,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹10,000 - ₹20,000"
  }
];
const SingaporeCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹83,000 - ₹1,66,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹50,000 - ₹1,00,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹15,000 - ₹25,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹20,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹5,000 - ₹10,000"
  }
];
const ItalyCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹1,25,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹25,000 - ₹45,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹15,000 - ₹25,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹15,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹3,000 - ₹8,000"
  }
];
const FranceCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹1,25,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹30,000 - ₹50,000"
  },
  {
    title: "Food",
    image: food,
    cost: "₹20,000 - ₹30,000"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹10,000 - ₹15,000"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹5,000 - ₹10,000"
  }
];
const DubaiCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹83,000 - ₹1,70,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹59,700 - ₹1,19,400"
  },
  {
    title: "Food",
    image: food,
    cost: "₹15,900 - ₹29,800"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹9,950 - ₹19,800"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹7,950 - ₹13,900"
  }
];

const NewZealandCost = [
  {
    title: "Tuition Fees",
    image: tution,
    cost: "₹83,000 - ₹1,70,000"
  },

  {
    title: "Accomodation",
    image: accomodation,
    cost: "₹59,700 - ₹1,19,400"
  },
  {
    title: "Food",
    image: food,
    cost: "₹15,900 - ₹29,800"
  },
  {
    title: "Miscellaneous",
    image: Miscellaneous,
    cost: "₹9,950 - ₹19,800"
  },
  {
    title: "Transportation",
    image: transport,
    cost: "₹7,950 - ₹13,900"
  }
];

export {
  USACost,
  UKCost,
  IrelandCost,
  CanadaCost,
  GermanyCost,
  AustraliaCost,
  SingaporeCost,
  ItalyCost,
  FranceCost,
  DubaiCost,
  NewZealandCost,
};
