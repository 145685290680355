import React, { useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import Header from 'src/Component/content-component/Header';
import TabData from 'src/Component/card-components/TabData';
import MbbsTabData from 'src/json/mbbsTabData';
const TabButton = ({ label, selected, onClick }) => {
  return (
    <Button
      sx={{
        padding: '5px 25px',
        margin: '0 10px',
        border: 'none',
        fontSize: { lg: "22px", md: "22px", sm: "15px" },
        fontWeight: "600",
        letterSpacing: "1px",
        borderBottom: selected ? '3px solid #00999E' : 'none',
        color: selected ? '#00999E' : 'rgba(0, 0, 0, 0.3)',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        outline: 'none',
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        transition: 'all 0.3s linear'

      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const MbbsTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
  };
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#EFFDFF',
        padding: { xs: "20px 0", md: '50px 0' },
      }}
      id="ug-pg"
    >
      <Container>
        <Header
          // color="#18181B"
          textAlign="center"
          content="MBBS UG & PG in India and Abroad"
        />
        <Box
          sx={{
            // border : '1px solid red',
            width: { sm: "70%", md: "60%", lg: "60%", xs: "110%" },
            margin: '20px auto',
            padding: { md: '0px 30px', sm: '0px 20px', xs: '0px 10px' },
            display: 'flex',
            justifyContent: 'space-evenly',

            alignItems: 'center',
            flexWrap: 'wrap'

          }}
        >
          <TabButton
            label="MBBS UG"
            selected={selectedTab === 0}
            onClick={() => handleTabChange(0)}
          />
          <TabButton
            label="MBBS PG"
            selected={selectedTab === 1}
            onClick={() => handleTabChange(1)}
          />
        </Box>
        <Box
          sx={{
            width: { sm: '90%', xs: "100%", md: "70%", lg: "60%", },
            margin: '0px auto',
            display: 'flex',
            justifyContent: 'space-evenly',
            // alignItems: 'center',
            flexWrap: 'wrap',
            gap: "20px"
          }}
        >
          {selectedTab === 0 && (
            <>
              <TabData
                data={MbbsTabData?.[0]}
              />
              <TabData
                data={MbbsTabData?.[1]}
              />
            </>
          )}
          {selectedTab === 1 && (
            <>
              <TabData
                data={MbbsTabData?.[2]}
              />
              <TabData
                data={MbbsTabData?.[3]}
              />
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default MbbsTabs;
