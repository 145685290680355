import uk from "../asset/ukflag.jpg";
import canada from "../asset/canadaflag.jpg";
import nepal from "../asset/nepal.png";
import bangladesh from "../asset/bangladeshflag.png";
import russia from "../asset/russia.png";
import Philippines from "../asset/philipines.png";
import usa from "../asset/usa.png";
import Kazakhstan from "../asset/Kazakhstan.png";
import Kyrgyzstan from "../asset/KYRGYZSTAN.png";
import Uzbekistan from "../asset/UZBEKISTAN.png";
import Germany from "../asset/Germany.png"
import Australia from "../asset/Australia.jpg"
const countryData = [
  {
    title: "Russia",

    Image: russia,
    path: "/mbbs/abroad/russia"

  },
  {
    title: "Bangladesh",
    path: "/mbbs/abroad/bangladesh",


    Image: bangladesh,
  },
  {
    title: "Canada",
    path: "/mbbs/abroad/canada",

    Image: canada,
  },
  {
    title: "Germany",

    Image: Germany,
    path: "/mbbs/abroad/germany"

  },
  {
    title: "Philippines",
    path: "/mbbs/abroad/philippines",


    Image: Philippines,
  },
  {
    title: "Australia",

    Image: Australia,
    path: "/mbbs/abroad/australia"

  },
  {
    title: "Nepal",

    Image: nepal,
    path: "/mbbs/abroad/nepal"
  }, {
    title: "USA",

    Image: usa,
    path: "/mbbs/abroad/usa"

  }, {
    title: "United Kingdom",

    Image: uk,
    path: "/mbbs/abroad/uk"

  }, {
    title: "Kazakhstan",

    Image: Kazakhstan,
    path: "/mbbs/abroad/kazakhstan"

  },
  {
    title: "Kyrgyzstan",

    Image: Kyrgyzstan,
    path: "/mbbs/abroad/kyrgyzstan"

  },
  {
    title: "Uzbekistan",

    Image: Uzbekistan,
    path: "/mbbs/abroad/uzbekistan"

  },



];

export default countryData;
