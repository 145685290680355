import { Container, createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import Mission from "./Mission";
import Vision from "./Vision";
import Box from "@mui/material/Box";

const MissionVision = () => {
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920,
      },
    },
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  return (
    <Box
      sx={{
        // backgroundImage: `url("${background}")`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        opacity: 0.85,
        width: "100%",
        position: "relative",
        // margin: "20px 0",
        height: "auto",
      }}
      style={{ backgroundColor: "#def2f2", padding: "50px 0" }}
    >
      <Container maxWidth={isLargeScreen ? "xl" : "lg"}>
        <Vision />
        <Box sx={{ mt: { sm: "20px", xs: "10px" } }}>
          <Mission />
        </Box>
      </Container>
    </Box>
  );
};
export default MissionVision;
