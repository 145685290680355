




const USAFaq =
[{
    "title": "Why study in the USA?",
    "Desc": "The USA offers top-ranked universities, cutting-edge research opportunities, diverse cultural experiences, and a wide range of academic programs."
},
{
    "title": "What are the visa requirements for Indian students studying in the USA?",
    "Desc": "Indian students typically need an F-1 student visa to study in the USA, which requires a Form I-20 from a SEVP-approved institution and proof of financial support."
},
{
    "title":"What are the popular courses for Indian students in the USA?",
    "Desc":"Popular courses among Indian students in the USA include computer science, engineering, business, finance, healthcare, and STEM fields."
},
{
    "title":"How much does it cost for Indian students to study in the USA?",
    "Desc":"Tuition fees for Indian students in the USA vary widely depending on the institution and program, but they can range from $20,000 to $50,000 or more per year."
},
{
    "title":"Are there scholarships available for Indian students in the USA?",
    "Desc":"Yes, many US universities offer scholarships, grants, and assistantships for international students, including those from India, based on academic merit and other criteria."
},
{
    "title":"What is the job market like for Indian students in the USA after graduation?",
    "Desc":"The USA offers diverse job opportunities for Indian students post-graduation, especially in sectors like technology, finance, healthcare, engineering, and research."
},
{
    "title":"Is it easy for Indian students to adapt to life in the USA?",
    "Desc":"While adapting to life in the USA can pose challenges initially, Indian students generally find support through cultural organizations, student services, and a vibrant international student community."
},
{
    "title":"Can Indian students work part-time while studying in the USA?",
    "Desc":"Yes, Indian students with an F-1 visa are typically allowed to work part-time on-campus during the academic year and full-time during designated breaks, subject to certain restrictions."
},
{
    "title":"What support services are available for Indian students in the USA?",
    "Desc":"US universities provide various support services for international students, including academic advising, counseling, housing assistance, English language programs, and cultural activities."
},
{
    "title":"How can Indian students stay connected with their culture while studying in the USA?",
    "Desc":"Indian students in the USA can stay connected with their culture through student associations, cultural events, festivals, religious organizations, and local Indian communities."
}
];

const UKFaq =
[{
    "title": "Why study in the UK?",
    "Desc": "The UK offers world-renowned universities, diverse cultural experiences, and globally recognized qualifications."
},
{
    "title": "What are the visa requirements for Indian students studying in the UK?",
    "Desc": "Indian students typically need a Tier 4 (General) student visa, which requires a Confirmation of Acceptance for Studies (CAS) from a UK institution and proof of funds for tuition and living expenses."
},
{
    "title": "What are the popular courses for Indian students in the UK?",
    "Desc": "Popular courses among Indian students in the UK include business, engineering, computer science, law, and medicine."
},
{
    "title": "How much does it cost for Indian students to study in the UK?",
    "Desc": "Tuition fees for Indian students in the UK vary depending on the university and course, but they generally range from £10,000 to £30,000 per year."
},
{
    "title": "Are there scholarships available for Indian students in the UK?",
    "Desc": "Yes, many UK universities offer scholarships and financial aid opportunities specifically for Indian students based on academic merit and other criteria."
},
{
    "title": "What is the job market like for Indian students in the UK after graduation?",
    "Desc": "The UK offers various job opportunities for Indian students post-graduation, especially in sectors like finance, technology, healthcare, and engineering."
},
{
    "title": "Is it easy for Indian students to adapt to life in the UK?",
    "Desc": "With its diverse and multicultural society, Indian students generally find it relatively easy to adapt to life in the UK, although it may take some time to adjust to the climate and cultural differences."
},
{
    "title": "Can Indian students work part-time while studying in the UK?",
    "Desc": "Yes, Indian students with a Tier 4 visa are typically allowed to work part-time during term-time and full-time during vacations, subject to certain restrictions."
},
{
    "title": "What support services are available for Indian students in the UK?",
    "Desc": "UK universities offer various support services for international students, including academic support, counseling, accommodation assistance, and student societies."
},
{
    "title": "How can Indian students stay connected with their culture while studying in the UK?",
    "Desc": "Indian students in the UK can stay connected with their culture through Indian student associations, cultural events, festivals, and communities both on and off-campus."
}
];
const IrelandFaq =
[{
    "title": "Why study in Ireland?",
    "Desc": "Ireland offers high-quality education, renowned universities, vibrant culture, and a welcoming environment for international students."
},
{
    "title": "What are the popular courses for Indian students in Ireland?",
    "Desc": "Popular courses for Indian students in Ireland include business, engineering, computer science, healthcare, and hospitality."
},
{
    "title": "What are the language requirements for studying in Ireland?",
    "Desc": "Most courses in Ireland are taught in English, so Indian students are generally required to demonstrate proficiency in English through tests like IELTS or TOEFL."
},
{
    "title": "Are there scholarships available for Indian students in Ireland?",
    "Desc": "Yes, there are scholarships available for Indian students in Ireland, offered by universities, government bodies, and private organizations."
},
{
    "title": "What is the cost of living for Indian students in Ireland?",
    "Desc": "The cost of living in Ireland can vary depending on the city and lifestyle, but Indian students should budget for expenses like accommodation, food, transportation, and health insurance."
},
{
    "title": "Can Indian students work part-time while studying in Ireland?",
    "Desc": "Yes, Indian students can work part-time (up to 20 hours per week) during term time and full-time during holidays in Ireland, subject to certain restrictions."
},
{
    "title": "What are the post-study work opportunities for Indian students in Ireland?",
    "Desc": "Ireland offers post-study work opportunities through schemes like the Third Level Graduate Scheme, allowing Indian students to work in Ireland after completing their studies."
},
{
    "title": "Is it easy to get a student visa for Ireland as an Indian student?",
    "Desc": "Obtaining a student visa for Ireland as an Indian student is relatively straightforward, provided you have an offer from a recognized institution and sufficient funds to support yourself."
},
{
    "title": "What is the student life like in Ireland for Indian students?",
    "Desc": "Student life in Ireland is vibrant and multicultural, with opportunities to engage in academic, social, and cultural activities both on and off-campus."
},
{
    "title": "How can Indian students adjust to life in Ireland?",
    "Desc": "Indian students can adjust to life in Ireland by familiarizing themselves with Irish culture, connecting with other international and"
}

];
const CanadaFaq =
[
    {
    "title": "Why study in Canada?",
    "Desc": "Canada offers world-class education, diverse culture, safe environment, excellent quality of life, and ample post-graduation work opportunities for international students, including those from India."
},
{
    "title": "What are the popular courses for Indian students in Canada?",
    "Desc": "Popular courses for Indian students in Canada include computer science, engineering, business, healthcare, and environmental studies."
},
{
    "title": "What are the language requirements for studying in Canada?",
    "Desc": "Most courses in Canada are taught in English, so Indian students are generally required to demonstrate proficiency in English through tests like IELTS or TOEFL"
},
{
    "title": "Are there scholarships available for Indian students in Canada?",
    "Desc": "Yes, there are scholarships available for Indian students in Canada, offered by universities, government bodies, and private organizations."
},
{
    "title": "What is the cost of living for Indian students in Canada?",
    "Desc": "The cost of living in Canada can vary depending on the city and lifestyle, but Indian students should budget for expenses like accommodation, food, transportation, and health insurance."
},
{
    "title": "Can Indian students work part-time while studying in Canada?",
    "Desc": "Yes, Indian students can work part-time (up to 20 hours per week) during term time and full-time during holidays in Canada, subject to certain restrictions"
},
{
    "title": "What are the post-study work opportunities for Indian students in Canada?",
    "Desc": "Canada offers excellent post-graduation work opportunities through programs like the Post-Graduation Work Permit (PGWP), allowing Indian students to gain valuable work experience in Canada after completing their studies"
},
{
    "title": "Is it easy to get a student visa for Canada as an Indian student?",
    "Desc": "Obtaining a student visa for Canada as an Indian student is relatively straightforward, provided you have an offer from a recognized institution, sufficient funds, and meet other visa requirements."
},
{
    "title": "What is the student life like in Canada for Indian students?",
    "Desc": "Student life in Canada is vibrant and inclusive, with opportunities to engage in academic, social, and cultural activities both on and off-campus"
},
{
    "title": "How can Indian students adjust to life in Canada?",
    "Desc": "Indian students can adjust to life in Canada by familiarizing themselves with Canadian culture, connecting with other international and local students, exploring the country, and seeking support from university services."
}
];
const GermanyFaq =
[
    {
    "title": "Why is Germany a popular destination for Indian students?",
    "Desc": "Germany offers high-quality education, renowned universities, affordable tuition fees, and a welcoming environment for international students."
},
    {
    "title": "What are the language requirements for studying in Germany?",
    "Desc": "While many programs are offered in English, some may require proficiency in the German language. It's advisable to check the language requirements for your chosen course."
},
    {
    "title": "How much does it cost to study in Germany for Indian students?",
    "Desc": "Tuition fees in Germany are relatively low, and many public universities offer tuition-free education. However, students should budget for living expenses, including accommodation, food, and transportation."
},
    {
    "title": "Are there scholarships available for Indian students to study in Germany?",
    "Desc": "Yes, there are scholarships and funding opportunities available for Indian students, including those offered by the DAAD (German Academic Exchange Service) and other organizations."
},
    {
    "title": "What are the visa requirements for Indian students studying in Germany?",
    "Desc": "Indian students typically require a student visa to study in Germany. Requirements include proof of acceptance at a German university, financial means, health insurance, and proficiency in the German language (if applicable)"
},
    {
    "title": "Can Indian students work while studying in Germany?",
    "Desc": "Yes, Indian students can work part-time (up to 20 hours per week) during their studies in Germany. This can help offset living expenses and gain valuable work experience."
},
    {
    "title": "What are the job prospects for Indian students after studying in Germany?",
    "Desc": "Germany has a strong economy and a high demand for skilled professionals, particularly in STEM fields. Indian students with relevant qualifications and language skills may find job opportunities in various industries."
},
    {
    "title": "How is student life in Germany for Indian students?",
    "Desc": "Germany offers a vibrant and multicultural student life, with numerous social and cultural activities. Indian students can also connect with local and international communities, making the transition easier."
},
    {
    "title": "What support services are available for Indian students in Germany?",
    "Desc": "German universities typically offer support services for international students, including orientation programs, language courses, counseling services, and student organizations."
},
    {
    "title": "What advice do you have for Indian students planning to study in Germany?",
    "Desc": "Research your options thoroughly, including universities, courses, and living arrangements. Be proactive in seeking scholarships and financial aid. And most importantly, embrace the experience and make the most of your time studying abroad in Germany!"
}
];
const AustraliaFaq =
[
    {
    "title": "Why is Australia a popular destination for Indian students?",
    "Desc": "Australia is known for its high-quality education system, diverse range of courses, welcoming multicultural environment, and post-study work opportunities"
},
    {
    "title": "What are the popular fields of study for Indian students in Australia?",
    "Desc": "Popular fields of study for Indian students in Australia include business, engineering, information technology, healthcare, and hospitality."
},
    {
    "title": "What are the visa requirements for Indian students studying in Australia?",
    "Desc": "Indian students typically need to apply for a student visa (subclass 500) to study in Australia. Requirements include proof of enrollment in a registered course, financial capacity, and health insurance."
},
    {
    "title": "Are there scholarships available for Indian students studying in Australia?",
    "Desc": "Yes, many Australian universities offer scholarships specifically for international students, including those from India. These scholarships may cover tuition fees, living expenses, or both."
},
    {
    "title": "What is the cost of living for Indian students in Australia?",
    "Desc": "The cost of living in Australia varies depending on the city and lifestyle, but Indian students should budget for expenses such as accommodation, food, transportation, and entertainment."
},
    {
    "title": "Can Indian students work while studying in Australia?",
    "Desc": "Yes, Indian students with a valid student visa are generally allowed to work part-time during the academic year and full-time during scheduled breaks. However, there are limitations on the number of hours students can work."
},
    {
    "title": "What are the opportunities for Indian students after graduation from Australian universities?",
    "Desc": "Australian qualifications are recognized globally, and Indian students may have opportunities to pursue further studies, gain work experience through post-study work visas, or explore job opportunities in Australia or back home."
},
    {
    "title": "Is English proficiency required for Indian students to study in Australia?",
    "Desc": "Yes, Indian students are usually required to demonstrate English proficiency through tests such as IELTS, TOEFL, or PTE Academic, depending on the institution and course requirements."
},
    {
    "title": "What support services are available for Indian students in Australia?",
    "Desc": "Australian universities offer various support services for international students, including orientation programs, academic support, counseling services, accommodation assistance, and student clubs and societies."
},
    {
    "title": "How can Indian students adapt to the Australian education system and culture?",
    "Desc": "Indian students can adapt to the Australian education system and culture by actively engaging in campus life, participating in cultural exchange programs, making friends from diverse backgrounds, and seeking support from university staff and fellow students."
}
];
const SingaporeFaq =
[
    {
    "title": "Why is Singapore a popular destination for Indian students?",
    "Desc": "Singapore offers world-class education, a safe and cosmopolitan environment, proximity to India, English as the primary language of instruction, and opportunities for internships and employment."
},
    {
    "title": "What are the popular fields of study for Indian students in Singapore?",
    "Desc": "Popular fields of study for Indian students in Singapore include business, engineering, information technology, hospitality, and healthcare."
},
    {
    "title": "What are the visa requirements for Indian students studying in Singapore?",
    "Desc": "Indian students typically need to apply for a Student’s Pass to study in Singapore. Requirements include proof of enrollment in a recognized institution, financial capacity, and medical insurance."
},
    {
    "title": "Are there scholarships available for Indian students studying in Singapore?",
    "Desc": "Yes, many Singaporean universities and organizations offer scholarships to international students, including those from India. These scholarships may cover tuition fees, living expenses, or both."
},
    {
    "title": "What is the cost of living for Indian students in Singapore",
    "Desc": "The cost of living in Singapore is relatively high, including expenses such as accommodation, food, transportation, and miscellaneous expenses. However, Indian students may find part-time work opportunities to supplement their income."
},
    {
    "title": "Can Indian students work while studying in Singapore?",
    "Desc": "Indian students with a valid Student’s Pass are generally allowed to work part-time during term time and full-time during scheduled breaks, subject to certain restrictions and approval from the Ministry of Manpower."
},
    {
    "title": "What are the opportunities for Indian students after graduation from Singaporean universities?",
    "Desc": "Singapore offers a range of opportunities for Indian students, including further studies, internships, and employment in various sectors such as finance, technology, healthcare, and hospitality."
},
    {
    "title": "Is English proficiency required for Indian students to study in Singapore?",
    "Desc": "Yes, Indian students are usually required to demonstrate English proficiency through tests such as IELTS, TOEFL, or SAT, depending on the institution and course requirements."
},
    {
    "title": "What support services are available for Indian students in Singapore?",
    "Desc": "Singaporean universities provide support services for international students, including orientation programs, academic advising, counseling services, accommodation assistance, and student organizations."
},
    {
    "title": "How can Indian students adapt to the Singaporean education system and culture?",
    "Desc": "Indian students can adapt to the Singaporean education system and culture by familiarizing themselves with local customs and norms, participating in cultural activities and events, building relationships with local and international students, and seeking support from university staff and resources."
}
];
const ItalyFaq =
[
    {
    "title": "Why study in Italy?",
    "Desc": "Italy offers world-renowned universities, a rich cultural experience, and a diverse range of academic programs, especially in fields like art, fashion, and design"
},
    {
    "title": "What are the language requirements for studying in Italy?",
    "Desc": "While many programs are offered in English, some may require proficiency in Italian. It's beneficial to have at least a basic understanding of Italian for daily life"
},
    {
    "title": "Are there scholarships available for Indian students in Italy?",
    "Desc": "Yes, there are various scholarships offered by the Italian government, universities, and other organizations specifically for international students, including those from India."
},
    {
    "title": "What is the cost of living for Indian students in Italy?",
    "Desc": "The cost of living in Italy varies depending on the city and lifestyle. Generally, it includes accommodation, food, transportation, and other expenses. It's advisable to budget around €800-€1,200 per month."
},
    {
    "title": "How is student accommodation in Italy?",
    "Desc": "Italian universities often provide accommodation options such as student dormitories or shared apartments. Alternatively, students can also opt for private accommodation, which may vary in cost and amenities."
},
    {
    "title": "What are the visa requirements for Indian students studying in Italy?",
    "Desc": "Indian students typically need to obtain a student visa (type D visa) to study in Italy. Requirements include proof of acceptance from a recognized Italian university, financial means, and health insurance."
},
    {
    "title": "Can Indian students work while studying in Italy?",
    "Desc": "Yes, Indian students can work part-time (up to 20 hours per week) during the academic year and full-time during holidays, provided they have the necessary work permits and meet visa requirements."
},
    {
    "title": "What are the popular courses for Indian students in Italy?",
    "Desc": "Popular courses for Indian students in Italy include art and design, fashion, engineering, architecture, hospitality, business, and culinary arts."
},
    {
    "title": "How is the student life in Italy for Indian students?",
    "Desc": "Student life in Italy is vibrant and multicultural, with plenty of opportunities for socializing, exploring Italian culture, and participating in extracurricular activities."
},
    {
    "title": "What support services are available for Indian students in Italy?",
    "Desc": "Italian universities offer support services for international students, including orientation programs, academic advising, language courses, and cultural integration activities."
}
];
const FranceFaq =
[
    {
    "title": "Why study in France?",
    "Desc": "France boasts world-class universities, a rich cultural heritage, and a strong focus on research and innovation, making it an attractive destination for Indian students."
},
    {
    "title": "What are the language requirements for studying in France?",
    "Desc": "While many programs are offered in English, some may require proficiency in French. Indian students are encouraged to have at least a basic understanding of French for daily life."
},
    {
    "title": "Are there scholarships available for Indian students in France?",
    "Desc": "Yes, there are various scholarships offered by the French government, universities, and other organizations specifically for international students, including those from India."
},
    {
    "title": "What is the cost of living for Indian students in France?",
    "Desc": "The cost of living in France varies depending on the city and lifestyle. Generally, it includes accommodation, food, transportation, and other expenses. It's advisable to budget around €800-€1,200 per month."
},
    {
    "title": "How is student accommodation in France?",
    "Desc": "French universities often provide accommodation options such as student residences or shared apartments. Alternatively, students can also opt for private accommodation, which may vary in cost and amenities."
},
    {
    "title": "What are the visa requirements for Indian students studying in France?",
    "Desc": "Indian students typically need to obtain a student visa (type D visa) to study in France. Requirements include proof of acceptance from a recognized French university, financial means, and health insurance."
},
    {
    "title": "Can Indian students work while studying in France?",
    "Desc": "Yes, Indian students can work part-time (up to 20 hours per week) during the academic year and full-time during holidays, provided they have the necessary work permits and meet visa requirements."
},
    {
    "title": "What are the popular courses for Indian students in France?",
    "Desc": "Popular courses for Indian students in France include engineering, management, hospitality, fashion, design, culinary arts, and humanities."
},
    {
    "title": "How is the student life in France for Indian students?",
    "Desc": "Student life in France is dynamic and culturally diverse, with opportunities for academic excellence, cultural immersion, and social activities"
},
    {
    "title": "What support services are available for Indian students in France",
    "Desc": "French universities offer support services for international students, including orientation programs, academic counseling, language courses, and cultural integration activities"
}
];
const DubaiFaq =
[
    {
    "title": "Why should Indian students consider studying in Dubai?",
    "Desc": "Dubai offers high-quality education with globally recognized universities and colleges. It provides a multicultural environment, exposure to diverse cultures, and excellent career opportunities."
},
    {
    "title": "What are the popular fields of study for Indian students in Dubai?",
    "Desc": "Popular fields of study for Indian students in Dubai include business, engineering, information technology, hospitality management, and healthcare."
},
    {
    "title": "Is the cost of education in Dubai affordable for Indian students?",
    "Desc": "While tuition fees in Dubai can vary depending on the institution and program, many universities offer competitive rates compared to Western countries. Additionally, scholarships and financial aid options may be available for eligible students."
},
    {
    "title": "What are the visa requirements for Indian students studying in Dubai?",
    "Desc": "Indian students typically require a student visa to study in Dubai. The specific requirements and application process can vary, but generally, students need to provide proof of admission to a recognized institution, financial stability, and medical insurance."
},
    {
    "title": "Can Indian students work while studying in Dubai?",
    "Desc": "Yes, Indian students studying full-time at recognized universities in Dubai are usually allowed to work part-time during their studies. However, there are restrictions on the number of hours they can work per week, and certain types of jobs may be prohibited."
},
    {
    "title": "What are the accommodation options for Indian students in Dubai?",
    "Desc": "Accommodation options for Indian students in Dubai include university dormitories, shared apartments, and private accommodation. Many universities also offer assistance in finding suitable housing for students."
},
    {
    "title": "How is student life in Dubai for Indian students?",
    "Desc": "Student life in Dubai is vibrant and diverse, with numerous cultural and recreational activities available. Indian students will find a welcoming community and plenty of opportunities to socialize, explore the city, and engage in extracurricular activities."
},
    {
    "title": "Are there any cultural considerations Indian students should be aware of in Dubai?",
    "Desc": "Indian students should be respectful of local customs and traditions, including dress codes, religious practices, and social norms. Dubai is a cosmopolitan city, but it's important to be mindful of cultural sensitivities."
},
    {
    "title": "What are the career prospects for Indian students after studying in Dubai?",
    "Desc": "Dubai's thriving economy offers excellent career prospects for Indian students, especially in sectors such as finance, hospitality, tourism, healthcare, and engineering. Many international companies have a presence in Dubai, providing ample job opportunities for graduates."
},
    {
    "title": "How can Indian students prepare for studying abroad in Dubai?",
    "Desc": "Indian students planning to study in Dubai should research their chosen institution and program thoroughly, understand the visa requirements, arrange for accommodation, and budget for living expenses. It's also beneficial to learn about the local culture and customs to ease the transition to life in Dubai."
}
];

//  New Zealand FAQ

const NewZealandFaq = [
    {
      title: "Why study in New Zealand?",
      Desc: "New Zealand offers a world-class education system with internationally recognized degrees. The country is known for its excellent quality of life, safe environment, and welcoming culture. Indian students benefit from post-study work opportunities, lower tuition costs compared to the US or UK, and diverse study programs aligned with global industry standards.",
    },
    {
      title: "What are the eligibility requirements for admission?",
      Desc: "Eligibility typically includes a strong academic background, English proficiency (e.g., IELTS/TOEFL), and course-specific prerequisites. Undergraduate programs require 10+2 certificates, while postgraduate courses need a relevant bachelor’s degree. Some programs might request work experience or portfolios, especially in creative fields. Always check individual university criteria for clarity.",
    },
    {
      title: "How much does it cost to study in New Zealand?",
      Desc: "Tuition fees range from NZD 22,000 to NZD 35,000 per year for undergraduate courses and NZD 26,000 to NZD 40,000 for postgraduate studies. Living expenses average NZD 15,000 annually. Scholarships and part-time work options help reduce financial burdens, making education in New Zealand relatively affordable for Indian students.",
    },
    {
      title: "Can Indian students work while studying?",
      Desc: "Yes, students on a valid student visa can work up to 20 hours per week during semesters and full-time during vacations. This opportunity helps manage living expenses and gain valuable work experience. Certain courses, like research-based programs, might offer more flexible work hours.",
    },
    {
      title: "Are scholarships available for Indian students in New Zealand?",
      Desc: "Numerous scholarships are available, such as the New Zealand Excellence Awards and university-specific grants. These cater to merit-based and need-based criteria, covering partial or full tuition costs. Indian students are encouraged to apply early as scholarship competition is high, and deadlines vary by institution.",
    },
    {
      title: "How safe is New Zealand for Indian students?",
      Desc: "New Zealand is among the safest countries globally, with low crime rates and a stable political environment. Universities have dedicated international student support systems. Its multicultural society ensures a welcoming atmosphere for Indian students, reducing culture shock and providing a sense of community.",
    },
    {
      title: "What visa is required for studying in New Zealand?",
      Desc: "The Fee Paying Student Visa is required for full-time studies exceeding three months. It allows students to study at an approved institution and work part-time. The visa process involves providing proof of enrollment, financial stability, health insurance, and English proficiency. Processing takes 4-6 weeks.",
    },
    {
      title: "What are the post-study work opportunities in New Zealand?",
      Desc: "New Zealand offers a Post-Study Work Visa for up to three years, depending on the qualification level. This allows graduates to gain local work experience and even transition to residency. Industries like IT, engineering, healthcare, and agriculture have high demand for skilled workers.",
    },
    {
      title: "How do I apply to universities in New Zealand?",
      Desc: "Research preferred programs and universities, ensuring they align with career goals. Submit an online application with required documents like academic transcripts, English proficiency scores, and a Statement of Purpose (SOP). Post-acceptance, pay the fee deposit to receive an offer letter and proceed with visa application.",
    },
    {
      title:
        "How is the quality of education in New Zealand compared to other countries?",
      Desc: "New Zealand’s education system emphasizes practical learning and research. Universities consistently rank in global top 500 lists. Small class sizes ensure personalized attention, fostering critical thinking and innovation. Degrees are globally recognized, and education standards are regulated by the New Zealand Qualifications Authority (NZQA).",
    },
  ];
  


export { USAFaq, UKFaq, IrelandFaq, CanadaFaq, GermanyFaq, AustraliaFaq, SingaporeFaq, ItalyFaq, FranceFaq, DubaiFaq,NewZealandFaq};