import { Box, Container, Grid } from '@mui/material';
import React from 'react'
import Card from 'src/Component/card-components/Card';
import Cta from 'src/Component/card-components/Cta';
import Header from 'src/Component/content-component/Header';

const CourseUniversity = ({ data }) => {
    console.log("objectqwertyuiop", data?.length)
    return (

        <Box sx={{ backgroundColor: "#effdff", padding: { xs: "10px 0", md: "50px 0" } }} id="colleges">
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={"Top 10 Universities Globally"}
                        textAlign={"center"}
                    />
                </Box>

                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: 15, lg: 15 }} sx={{ display: "flex", justifyContent: "center" }}
                >
                    {data?.map((college, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    key={index}
                                    sx={{ display: "flex", justifyContent: "center" }}
                                >
                                    <Card
                                        padding={"5px 0"}
                                        data={college}
                                        borderRadius="10%"
                                        titleFontSize={"16px"}
                                        initialheight={"22%"}
                                        width="150px"
                                        height="150px"
                                        listStyle={"none"}
                                        fontSize={"14px"}
                                    />
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
                {/* <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                margin: "40px 0",
            }}
        >
            <ButtonComponent
                height={"50px"}
                width={"200px"}
                text="View more"
                icon={<Icon icon="fluent:arrow-right-28-regular" />}

            // href="/mbbs/india"
            />
        </Box> */}
                <Box sx={{ display: "flex", justifyContent: "center", mt: "30px", mb: "10px" }}>

                    <Cta />
                </Box>
            </Container>

        </Box>

    )
}

export default CourseUniversity
