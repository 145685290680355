import { Box, Container, Dialog, DialogTitle, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import ButtonComponent from 'src/Component/content-component/ButtonComponent';
import { Icon } from '@iconify/react';
import FormComponent from 'src/Component/form-component/Form';



const Universitybanner = ({ data }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <Box sx={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${data?.image}")`, backgroundSize: "cover", height: "600px", width: "100%", display: "flex", alignItems: "center" }}>

                <Container>

                    <Box sx={{}}>

                        <Typography
                            variant="h2"
                            sx={{
                                color: "#ffffff",
                                display: "inline-block",
                                fontWeight: "600",
                                fontSize: "40px",
                            }}
                        >
                            {data?.title}
                        </Typography>




                        <Box sx={{ my: { xs: 2, sm: 5 } }}>
                            <ButtonComponent
                                fontSize="20px"
                                text="Register Now"
                                onClick={handleOpenDialog}
                            />
                        </Box>
                    </Box>

                </Container>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>
                        Register Now
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
                        >
                            <Icon icon="bitcoin-icons:cross-outline" />
                        </IconButton>
                    </DialogTitle>
                    <FormComponent
                        handleCloseDialog={handleCloseDialog}
                        setOpenDialog={setOpenDialog}
                    />
                </Dialog>

            </Box>
        </>
    )
}

export default Universitybanner
