import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import forward from "src/asset/universityfinder/front.png";
import backward from "src/asset/universityfinder/back.png";

export default function CareerDialogPage({ onSelectCountry }) {
  const countries = [
    { name: "Japan", flagUrl: "https://flagcdn.com/w320/jp.png" },
    { name: "Australia", flagUrl: "https://flagcdn.com/w320/au.png" },
    { name: "Canada", flagUrl: "https://flagcdn.com/w320/ca.png" },
    { name: "Brazil", flagUrl: "https://flagcdn.com/w320/br.png" },
    { name: "France", flagUrl: "https://flagcdn.com/w320/fr.png" },
    { name: "India", flagUrl: "https://flagcdn.com/w320/in.png" },
    { name: "Italy", flagUrl: "https://flagcdn.com/w320/it.png" },
    { name: "China", flagUrl: "https://flagcdn.com/w320/cn.png" },
    { name: "Russia", flagUrl: "https://flagcdn.com/w320/ru.png" },
    { name: "South Africa", flagUrl: "https://flagcdn.com/w320/za.png" },
  ];

  const ITEMS_PER_PAGE = 9;
  const totalItems = countries.length;
  const [page, setPage] = useState(1);

  const handleAddCountry = (country) => {
    onSelectCountry(country);
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && page * ITEMS_PER_PAGE < totalItems) {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        mt: 4,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} display="flex" justifyContent="center">
          <Box
            onClick={() => console.log("Add Country Modal")}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
              width: "200px",
              backgroundColor: "#00999E",
              color: "white",
              borderRadius: "16px",
              fontWeight: "bold",
              cursor: "pointer",
              padding: "0 20px",
              fontSize: "16px",
              mt: { xs: 2, md: "60%" },
            }}
          >
            Add Country
            <ArrowForwardIosIcon sx={{ color: "white", ml: 2 }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {countries.slice(page - 1).map((country, index) => (
              <Grid item xs={6} sm={4} md={4} key={index}>
                <Box
                  onClick={() => handleAddCountry(country)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    backgroundColor: "#EFFDFF",
                    borderRadius: "16px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={country.flagUrl}
                    alt={`Flag of ${country.name}`}
                    style={{
                      width: "80px",
                      height: "60px",
                      borderRadius: "10%",
                    }}
                  />
                  <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    {country.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Box
              sx={{
                width: "80px",
                opacity: page === 1 ? 0.5 : 1,
                cursor: page === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => handlePageChange("prev")}
            >
              <img src={backward} alt="Previous" width={"100%"} />
            </Box>
            <Box
              sx={{
                width: "80px",
                opacity: page * ITEMS_PER_PAGE >= totalItems ? 0.5 : 1,
                cursor:
                  page * ITEMS_PER_PAGE >= totalItems
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => handlePageChange("next")}
            >
              <img src={forward} alt="Next" width={"100%"} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
