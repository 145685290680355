import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MainHeader from "src/Component/content-component/MainHeader";
import { Icon } from "@iconify/react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { useNavigate } from "react-router";
import axios from "axios";

const Mocktest = () => {
  const navigate = useNavigate();
  const [mockTests, setMockTests] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleButtonClickTestList = (parentId) => {
    navigate(`mocktestList/${parentId}/MockTest`);
  };
  const planId = sessionStorage.getItem("courseId");

  useEffect(() => {
    const fetchMockTests = async () => {
      const token = sessionStorage.getItem("token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}mockTest/userMockTest`,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json"
            },
            params: {
              planId: planId
            }
          }
        );
        if (response.data.success) {
          setMockTests(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching mock tests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMockTests();
  }, [planId]);

  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: "30px",
          alignItems: "center"
        }}
      >
        <Icon
          icon="heroicons:light-bulb"
          style={{ color: "#00999E", fontSize: "28px" }}
        />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Mock Test"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={3}>
          {mockTests?.map((item, index) => (
            <Grid
              item
              md={4}
              xs={12}
              key={item._id}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  width: "340px",
                  border: "1px solid #00999E",
                  borderRadius: "20px",
                  background: `linear-gradient(to bottom, 
              rgba(167, 214, 215, 0.4) 5%,   
              rgba(218, 240, 241, 0.5) 40%, 
              rgba(255, 255, 255, 1) 100%)`,
                  padding: "10px"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: "20px"
                  }}
                >
                  <Typography
                    sx={{
                      color: "#00999E",
                      fontWeight: "500",
                      fontSize: "14px"
                    }}
                  >
                    {"Date : " +
                      new Date(item.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric"
                      })}
                    
                  </Typography>
                  <Typography
                    sx={{
                      color: "#00999E",
                      fontWeight: "500",
                      fontSize: "14px"
                    }}
                  >
                    {"Time : " + item.time}
                    
                  </Typography>
                </Box>
                <MainHeader
                  content={item.name}
                  color={"#1a1a1a"}
                  fontWeight={"550"}
                  fontSize={"24px"}
                />
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    m: "20px 0"
                  }}
                >
                  <Icon
                    icon="material-symbols-light:scoreboard-rounded"
                    style={{ color: "#667085" }}
                  />
                  <Typography
                    sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                  >
                    Score
                  </Typography>
                  <Box
                    sx={{
                      height: "25px",
                      ml: "auto",
                      borderRadius: "5px",
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                    >
                      7.0{" "}
                    </Typography>
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: "20px ",
                    mb: "50px"
                  }}
                >
                  <Typography
                    sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                  >
                    Course Name
                  </Typography>
                  <Box
                    sx={{
                      height: "25px",
                      ml: "auto",
                      borderRadius: "5px",
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                    >
                      {" "}
                      {item.courseName}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: "20px ",
                    mb: "50px"
                  }}
                >
                  <Icon
                    icon="ic:outline-feedback"
                    style={{ color: "#667085" }}
                  />
                  <Typography
                    sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                  >
                    Feedback
                  </Typography>
                  <Box
                    sx={{
                      height: "25px",
                      ml: "auto",
                      borderRadius: "5px",
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                    >
                      Check{" "}
                    </Typography>
                    <Icon
                      icon="eva:arrow-forward-fill"
                      style={{ color: "#667085" }}
                    />
                  </Box>
                </Box> */}
                <ButtonComponent
                  width={"100%"}
                  onClick={() => handleButtonClickTestList(item._id)}
                  text={"Go To Test Series"}
                  icon={
                    <Icon
                      icon="eva:arrow-forward-fill"
                      style={{ color: "#fff" }}
                    />
                  }
                  fontWeight={"550"}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Mocktest;
