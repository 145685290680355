import React from "react";
import ProgressChart from "./ProgressChart";
import { Box, Grid } from "@mui/material";
import StudyAbroadCard from "./StudyAbroadCard";

const AdmissionDashboard = () => {
  return (
    <Box sx={{ margin: "40px 20px", display: "flex", flexDirection: "column" }}>
      <ProgressChart />
      <Box marginTop={"20px"}>
        <Grid container spacing={2}>
          {data?.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <StudyAbroadCard data={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AdmissionDashboard;

const data = [
  {
    title: "PERSONALIZED MENTORING",
    subtitle: "Information and usages of your current plan",
    statuses: [
      { label: "Counselling", status: "In Progress", color: "#FF914D" },
      { label: "Psychometric Test", status: "Not Taken", color: "#333333" },
      { label: "Profile Building", status: "Completed", color: "#4ADF86" }
    ]
  },
  {
    title: "Career Mapping",
    subtitle: "Information and usages of your current plan",
    statuses: [
      { label: "Countries", status: "In Progress", color: "#FF914D" },
      { label: "Courses", status: "Not Taken", color: "#333333" },
      { label: "Universities", status: "Completed", color: "#4ADF86" }
    ]
  },
  {
    title: "Education Path",
    subtitle: "Information and usages of your current plan",
    statuses: [
      { label: "Test Preparation", status: "Completed", color: "#4ADF86" },
      { label: "LORs/ essays/ sOPs", status: "In Progress", color: "#FF914D" },
      { label: "Scholarship", status: "Not Taken", color: "#333333" }
    ]
  },
  {
    title: "Skill Development",
    subtitle: "Information and usages of your current plan",
    statuses: [
      { label: "Education Loan", status: "In Progress", color: "#FF914D" },
      {
        label: "VISA/IMMIGRATION/TRAVEL & FOREX",
        status: "Completed",
        color: "#4ADF86"
      },
      { label: "ACCOMMODATION", status: "Not Taken", color: "#333333" }
    ]
  }
];
