import { Box, Button, Card, MenuList, Typography } from '@mui/material'
import React from 'react'
import Header from '../content-component/Header'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router'

const TabData = ({ data }) => {
    const navigate = useNavigate()
    return (
        <>
            <Card
                sx={
                    {
                        width: "300px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "10px 20px",
                        borderTop: "3px solid #00999E"
                    }
                }
            >
                <Box
                    sx={{
                        margin: "30px 0"
                    }}
                >
                    <Header
                        fontSize="20px"
                        textAlign="center"
                        content={data.title}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <MenuList>
                        {
                            data.items?.map((item, index) => {
                                return <>
                                    <Box key={index} sx={{ pr: 2, display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <Box
                                            sx={{
                                                width: "35px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <Icon icon="material-symbols-light:check"
                                                style={{ fontSize: '35px', color: '#00999E' }}
                                            />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                wordBreak: 'break-word',
                                                wordWrap: 'wrap',
                                                mr: 2
                                            }}
                                        >
                                            {item}
                                        </Typography>
                                    </Box>
                                </>
                            })
                        }
                    </MenuList>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: "auto",
                        marginBottom: "10px"
                    }}
                >

                    <Button
                        sx={{
                            color: "#000000",
                            fontSize: "16px",
                            width: "80%",
                            height: "40px",
                            fontWeight: "200",
                            textTransform: "capitalize",
                            textAlign: "center",
                            borderRadius: "8px",
                            transition: "all 0.3s linear",
                            border: "2px solid #000000",
                            '&:hover': {
                                backgroundColor: "#00999E",
                                color: "#fff",
                                border: "2px solid #00999E",
                            },
                        }}
                        onClick={() => navigate("/contact")}
                    >

                        Get started
                    </Button>
                </Box>
            </Card>
        </>
    )
}

export default TabData