import { Box } from "@mui/material";
import React from "react";
// import { useNavigate } from 'react-router';
import Footer from "src/Component/Footer";
import Navbar from "src/Component/Navbar";
import StudyAbroadCountry from "./StudyAbroadMainComponents/StudyAbroadCountry";
import CountryIntro from "./StudyAbroadMainComponents/CountryIntro";
import StudyAbroadBanner from "./StudyAbroadMainComponents/StudyAbroadBanner";
import { Helmet } from "react-helmet";

const StudyAbroad = () => {
  // const navigate = useNavigate();
  // const handleNavigation = (path) => {
  //   window.scrollTo(0, 0);
  //   navigate(path);
  // };
  return (
    <>
      <Helmet>
        <title>Taksheela Institute: Your Guide to Studying Abroad</title>
        <meta
          name="description"
          content="Explore the best study abroad programs with Taksheela Institute. Benefit from expert counseling, comprehensive information, and dedicated admission support."
        />
        <meta
          name="keywords"
          content="study abroad opportunities in russia, student abroad programs, international study abroad, universities overseas"
        />
      </Helmet>
      <Box>
        <Navbar />
        <StudyAbroadBanner />
        <CountryIntro />

        <Box sx={{ mb: "40px" }}>
          <StudyAbroadCountry />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default StudyAbroad;
