import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import registerimg from "../../asset/universityfinder/registerImg.png";
import MainHeader from "src/Component/content-component/MainHeader";
import FormComponent from "src/Component/form-component/Form";
import CountrySelect from "./countrySelect";
import SelectionBox from "./SelectionBox";
import RangeSelectionBox from "./RangeSelectionBox";
import DropdownSelectionBox from "./DropdownSelectionBox";
import StateSelect from "./stateSelect";
import axios from "axios";

export const RegisterYourself = ({ filters, setFilters, isFormValid }) => (
  <Box sx={{ padding: "20px" }}>
    <Container>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { md: "600px", xs: "320" }, position: "relative" }}>
            <img
              src={registerimg}
              alt="Register"
              width={"100%"}
              style={{ position: "relative" }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { sm: "320px", xs: "220px" },
                top: "0",
                right: { lg: "0px", md: "-13px", sm: "0px", xs: "-42px" },
                textAlign: "center",
              }}
            >
              <MainHeader content={"Welcome"} />
              <Typography
                sx={{
                  fontSize: { sm: "28px", xs: "11px" },
                  fontWeight: "550",
                  color: "#606060",
                }}
              >
                To Study Abroad Journey
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "320px",
              p: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
            }}
          >
            <MainHeader
              content={"Start Your Journey"}
              color={"#000"}
              textAlign={"center"}
              fontSize={"24px"}
            />
            {/* <FormComponent buttonText="Submit" /> */}
            <FormComponent
              buttonText="Next"
              filters={filters}
              setFilters={setFilters}
              isFormValid={isFormValid}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export const Location = ({ filters, setFilters }) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    filters.fieldofInterestValues
  );
  const [selectedCountrie, setSelectedCountrie] = useState(filters.country);

  const handleSelect = (value) => {
    // Ensure value is not null or undefined
    if (!value || value.length === 0) {
      setSelectedValue([]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        fieldofInterest: [],
        fieldofInterestValues: [],
      }));
      return;
    }

    // Match selected values with options
    const selectedCourse = options.filter((option) =>
      value.includes(option.name)
    );

    if (selectedCourse.length > 0) {
      setSelectedValue(selectedCourse.map((course) => course.name)); // Display selected names

      setFilters((prevFilters) => ({
        ...prevFilters,
        fieldofInterest: selectedCourse.map((course) => course._id), // Update with IDs
        fieldofInterestValues: selectedCourse.map((course) => course.name), // Keep names for display
      }));
    }
  };

  const [locationsData, setLocationsData] = useState([]);

  useEffect(() => {
    const fetchLocatins = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}university/courseList`
        );
        if (response.data.success && response.data.status === 200) {
          setLocationsData(response.data.data.formattedData);
          setOptions(response.data.data.formattedData);
          const storedSelectedLocations = JSON.parse(
            localStorage.getItem("selectedLocations")
          );
          if (storedSelectedLocations) {
            setSelectedValue(storedSelectedLocations);
            setFilters((prev) => ({
              ...prev,
              fieldofInterest: storedSelectedLocations,
            }));
          }
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchLocatins();
  }, [setFilters]);

  const handleCard = (countryId) => {
    setSelectedCountrie((prevSelected) => {
      const isSelected = prevSelected.includes(countryId);
      if (isSelected) {
        // Remove country if already selected
        return prevSelected.filter((id) => id !== countryId);
      } else {
        // Add country if not already selected
        return [...prevSelected, countryId];
      }
    });

    setFilters((prev) => ({
      ...prev,
      country: selectedCountrie.includes(countryId)
        ? selectedCountrie.filter((id) => id !== countryId)
        : [...selectedCountrie, countryId],
    }));
  };

  useEffect(() => {
    console.log("filters =>", filters);
  }, [filters]);

  return (
    <>
      <div>
        <Container>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box>
                <DropdownSelectionBox
                  sx={{ p: "0 80px" }}
                  label="Choose Your Field Of Interest?"
                  options={options.map((option) => option.name)}
                  selectedValues={selectedValue}
                  onChange={handleSelect}
                />
              </Box>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                background:
                  "linear-gradient(to right, rgba(167, 214, 215, 0.6) 10%, rgba(218, 240, 241, 0.5) 60%, rgba(255, 255, 255, 1) 100%)",
                border: "2px solid #00999E",
                borderRadius: 4,
                padding: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  justifyContent: {
                    lg: "flex-start",
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: { md: "500px", sm: "320px", xs: "180px" },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { sm: "20px", xs: "20px" },
                        fontWeight: "550",
                        // color: "#000",
                        mb: "10px",
                      }}
                    >
                      Where Do You Plan To Study?{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CountrySelect
                  selectedCountries={selectedCountrie}
                  handleCardClick={handleCard}
                  setFilters={setFilters}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export const ProgramAndPreferredYear = ({ filters, setFilters }) => {
  const [pursue, setPursue] = useState(filters.pursue || "");
  const [year, setYear] = useState(filters.year || []);
  const [intake, setIntake] = useState(filters.intake || []);
  const [duration, setDuration] = useState(filters.duration || []);

  const handleSelectPursue = (value) => {
    setPursue(value);
    setFilters((prevFilters) => ({ ...prevFilters, pursue: value }));
  };

  const handleSelectYear = (values) => {
    setYear(values);
    setFilters((prevFilters) => ({ ...prevFilters, year: values }));
  };

  const handleSelectIntake = (values) => {
    setIntake(values);
    setFilters((prevFilters) => ({ ...prevFilters, intake: values }));
  };

  const handleSelectDuration = (values) => {
    setDuration(values);
    setFilters((prevFilters) => ({ ...prevFilters, duration: values }));
  };

  console.log(filters);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box p={3} borderRadius={2}>
          {/* Single selection for Pursue */}
          <SelectionBox
            label="What Are You Planning To Pursue?"
            options={[
              "Undergraduate",
              "Graduate",
              "PHD",
              "Certificate Program",
            ]}
            gridsizesmall={12}
            selectedValue={pursue}
            onSelect={handleSelectPursue}
          />

          {/* Multiple selections for Year */}
          <SelectionBox
            label="What Is Your Preferred Year?"
            options={["2024", "2025", "2026", "2027", "2028", "2029"]}
            selectedValue={year}
            multiple
            gridsizemedium={4}
            onSelect={handleSelectYear}
          />

          {/* Multiple selections for Intake */}
          <SelectionBox
            label="What Is Your Preferred Intake?"
            options={["Fall", "Spring", "Summer", "Winter"]}
            selectedValue={intake}
            multiple
            gridsizesmall={12}
            onSelect={handleSelectIntake}
          />

          {/* Multiple selections for Duration */}
          <SelectionBox
            label="What Is Your Preferred Duration?"
            options={[
              "less than 1 Year",
              "1-2 year",
              "3-4 year",
              "more than 4 year",
            ]}
            selectedValue={duration}
            multiple
            gridsizesmall={12}
            onSelect={handleSelectDuration}
          />
        </Box>
      </Container>
    </Box>
  );
};

export const FieldOfStudy = ({ filters, setFilters }) => {
  const [sliderValue, setSliderValue] = useState(filters.tutionfee || 0);
  const [selectedValue, setSelectedValue] = useState(
    filters.fieldofInterest || ""
  );

  const [scholarship, setscholarship] = useState(filters.scholarship || "");

  const [options, setOptions] = useState([]);

  const handleSelectScholarship = (value) => {
    setscholarship(value);
    setFilters((prevFilters) => ({ ...prevFilters, scholarship: value }));
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setFilters((prevFilters) => ({ ...prevFilters, tutionfee: newValue }));
  };

  return (
    <Box
      sx={{
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box p={3} borderRadius={2}>
          <SelectionBox
            label="Scholarship Availability"
            options={[
              "Full Scholarships",
              "Partial Scholarships",
              "No Scholarships",
            ]}
            gridsizesmall={12}
            gridsizemedium={12}
            width={"100%"}
            selectedValue={scholarship}
            onSelect={handleSelectScholarship}
          />
          <RangeSelectionBox
            label="Tuition Fee (Optional)"
            value={sliderValue}
            onChange={handleSliderChange}
          />
        </Box>
      </Container>
    </Box>
  );
};

export const AdmissionRequirements = ({ filters, setFilters }) => {
  const [admission, setadmission] = useState(filters.admission || "");
  console.log("filters.admission", filters.admission);

  const [qualification, setqualification] = useState(
    filters.qualification || ""
  );

  const handleSelectadmission = (values) => {
    setadmission(values);
    setFilters((prevFilters) => ({ ...prevFilters, admission: values }));
  };

  const handleSelectQualification = (value) => {
    setqualification(value);
    setFilters((prevFilters) => ({ ...prevFilters, qualification: value }));
  };

  return (
    <Box
      sx={{
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box p={3} borderRadius={2}>
          <SelectionBox
            label="Admission Requirement"
            options={["PTE", "IELTS", "TOEFL", "SAT", "GRE"]}
            selectedValue={admission}
            multiple
            gridsizesmall={12}
            onSelect={handleSelectadmission}
          />
          <SelectionBox
            label="Highest Qualification"
            options={[
              "Higher Secondary",
              "Undergraduate",
              "Graduate",
              "Certificate Program",
            ]}
            gridsizesmall={12}
            selectedValue={qualification}
            onSelect={handleSelectQualification}
          />
        </Box>
      </Container>
    </Box>
  );
};

//Required
// export const Scholarship = ({ filters, setFilters }) => {
//   const [scholarship, setscholarship] = useState(filters.scholarship || "");
//   const [language, setlanguage] = useState(filters.language || "");

//   const handleSelectScholarship = (value) => {
//     setscholarship(value);
//     setFilters((prevFilters) => ({ ...prevFilters, scholarship: value }));
//   };

//   const handleSelectLanguage = (value) => {
//     setlanguage(value);
//     setFilters((prevFilters) => ({ ...prevFilters, language: value }));
//   };

//   return (
//     <Box
//       sx={{
//         // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center"
//       }}
//     >
//       <Container>
//         <Box p={3} borderRadius={2}>
//           {/* <SelectionBox
//             label="Scholarship Availability"
//             options={[
//               "Full Scholarships",
//               "Partial Scholarships",
//               "No Scholarships"
//             ]}
//             gridsizesmall={12}
//             gridsizemedium={12}
//             width={"100%"}
//             selectedValue={scholarship}
//             onSelect={handleSelectScholarship}
//           /> */}
//           {/* <SelectionBox
//             label="Language Of Instruction"
//             options={["English", "Other"]}
//             width={"120px"}
//             selectedValue={language}
//             onSelect={handleSelectLanguage}
//           /> */}
//         </Box>
//       </Container>
//     </Box>
//   );
// };
