const USAPost =
    [
        {
            Field: "Computer Science and Engineering",
            Salary: "₹15,00,000 - ₹25,00,000"
        },
        {
            Field: "Business Administration and Management",
            Salary: "₹12,00,000 - ₹20,00,000"
        },
        {
            Field: "Health Sciences and Medicine",
            Salary: "₹20,00,000 - ₹35,00,000"
        },
        {
            Field: "Economics and Finance",
            Salary: "₹18,00,000 - ₹30,00,000"
        },
        {
            Field: "STEM Fields (Science, Technology, Engineering, Mathematics)",
            Salary: "₹16,00,000 - ₹28,00,000"
        },


    ];

const UKPost =
    [
        {
            Field: "Computer Science and Engineering",
            Salary: "₹1,500,000 - ₹3,000,000"
        },
        {
            Field: "Business Administration and Management",
            Salary: "₹1,200,000 - ₹2,500,000"
        },
        {
            Field: "Health Sciences and Medicine",
            Salary: "₹2,500,000 - ₹5,000,000"
        },
        {
            Field: "Economics and Finance",
            Salary: "₹1,800,000 - ₹3,500,000"
        },
        {
            Field: "STEM Fields (Science, Technology, Engineering, Mathematics)",
            Salary: "₹1,600,000 - ₹3,200,000"
        },
        {
            Field: "Law",
            Salary: "₹1,800,000 - ₹3,500,000"
        },
        {
            Field: "Creative Arts and Design",
            Salary: "₹1,000,000 - ₹2,000,000"
        },
        {
            Field: "Literature",
            Salary: "₹800,000 - ₹1,500,000"
        },


    ];
const IrelandPost =
    [
        {
            Field: "Computer Science and Engineering",
            Salary: "₹1,200,000 - ₹1,800,000"
        },
        {
            Field: "Business Administration and Management",
            Salary: "₹1,000,000 - ₹1,500,000"
        },
        {
            Field: "Health Sciences and Medicine",
            Salary: "₹1,500,000 - ₹2,500,000"
        },
        {
            Field: "Economics and Finance",
            Salary: "₹1,400,000 - ₹2,000,000"
        },
        {
            Field: "STEM Fields",
            Salary: "₹1,200,000 - ₹2,000,000"
        },


    ];
const CanadaPost =
    [{
        Field: "Computer Science and Engineering",
        Salary: "₹44,65,600"
    },
    {
        Field: "Business Administration and Management",
        Salary: "₹39,06,600"
    },
    {
        Field: "Health Sciences and Medicine",
        Salary: "₹50,59,500"
    },
    {
        Field: "Economics and Finance",
        Salary: "₹47,44,250"
    },
    {
        Field: "STEM Fields",
        Salary: "₹47,44,250"
    },


    ];
const GermanyPost =
    [
    {
        Field: "Computer Science and Engineering",
        Salary: "30,00,000 - 45,00,000"
    },
    {
        Field: "Business Administration and Management",
        Salary: "25,00,000 - 40,00,000"
    },
    {
        Field: "Health Sciences and Medicine",
        Salary: "40,00,000 - 60,00,000"
    },
    {
        Field: "Economics and Finance",
        Salary: "35,00,000 - 50,00,000"
    },
    {
        Field: "STEM Fields",
        Salary: "35,00,000 - 50,00,000"
    },
    {
        Field: "Law",
        Salary: "30,00,000 - 45,00,000"
    },
    {
        Field: "Creative Arts and Design",
        Salary: "25,00,000 - 40,00,000"
    },


    ];
const AustraliaPost =
    [ {
        Field: "Computer Science and Engineering",
        Salary: "₹1,500,000 - ₹2,500,000"
    },
        {
        Field: "Business Administration and Management",
        Salary: "₹1,200,000 - ₹2,000,000"
    },
        {
        Field: "Health Sciences and Medicine",
        Salary: "₹2,000,000 - ₹3,500,000"
    },
        {
        Field: "Economics and Finance",
        Salary: "₹1,800,000 - ₹3,000,000"
    },
        {
        Field: "STEM Fields (Science, Technology, Engineering, Mathematics)",
        Salary: "₹1,600,000 - ₹2,800,000"
    },


    ];
const SingaporePost =
    [ {
        Field: "Computer Science and Engineering",
        Salary: "₹1,200,000 - ₹2,400,000"
    },
        {
        Field: "Business Administration and Management",
        Salary: "₹800,000 - ₹1,600,000"
    },
        {
        Field: "Health Sciences and Medicine",
        Salary: "₹1,600,000 - ₹3,200,000"
    },
        {
        Field: "Economics and Finance",
        Salary: "₹1,400,000 - ₹2,800,000"
    },
        {
        Field: "STEM Fields (Science, Technology, Engineering, Mathematics)",
        Salary: "₹1,000,000 - ₹2,000,000"
    },


    ];
const ItalyPost =
    [ {
        Field: "Computer Science and Engineering",
        Salary: "₹ 21,00,000"
    },
        {
        Field: "Business Administration and Management",
        Salary: "₹ 18,50,000"
    },
        {
        Field: "Health Sciences and Medicine",
        Salary: "₹ 27,50,000"
    },
        {
        Field: "Economics and Finance",
        Salary: "₹ 24,00,000"
    },
        {
        Field: "STEM Fields",
        Salary: "₹ 22,50,000"
    },


    ];
const FrancePost =
    [ {
        Field: "Computer Science and Engineering",
        Salary: "₹1,200,000"
    },
        {
        Field: "Business Administration and Management",
        Salary: "₹1,500,000"
    },
        {
        Field: "Health Sciences and Medicine",
        Salary: "₹2,000,000"
    },
        {
        Field: "Economics and Finance",
        Salary: "₹1,800,000"
    },
        {
        Field: "STEM Fields (Science, Technology, Engineering, Mathematics)",
        Salary: "₹1,400,000"
    },
        {
        Field: "Law",
        Salary: "₹1,600,000"
    },
        {
        Field: "Creative Arts and Design",
        Salary: "₹1,000,000"
    },
        {
        Field: "Literature",
        Salary: "₹900,000"
    },


    ];
const DubaiPost =
    [ {
        Field: "Computer Science and Engineering",
        Salary: "800,000 - 1,500,000"
    },
        {
        Field: "Business Administration and Management",
        Salary: "600,000 - 1,200,000"
    },
        {
        Field: "Health Sciences and Medicine",
        Salary: "800,000 - 1,800,000"
    },
        {
        Field: "Economics and Finance",
        Salary: "700,000 - 1,400,000"
    },
        {
        Field: "STEM Fields",
        Salary: "900,000 - 1,600,000"
    },


    ];

    const NewZealandPost =
    [ {
        Field: "Computer Science and Engineering",
        Salary: "800,000 - 1,500,000"
    },
        {
        Field: "Business Administration and Management",
        Salary: "600,000 - 1,200,000"
    },
        {
        Field: "Health Sciences and Medicine",
        Salary: "800,000 - 1,800,000"
    },
        {
        Field: "Economics and Finance",
        Salary: "700,000 - 1,400,000"
    },
        {
        Field: "STEM Fields",
        Salary: "900,000 - 1,600,000"
    },


    ];




export { USAPost, UKPost, IrelandPost, CanadaPost, GermanyPost, AustraliaPost, SingaporePost, ItalyPost, FrancePost, DubaiPost,NewZealandPost };