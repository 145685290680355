
import { Box, Container, Grid } from "@mui/material";
import PackageCard from "src/Component/card-components/PackageCard";
import Header from "src/Component/content-component/Header";
const TestPackages = ({ PackageData, setItemUpdate, itemupdate }) => {
    return (
        <>
            <Box> <Box
                sx={{
                    width: '100%',
                    backgroundColor: '#ffffff',
                    padding: { xs: "20px 0", md: '50px 0' },
                }}
                id="ug-pg"
            >
                <Container>
                    <Box
                        sx={{ display: "flex", justifyContent: "center", margin: { xs: "10px 0", md: "20px 0" } }}>

                        <Header
                            // color="#18181B"
                            textAlign="center"
                            content="Test Packages"

                        />
                    </Box>


                    <Grid container spacing={3} justifyContent={"center"}>
                        {PackageData?.map((country, index) => {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        key={index}
                                        sx={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <PackageCard
                                            data={country}

                                            setItemUpdate={setItemUpdate} itemupdate={itemupdate}
                                        />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>

                </Container>
            </Box></Box>
        </>
    );
};
export default TestPackages;
