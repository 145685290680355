import React from 'react'
import Header from 'src/Component/content-component/Header'
import ParaData from 'src/Component/content-component/ParaData'
import ContentContainer from 'src/Component/grid-component/ContentContainer'
import ImageContainer from 'src/Component/grid-component/ImageContainer'
import MainContainer from 'src/Component/grid-component/MainContainer'

const CourseIntro = ({ data }) => {
    return (
        <>
            <MainContainer
                dir="column-reverse"
                color={"#ffffff"}
                com1={<ImageContainer img={data?.image} />}
                com2={
                    <ContentContainer
                        Header={<Header content={data?.title} />}
                        Content={
                            <ParaData
                                color={"#525560"}
                                fontSize={"16px"}
                                content={
                                    data?.content
                                }
                            />
                        }

                    />
                }
            />
        </>
    )
}

export default CourseIntro
