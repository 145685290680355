import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import RuleCard from "src/Component/card-components/RuleCard";
import Header from "src/Component/content-component/Header";
import {RulesData} from "../../src/json/rules"
const Rules = () => {
  const [content, setContent] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  console.log(selectedIndex, 'selectedIndex')
  return (
    <>
      <Box
        sx={{
          padding: "50px 0",
          display: "flex",
          justifyContent: "center",
        }}
        id="mbbsRules"
        minHeight={600}
      >
        <Container maxWidth="lg">
          <Header
            textAlign="center"
            content="NMC Rules for Practising in India post MBBS Abroad"
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#00999E",
              textAlign: "center",
            }}
          >
            Here are the general rules by the National Medical Commission (NMC)
          </Typography>
          <Grid container spacing={6} mt={1} justifyContent={"center"}>
            {RulesData?.map((item, index) => (
              <Grid item key={index} position={"relative"} height={{ lg: "500px", md: "500px", sm: "520px", xs: "auto" }}>
                <RuleCard
                  data={item}
                  key={index}
                  setContent={setContent}
                  setSelectedIndex={setSelectedIndex}
                  index={index}
                />
                {(content && index === selectedIndex) && (
                  <Box
                    sx={{
                      width: { lg: "430px", md: "380px", sm: "350px", xs: "auto" },
                      position: { lg: "absolute", md: "absolute", sm: "absolute" },
                      top: { lg: "345px", md: "345px", sm: "345px" },
                      left: { lg: "-30%", md: "-10%", sm: "-20%", xs: "0%" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      backgroundColor: "#ffffff",
                      boxShadow: "0 0 10px #c7dbe6",
                      borderRadius: "5px"
                    }}
                  >
                    <Box
                      sx={{ height: "auto", padding: "10px", color: "#00999E" }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "11.5px",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                        }}
                      >
                        {item?.content}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>

        </Container>
      </Box>
    </>
  );
};

export default Rules;
