import { Icon } from '@iconify/react'
import { Box, Container, Dialog, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import ButtonComponent from 'src/Component/content-component/ButtonComponent'
import Header from 'src/Component/content-component/Header'
import FormComponent from 'src/Component/form-component/Form'

const CourseSnapshot = ({ data }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <Box sx={{ bgcolor: "#ffffff", padding: "40px 0" }}>
                <Container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 5,
                            marginTop: 5
                        }}
                    >
                        <Header content={data?.heading} textAlign={"center"} />
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        columns={{ xs: 12, md: 15, }}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        {
                            data?.item?.map((item) => (
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    sx={{ display: "flex", justifyContent: "center" }}
                                >
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <Box
                                            sx={{
                                                width: "150px",
                                                height: "150px",
                                                bgcolor: "#ffffff",
                                                borderRadius: "50%",
                                                border: "3px solid #00999E",
                                                boxShadow: " rgba(100, 100, 111, 0.2) 7px 7px 29px 0px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Box sx={{ width: "180px", padding: "10px 12px" }}>
                                                <Typography
                                                    variant="h6"
                                                    textAlign={"center"}
                                                    sx={{
                                                        fontSize: "15px",

                                                        fontWeight: "550",
                                                        color: "#00999E"
                                                    }}
                                                >
                                                    {item?.count}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ padding: "10px 0", display: "flex", justifyContent: "center", height: "140px", width: "300px" }}>
                                            <Typography
                                                variant="body2"
                                                textAlign={"center"}
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "#000"
                                                }}
                                            >
                                                {item?.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Box sx={{ mt: { xs: 2, sm: 8, textAlign: 'center' } }}>
                        <ButtonComponent
                            fontSize="20px"
                            text="Register Now"
                            onClick={handleOpenDialog}
                        />
                    </Box>
                </Container>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>
                        Register Now
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{ position: "absolute", right: 8, top: 8, color: "inherit" }}
                        >
                            <Icon icon="bitcoin-icons:cross-outline" />
                        </IconButton>
                    </DialogTitle>
                    <FormComponent
                        handleCloseDialog={handleCloseDialog}
                        setOpenDialog={setOpenDialog}
                    />
                </Dialog>
            </Box>
        </>
    )
}

export default CourseSnapshot
