const CaliforniaInfo = {
  name: "University of California, Berkeley",
  location: "Berkeley, California, USA",
  type: "Public Research University",
  enrollment: "Approximately 46000",
  indian: "Over 500",
  students: "Approximately 43,000",
  url: "https://www.berkeley.edu"
};
const WisconsinInfo = {
  name: "University of Wisconsin-Madison",
  location: "Madison, Wisconsin, USA",
  type: "Public Research University",
  enrollment: "48,557",
  indian: "Over 500",
  students: "27,527",
  url: "https://www.wisc.edu"
};
const YeshivaInfo = {
  name: "Yeshiva University",
  location: "New York City, New York, USA",
  type: "Private Research University",
  enrollment: "7800",
  indian: "NA",
  students: "6,500",
  url: "http://www.yu.edu"
};
const UtahInfo = {
  name: "Southern Utah University (SUU)",
  location: "Cedar City, Utah",
  type: "Public University",
  enrollment: "15,000",
  indian: "50+",
  students: "14,330",
  url: "https://www.suu.edu"
};
const ColoradoInfo = {
  name: "Colorado State University",
  location: "Fort Collins, Colorado",
  type: "Public",
  enrollment: "35,000",
  indian: "100+",
  students: "33,364",
  url: "http://www.colostate.edu"
};

const IllinoisInfo = {
  name: "The University of Illinois at Urbana-Champaign",
  location: "Urbana-Champaign, United States of America.",
  type: "Public",
  enrollment: "48,000",
  indian: "3,000+",
  students: "48,000",
  url: "http://www.illinois.edu"
};
const StanfordInfo = {
  name: "Stanford University",
  location: "Stanford, United States of America.",
  type: "Public",
  enrollment: "1,500",
  indian: "1,500+",
  students: "1,500",
  url: "http://www.stanford.edu"
};
const PennsylvaniaInfo = {
  name: "University of Pennsylvania",
  location: "Pennsylvania, United States of America.",
  type: "Public",
  enrollment: " 25,000",
  indian: "1,500",
  students: " 25,000",
  url: "http://www.pennsylvania.edu"
};
const ChicagoInfo = {
  name: "The University of Chicago",
  location: "Chicago, United States of America.",
  type: "Public",
  enrollment: " 16,000",
  indian: "1,200+",
  students: " 16,000",
  url: "http://www.chicago.edu"
};
const MITInfo = {
  name: "The Massachusetts Institute of Technology (MIT)",
  location: "MIT, United States of America.",
  type: "Public",
  enrollment: " 16,000",
  indian: "1,200+",
  students: " 16,000",
  url: "http://www.mit.edu"
};

//UK
const BristolInfo = {
  name: "University of Bristol",
  location: "Bristol, England",
  type: "Public Research University",
  enrollment: "27,000",
  indian: "500-600",
  students: "27,000",
  url: "https://www.bristol.ac.uk/"
};
const BirminghamInfo = {
  name: "University of Birmingham",
  location: "Birmingham, England",
  type: "Public Research University",
  enrollment: "34,000",
  indian: "1,700",
  students: "34,000",
  url: "https://www.birmingham.ac.uk"
};
const ImperialInfo = {
  name: "Imperial College London",
  location: "London, United Kingdom",
  type: "Public",
  enrollment: "19,000",
  indian: "1000",
  students: "19,000",
  url: "https://www.imperial.ac.uk"
};

const QueenMaryInfo = {
  name: " Queen Mary University of London",
  location: "London, England",
  type: "Public",
  enrollment: "32,000",
  indian: "800",
  students: "32,000",
  url: "https://www.qmul.ac.uk"
};
const LiverpoolInfo = {
  name: " University of Liverpool",
  location: "Liverpool, England",
  type: "Public",
  enrollment: "27,000",
  indian: "600",
  students: "27,000",
  url: "https://www.liverpool.ac.uk"
};
const EdinburghInfo = {
  name: " University of Edinburgh",
  location: "Edinburgh, United Kingdom",
  type: "Public",
  enrollment: "40,000",
  indian: "2,000",
  students: "40,000",
  url: "https://www.edinburgh.ac.uk"
};
const SouthamptonInfo = {
  name: " University of Southampton",
  location: "Southampton, United Kingdom",
  type: "Public",
  enrollment: "24,000",
  indian: "2,000",
  students: "24,000",
  url: "https://www.southampton.ac.uk"
};
const DurhamInfo = {
  name: " Durham University",
  location: "Durham, United Kingdom",
  type: "Public",
  enrollment: "18,000",
  indian: "1000",
  students: "18,000",
  url: "https://www.durham.ac.uk"
};
const GlasgowInfo = {
  name: "University of Glasgow",
  location: "Glasgow, Scotland",
  type: "Public Research University",
  enrollment: "35,000",
  indian: "900+",
  students: "35,000+",
  url: "https://www.gla.ac.uk"
};
const LeedsInfo = {
  name: "University of Leeds",
  location: "Leeds, England",
  type: "Public",
  enrollment: "34,000",
  indian: "may vary Annually",
  students: "34,000",
  url: "https://www.leeds.ac.uk/"
};
const UNSWInfo = {
  name: "University of New South Wales",
  location: "Sydney, Australia",
  founded: 1949,
  type: "Public Research University",
  enrollment: "Approximately 63,000",
  indian: "Over 12,000 (approx.)",
  students: "Approximately 63,000",
  url: "https://www.unsw.edu.au/"
};
const MonashInfo = {
  name: "Monash University",
  location: "Melbourne, Australia",
  founded: 1958,
  type: "Public Research University",
  enrollment: "Approximately 80,000 students ",
  indian: "Around 12,000 ",
  students: "Approximately 80,000 students",
  url: "https://www.monash.edu/"
};
const QueenslandInfo = {
  name: "University of Queensland",
  location: "Brisbane, Australia",
  founded: 1909,
  type: "Public Research University",
  enrollment: "Approximately 50,000",
  indian: "Approximately 9,000",
  students: "Approximately 60,000",
  url: "https://www.uq.edu.au/"
};
const UWAInfo = {
  name: "University of Western Australia",
  location: "Perth, Australia",
  founded: 1911,
  type: "Public Research University",
  enrollment: "Approximately 25,000",
  indian: "Approximately 5,000",
  students: "Approximately 30,000",
  url: "https://www.uwa.edu.au/"
};
const AdelaideInfo = {
  name: "University of Adelaide",
  location: "Adelaide, South Australia",
  founded: 1874,
  type: "Public Research University",
  enrollment: "Approximately 27,000",
  indian: "Approximately 5,000",
  students: "Approximately 30,000",
  url: "https://www.adelaide.edu.au/"
};
const MelbourneInfo = {
  name: "University of Melbourne",
  location: "Melbourne, Australia",
  founded: 1853,
  type: "Public University",
  enrollment: "Approximately 50,000",
  indian: "Over 5,000 (approx.)",
  students: "Approximately 50,000",
  url: "https://www.unimelb.edu.au/"
};
const SydneyInfo = {
  name: "University of Sydney",
  location: "Sydney, Australia",
  founded: 1850,
  type: "Public University",
  enrollment: "Approximately 45,000",
  indian: "Around 7,000",
  students: "Approximately 45,000",
  url: "https://www.sydney.edu.au/"
};
const ANUInfo = {
  name: "Australian National University",
  location: "Canberra, Australia",
  founded: 1946,
  type: "Public Research University",
  enrollment: "Approximately 20,000",
  indian: "Around 3,000",
  students: "Approximately 20,000",
  url: "https://www.anu.edu.au/"
};
const UTSInfo = {
  name: "University of Technology Sydney",
  location: "Sydney, Australia",
  founded: 1988,
  type: "Public University",
  enrollment: "47,000",
  indian: "12,000",
  students: "47,000",
  url: "https://www.uts.edu.au/"
};
const MacquarieInfo = {
  name: "Macquarie University",
  location: "Sydney, Australia",
  founded: 1964,
  type: "Public Research University",
  enrollment: "40,000",
  indian: "7,000",
  students: "40,000",
  url: "https://www.mq.edu.au/"
};
const TrentInfo = {
  name: "Trent University",
  location: "Peterborough, Ontario, Canada",
  founded: 1964,
  type: "Public Liberal Arts and Science University",
  enrollment: "10,000+",
  indian: "Approximately 300+",
  students: "10,000+",
  url: "https://www.trentu.ca/"
};
const TrinityInfo = {
  name: "Trinity Western University (TWU)",
  location: "Langley, British Columbia, Canada",
  founded: 1962,
  type: "Private Christian University",
  enrollment: "Approximately 5,000 students",
  indian: "Approximately 100+",
  students: "5,000+",
  url: "https://www.twu.ca/"
};
const RRUInfo = {
  name: "Royal Roads University (RRU)",
  location: "Victoria, British Columbia, Canada",
  founded: 1995,
  type: "Public University",
  enrollment: "5,300+ students",
  indian: "Approximately 200+",
  students: "5,300+",
  url: "https://www.royalroads.ca/"
};
const WaterlooInfo = {
  name: "University of Waterloo",
  location: "Waterloo, Ontario, Canada",
  founded: 1957,
  type: "Public Research University",
  enrollment: "40,000+ students",
  indian: "Approximately 4,000+",
  students: "40,000+",
  url: "https://www.uwaterloo.ca/"
};
const CalgaryInfo = {
  name: "University of Calgary Continuing Education",
  location: "Calgary, Alberta, Canada",
  founded: "1945 ",
  type: "Public Institution",
  enrollment: "20,000+ ",
  indian: "Not specifically available",
  students: "20,000+ annually ",
  url: "https://conted.ucalgary.ca/"
};
const DalhousieInfo = {
  name: "Dalhousie University",
  location: "Halifax, Nova Scotia, Canada",
  founded: 1818,
  type: "Public",
  enrollment: "Approximately 19,000",
  indian: "Over 1,500",
  students: "Approximately 19,000",
  url: "www.dal.ca"
};
const BrockInfo = {
  name: "Brock University",
  location: "St. Catharines, Ontario, Canada",
  founded: 1964,
  type: "Public University",
  enrollment: "Approximately 19,000",
  indian: "Approximately 1,500",
  students: "Approximately 19,000",
  url: "https://brocku.ca/"
};
const WindsorInfo = {
  name: "University of Windsor",
  location: "Windsor, Ontario, Canada",
  founded: 1857,
  type: "Public",
  enrollment: "16,000+",
  indian: "Over 1,000",
  students: "16,000+",
  url: "www.uwindsor.ca"
};
const TorontoInfo = {
  name: "University of Toronto",
  location: "Toronto, Ontario, Canada",
  founded: 1827,
  type: "Public Research University",
  enrollment: "Approximately 90,000",
  indian: "Approximately 6,000",
  students: "Approximately 90,000",
  url: "https://www.utoronto.ca/"
};
const VCCInfo = {
  name: "Vancouver Community College (VCC)",
  location: "Vancouver, British Columbia, Canada",
  founded: 1965,
  type: "Public",
  enrollment: "Approx. 14,000 students ",
  indian: "Specific numbers not  available",
  students: "Over 14,000 students",
  url: "https://www.vcc.ca/"
};

//Germany

const LMUInfo = {
  name: "Ludwig Maximilian University of Munich (LMU)",
  location: "Munich, Germany",
  founded: 1472,
  type: "Public University",
  enrollment: "Approximately 50,000",
  indian: "Around 1,500",
  students: "Approximately 50,000",
  url: "https://www.lmu.de/"
};

const TUMInfo = {
  name: "Technical University of Munich (TUM)",
  location: "Munich, Germany",
  founded: 1868,
  type: "Public University",
  enrollment: "Approximately 45,000",
  indian: "Around 2,500",
  students: "Approximately 45,000",
  url: "https://www.tum.de/"
};

const HeidelbergUniversityInfo = {
  name: "Heidelberg University",
  location: "Heidelberg, Germany",
  founded: 1386,
  type: "Public University",
  enrollment: "Approximately 30,000",
  indian: "Around 1000",
  students: "Approximately 30,000",
  url: "https://www.uni-heidelberg.de/"
};

const  HumboldtUniversityInfo = {
  name: "Humboldt University of Berlin",
  location: "Berlin, Germany",
  founded: 1810,
  type: "Public University",
  enrollment: "Approximately 34,000",
  indian: "Around 1,500",
  students: "Approximately 34,000",
  url: "https://www.hu-berlin.de/"
};

const  RWTHInfo = {
  name: "RWTH Aachen University",
  location: "Aachen, Germany",
  founded: 1870,
  type: "Public University",
  enrollment: "Approximately 47,000",
  indian: "Around 2,500",
  students: "Approximately 47,000",
  url: "https://www.rwth-aachen.de/"
};

const  FUBInfo = {
  name: "Freie Universität Berlin",
  location: "Berlin, Germany",
  founded: 1948,
  type: "Public research university",
  enrollment: "Approximately 36,000",
  indian: "Around 2,500",
  students: "Approximately 36,000",
  url: "https:// fu-berlin.de/"
};

const  UOFInfo = {
  name: "University of Freiburg",
  location: "Freiburg im Breisgau, Germany",
  founded: 1457,
  type: "Public research university",
  enrollment: "Approximately 25,000 students",
  indian: "Varies, typically several hundred",
  students: "Approximately 25,000",
  url: "https://uni-freiburg.de/"
};

const  UOTInfo = {
  name: "University of Tübingen",
  location: "Tübingen, Baden-Württemberg, Germany",
  founded: 1477,
  type: "Public research university",
  enrollment: "Approximately 28,000 students",
  indian: "Varies, typically several hundred",
  students: "Approximately 28,000",
  url: "https://uni-tuebingen.de/"
};

const  UOMInfo = {
  name: "University of Mannheim",
  location: "Mannheim, Germany",
  founded: 1967,
  type: "Public research university",
  enrollment: "Approximately 13,000 students",
  indian: "Varies, typically several hundred",
  students: "Approximately 13,000",
  url: "https://www.uni-mannheim.de/en/"
};

const  UOGInfo = {
  name: "University of Göttingen",
  location: " Göttingen, Germany",
  founded: 1734,
  type: "Public research university",
  enrollment: "Approximately 42,000 students",
  indian: "Varies, typically several hundred",
  students: "Approximately 42,000",
  url: "https://uni-goettingen.de/"
};

//France

const UPSLInfo = {
  name: "Université PSL (Paris Sciences et Lettres)",
  location: "Paris, France",
  founded: 2010,
  type: "Public University",
  enrollment: "Approximately 28,000 students",
  indian: "Around 1,000 (approximate)",
  students: "Approximately 28,000",
  url: "http://www.psl.eu"
};

const UPSInfo = {
  name: "Université Paris-Saclay",
  location: "Saclay, France (near Paris)",
  founded: 2014,
  type: "Public Research University",
  enrollment: "Approximately 20,000 students",
  indian: "Around 1,200 (approximate)",
  students: "Approximately 20,000",
  url: "http://www.universite-paris-saclay.fr"
};

const SUInfo = {
  name: "Sorbonne University",
  location: "Paris, France",
  founded: 2018,
  type: "Public Research University",
  enrollment: "Approximately 30,000 students",
  indian: "Around 1,500 (approximate)",
  students: "Approximately 30,000",
  url: "https://www.sorbonne-universite.fr"
};
const UGAInfo = {
  name: "Université Grenoble Alpes (UGA)",
  location: "Grenoble, France",
  founded: 1339,
  type: "Public University (Research-focused)",
  enrollment: "Approximately 45,000 students",
  indian: "Estimated to be around 1,000+",
  students: "Around 45,000 students (Undergraduate, Graduate, and PhD)",
   url: "https://www.univ-grenoble-alpes.fr"
};
const UDSInfo = {
  name: "Université de Strasbourg",
  location: "Strasbourg, France",
  founded: 1538,
  type: "Public University (Research-focused)",
  enrollment: "Approximately 55,000 students",
  indian: "Estimated to be around 1,500+",
  students: "Around 55,000 students (Undergraduate, Graduate, and PhD)",
  url: "https://www.unistra.fr",
};
const UDMInfo = {
  name: "Université de Montpellier",
  location: "Montpellier, France",
  founded: 1289,
  type: "Public University",
  enrollment: "Over 40,000 students",
  indian: "Approximately 1,000 (varies each year)",
  students: "Around 40,000+",
  url: "https://www.umontpellier.fr",
};

const UDLInfo = {
  name: "Université de Lille",
  location: "Lille, France",
  founded: 1560,
  type: "Public University",
  enrollment: "Over 70,000 students",
  indian: "Approximately 2,000 (varies each year)",
  students: "Around 70,000+",
  url: "https://www.univ-lille.fr",
};

const UDNInfo = {
  name: "Université de Nantes",
  location: "Nantes, France",
  founded: 1460,
  type: "Public University",
  enrollment: "Over 40,000 students",
  indian: "Approximately 1,000 (varies each year)",
  students: "Around 40,000+",
  url: "https://www.univ-nantes.fr",
};

const UDBInfo = {
  name: "Université de Bordeaux",
  location: "Bordeaux, France",
  founded: 1441,
  type: "Public University",
  enrollment: "Over 52,000 students",
  indian: "Approximately 1,500-2,000 (varies each year)",
  students: "Around 52,000+",
  url: "https://www.u-bordeaux.fr",
};
const UPPSInfo = {
  name: "Université Paris 1 Panthéon-Sorbonne",
  location: "Paris, France",
  founded: 1970,
  type: "Public University",
  enrollment: "Over 40,000 students",
  indian: "Approximately 500-1,000 (varies each year)",
  students: "Around 40,000+",
  url: "https://www.pantheonsorbonne.fr",
};

//Italy

const PDMilanoiInfo = {
  name: "Politecnico di Milano",
  location: "Milan, Italy",
  founded: 1863,
  type: "Public Technical University",
  enrollment: "Approximately 46,000 students",
  indian: "Around 3,000 (varies yearly)",
  students: "Approximately 46,000",
  url: "https://www.polimi.it",
};

const UOMilanInfo = {
  name: "University of Milan",
  location: "Milan, Italy",
  founded: 1924,
  type: "Public University",
  enrollment: "Approximately 60,000 students",
  indian: "Around 1,500 (varies yearly)",
  students: "Approximately 60,000",
  url: "https://www.unimi.it",
};

const UniboInfo = {
  name: "University of Bologna",
  location: "Bologna, Italy",
  founded: 1088,
  type: "Public University",
  enrollment: "Approximately 85,000 students",
  indian: "Around 3,000 (varies yearly)",
  students: "Approximately 85,000 students",
  url: "https://www.unibo.it",
};

const UOFlorenceInfo = {
  name: "University of Florence",
  location: "Florence, Italy",
  founded: 1321,
  type: "Public University",
  enrollment: "Approximately 50,000",
  indian: "~1,500",
  students: "Approximately 50,000",
  url: "https://www.unifi.it",
};

const UOPaduaInfo = {
  name: "University of Padua",
  location: "Padua, Italy",
  founded: 1222,
  type: "Public University",
  enrollment: "Approximately 50,000",
  indian: "~1,500",
  students: "Approximately 50,000",
  url: "https://www.unipd.it/en/",
};

const UOTurinInfo = {
  name: "University of Turin",
  location: "Turin, Italy",
  founded: 1404,
  type: "Public University",
  enrollment: "Approximately 70,000 students",
  indian: "Varies, growing community",
  students: "About 70,000",
  url: "https://www.unito.it",
};


const RLSInfo = {
  name: "University of Rome La Sapienza",
  location: "Rome, Italy",
  founded: 1303,
  type: "Public University",
  enrollment: "Approximately 100,000",
  indian: "Around 1,500",
  students: "Approximately 100,000",
  url: "https://www.uniroma1.it",
};

const UONaplesInfo = {
  name: "University of Naples Federico II",
  location: "Naples, Italy",
  founded: 1224,
  type: "Public University",
  enrollment: "Approximately 60,000",
  indian: "Around 1,200",
  students: "Approximately 60,000",
  url: "https://www.unina.it",
};

const UOPisaInfo = {
  name: "University of Pisa",
  location: "Pisa, Italy",
  founded: 1343,
  type: "Public University",
  enrollment: "Approximately 50,000",
  indian: "Around 1,000",
  students: "Approximately 50,000",
  url: "https://www.unipi.it",
};

const IEDInfo = {
  name: "Instituto Europeo di Design (IED)",
  location: "Milan, Italy",
  founded: 1966,
  type: "Private Institution",
  enrollment: "Approximately 3,500",
  indian: "Varies (approximately 100-150)",
  students: "Approximately 3,500",
  url: "https://www.ied.edu",
};

//Ireland

const TCDublinInfo = {
  name: "Trinity College Dublin (TCD)",
  location: "Dublin, Ireland",
  founded: 1592,
  type: "Public University",
  enrollment: "Approximately 17,000 students",
  indian: "Over 1,200 (as of the latest data)",
  students: "Around 18,000 students",
  url: "https://www.tcd.ie",
};

const UCDublinInfo = {
  name: "University College Dublin (UCD)",
  location: "Dublin, Ireland",
  founded: 1854,
  type: "Public University",
  enrollment: "Approximately 30,000 students",
  indian: "Over 2,000 students (approx.)",
  students: "Around 30,000 students",
  url: "https://www.ucd.ie",
};

const UOCorkInfo = {
  name: "University College Cork (UCC)",
  location: "Cork, Ireland",
  founded: 1845,
  type: "Public Research University",
  enrollment: "Approximately 23,000 students",
  indian: "Around 300+",
  students: "Around 23,000 students",
  url: "https://www.ucc.ie",
};

const UOGalwayInfo = {
  name: "University of Galway",
  location: "Galway, Ireland",
  founded: 1845,
  type: "Public Research University",
  enrollment: "Approximately 18,000 students",
  indian: "Around 500+",
  students: "Over 18,000 students",
  url: "https://www.universityofgalway.ie",
};

const DCUInfo = {
  name: "Dublin City University (DCU)",
  location: "Dublin, Ireland",
  founded: 1980,
  type: "Public University",
  enrollment: "Approximately 18,000 students",
  indianStudents: "Around 500+",
  totalStudents: "Over 18,000 students",
  url: "https://www.dcu.ie",
};

const UOLimerickInfo = {
  name: "University of Limerick (UL)",
  location: "Limerick, Ireland",
  founded: 1972,
  type: "Public University",
  enrollment: "Approximately 16,000 students",
  indian: "Around 700+",
  students: "Over 16,000 students",
  url: "https://www.ul.ie",
};


const MUInfo = {
  name: "Maynooth University",
  location: "Maynooth, County Kildare, Ireland",
  founded: 1997,  // Merger of three institutes of technology
  type: "Public",
  enrollment: " Approximately 15,180 students",
  indian: " 70 Indian postgraduate students",
  students: "Includes undergraduate and graduate programs",
  url: "https://www.maynoothuniversity.ie/",
};

const TUDublinInfo = {
  name: "Technological University Dublin (TU Dublin)",
  location: "Dublin, Ireland",
  founded: 2019,  // Merger of three institutes of technology
  type: "Public University",
  enrollment: "Approximately 28,000 students",
  indian: "Around 1,500+",
  students: "Over 28,000 (including international students)",
  url: "https://www.tudublin.ie",
};

const DBSInfo = {
  name: "Dublin Business School (DBS)",
  location: "Dublin, Ireland",
  founded: 1975,
  type: "Independent College",
  enrollment: "Approximately 9,000 students",
  indian: "Estimated 1,000+",
  students: "9,000 (including international students from 70+ countries)",
  url: "https://www.dbs.ie",
};

const RCSInfo = {
  name: "Royal College of Surgeons in Ireland (RCSI)",
  location: "Dublin, Ireland",
  founded: 1784,
  type: "Medical University/College",
  enrollment: "Over 4,000 students",
  indian: "Estimated 300-400",
  students: "Approximately 4,000 students",
  url: "https://www.rcsi.com",
};


 //New-Zealand

const UoAInfo = {
  name: "University of Auckland",
  location: "Auckland, New Zealand",
  founded: 1883,
  type: "Public University",
  enrollment: "Approximately 42,000 students",
  indian: "Around 5,000 (estimated)",
  students: "42,000+ students",
  url: "https://www.auckland.ac.nz/",
};

const MasseyInfo = {
  name: "Massey University",
  location:
    "Albany (Auckland) New Zealand",
  founded: 1927,
  type: "Public University",
  enrollment: "Approximately 30,000 students",
  indian: "Around 1,000 (estimated)",
  students:
    "Approximately 30,000 students",
  url: "https://www.massey.ac.nz/",
};

const WaikatoInfo = {
  name: "University of Waikato",
  location: "Hamilton, New Zealand",
  founded: 1964,
  type: "Public University",
  enrollment: "Approximately 12,000 students",
  indian: "Around 1,000 (estimated)",
  students:
    "Approximately 12,000 students",
  url: "https://www.waikato.ac.nz/",
};

const VUWInfo = {
  name: "Victoria University of Wellington",
  location: "Wellington, New Zealand",
  founded: 1897,
  type: "Public University",
  enrollment: "Approximately 22,000 students",
  indian: "Around 1,000 (estimated)",
  students:
    "Approximately 22,000 students",
  url: "https://www.wgtn.ac.nz/",
};

const CanterburyInfo = {
  name: "University of Canterbury",
  location: "Christchurch, New Zealand",
  founded: 1873,
  type: "Public University",
  enrollment: "Approximately 15,000 students",
  indian: "Around 1,000 (estimate)",
  students:
    "Approximately 15,000 students",
  url: "https://www.canterbury.ac.nz/",
};
const OtagoInfo = {
  name: "University of Otago",
  location: "Dunedin, New Zealand",
  founded: 1869,
  type: "Public University",
  enrollment: "Approximately 20,000 students",
  indian: "Around 1,500 (estimate)",
  students:
    "Approximately 20,000 students",
  url: "https://www.otago.ac.nz/",
};

const AUTInfo = {
  name: "Auckland University of Technology (AUT)",
  location: "Auckland, New Zealand",
  founded: 2000,
  type: "Public University",
  enrollment: "Approximately 29,000 students",
  indian: "Around 3,000 (estimate)",
  students:
    "Approximately 29,000 students",
  url: "https://www.aut.ac.nz/",
};

const LincolnInfo = {
  name: "Lincoln University",
  location: "Lincoln, New Zealand",
  founded: 1878,
  type: "Public University",
  enrollment: "Approximately 3,000 students",
  indian: "Around 400-500 (estimate)",
  students:
    "Approximately 3,000 students",
  url: "https://www.lincoln.ac.nz/",
};

const EITInfo = {
  name: "Eastern Institute of Technology (EIT), Te Pūkenga",
  location: "Hawke's Bay, New Zealand",
  founded: 1978,
  type: "Public Institute (part of Te Pūkenga)",
  enrollment: "Approximately 4,000 students",
  indian: "Estimated 200-300 (varies annually)",
  students:
    "Approximately 4,000 students",
  url: "https://www.eit.ac.nz/",
};

const UCICInfo = {
  name: "UC International College (UCIC)",
  location: "Christchurch, New Zealand",
  founded: 2009,
  type: "Pathway College (affiliated with the University of Canterbury)",
  enrollment: "Approximately 1,000 students",
  indian: "Estimated 100-200 (varies annually)",
  students:
    "Approximately 1,000 students",
  url: "https://www.ucic.ac.nz/",
};



//Singapur

const NUSInfo = {
  name: "National University of Singapore (NUS)",
  location: "Singapore",
  founded: 1905,
  type: "Public Research University",
  enrollment: "Approximately 40,000 students",
  indian: "Around 5,000 (as of recent data; may vary)",
  students: "Approximately 40,000",
  url: "https://www.nus.edu.sg",
};
const NTUInfo = {
  name: "Nanyang Technological University (NTU)",
  location: "Singapore",
  founded: 1991,
  type: "Public Research University",
  enrollment: "Over 33,000 students",
  indian: "Around 6,000",
  students: "33,000+",
  url: "https://www.ntu.edu.sg",
};
const SUTDInfo = {
  name: "Singapore University of Technology and Design (SUTD)",
  location: "8 Somapah Road, Singapore 487372",
  founded: 2009,
  type: "Public University",
  enrollment: "Approximately 2,200 students",
  indian: "Not officially disclosed, but SUTD has a growing international student population",
  students: "Over 2,000 students",
  url: "https://www.sutd.edu.sg",
};
const SMInfo = {
  name: "Singapore Management University (SMU)",
  location: "81 Victoria Street, Singapore 188065",
  founded: 2000,
  type: "Private University",
  enrollment: "Approximately 8,000 students",
  indian: "Exact numbers not publicly disclosed, but Indian students make up a significant portion of the international student body",
  students: "Around 8,000 students",
  url: "https://www.smu.edu.sg",
};
const JCInfo = {
  name: "James Cook University (JCU)",
  location: "Townsville and Cairns (Australia), and Singapore",
  founded: 1970,
  type: "Public University",
  enrollment: "Approximately 21,000 students",
  indian: "Not explicitly available, but Indian students form a significant portion of the international cohort",
  students: "21,000 students",
  url: "https://www.jcu.edu.au",
};
const MDISInfo = {
  name: "Management Development Institute of Singapore (MDIS)",
  location: "Singapore",
  founded: 1956,
  type: "Private, Not-for-profit",
  enrollment: "Approximately 9,000 students",
  indian: "Around 2,000",
  students: "9,000 students",
  url: "https://www.mdis.edu.sg",
};
const SIMGEInfo = {
  name: "SIM Global Education (SIM GE)",
  location: "Singapore",
  founded: 2006,
  type: "Private Educational Institution",
  enrollment: "16,000+ students",
  indian: "Approximately 25% of the student body",
  students: "Over 16,000",
  url: "https://www.simge.edu.sg",
};


const RCOHInfo = {
  name: "Raffles College of Higher Education",
  location: "Singapore",
  founded: "1990",
  type: "Private Institution",
  enrollment: "Approximately 2,000 students",
  indian: "Varies annually",
  students: " 2,000+",
  url: " https://raffles-college.edu.sg/",
};

const ERAInfo = {
  name: "Embry-Riddle Aeronautical University Asia",
  location: "Singapore",
  founded: "1926 (main campus in the U.S.); Asia campus established in 2011",
  type: "Private University",
  enrollment: "Approximately 33,500  students",
  indian: "Data not specifically available; a growing number due to aviation interest",
  students: "500+ at the Singapore campus",
  url: "https://asia.erau.edu/",
};
const CSInfo = {
  name: "Curtin Singapore",
  location: "Singapore",
  founded: 2008,
  type: "Public University",
  enrollment: "Approximately 3,000 students",
  indian: "Approximately 25-30% of the student population",
  students: "Around 3,000 students, including international students",
  url: "https://www.curtin.edu.sg",
};





//UAE
const CUDInfo = {
  name: "Canadian University Dubai",
  location: "Dubai, United Arab Emirates",
  founded: 2006,
  type: "Private University",
  enrollment: "Over 1,000 students",
  indian: "Significant Indian student presence.",
  students: "Approximately 1,300+ students",
  url: "https://www.cud.ac.ae",
};

const AjmanInfo = {
  name: "Ajman University",
  country: "Ajman, United Arab Emirates",
  founded : 1998,
  type : 'Private University',
  enrollment : 'Approximately 7,000 students',
  indian : 'Around 800+',
  students : 'Over 7,000',
  url : 'https://www.ajman.ac.ae',
};

const AbuDhabiInfo = {
  name: "Abu Dhabi University",
  location: "Abu Dhabi, United Arab Emirates",
  founded: 2003,
  type: "Private",
  enrollment: "Over 8,000 students",
  indian: "Approximately 2,000 (varies by year)",
  students: "8,000+ students",
  url: "https://www.adu.ac.ae",
};
const AURAKInfo = {
  name: "(AURAK)",
  location: "Ras Al Khaimah, United Arab Emirates (UAE)",
  founded: 2009,
  type: "Private University",
  enrollment: "Approximately 2,500 students",
  indian: "Approximately 20-30% of the student",
  students: "Around 2,500 students",
  url: "https://www.aurak.ac.ae",
};
const UAEUInfo = {
  name: "United Arab Emirates University",
  location: "Al Ain, Abu Dhabi, United Arab Emirates",
  founded: 1976,
  type: "Public University",
  enrollment: "Approx. 14,000 students",
  indian: "Approximately 2,000-3,000 students",
  students: "Around 14,000 students",
  url: "https://www.uaeu.ac.ae",
};
const KhalifaInfo = {
  name: "Khalifa University of Science and Technology",
  location: "Abu Dhabi, United Arab Emirates",
  founded: 2007,
  type: "Public University",
  enrollment: "Approximately 1,100+ students",
  indian: "Significant Indian presence.",
  students: "Around 1,100+ total enrollment",
  url: "https://www.ku.ac.ae",
};
const AUSInfo = {
  name: "American University of Sharjah (AUS)",
  location: "Sharjah, United Arab Emirates",
  founded: 1997,
  type: "Private, Liberal Arts University",
  enrollment: "Approximately 5,000 students",
  indian: "Over 1,000 Indian students (approximate)",
  students: "Around 5,000 students",
  url: "https://www.aus.edu",
};

const ALAinInfo = {
  name: "Al Ain University (AAU)",
  location: "Al Ain, United Arab Emirates (UAE)",
  founded: 2004,
  type: "Private University",
  enrollment: "Approx. 5,000 students",
  indian: "Varies",
  students: "5,000+ students",
  url: "https://www.aau.ac.ae",
};
const ZayedUniversityInfo = {
  name: "Zayed University",
  location: "Abu Dhabi, United Arab Emirates",
  founded: 1998,
  type: "Public University",
  enrollment: "Around 4,000 students",
  indian: "Approximately 400",
  students: "4,000+ students",
  url: "https://www.zu.ac.ae",
};
const UniversityOfDubaiInfo = {
  name: "University of Dubai",
  location: "Dubai, United Arab Emirates",
  founded: 1997,
  type: "Private University",
  enrollment: "Approximately 2,500 students",
  indian: "Around 15-20% of the total student",
  url: "https://www.ud.ac.ae",
};



export {
  MelbourneInfo,
  CaliforniaInfo,
  WisconsinInfo,
  YeshivaInfo,
  UtahInfo,
  ColoradoInfo,
  IllinoisInfo,
  StanfordInfo,
  PennsylvaniaInfo,
  ChicagoInfo,
  MITInfo,

  LeedsInfo,
  BristolInfo,
  BirminghamInfo,
  GlasgowInfo,
  ImperialInfo,
  QueenMaryInfo,
  LiverpoolInfo,
  EdinburghInfo,
  SouthamptonInfo,
  DurhamInfo,

  UNSWInfo,
  MonashInfo,
  QueenslandInfo,
  AdelaideInfo,
  SydneyInfo,
  UTSInfo,
  ANUInfo,
  MacquarieInfo,
  UWAInfo,
  TrentInfo,
  TrinityInfo,
  TorontoInfo,
  VCCInfo,
  WaterlooInfo,
  DalhousieInfo,
  CalgaryInfo,
  WindsorInfo,
  RRUInfo,
  BrockInfo,
  
  //Germany
  LMUInfo,
  TUMInfo,
  HeidelbergUniversityInfo,
  HumboldtUniversityInfo,
  FUBInfo,
  RWTHInfo,
  UOFInfo,
  UOTInfo,
  UOMInfo,
  UOGInfo,

   //france

   UPSLInfo,
   UPSInfo,
   SUInfo,
   UGAInfo,
   UDSInfo,
   UDLInfo,
   UDMInfo,
   UDNInfo,
   UDBInfo,
   UPPSInfo,

   //Italy

   PDMilanoiInfo,
   UOMilanInfo,
   UniboInfo,
   UOFlorenceInfo,
   UOPaduaInfo,
   UOTurinInfo,
   RLSInfo,
   UONaplesInfo,
   UOPisaInfo,
   IEDInfo,

//Ireland

TCDublinInfo,
UCDublinInfo,
UOCorkInfo,
UOGalwayInfo,
DCUInfo,
UOLimerickInfo,
MUInfo,
TUDublinInfo,
DBSInfo,
RCSInfo,

 //New-Zealand
 UoAInfo,
 MasseyInfo,
 WaikatoInfo,
 VUWInfo,
 CanterburyInfo,
 OtagoInfo,
 AUTInfo,
 LincolnInfo,
 EITInfo,
 UCICInfo,

 // Singapor
NUSInfo,
NTUInfo,
SUTDInfo,
SMInfo,
JCInfo,
MDISInfo,
SIMGEInfo,
RCOHInfo,
ERAInfo,
CSInfo,



 // UAE
CUDInfo,
AjmanInfo,
AbuDhabiInfo,
AURAKInfo,
UAEUInfo,
KhalifaInfo,
AUSInfo,
ALAinInfo,
ZayedUniversityInfo,
UniversityOfDubaiInfo,

};
