import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios"; // Import axios

import Navbar from "src/Component/Navbar";
import UniversityFinderBanner from "./components/UniversityFinderBanner";
import LetsGetStarted from "src/Component/LetsGetStarted";
import Footer from "src/Component/Footer";

const UniversityComparison = () => {
  const [universities, setUniversities] = useState([]); // State to store university data
  const location = useLocation();

  // Function to fetch comparison data from API
  const fetchComparisonData = async (ids) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}university/compareUniveristy`,
        { ids: ids.split(",") }, // Send array of university IDs
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.status === 200) {
        setUniversities(response.data.data); // Store the data in state
      } else {
        alert("Failed to retrieve comparison data.");
      }
    } catch (error) {
      console.error("Error fetching comparison data:", error);
    }
  };

  useEffect(() => {
    // Get IDs from the URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const ids = searchParams.get("ids");

    if (ids) {
      // Fetch comparison data
      fetchComparisonData(ids);
    }
  }, [location]);

  return (
    <Box>
      <Navbar />
      <UniversityFinderBanner />
      <Box sx={{ padding: { xs: 2, md: 4 } }}>
        <Container>
          <Typography
            variant="h5"
            sx={{
              mb: "40px",
              fontWeight: "550",
              textAlign: { xs: "center", md: "left" }
            }} // Center text on small screens
            gutterBottom
          >
            Top University for you
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ border: "2px solid #00999E" }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{ bgcolor: "#ffffff", borderBottom: "2px solid #00999E" }}
                >
                  <TableCell sx={{ fontWeight: "550" }}>
                    University Name
                  </TableCell>
                  {universities.map((university) => (
                    <TableCell key={university.universityId}>
                      <Box
                        sx={{
                          width: { xs: "80px", sm: "100px" }, // Adjust image size for small screens
                          justifyContent: "center",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        {/* <Box
                          sx={{
                            height: { xs: "60px", sm: "80px" },
                            width: { xs: "60px", sm: "80px" },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <img
                            src={university.universityImage}
                            alt={university.universityName}
                            style={{ width: "100%" }}
                          />
                        </Box> */}
                        <Box>
                          <Typography
                            variant="body2"
                            align="center"
                            sx={{
                              fontWeight: "600",
                              fontSize: "20px",
                              color: "#00999E"
                            }}
                          >
                            {university.universityName}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  { label: "Country", key: "countryName" },
                  { label: "Intake", key: "Intake" },
                  { label: "Courses", key: "Courses" },
                  { label: "Tuition Fees", key: "TutionFee" },
                  { label: "Language", key: "Language" },
                  { label: "QS Ranking", key: "QSRanking" }
                ].map((detail, index) => (
                  <TableRow
                    key={detail.key}
                    sx={{
                      bgcolor: index % 2 === 0 ? "#effdff" : "#ffffff",
                      borderBottom:
                        detail.label === "QS Ranking" ? "" : "2px solid #00999E"
                    }}
                  >
                    <TableCell sx={{ fontWeight: "550" }}>
                      {detail.label}
                    </TableCell>
                    {universities.map((university) => (
                      <TableCell key={university.universityId}>
                        <Typography variant="body2" sx={{ fontWeight: "550" }}>
                          {university[detail.key]}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
      <LetsGetStarted marginTop={"50px"} />
      <Footer />
    </Box>
  );
};

export default UniversityComparison;
