/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Grid } from "@material-ui/core";
import {
  Box,
  Stack,
  Typography,
  createTheme,
  useMediaQuery
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import "../../index.css";
import ContactForm from "./ContactForm";
import background from "../../asset/contactBg.svg";
import Header from "../content-component/Header";
import { useTheme } from "@emotion/react";

const ContactMain = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920
      }
    }
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));

  return (
    <Container
      style={{
        padding: "40px 0"
      }}
      maxWidth={isLargeScreen ? "xl" : "lg"}
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Header color="#00999e" textAlign="center" content="Contact Us" />

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: "#717171",
            fontSize: { xs: "14px", sm: "17px", md: "18px", lg: "18px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
            textAlign: "center",
            width: { xs: "100%", sm: "80%", md: "80%", lg: "50%" },
            margin: "auto"
          }}
        >
          Any question or remarks? Just write us a message!
        </Typography>
      </Grid>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: "100%",
          padding: isSmScreen
            ? "20px 10px"
            : isMediumScreen
            ? "40px"
            : isFullScreen
            ? "60px"
            : "40px",
          width: "100%",

          background: "white",
          boxShadow: "0px 0px 60px 30px rgba(0, 0, 0, 0.03)",
          borderRadius: 10,
          display: "flex",
          marginTop: "50px"
        }}
      >
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Stack
            sx={{
              height: { xs: "70vh", sm: "60vh", md: "60vh", lg: "60vh" },
              background: "linear-gradient(0deg, #00999E 0%, #00999E 100%)",
              padding: {
                xs: "20px 25px",
                sm: "20px 40px",
                md: "20px 40px",
                lg: "20px 40px"
              },
              borderRadius: "9.10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "space-between",
              position: "relative"
            }}
          >
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "22.49px",
                    sm: "23.49px",
                    md: "22.49px",
                    lg: "25.49px"
                  },
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  textTransform: "capitalize"
                }}
              >
                Contact Information
              </Typography>
              {/* <Typography
                variant="p"
                sx={{
                  color: "#C9C9C9",
                  fontSize: {
                    xs: "14.39px",
                    sm: "16.39px",
                    md: "16.39px",
                    lg: "16.39px",
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  opacity: 0.55,
                }}
              >
                Say something to start a live chat!
              </Typography> */}
            </Box>
            <Box
              sx={{
                padding: "20px 0"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "white",
                  fontSize: {
                    xs: "14.57px",
                    sm: "14.57px",
                    md: "14.57px",
                    lg: "14.57px"
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  padding: "10px 0"
                }}
              >
                <Stack>
                  <CallIcon />
                </Stack>
                <Typography
                  variant="body2"
                  onClick={() => window.open(`tel:${+919831241212}`)}
                  sx={{
                    paddingLeft: "30px"
                  }}
                >
                  +91 9831241212
                </Typography>
              </Box>
              <a
                href={"mailto:info@taksheela.com"}
                style={{ textDecoration: "none" }}
              >
                <Box
                  // onClick={() => window.open(`mailto:`)}
                  sx={{
                    display: "flex",
                    color: "white",
                    fontSize: {
                      xs: "14.57px",
                      sm: "14.57px",
                      md: "14.57px",
                      lg: "14.57px"
                    },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    padding: "10px 0"
                  }}
                >
                  <Stack>
                    <EmailIcon />
                  </Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "30px"
                    }}
                  >
                    info@taksheela.com
                  </Typography>
                </Box>
              </a>
              <Box
                sx={{
                  display: "flex",
                  color: "white",
                  fontSize: {
                    xs: "14.57px",
                    sm: "14.57px",
                    md: "14.57px",
                    lg: "14.57px"
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  padding: "10px 0"
                }}
              >
                <Stack>
                  <LocationOnIcon />
                </Stack>
                <Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "30px"
                    }}
                  >
                    <b>Head Office:</b> 79/16 Palm Avenue, Ballygunje Phari,
                    Kolkata – 700019
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "30px"
                    }}
                  >
                    <b>Delhi Branch Office:</b> Hub Hive 11, 1st Floor, 262,
                    Plot 1, ITDC Western Marg, Near Saket Metro,
                    New Delhi 110017
                    {/* 1st floor above IDTC Western Marg, Near Saket Metro, New Delhi 110017 */}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "white"
              }}
            >
              <a
                href="https://www.facebook.com/taksheelainstituteofeducation/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlinedIcon
                  sx={{
                    marginRight: "15px",
                    border: "1px solid white",
                    borderRadius: "50%",
                    padding: "2px",
                    fontSize: "25px",
                    color: "white"
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/taksheelaedu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  sx={{
                    marginRight: "15px",
                    border: "1px solid white",
                    borderRadius: "50%",
                    padding: "2px",
                    fontSize: "25px",
                    color: "white"
                  }}
                />
              </a>
            </Box>
            <img
              src={background}
              alt="Background"
              style={{
                position: "absolute",
                right: 0,
                bottom: 0
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8}>
          <ContactForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactMain;
