import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import StarIcon from "../asset/Icon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../index.css";
import "../globle.css";
const ReviewCard = ({ id, name, icon, about, university, review }) => {
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <Stack
      sx={{
        width: { xs: "auto", sm: "auto", md: "430px", lg: "560px" },
        height: { xs: "260px", sm: "300px", md: "380px", lg: "400px" },
        cursor: "pointer",
        padding: { xs: "1.2rem", sm: "1.2rem", md: "2.2rem", lg: "2.2rem" },
        boxSizing: "border-box",
        border: "1px solid #ccc",
        borderRadius: "10px",
        backgroundColor: "white",
        marginBottom: "50px",
        margin: "auto",
        overflow: "auto",
        display: "grid",
        alignContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignContent: "space-around",
        }}
      >
        <Stack sx={{ display: "flex", gap: ".2rem", flexDirection: "row" }}>
          <img
            src={StarIcon}
            alt="StarIcon"
            style={{
              width: isSmScreen ? "3.5vw" : isMediumScreen ? "1.8vw" : isLargeScreen ? '1.5vw' : "1.5vw",
            }}
          />
          <img
            src={StarIcon}
            alt="StarIcon"
            style={{
              width: isSmScreen ? "3.5vw" : isMediumScreen ? "1.8vw" : isLargeScreen ? '1.5vw' : "1.5vw",
            }}
          />
          <img
            src={StarIcon}
            alt="StarIcon"
            style={{
              width: isSmScreen ? "3.5vw" : isMediumScreen ? "1.8vw" : isLargeScreen ? '1.5vw' : "1.5vw",
            }}
          />
          <img
            src={StarIcon}
            alt="LightStarIcon"
            style={{
              width: isSmScreen ? "3.5vw" : isMediumScreen ? "1.8vw" : isLargeScreen ? '1.5vw' : "1.5vw",
            }}
          />
          <img
            src={StarIcon}
            alt="LightStarIcon"
            style={{
              width: isSmScreen ? "3.5vw" : isMediumScreen ? "1.8vw" : isLargeScreen ? '1.5vw' : "1.5vw",
            }}
          />
        </Stack>
          <Typography
            sx={{
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "16px",
                lg: "18px",
              },
              fontWeight: 500,
              color: "#1B1D21",
              fontStyle: "normal",
              lineHeight: {
                xs: "16.349px",
                sm: "22.349px",
                md: "26.349px",
                lg: "30.349px",
              },
            }}
            className="text-justify"
          >
            {`"${review.slice(0, 247)}"`}
          </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: ".5rem",
          flexDirection: "row",
        }}
      >
        <Box>
          {icon ? (
            <img
              style={{ width: isSmScreen ? "75px" : "100px" }}
              src={icon}
              alt="Avatar"
            />
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Stack sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontWeight: 600,
                color: "#00999e",
                fontSize: { xs: "16px", sm: "17px", md: "25px", lg: "26px" },
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "14px",
                  lg: "18px",
                },
              }}
            >
              {about}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "14px",
                  lg: "18px",
                },
              }}
            >
              {university}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default ReviewCard;
