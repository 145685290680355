import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ThumbUpOffAltOutlined } from "@mui/icons-material";
import { Icon } from "@iconify/react";


const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const Image = imageBaseUrl + "comunnity/Frame 1686554624.png";
const Image2 = imageBaseUrl + "comunnity/Frame 1686556206.png";
const Image3 = imageBaseUrl + "comunnity/Group 1686555167.png";

const posts = [
  {
    id: 1,
    content:
      "Lorem ipsum dolor sit amet consectetur. Urna mi ipsum sapien lectus ...",
    images: [
      Image, // replace with actual image URLs
      Image2,
    ],
  },
  {
    id: 2,
    content:
      "Lorem ipsum dolor sit amet consectetur. Urna mi ipsum sapien lectus ...",
    images: [],
  },
  {
    id: 3,
    content:
      "Lorem ipsum dolor sit amet consectetur. Urna mi ipsum sapien lectus ...",
    images: [Image3],
  },
];

const Post = ({ post }) => (
  <Card sx={{ marginBottom: 2 }}>
    <CardHeader
      avatar={<Avatar sx={{ bgcolor: "#00999E" }}>M</Avatar>}
      title="Mitali Raj"
    />
    <CardContent>
      <Typography variant="body2" color="text.secondary">
        {post.content}
      </Typography>
      {post.images.length > 0 && (
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          {post.images.map((image, index) => (
            <Grid item xs={12} md={4} key={index}>
              <CardMedia
                component="img"
                sx={{
                  height: 300,
                  width: "100%", // Ensures the image takes full width in its container
                }}
                image={image}
                alt={`Post image ${index + 1}`}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </CardContent>

    <CardContent>
      <IconButton sx={{ color: "#252525" }}>
        <ThumbUpOffAltOutlined />
      </IconButton>
      <Typography sx={{ color: "#252525" }} variant="caption">
        Like 100
      </Typography>
      <IconButton sx={{ color: "#252525" }}>
        <Icon icon={"carbon:mail-reply"} />
      </IconButton>
      <Typography variant="caption" sx={{ color: "#252525" }}>
        1.2k Reply
      </Typography>
    </CardContent>
  </Card>
);

const Feed = () => (
  <div style={{ padding: "16px" }}>
    {posts.map((post) => (
      <Post key={post.id} post={post} />
    ))}
  </div>
);

export default Feed;
