import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CourseMainBanner from './Courses/CourseMainBanner';
import { Box } from '@mui/material';
import { BusinessBanner, CJBanner, CSITBanner, DesigningBanner, EngineeringBanner, FaaBanner, HsmBanner, PlsBanner, SocialBanner } from './CoursesJson/CourseMainBanner';
import CourseIntro from './Courses/CourseIntro';
import { BusinessIntro, CJIntro, CSITIntro, DesigningIntro, EngineeringIntro, FaaIntro, HsmIntro, PlsIntro, SocialIntro } from './CoursesJson/CourseIntro';
import Navbar from 'src/Component/Navbar';
import Footer from 'src/Component/Footer';
import LetsGetStarted from 'src/Component/LetsGetStarted';
import TestFaq from 'src/TestPages/TestFaq';
import { BusinessFaq, CJFaq, CSITFaq, DesigningFaq, EngineeringFaq, FaaFaq, HsmFaq, PlsFaq, SocialFaq } from './CoursesJson/CourseFaq';
import CourseWhyChoose from './Courses/CourseWhyChoose';
import { BusinessChoose, CJChoose, CSITChoose, DesigningChoose, EngineeringChoose, FaaChoose, HsmChoose, PlsChoose, SocialChoose } from './CoursesJson/CourseWhyChoose';
import { BusinessLevel, CJLevel, CSITLevel, DesigningLevel, EngineeringLevel, FaaLevel, HsmLevel, PlsLevel, SocialLevel } from './CoursesJson/CourseLevel';
import CourseLevel from './Courses/CourseLevel';
import CourseSnapshot from './Courses/CourseSnapshot';
import { BusinessSnapshot, CJSnapshot, CSITSnapshot, DesigningSnapshot, EngineeringSnapshot, FaaSnapshot, HsmSnapshot, PlsSnapshot, SocialSnapshot } from './CoursesJson/CourseSnapshot';
import CourseSpecialization from './Courses/CourseSpecialization';
import { BusinessSpecialization, CJSpecialization, CSITSpecialization, DesigningSpecialization, EngineeringSpecialization, FaaSpecialization, HSMSpecialization, PlsSpecialization, SocialSpecialization } from './CoursesJson/CourseSpecialization';
import CourseSynopsis from './Courses/CourseSynopsis';
import { BusinessSynopsis, CJSynopsis, CSITSynopsis, DesigningSynopsis, EngineeringSynopsis, FaaSynopsis, HsmSynopsis, PlsSynopsis, SocialSynopsis } from './CoursesJson/CountrySynopsis';
import CourseUniversity from './Courses/CourseUniversity';
import { BusinessUniversity, CJUniversity, CSITUniversity, DesigningUniversity, EngineeringUniversity, FaaUniversity, HsmUniversity, PlsUniversity, SocialUniversity } from './CoursesJson/CourseUniversities';
import BackRouteContainer from 'src/Component/grid-component/BackButtonComponent';
import logo from "../asset/backcourse.png"


const jsonData = [
    {
        pathname: "enginnering",
        banner: EngineeringBanner,
        intro: EngineeringIntro,
        faq: EngineeringFaq,
        choose: EngineeringChoose,
        level: EngineeringLevel,
        snapshot: EngineeringSnapshot,
        specialization: EngineeringSpecialization,
        synopsis: EngineeringSynopsis,
        university: EngineeringUniversity
    },
    {
        pathname: "csit",
        banner: CSITBanner,
        intro: CSITIntro,
        faq: CSITFaq,
        choose: CSITChoose,
        level: CSITLevel,
        snapshot: CSITSnapshot,
        specialization: CSITSpecialization,
        synopsis: CSITSynopsis,
        university: CSITUniversity
    },
    {
        pathname: "healthscience",
        banner: HsmBanner,
        intro: HsmIntro,
        faq: HsmFaq,
        choose: HsmChoose,
        level: HsmLevel,
        snapshot: HsmSnapshot,
        specialization: HSMSpecialization,
        synopsis: HsmSynopsis,
        university: HsmUniversity
    },
    {
        pathname: "socialscience",
        banner: SocialBanner,
        intro: SocialIntro,
        faq: SocialFaq,
        choose: SocialChoose,
        level: SocialLevel,
        snapshot: SocialSnapshot,
        specialization: SocialSpecialization,
        synopsis: SocialSynopsis,
        university: SocialUniversity
    },
    {
        pathname: "bussinessmanagement",
        banner: BusinessBanner,
        intro: BusinessIntro,
        faq: BusinessFaq,
        choose: BusinessChoose,
        level: BusinessLevel,
        snapshot: BusinessSnapshot,
        specialization: BusinessSpecialization,
        synopsis: BusinessSynopsis,
        university: BusinessUniversity
    },
    {
        pathname: "physicalscience",
        banner: PlsBanner,
        intro: PlsIntro,
        faq: PlsFaq,
        choose: PlsChoose,
        level: PlsLevel,
        snapshot: PlsSnapshot,
        specialization: PlsSpecialization,
        synopsis: PlsSynopsis,
        university: PlsUniversity
    },
    {
        pathname: "fineart",
        banner: FaaBanner,
        intro: FaaIntro,
        faq: FaaFaq,
        choose: FaaChoose,
        level: FaaLevel,
        snapshot: FaaSnapshot,
        specialization: FaaSpecialization,
        synopsis: FaaSynopsis,
        university: FaaUniversity
    },
    {
        pathname: "journalism",
        banner: CJBanner,
        intro: CJIntro,
        faq: CJFaq,
        choose: CJChoose,
        level: CJLevel,
        snapshot: CJSnapshot,
        specialization: CJSpecialization,
        synopsis: CJSynopsis,
        university: CJUniversity
    },
    {
        pathname: "designing",
        banner: DesigningBanner,
        intro: DesigningIntro,
        faq: DesigningFaq,
        choose: DesigningChoose,
        level: DesigningLevel,
        snapshot: DesigningSnapshot,
        specialization: DesigningSpecialization,
        synopsis: DesigningSynopsis,
        university: DesigningUniversity
    },
];

const CoursePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { pageName } = useParams();
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        const currentPageData = jsonData.find((data) => data?.pathname === pageName);
        setPageData(currentPageData);
        console.log(currentPageData);
    }, [pageName]);
    return (
        <Box >
            <Navbar />
            <BackRouteContainer path={"/study-abroad/courses"} title={"Select Course Page"} logo={logo} />
            <CourseMainBanner data={pageData?.banner} />
            <CourseIntro data={pageData?.intro} />
            <CourseWhyChoose data={pageData?.choose} />
            <CourseSnapshot data={pageData?.snapshot} />
            <CourseSpecialization data={pageData?.specialization} />
            <CourseLevel data={pageData?.level} />
            <Box sx={{ pt: "40px", mb: "-40px" }}>
                <LetsGetStarted />
            </Box>
            <CourseUniversity data={pageData?.university} />
            <CourseSynopsis data={pageData?.synopsis} />
            <Box sx={{ pb: "30px" }}>

                <TestFaq faqData={pageData?.faq} backgroundColor={"#effdff"} />
            </Box>
            <BackRouteContainer path={"/study-abroad/courses"} title={"Select Course Page"} logo={logo} />
            <LetsGetStarted />
            <Footer />
        </Box>
    )
}

export default CoursePage
