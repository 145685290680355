// import { Box, Card, Container, Grid, Typography } from '@mui/material'
// import React from 'react'
// import CheckIcon from "@mui/icons-material/Check";
// import Header from 'src/Component/content-component/Header';
// import './universityScholarship.css';


// const UniversityScholarship = ({ data }) => {
//     return (
//         <>
//             <Container
//                 sx={{
//                     padding: "50px 0"
//                 }}
//             >
//                 <Box
//                     sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         mb: 5,
//                         marginTop: 5
//                     }}
//                 >
//                     <Header content={"Scholarship"} textAlign={"center"} />
//                 </Box>

//                 <Grid className='scholarship' sx={{display:'flex',justifyContent:'center',p:{lg:'0 50px',md:"0 30px",sm:"0 195px",xs:"0 3%"}}} container spacing={4}>
//                     {data?.map((item) => (
//                         <Grid item xl={4} md={4} sm=
//                             {12} xs={12} key={item.id} 

//                             >
//                             <Card
//                                 sx={{
//                                     width: "300px",
//                                     // height: "auto",
//                                     height: "100%",
//                                     borderRadius: "8px",
//                                     display: "flex",
//                                     justifyContent: "flex-start",
//                                     alignItems: "center",
//                                     flexDirection: "column",
//                                     padding: "30px 20px",
//                                     borderTop: `3px solid ${item?.topColor}`
//                                 }}
//                             >
//                                 <Box sx={{ padding: "20px 0" }}>
//                                     <Typography
//                                         variant="h2"
//                                         sx={{
//                                             color: "#00999E",
//                                             fontFamily: "poppins",
//                                             fontSize: "22px",
//                                             fontWeight: "600",
//                                             textAlign: "center", mb: "20px"
//                                         }}
//                                     >
//                                         {item.title}
//                                     </Typography>
//                                     {item.listitem.map((listItem) => (
//                                         <ul>
//                                             <Box
//                                                 sx={{
//                                                     display: "flex",
//                                                     alignItems: "center",
//                                                     justifyContent: "flex-start", mb: "10px"

//                                                 }}
//                                             >
//                                                 <li
//                                                     style={{
//                                                         listStyleType: "none",
//                                                         fontFamily: "poppins",
//                                                         display: "flex",
//                                                         alignItems: "center",
//                                                         fontSize: "16px",
//                                                         color: "black",
//                                                         fontWeight: "400",
//                                                     }}
//                                                 >
//                                                     <CheckIcon
//                                                         style={{
//                                                             color: "#00999E",
//                                                             fontSize: "28px",
//                                                             paddingRight: "5px"
//                                                         }}
//                                                     />
//                                                     {listItem}
//                                                 </li>
//                                             </Box>
//                                         </ul>
//                                     ))}
//                                 </Box>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>

//         </>
//     )
// }

// export default UniversityScholarship



import { Box, Card, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from "@mui/icons-material/Check";
import Header from 'src/Component/content-component/Header';
import './universityScholarship.css';

const UniversityScholarship = ({ data }) => {
    return (
        <>
            <Container
                sx={{
                    padding: "50px 0",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 5,
                        marginTop: 3,
                    }}
                >
                    <Header content={"Scholarship"} textAlign={"center"} />
                </Box>

                <Grid
                    className="scholarship"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: {
                            lg: '0 50px',
                            md: "0 30px",
                            sm: "0 195px",
                            // xs: "0 3%",
                        },
                        '@media (max-width: 425px)': {
                            padding: '0 15%',
                        },
                        '@media (max-width: 375px)': {
                            padding: '0 10%',
                        },
                        '@media (max-width: 320px)': {
                            padding: '0 3%',
                        },
                    }}
                    container
                    spacing={4}
                >
                    {data?.map((item) => (
                        <Grid
                            item
                            xl={4}
                            md={4}
                            sm={12}
                            xs={12}
                            key={item.id}
                        >
                            <Card
                                sx={{
                                    width: "300px",
                                    height: "100%",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "30px 20px",
                                    borderTop: `3px solid ${item?.topColor}`,
                                }}
                            >
                                <Box sx={{ padding: "20px 0" }}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            color: "#00999E",
                                            fontFamily: "poppins",
                                            fontSize: "22px",
                                            fontWeight: "600",
                                            textAlign: "center",
                                            mb: "20px",
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    {item.listitem.map((listItem, index) => (
                                        <ul key={index}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    mb: "10px",
                                                }}
                                            >
                                                <li
                                                    style={{
                                                        listStyleType: "none",
                                                        fontFamily: "poppins",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: "16px",
                                                        color: "black",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    <CheckIcon
                                                        style={{
                                                            color: "#00999E",
                                                            fontSize: "28px",
                                                            paddingRight: "5px",
                                                        }}
                                                    />
                                                    {listItem}
                                                </li>
                                            </Box>
                                        </ul>
                                    ))}
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default UniversityScholarship;
