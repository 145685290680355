import React, { useEffect, useState } from "react";
import DropdownComponent from "./DropdownComponent";
import { Box, Button, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import axios from "axios";

const FiltersComponent = ({ filterData, onFilterChange }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    filterData.countryId || []
  );
  const [courses, setCourses] = useState([]);
  
  const [selectedPursue, setSelectedPursue] = useState(filterData.pursue || "");
  const [selectedYear, setSelectedYear] = useState(filterData.year || []);
  const [selectedDuration, setSelectedDuration] = useState(
    filterData.duration || []
  );
  const [selectedIntake, setSelectedIntake] = useState(filterData.intake || []);
  const [selectedCourses, setSelectedCourses] = useState(
    filterData.courses || []
  );
  const [selectedScholarship, setSelectedScholarship] = useState(
    filterData.scholarAvailability || ""
  );
  const [selectedQualification, setSelectedQualification] = useState(
    filterData.highestQualification || ""
  );
  const [selectedFee, setSelectedFee] = useState( filterData.tutionFee || "");
  const [selectedAdmission, setSelectedAdmission] = useState(
    filterData.admissionRequirement || []
  );

  
  
  // Fetch countries
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}university/countryList`)
      .then((response) => {
        setCountries(response.data.data.formattedData);
      })
      .catch((error) => console.log(error));
  }, []);

  // Fetch states when a country is selected
  useEffect(() => {
    if (selectedCountry) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}master/states`, {
          countryId: selectedCountry
        })
        .then((response) => {
          setStates(response.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [selectedCountry]);

  // Fetch courses
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}university/courseList`)
      .then((response) => {
        setCourses(response.data.data.formattedData);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleApplyFilter = () => {
    const filterPayload = {
      countryId: selectedCountry,
      pursue: selectedPursue,
      year: selectedYear,
      duration: selectedDuration,
      intake: selectedIntake,
      courses: selectedCourses,
      scholarAvailability: selectedScholarship,
      highestQualification: selectedQualification,
      tutionFee: selectedFee,
      admissionRequirement: selectedAdmission,
    };


    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}university/universityFilter`,
        filterPayload
      )
      .then((response) => {
        // Send updated university and filter data back to UniversityView
        onFilterChange(response.data.data.formattedData, filterPayload);
      })
      .catch((error) => {
        console.log("Error applying filter:", error);
      });
  };

  const handleResetFilter = () => {
    setSelectedCountry("");
    // setSelectedState("");
    setSelectedPursue("");
    setSelectedYear("");
    setSelectedDuration("");
    setSelectedIntake("");
    setSelectedCourses("");
    setSelectedScholarship("");
    setSelectedQualification("");
    setSelectedFee("");
    setSelectedAdmission("");
    // setSelectedLanguage("");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}university/universityFilter`, {
        countryId: "",
        courseId: "",
        stateId: "",
        pursue: "",
        year: "",
        tutionFee: "",
        duration: "",
        intake: "",
        admissionRequirement: "",
        scholarAvailability: "",
        language: "",
        highestQualification: ""
      })
      .then((response) => {
        // Send reset data back to UniversityView
        onFilterChange(response.data.data.formattedData, {});
      })
      .catch((error) => {
        console.log("Error resetting filters:", error);
      });
  };

  return (
    <Box
      sx={{
        margin: "20px 0",
        bgcolor: "#effdff",
        border: "2px solid #00999E",
        boxShadow: 1,
        borderRadius: "20px"
      }}
    >
      <Box
        sx={{
          padding: "10px 20px ",
          color: "#000",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Icon icon="mdi:filter" style={{ color: "black", fontSize: "26px" }} />
        <Typography variant="h6" sx={{ fontWeight: "600" }} gutterBottom>
          Filters
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "10px 20px ",
          color: "#fff",
          bgcolor: "#00999E"
        }}
      >
        <Typography variant="h6" gutterBottom>
          ELIGIBILITY
        </Typography>
      </Box>
      <Box sx={{ p: "20px" }}>
       

        <DropdownComponent
          label="Country"
          options={countries?.map((country) => ({
            value: country._id,
            label: country.name
          }))}
          value={selectedCountry}
          onChange={(value) => setSelectedCountry(value)}
          multiSelect // Enable multi-select
        />

       
        <DropdownComponent
          label="Planning To Pursue"
          options={["Undergraduate", "Graduate", "PHD", "Certificate Program"]}
          value={selectedPursue}
          onChange={(value) => setSelectedPursue(value)}
        />
        <DropdownComponent
          label="Preferred Year"
          options={["2024", "2025", "2026", "2027", "2028", "2029"]}
          value={selectedYear}
          onChange={(value) => setSelectedYear(value)}
          multiSelect
        />
        <DropdownComponent
          label="Preferred Intake"
          options={["Fall", "Spring", "Summer", "Winter"]}
          value={selectedIntake}
          onChange={(value) => setSelectedIntake(value)}
          multiSelect
        />
        <DropdownComponent
          label="Preferred Duration"
          options={["less than 1 Year", "1-2 year", "3-4 year", "more than 4 year"]}
          value={selectedDuration}
          onChange={(value) => {
            console.log(value,'valuedsd')
            setSelectedDuration(value)}}
          multiSelect
        />
        <DropdownComponent
          label="Field of Interest"
          options={courses?.map((course) => ({
            value: course._id,
            label: course.name
          }))}
          value={selectedCourses}
          onChange={(value) =>{ 
            console.log(value,'value')
            setSelectedCourses(value)}}
          multiSelect
        />
        <DropdownComponent
          label="Tuition Fee (Optional)"
          options={[
            "0",
            "50000",
            "100000",
            "150000",
            "200000",
            "250000",
            "300000",
            "350000",
            "400000",
            "450000",
            "500000"
          ]}
          value={selectedFee}
          onChange={(value) => setSelectedFee(value)}
        />
        <DropdownComponent
          label="Admission Requirements"
          options={["PTE", "IELTS", "TOEFL", "SAT", "GRE", "GMAT", "EXEMPT"]}
          value={selectedAdmission}
          onChange={(value) => setSelectedAdmission(value)}
          multiSelect
        />
        <DropdownComponent
          label="Highest Qualification"
          options={[
            "Higher Secondary",
            "Undergraduate",
            "Graduate",
            "Certificate Program"
          ]}
          value={selectedQualification}
          onChange={(value) => setSelectedQualification(value)}
        />
        <DropdownComponent
          label="Scholarship"
          options={[
            "Full Scholarships",
            "Partial Scholarships",
            "No Scholarships"
          ]}
          value={selectedScholarship}
          onChange={(value) => setSelectedScholarship(value)}
        />
        
        <Button
          variant="contained"
          sx={{
            bgcolor: "#00999E",
            color: "#fff"
          }}
          fullWidth
          style={{ marginTop: "20px" }}
          onClick={handleApplyFilter}
        >
          APPLY FILTER
        </Button>
        <Button
          sx={{ color: "#00999E", border: "2px solid #00999E" }}
          fullWidth
          style={{ marginTop: "10px" }}
          onClick={handleResetFilter}
        >
          RESET FILTER
        </Button>
      </Box>
    </Box>
  );
};

export default FiltersComponent;
