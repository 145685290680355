// import { Box, Paper, Typography, Button } from "@mui/material";
// import rightIcon from "../../../../asset/Right_abroad.svg";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import Build from "../../../../asset/builder_abroad.svg";

// export default function Right({ title, actionType }) {
//   if (actionType === "Completed") return;
//   return (
//     <Paper
//       elevation={4}
//       sx={{
//         padding: "3rem 3rem",
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-around",
//           alignItems: "center",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             marginLeft: 3,
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               bgcolor: "#00999E",
//               color: "white",
//               padding: "5rem 7rem",
//               borderRadius: 5,
//               marginRight: 3,
//             }}
//           >
//             {title}
//           </Box>
//           <Box
//             sx={{
//               color: "#00999E",
//             }}
//           >
//             <ArrowForwardIosIcon />
//           </Box>
//         </Box>

//         <Box
//           sx={{
//             padding: { xs: "16px", sm: "32px" },
//             borderRadius: "10px",
//             backgroundColor: "#E2FEFF",
//             border: "1px solid #ccc",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//           }}
//         >
//           <Box sx={{ textAlign: "center", mb: 2 }}>
//             <Typography
//               variant="h4"
//               sx={{
//                 fontSize: { xs: "20px", sm: "24px", md: "32px" },
//                 fontWeight: "bold",
//               }}
//             >
//               {title}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               sx={{
//                 fontSize: { xs: "12px", sm: "14px", md: "16px" },
//                 color: "gray",
//               }}
//             >
//               Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet
//               consectetur.
//             </Typography>
//           </Box>

//           {title === "Profile Building" ? (
//             <>
//               <Box
//                 sx={{
//                   width: { xs: "100%", sm: "570px" },
//                   height: "265px",
//                   padding: "16px",
//                   borderRadius: "20px",
//                   border: "1px solid #ccc",
//                   backgroundColor: "#FFF",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   textAlign: "center",
//                   gap: "8px",
//                   marginLeft: { xs: "0", sm: "3rem" },
//                 }}
//               >
//                 <img src={Build} alt="wrong" />

//                 <Box>
//                   <Typography
//                     variant="h6"
//                     color="#606060"
//                     sx={{
//                       fontSize: "25px",
//                       fontWeight: 600,
//                       fontFamily: "Poppins",
//                     }}
//                   >
//                     Download your updated Profile Document
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     color="#9BA1B2"
//                     sx={{
//                       marginTop: -0.5,
//                       opacity: 0.8,
//                     }}
//                   >
//                     Your Profile building Document
//                   </Typography>
//                 </Box>

//                 <Button
//                   variant="contained"
//                   sx={{
//                     backgroundColor: "#00999E",
//                     mt: 2,
//                     "&:hover": {
//                       backgroundColor: "#007f80",
//                       color: "#fff",
//                     },
//                   }}
//                 >
//                   View Profile Building
//                 </Button>
//               </Box>
//             </>
//           ) : (
//             <>
//               <Box
//                 sx={{
//                   width: "100%",
//                   padding: { xs: "16px", sm: "24px" },
//                   borderRadius: "20px",
//                   backgroundColor: "#FFFFFF",
//                   border: "1px solid #ccc",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     mb: 2,
//                   }}
//                 >
//                   <Typography>YES</Typography>
//                   <img
//                     src={rightIcon || ""}
//                     alt="Right Icon"
//                     style={{ width: "24px" }}
//                   />
//                 </Box>

//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexWrap: "wrap",
//                     justifyContent: "space-between",
//                     gap: 1,
//                     mb: 2,
//                   }}
//                 >
//                   <Typography>Date:</Typography>
//                   <Typography>23 AUGUST 2024</Typography>
//                   <Typography>Time:</Typography>
//                   <Typography>10:00 AM - 12:00 PM</Typography>
//                 </Box>

//                 {title === "Psychometric Test" ? (
//                   <>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         mb: 2,
//                       }}
//                     >
//                       <Typography>Psychometric Test Report</Typography>
//                       <Button
//                         variant="contained"
//                         sx={{
//                           borderRadius: 2,
//                           backgroundColor: "#00999E",
//                           "&:hover": {
//                             backgroundColor: "#007f80",
//                             color: "#fff",
//                           },
//                           width: "160px",
//                           fontSize: 13,
//                           padding: "7px 4px",
//                         }}
//                       >
//                         Download
//                       </Button>
//                     </Box>
//                   </>
//                 ) : (
//                   <>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         mb: 2,
//                       }}
//                     >
//                       <Typography>Counselor Name</Typography>
//                       <Typography>Mr. Abhishek Verma</Typography>
//                     </Box>

//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         mb: 2,
//                       }}
//                     >
//                       <Typography>Counselling Form</Typography>
//                       <Button
//                         variant="contained"
//                         sx={{
//                           borderRadius: 2,
//                           backgroundColor: "#00999E",
//                           "&:hover": {
//                             backgroundColor: "#007f80",
//                             color: "#fff",
//                           },
//                           width: "160px",
//                           fontSize: 13,
//                           padding: "7px 4px",
//                         }}
//                       >
//                         Download
//                       </Button>
//                     </Box>

//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                       }}
//                     >
//                       <Typography>Counselling Schedule</Typography>
//                       <Button
//                         variant="contained"
//                         sx={{
//                           borderRadius: 2,
//                           backgroundColor: "#00999E",
//                           "&:hover": {
//                             backgroundColor: "#007f80",
//                             color: "#fff",
//                           },
//                           width: "160px",
//                           fontSize: 13,
//                           padding: "7px 4px",
//                         }}
//                       >
//                         Schedule
//                       </Button>
//                     </Box>
//                   </>
//                 )}
//               </Box>
//             </>
//           )}
//         </Box>
//       </Box>
//     </Paper>
//   );
// }

import { Box, Paper, Typography, Button, Grid } from "@mui/material";
import rightIcon from "../../../../asset/Right_abroad.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Build from "../../../../asset/builder_abroad.svg";

export default function Right({ title, actionType }) {
  if (actionType === "Completed") return null;

  return (
    <Paper
      elevation={4}
      sx={{
        padding: { xs: "10px", sm: "3rem" }, // Adjusted padding for xs
        margin: "auto",
        maxWidth: "100%",
        boxSizing: "border-box", // Ensure padding does not add to width
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: { sm: 3 },
            flex: 1,
            minWidth: 0, // Prevent overflow due to flex
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#00999E",
              color: "white",
              padding: { xs: "1.5rem", sm: "3.5rem 4rem" }, // Reduced padding on xs
              borderRadius: 5,
              marginRight: 3,
              textAlign: "center",
              maxWidth: "100%", // Prevents width overflow
            }}
          >
            {title}
          </Box>
          <ArrowForwardIosIcon
            sx={{
              color: "#00999E",
              // display: { xs: "none", sm: "block" },
            }}
          />
        </Box>

        <Box
          sx={{
            padding: { xs: "0px", sm: "32px" },
            borderRadius: "10px",
            backgroundColor: "#E2FEFF",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            flex: 2,
            width: "100%", // Ensure the box does not exceed width
            boxSizing: "border-box", // Prevent overflow
          }}
        >
          <Box sx={{ textAlign: "center", mb: 2, p: 1 }}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                color: "gray",
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet
              consectetur.
            </Typography>
          </Box>

          {title === "Profile Building" ? (
            <Box
              sx={{
                width: "100%",
                maxWidth: "570px",
                padding: "16px",
                borderRadius: "20px",
                border: "1px solid #ccc",
                backgroundColor: "#FFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: 2,
                mx: "auto",
              }}
            >
              <img
                src={Build}
                alt="wrong"
                style={{ width: "80%", maxWidth: "300px", height: "auto" }}
              />

              <Typography
                variant="h6"
                color="#606060"
                sx={{
                  fontSize: "20px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                Download your updated Profile Document
              </Typography>
              <Typography
                variant="body1"
                color="#9BA1B2"
                sx={{
                  opacity: 0.8,
                }}
              >
                Your Profile building Document
              </Typography>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#00999E",
                  mt: 2,
                  "&:hover": {
                    backgroundColor: "#007f80",
                    color: "#fff",
                  },
                }}
              >
                View Profile Building
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                width: "97%",
                padding: { xs: "16px", sm: "24px" },
                borderRadius: "20px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #ccc",
                boxSizing: "border-box", // Prevent overflow
                margin: {
                  xs: "7px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography>YES</Typography>
                <img
                  src={rightIcon || ""}
                  alt="Right Icon"
                  style={{ width: "24px" }}
                />
              </Box>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
  {/* Date Section */}
  <Grid item xs={12} sm={6}>
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "flex-start" },
        }}
      >
        <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
          Date:
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: {
            xs: "flex-end",
            sm: "flex-end",
            md: "center",
          },
        }}
      >
        <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
          23 AUGUST 2024
        </Typography>
      </Grid>
    </Grid>
  </Grid>

  {/* Time Section */}
  <Grid item xs={12} sm={6}>
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "center" },
        }}
      >
        <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
          Time:
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
          10:00 AM - 12:00 PM
        </Typography>
      </Grid>
    </Grid>
  </Grid>
</Grid>


              {title === "Psychometric Test" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography>Psychometric Test Report</Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "#00999E",
                      "&:hover": {
                        backgroundColor: "#007f80",
                        color: "#fff",
                      },
                      width: "160px",
                      fontSize: 13,
                      padding: "7px 4px",
                    }}
                  >
                    Download
                  </Button>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                      Counselor Name
                    </Typography>
                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                      Mr. Abhishek Verma
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                      Counselling Form
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "#00999E",
                        "&:hover": {
                          backgroundColor: "#007f80",
                          color: "#fff",
                        },
                        width: { xs: "110px", sm: "160px" },
                        fontSize: 13,
                        padding: "7px 4px",
                      }}
                    >
                      Download
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                      Counselling Schedule
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "#00999E",
                        "&:hover": {
                          backgroundColor: "#007f80",
                          color: "#fff",
                        },
                        width: { xs: "110px", sm: "160px" },
                        fontSize: 13,
                        padding: "7px 4px",
                      }}
                    >
                      Schedule
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
