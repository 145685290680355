import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Grid,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
// import CoursesFlag from "./CoursesFlag";
import University_abroad from "../../../../asset/University_abroad.svg";
import Plus_icon_abroad from "../../../../asset/Plus_icon_abroad.svg";
import { Link } from "react-router-dom";
import UniversityList from "./AddMoreUniversityPage";
import CheckAllAppStatus from "./CheckAllAppStatus";
import UniversityDetailsDialog from "./UniversityDetailsDialog";
export default function CareerUniversityList() {
  const [openUniversityDetailsModal, setOpenUniversityDetailsModal] =
    useState(false);
  const [openMoreAddUniversity, setOpenMoreAddUniversity] = useState(false);
  const [openCheckAllApp, setOpenCheckAllApp] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([
    { name: "Harvard University", flagUrl: University_abroad },
    { name: "Harvard University", flagUrl: University_abroad },
    { name: "Harvard University", flagUrl: University_abroad },
    { name: "Harvard University", flagUrl: University_abroad },
    { name: "Harvard University", flagUrl: University_abroad },
  ]);

  const handleSelectCourses = (course) => {
    // Avoid adding duplicates
    if (!selectedCourses.find((c) => c.name === course.name)) {
      setSelectedCourses((prev) => [...prev, course]);
    }
    handleCloseModal();
  };

  const handleOpenModal = () => setOpenUniversityDetailsModal(true);

  const handleCloseModal = () => setOpenUniversityDetailsModal(false);

  const handleRemoveCountry = (courseName) => {
    setSelectedCourses((prev) =>
      prev.filter((course) => course.name !== courseName)
    );
  };

  const handleCloseDialog = () => {
    setOpenMoreAddUniversity(false);
    setOpenCheckAllApp(false);
    setOpenUniversityDetailsModal(false);
  };

  //    px: { xs: "16px", sm: "24px" }

  return (
    <Box
      sx={{
        mt: "20px",
        width: {
          xs: "50%",
          sm: "100%",
        },

        margin: "auto",
        
      }}
    >
      <Paper sx={{
       paddingTop : 4
      }} 
      elevation={1}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="h6">
              Selected University
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#00999E",
                border: "1px solid #00999E",
                marginLeft: 22,
                "&:hover" : {
                  backgroundColor : "none",
                  color : "#00999E",
                  border : '1px solid #00999E'
                }
              }}
              onClick={() => setOpenCheckAllApp(true)}
            >
              Check all application status
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: "12px",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            padding: "18px 30px",
            gap: { xs: "12px", sm: "16px" },
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              alignContent: "flex-start",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "150px" },
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00999E",
                color: "white",
                borderRadius: "12px",
                fontWeight: "bold",
              }}
            >
              Profile Building
            </Box>
            <ArrowForwardIosIcon
              sx={{
                color: "#00999E",
                fontSize: "20px",
                display: { xs: "none", sm: "block" },
                mx: "12px",
              }}
            />
          </Box>
          <Grid
            container
            spacing={2}
            columnGap={9}
            rowGap={3}
            sx={{
              marginLeft: 22,
            }}
          >
            {selectedCourses.map((course, index) => (
              <Grid
                item
                xs={1}
                sm={3}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  padding: "12px",
                  width: { xs: "100%", sm: "150px" },
                  height: "120px",
                  backgroundColor: "#EFFDFF",
                  borderRadius: "12px",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #B7B7B7",
                  cursor : "pointer"

                }}
                onClick={() => setOpenUniversityDetailsModal(true)}
              >
                <img
                  src={course.flagUrl}
                  alt={course.name}
                  style={{
                    width: "80px",
                    height: "60px",
                    borderRadius: "10%",
                  }}
                />
                <Typography
                  sx={{ fontSize: "15px", fontWeight: "600", color: "#595959" }}
                >
                  {course.name}
                </Typography>
              </Grid>
            ))}
            <Grid
              item
              xs={1}
              sm={3}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "7px",
                padding: "12px",
                width: { xs: "100%", sm: "150px" },
                height: "120px",
                backgroundColor: "#EFFDFF",
                borderRadius: "12px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                border: "1px solid #B7B7B7",
                cursor : "pointer"
              }}
              onClick={() => setOpenMoreAddUniversity(true)}
            >
              <img
                src={Plus_icon_abroad}
                alt="+"
                style={{
                  // width: "80px",
                  // height: "60px",
                  borderRadius: "10%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#595959",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Add
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  More University
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Button
          variant="contained"
          disableElevation
          disableRipple
          disableTouchRipple
          sx={{
            color: "white",
            background: "#00999E",
            textTransform: "capitalize",
            fontSize: "16px",
            marginBottom: 3,
            marginLeft: "27%",
            marginTop: 3,
            padding: ".6% 1.8%",
            "&:hover" : {
              backgroundColor : "#00999E"
            }
          }}
        >
          University Master File
        </Button>
      </Paper>
      <Dialog
        open={openMoreAddUniversity}
        onClose={handleCloseDialog}
        fullWidth
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
            borderRadius: "5px",
            opacity: "1",
            position: "absolute",
            align: "center",
            zIndex: 1000,
          },
        }}
      >
        <DialogContent>
          <UniversityList />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCheckAllApp}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth={false} // Disable default max width behavior
        PaperProps={{
          sx: {
            width: {
              xs : "100vw",
              sm : "80vw",
              md : "56vw"
            }, // Make dialog take full width of the screen
            height: "auto", // Adjust height if needed
            borderRadius: "30px", // Set border radius
            opacity: "1", // Full visibility
            position: "absolute",
            align: "center",
            zIndex: 1000, // Ensure dialog appears above other content
          },
        }}
      >
        <DialogContent>
          <CheckAllAppStatus handleClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUniversityDetailsModal}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth={false} // Disable default max width behavior
        PaperProps={{
          sx: {
            width: "85%", // Make dialog take full width of the screen
            height: "90%", // Adjust height if needed
            borderRadius: "30px", // Set border radius
            opacity: "1", // Full visibility
            position: "absolute",
            align: "center",
            zIndex: 1000, // Ensure dialog appears above other content
          },
        }}
      >
        <DialogContent>
          <UniversityDetailsDialog />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
