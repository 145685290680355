import Header from "src/Component/content-component/Header";
import ParaData from "src/Component/content-component/ParaData";
import ContentContainer from "src/Component/grid-component/ContentContainer";
import ImageContainer from "src/Component/grid-component/ImageContainer";
import MainContainer from "src/Component/grid-component/MainContainer";
import mbbsImg1 from "../../asset/studyAbroadInfo.svg";
// import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { Box } from "@mui/material";
import Cta from "src/Component/card-components/Cta";

const StudyAbroadInfo = () => {
    return (
        <>
            <MainContainer
                dir="column-reverse"
                color={"#ffffff"}
                com1={<ImageContainer img={mbbsImg1} />}
                com2={
                    <Box>
                        <ContentContainer
                            Header={<Header content={"Study Abroad"} />}
                            Content={
                                <ParaData
                                    color={"#525560"}
                                    fontSize={"16px"}
                                    textAlign={"left"}
                                    content={
                                        "From the Ivy League institutions of the U.S. and historic universities of the U.K. to Australia's and Canada's innovative research facilities and multicultural environments, our platform guides you through diverse study destinations. Discover the rich educational experiences, cultures, and vibrant communities awaiting you in international education."
                                    }
                                />
                            }


                        />
                        <Box sx={{
                            paddingLeft: "20px"
                        }}>

                            <Cta />
                            {/* <ButtonComponent
                                width={"250px"}
                                text="Download Company Profile"
                            // href="/mbbs/india"
                            /> */}
                        </Box>
                    </Box>
                }
            />
        </>
    );
};
export default StudyAbroadInfo;
