import { Box, Container, Grid } from '@mui/material';
import React from 'react'
import Header from 'src/Component/content-component/Header';
// import CompaniesCard from '../CompaniesCard';
import Card from 'src/Component/card-components/Card';
import Cta from 'src/Component/card-components/Cta';

const StudyRecruiters = ({ data }) => {
    return (
        <Box pt={6} pb={8} sx={{ backgroundColor: "#effdff" }}>

            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={"Popular Recruiters"}
                        textAlign={{ md: "center", sm: "center", xs: "center" }}
                    />
                </Box>
                <Box >
                    <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 15 }} sx={{ display: "flex", justifyContent: "center" }}>
                        {data?.map((item, index) => (

                            <Grid item xs={15} sm={6} md={3} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                                <Card
                                    data={item}
                                    borderRadius="10%"
                                    hoverHeight={"23%"}
                                    initialheight={"23%"}
                                    width="150px"
                                    height="150px"
                                    listStyle={"none"}
                                    fontSize={"18px"}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>
                    <Cta />
                </Box>
            </Container>
        </Box>
    )
}

export default StudyRecruiters
