import { Grid } from "@material-ui/core";
import {
  Container,
  useMediaQuery,
  Stack,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MilestonesCard from "./MilestonesCard";
import icon1 from "../../asset/milestone1.png";
import icon2 from "../../asset/mileston2.png";
import icon3 from "../../asset/mileston3.png";
import icon4 from "../../asset/mileston4.png";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import Header from "../content-component/Header";

const Milestones = () => {
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920,
      },
    },
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [showMilestones, setShowMilestones] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 800;
      const scrollPosition = window.scrollY;

      setShowMilestones(scrollPosition >= threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  var settings = {
    arrow: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    infinite: true,
    autoplay: true,
    dots: true,
    speed: 3000,
    autoplaySpeed: 3000,

    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          speed: 3000,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 800,
        settings: {

          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          speed: 3000,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 3000,
          autoplaySpeed: 3000,
        }
      },

    ]
  };

  const mentorsCardData = [
    {
      id: 1,
      icon: icon1,
      count: 10,
      parameter: "+",
      title: "Year",
    },
    {
      id: 2,
      icon: icon2,
      count: 98,
      parameter: "%",
      title: "Success Rate",
    },
    {
      id: 3,
      icon: icon3,
      count: 1000,
      parameter: "+",
      title: "Universities",
    },
    {
      id: 4,
      icon: icon4,
      count: 500,
      parameter: "+",
      title: "Students Placed",
    },
  ];
  return (
    <Box
      sx={{
        padding: "50px 0",
        backgroundColor: "#EffDff",
        opacity: "0.75",
      }}
    >
      <Container maxWidth={isLargeScreen ? "xl" : "lg"} >
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          direction="column"
        >

          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: ".5rem",
            }}
          > <Header
                        color="#00999e"
                        textAlign="center"
                        content="Milestones"
                    />
            {/* <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "18px", sm: "20px", md: "32px", lg: "36px" },
                fontWeight: 600,
                color: "#00999E",
                marginBottom: {
                  xs: "15px",
                  sm: "15px",
                  md: "25px",
                  lg: "25px",
                },
              }}
            >
              ~Milestones~
            </Typography> */}
          </Stack>

          <Container
            style={{
              height: "100%",
              justifyContent: isSmScreen
                ? "center"
                : isMediumScreen
                  ? "center"
                  : "space-between",
              alignItems: "center",
            }}
            maxWidth={isLargeScreen ? "xl" : "lg"}
          >
            {" "}
            <div
              style={{
                gap: "2rem",
                justifyContent: "center",
                margin: "auto",
                padding: "30px 0",
              }}
            >
              {" "}
              {showMilestones && (
                <Slider {...settings}>
                  {mentorsCardData.map((card) => (
                    <MilestonesCard
                      key={card.id}
                      id={card.id}
                      icon={card.icon}
                      count={card.count}
                      parameter={card.parameter}
                      title={card.title}
                    />
                  ))}
                </Slider>
              )}
            </div>
          </Container>
        </Grid>
      </Container>
    </Box>
  );
};

export default Milestones;
