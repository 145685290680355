import { Typography } from "@mui/material";
import React from "react";

const ParaData = ({ color, textAlign, content, fontWeight, fontSize }) => {
  return (
    <Typography
      sx={{
        fontSize: `${fontSize}`,
        fontFamily: "Poppins",
        margin: "8px 0",
        color: `${color}`,
        fontWeight: `${fontWeight}`,
        textAlign: textAlign ?? "left",
       
             }}
    >
      {content}
    </Typography>
  );
};

export default ParaData;
