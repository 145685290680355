import { Box, Container, Typography } from '@mui/material';
import React from 'react'
import Slider from 'react-slick';
import Cta from 'src/Component/card-components/Cta';
import Header from 'src/Component/content-component/Header';



const StudyPlaces = ({ data }) => {
    var settings = {
        dots: true,
        infinite: true,
        arrow: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 800,
                settings: {

                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 3000,
                    autoplaySpeed: 2000,
                }
            },

        ]

    };
    return (
        <Box pt={6} pb={8} sx={{ backgroundColor: "#ffffff" }}>

            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={data?.title}
                        textAlign={{ md: "center", sm: "center", xs: "center" }}
                    />
                </Box>
                <Slider {...settings} >
                    {data?.data?.map((item, index) => {
                        return (
                            <>
                                <Box
                                    sx={{
                                        width: "320px",
                                        height: "380px",
                                        padding: "0 !important",
                                        backgroundColor: "#effdff",
                                        // marginBottom: "25px",
                                        justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center",
                                        position: "relative",
                                    }}
                                >
                                    <Box sx={{ width: "300px", }}>
                                        <img src={item?.img} alt='' width={"100%"} />
                                    </Box>

                                    <Box
                                        sx={{
                                            padding: "10px",
                                            marginTop: "7px",
                                        }}
                                    >


                                        <Typography variant='h5'
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: "600", textAlign: "center"
                                            }}
                                        >
                                            {item?.city}
                                        </Typography>

                                    </Box>



                                </Box>

                            </>
                        );
                    })}
                </Slider>
                <Box sx={{ display: "flex", justifyContent: "center", mt: "40px", mb: "10px" }}>
                    <Cta />
                </Box>
            </Container>
        </Box>
    )
}

export default StudyPlaces
