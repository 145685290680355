import Header from "src/Component/content-component/Header";
import ParaData from "src/Component/content-component/ParaData";
import ContentContainer from "src/Component/grid-component/ContentContainer";
import ImageContainer from "src/Component/grid-component/ImageContainer";
import MainContainer from "src/Component/grid-component/MainContainer";
import mbbsImg2 from "../asset/mbbsabroad.png";
import mbbsicon1 from "../asset/mbbsIcon1.svg";
import mbbsicon2 from "../asset/mbbsIcon2.svg";
import mbbsicon3 from "../asset/mbbsIcon3.svg";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { Icon } from "@iconify/react";
const images = [
  {
    image: mbbsicon1,
    title: "Eligibility Criteria",
    page: "/mbbs/abroad",
    section: "#abroadCriteria",
  },
  {
    image: mbbsicon2,
    title: "MBBS UG & PG",
    page: "/mbbs/abroad",
    section: "#ug-pg",
  },
  {
    image: mbbsicon3,
    title: "NMC Rules",
    page: "/mbbs/abroad",
    section: "#mbbsRules",
  },
];
const WhyStudyMbbsAbroad = () => {
  return (
    <>
      <MainContainer
        com1={
          <ContentContainer
            Header={
              <Header content={"Why Study MBBS Abroad?"} />
            }
            Content={
              <ParaData
                color={"#525560"}
                fontSize={"16px"}
                content={
                  "Despite several advantages of studying MBBS in India, various limiting factors such as overall cost of education, shortage of seats, limited clinical exposure, studying MBBS abroad has come out as a very attractive option for the aspirants. It not only provides a culturally enriching environment, fostering cross-cultural understanding and communication skills crucial for today's interconnected world but additionally, it often presents cost-effective alternatives compared to some domestic medical education programs."
                }
              />
            }
            images={images}
            button={
              <ButtonComponent
                text="Read More"
                // fontSize="18px"
                // padding="10px 30px"
                href="/mbbs/abroad"
                icon={<Icon icon="mynaui:arrow-long-right" />}
              />
            }
          />
        }
        com2={<ImageContainer img={mbbsImg2} />}
      />
    </>
  );
};
export default WhyStudyMbbsAbroad;
