import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Header from 'src/Component/content-component/Header'

const CourseSpecialization = ({ data }) => {
    return (
        <Box pt={6} pb={8} sx={{ backgroundColor: "#effdff" }}>

            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={"Specializtion"}
                        textAlign={{ md: "center", sm: "center", xs: "center" }}
                    />
                </Box>
                <Box >
                    <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
                        {data?.map((item, index) => (

                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ display: "flex", justifyContent: "center" }}>
                                <Box>
                                    <Box
                                        sx={{
                                            width: { xs: "300px", md: "350px" },
                                            height: "70px",
                                            bgcolor: "#ffffff",
                                            borderRadius: "10px",
                                            // border: "2px solid #00999E",
                                            boxShadow: " rgba(100, 100, 111, 0.2) 7px 7px 29px 0px",
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: "0 10px",
                                            justifyContent: "start", alignItems: "center"
                                        }}
                                    >
                                        <Box sx={{ width: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={item?.Image} width={"100%"} alt="immigration-benefit" />
                                        </Box>
                                        <Box sx={{ height: "100px", padding: "0px 10px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                            <Typography variant="body1" textAlign={"center"} sx={{ color: "#000000", fontWeight: "550", fontSize: "15px" }}>{item?.title}</Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </Box>
            </Container>
        </Box>
    )
}

export default CourseSpecialization
