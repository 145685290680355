import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import BarGraph from "./Components/BarGraph";
import ScoreMarks from "./Components/ScoreMarks";
import { Icon } from "@iconify/react";
import MainHeader from "src/Component/content-component/MainHeader";
import PracticeBarGraph from "./Components/PracticeBarGraph";
import PracticeScoreMarks from "./Components/PracticeScoreMarks";
const username = sessionStorage.getItem("username");

const Performances = () => {
  // State to keep track of the selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  // Handle tab change
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: "30px",
          alignItems: "center"
        }}
      >
        <Icon
          icon="game-icons:progression"
          style={{ color: "#00999E", fontSize: "28px" }}
        />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Performance"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <MainHeader content={"Hello,"} fontSize={"20px"} />

          <MainHeader content={username} color={"#454545"} fontSize={"20px"} />
        </Box>
        <Typography
          sx={{
            pt: "8px",
            pb: "25px",
            color: "#757575",
            fontWeight: "550",
            fontSize: "18px"
          }}
        >
          You are doing great. Keep practicing
        </Typography>
        <MainHeader
          content={"Mock Test Performance"}
          color={"#000000"}
          fontSize={"20px"}
        />
      </Box>
      <Box
        sx={{ m: "20px", border: "1px solid #a4a4a4", boxShadow: 2, p: "10px" }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="standard"
          textColor="inherit" // Allows to set custom colors via sx
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#00999E" // Custom color for the indicator
            }
          }}
        >
          <Tab label="Score Marks" />
          <Tab label="Analyze Performance" />
        </Tabs>

        {/* Content for each tab */}
        <Box sx={{ padding: 2 }}>
          {selectedTab === 0 && (
            <Box>
              <PracticeScoreMarks />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box>
              <PracticeBarGraph />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MainHeader
          content={"Over All Analysis"}
          color={"#000000"}
          fontSize={"20px"}
        />
      </Box>
      <Box
        sx={{ m: "20px", border: "1px solid #a4a4a4", boxShadow: 2, p: "10px" }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="standard"
          textColor="inherit" // Allows to set custom colors via sx
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#00999E" // Custom color for the indicator
            }
          }}
        >
          <Tab label="Score Marks" />
          <Tab label="Analyze Performance" />
        </Tabs>

        {/* Content for each tab */}
        <Box sx={{ padding: 2 }}>
          {selectedTab === 0 && (
            <Box>
              <ScoreMarks />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box>
              <BarGraph />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Performances;
