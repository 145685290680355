

const EngineeringFaq = [
    {
        "title": "What are the benefits of studying engineering abroad?",
        "Desc": "Studying engineering abroad offers diverse academic perspectives, exposure to cutting-edge technology, access to world-class facilities, opportunities for cultural enrichment, and enhanced career prospects through global networking."
    },
    {
        "title": "Which countries are popular destinations for studying engineering abroad?",
        "Desc": "Popular destinations for engineering studies abroad include the USA, UK, Canada, Australia, Germany, France, Singapore, and others, each offering unique advantages in terms of academic excellence, research opportunities, and industry connections."
    },
    {
        "title": "What are the typical admission requirements for engineering programs abroad?",
        "Desc": "Admission requirements vary by country and institution but generally include academic transcripts, standardized test scores (such as SAT, ACT, GRE, or IELTS/TOEFL for English proficiency), letters of recommendation, statement of purpose, and sometimes a portfolio for certain disciplines."
    },
    {
        "title": "Are scholarships available for international students pursuing engineering degrees abroad?",
        "Desc": "Yes, many universities abroad offer scholarships, grants, and financial aid packages specifically for international students, including those from India. These scholarships can be merit-based, need-based, or specific to particular engineering disciplines."
    },
    {
        "title": "How do I evaluate the quality of engineering programs offered by international universities?",
        "Desc": "Consider factors such as accreditation status, faculty expertise, research opportunities, industry partnerships, alumni success, campus infrastructure, and student support services when evaluating the quality of engineering programs abroad."
    },
    {
        "title": "Are there opportunities for internships or co-op programs during engineering studies abroad?",
        "Desc": "Many engineering programs abroad offer opportunities for internships, co-op programs, industry projects, and research collaborations with leading companies and organizations, providing valuable hands-on experience and networking opportunities for students."
    },
    {
        "title": "What are the visa requirements for Indian students pursuing engineering studies abroad?",
        "Desc": "Visa requirements vary by country, but typically involve obtaining a student visa or study permit, which may require proof of admission to a recognized institution, financial documentation, medical insurance, and other supporting documents."
    },
    {
        "title": "How can I adapt to the cultural and academic differences while studying engineering abroad?",
        "Desc": "Universities abroad often provide orientation programs, academic support services, cultural integration activities, and student clubs to help international students adapt to their new environment. Engaging with the local community, making friends, and seeking support when needed can also facilitate the transition."
    },
    {
        "title": "What career opportunities are available for international engineering graduates?",
        "Desc": "International engineering graduates can find diverse career opportunities in aerospace, automotive, biomedical, civil, computer, electrical, environmental, and mechanical engineering. They can pursue careers in multinational corporations, research institutions, government agencies, and startups globally."
    },
    {
        "title": "How can I stay connected with the Indian engineering community while studying abroad?",
        "Desc": "International universities often host Indian student associations or cultural clubs, facilitating social events and networking opportunities. Online platforms and alumni networks provide further connections and support for Indian engineering students studying abroad."
    }
];

const CSITFaq = [
    {
        "title": "What are the top destinations for studying Computer Science and Information Technology abroad as an Indian student?",
        "Desc": "Popular destinations include the USA, Canada, UK, Australia, Germany, Singapore, and others known for their quality education in these fields."
    },
    {
        "title": "What are the basic eligibility criteria for applying to Computer Science programs abroad?",
        "Desc": "Eligibility requirements typically include a strong academic background in mathematics and science, English proficiency (usually demonstrated through tests like TOEFL or IELTS), and satisfactory scores in standardized tests like the SAT or GRE."
    },
    {
        "title": "How do I choose the right university or college for Computer Science and IT studies abroad?",
        "Desc": "Consider factors such as program reputation, faculty expertise, research opportunities, industry connections, internship opportunities, location, and cost of living."
    },
    {
        "title": "What are the typical application requirements for international Computer Science programs?",
        "Desc": "Application requirements usually include transcripts, standardized test scores (like SAT or GRE), letters of recommendation, statement of purpose (SOP), resume/CV, and proof of English proficiency."
    },
    {
        "title": "Are scholarships available for Indian students pursuing Computer Science degrees abroad?",
        "Desc": "Yes, many universities offer scholarships, grants, and financial aid specifically for international students, including those from India. Additionally, there are external scholarship opportunities and government-sponsored programs."
    },
    {
        "title": "How much does it cost to study Computer Science abroad for Indian students?",
        "Desc": "The cost varies depending on the country, university, program, and duration of study. Generally, tuition fees for international students range from INR 10 lakhs to 50 lakhs per year, excluding living expenses."
    },
    {
        "title": "Can I work while studying Computer Science abroad as an Indian student?",
        "Desc": "Many countries allow international students to work part-time during their studies to support themselves financially. However, there are usually restrictions on the number of hours you can work."
    },
    {
        "title": "What are the post-graduation opportunities for Indian students after completing a Computer Science degree abroad?",
        "Desc": "Graduates can pursue various career paths in software development, IT consulting, cybersecurity, data analysis, artificial intelligence, and more. Many countries also offer post-study work visas to allow students to gain work experience after graduation."
    },
    {
        "title": "How do I apply for a student visa to study Computer Science abroad as an Indian student?",
        "Desc": "Once you receive an acceptance letter from a university, you'll need to apply for a student visa. Requirements vary by country but typically include proof of acceptance, financial stability, health insurance, and a clean criminal record."
    },
    {
        "title": "What support services are available for Indian students studying Computer Science abroad?",
        "Desc": "Most universities offer support services such as orientation programs, academic advising, career counseling, international student organizations, cultural integration programs, and access to health and wellness resources. Additionally, there are often Indian student associations or clubs on campus to provide a sense of community and support."
    }
];
const HsmFaq = [
    {
        "title": "Can Indian students study Health Sciences and Medicine abroad?",
        "Desc": "Absolutely! Many countries offer excellent programs in Health Sciences and Medicine that are open to international students, including those from India."
    },
    {
        "title": "What are the popular destinations for studying Health Sciences and Medicine abroad for Indian students?",
        "Desc": "Popular destinations include the United States, the United Kingdom, Canada, Australia, Germany, and other European countries like Ireland and the Netherlands."
    },
    {
        "title": "Are there any specific entrance exams required to study Health Sciences and Medicine abroad?",
        "Desc": "Yes, many countries require standardized tests such as the MCAT (Medical College Admission Test) for the US, the BMAT (Biomedical Admissions Test) or UKCAT (UK Clinical Aptitude Test) for the UK, and the GAMSAT (Graduate Medical School Admissions Test) for Australia."
    },
    {
        "title": "What are the language requirements for studying Health Sciences and Medicine abroad?",
        "Desc": "Language requirements vary depending on the country and institution. Generally, English proficiency tests such as the IELTS or TOEFL are required. Some countries may also require proficiency in the local language."
    },
    {
        "title": "Are scholarships available for Indian students pursuing Health Sciences and Medicine abroad?",
        "Desc": "Yes, many universities and organizations offer scholarships, grants, and financial aid specifically for international students, including those from India. It's important to research and apply for these opportunities early."
    },
    {
        "title": "How long does it take to complete a degree in Health Sciences and Medicine abroad?",
        "Desc": "The duration varies depending on the country and program. Typically, undergraduate programs last around 4-6 years, while postgraduate programs such as MD or MS can take an additional 2-4 years."
    },
    {
        "title": "Can Indian medical degrees be recognized abroad?",
        "Desc": "Yes, Indian medical degrees can be recognized abroad, but it often requires a process of verification and may vary depending on the destination country's regulations."
    },
    {
        "title": "Are there opportunities for internships or clinical placements during Health Sciences and Medicine programs abroad?",
        "Desc": "Yes, many programs offer opportunities for hands-on experience through internships, clinical rotations, and placements in hospitals, clinics, and research institutions."
    },
    {
        "title": "What are the career prospects for Indian students after studying Health Sciences and Medicine abroad?",
        "Desc": "Studying Health Sciences and Medicine abroad opens up a wide range of career opportunities globally. Indian students can pursue careers as physicians, surgeons, researchers, public health professionals, and more, both internationally and in India."
    },
    {
        "title": "How can Indian students prepare for studying Health Sciences and Medicine abroad?",
        "Desc": "Preparation may include thorough research on programs and institutions, preparing for entrance exams, meeting language requirements, gathering necessary documentation, applying for scholarships, and ensuring financial preparedness for tuition and living expenses abroad."
    }
]
    ;
const SocialFaq = [
    {
        "title": "Why should I study Social Sciences abroad as an Indian student?",
        "Desc": "Studying Social Sciences abroad offers exposure to diverse perspectives, cultures, and academic approaches. It enhances critical thinking, communication skills, and global awareness, making you a well-rounded professional."
    },
    {
        "title": "What are the popular destinations for studying Social Sciences abroad?",
        "Desc": "Popular destinations include the USA, UK, Canada, Australia, and European countries like Germany, France, and the Netherlands."
    },
    {
        "title": "Are scholarships available for Indian students pursuing Social Sciences abroad?",
        "Desc": "Yes, many universities and organizations offer scholarships specifically for international students, including those from India. These can help offset tuition and living expenses."
    },
    {
        "title": "How do I choose the right university for Social Sciences abroad?",
        "Desc": "Consider factors such as academic reputation, faculty expertise, program curriculum, location, campus facilities, and opportunities for internships or research."
    },
    {
        "title": "What career opportunities are available after studying Social Sciences abroad?",
        "Desc": "Graduates can pursue diverse career paths in fields such as social work, public policy, international relations, journalism, research, education, and non-profit organizations."
    },
    {
        "title": "Will language be a barrier for Indian students studying Social Sciences abroad?",
        "Desc": "Many universities offer programs taught in English, eliminating language barriers for Indian students. However, learning the local language can enhance the study abroad experience and open up additional opportunities."
    },
    {
        "title": "How can I finance my studies in Social Sciences abroad?",
        "Desc": "In addition to scholarships, options include student loans, financial aid, part-time work opportunities (where permitted by visa regulations), and savings."
    },
    {
        "title": "What is the visa process like for Indian students studying Social Sciences abroad?",
        "Desc": "The visa process varies by country and university. Typically, it involves providing proof of acceptance to a program, financial resources, health insurance, and meeting visa requirements specific to the destination country."
    },
    {
        "title": "How can I adapt to the cultural differences while studying Social Sciences abroad?",
        "Desc": "Stay open-minded, engage with local communities, participate in cultural events, join student clubs or organizations, and seek support from international student services offered by the university."
    },
    {
        "title": "What support services are available for Indian students studying Social Sciences abroad?",
        "Desc": "Universities often provide support services such as academic advising, career counseling, housing assistance, health services, and cultural integration programs to help international students succeed academically and socially."
    }
];
const BusinessFaq = [
    {
        "title": "Why should Indian students consider studying Business and Management abroad?",
        "Desc": "Studying Business and Management abroad offers exposure to international perspectives, access to world-class faculty, networking opportunities, and the chance to immerse oneself in diverse cultures, all of which can enhance career prospects and personal growth."
    },
    {
        "title": "What are the entry requirements for Business and Management programs abroad?",
        "Desc": "Entry requirements vary by country and institution but typically include academic transcripts, standardized test scores (like GMAT/GRE), letters of recommendation, statement of purpose, and English language proficiency tests (such as TOEFL or IELTS)."
    },
    {
        "title": "Are scholarships available for Indian students pursuing Business and Management abroad?",
        "Desc": "Yes, many institutions abroad offer scholarships and financial aid for international students, including those from India. These can be merit-based, need-based, or specific to certain programs. It's essential to research and apply for scholarships early."
    },
    {
        "title": "What are the visa requirements for Indian students studying Business and Management abroad?",
        "Desc": "Visa requirements vary depending on the country of study. Generally, students will need to provide proof of acceptance into a recognized institution, demonstrate financial solvency, and sometimes undergo medical examinations. Visa applications should be initiated well in advance."
    },
    {
        "title": "How can Indian students overcome language barriers while studying Business and Management abroad?",
        "Desc": "Many Business and Management programs abroad are taught in English, but language support services such as English language courses, tutoring, and language exchange programs are often available. Adapting to a new language may take time, but immersion and practice are key."
    },
    {
        "title": "What career opportunities are available for Indian students after completing Business and Management programs abroad?",
        "Desc": "Graduates of Business and Management programs abroad can pursue various career paths, including roles in consulting, finance, marketing, entrepreneurship, and international business. Networking, internships, and career services provided by institutions can facilitate job placement."
    },
    {
        "title": "How can Indian students cope with cultural differences while studying Business and Management abroad?",
        "Desc": "Cultural adaptation is part of the study abroad experience. Indian students can prepare by learning about the host country's customs, values, and etiquette before arrival. Engaging with local communities, participating in cultural events, and seeking support from student services can ease the transition."
    },
    {
        "title": "Can Indian students work part-time while studying Business and Management abroad?",
        "Desc": "In many countries, international students are allowed to work part-time during their studies to supplement their income and gain professional experience. However, there are usually restrictions on the number of hours students can work, depending on the country's regulations."
    },
    {
        "title": "How can Indian students ensure their safety and well-being while studying Business and Management abroad?",
        "Desc": "Institutions typically provide support services for international students, including orientation sessions, health services, counseling, and emergency assistance. It's essential for Indian students to familiarize themselves with safety protocols, local laws, and emergency contacts in their host country."
    },
    {
        "title": "How can Indian students stay connected with family and friends back home while studying Business and Management abroad?",
        "Desc": "With advancements in technology, staying connected has never been easier. Indian students can use various communication tools such as email, social media, messaging apps, and video calls to stay in touch with loved ones. Planning regular communication schedules can help maintain relationships despite the distance."
    }
];
const PlsFaq = [
    {
        "title": "What are the popular destinations for studying Physical and Life Sciences abroad?",
        "Desc": "Popular destinations include the USA, UK, Canada, Australia, Germany, and the Netherlands."
    },
    {
        "title": "Are scholarships available for Indian students pursuing Physical and Life Sciences abroad?",
        "Desc": "Yes, many universities offer scholarships and financial aid specifically for international students, including those from India. It's essential to research and apply for these opportunities early."
    },
    {
        "title": "How do I choose the right university for Physical and Life Sciences studies abroad?",
        "Desc": "Consider factors such as academic reputation, faculty expertise, research opportunities, location, campus facilities, and available specializations within your field of interest."
    },
    {
        "title": "What are the language requirements for studying Physical and Life Sciences abroad?",
        "Desc": "Language requirements vary by country and university. Most programs require proficiency in English, demonstrated through standardized tests like the TOEFL or IELTS."
    },
    {
        "title": "Can I work part-time while studying Physical and Life Sciences abroad?",
        "Desc": "Yes, many countries allow international students to work part-time during their studies. However, there are usually restrictions on the number of hours and types of employment. Check the regulations of the specific country you plan to study in."
    },
    {
        "title": "What career opportunities are available after completing a degree in Physical and Life Sciences abroad?",
        "Desc": "Graduates can pursue careers in research, academia, healthcare, environmental science, biotechnology, pharmaceuticals, and various other industries."
    },
    {
        "title": "How do I apply for a student visa to study Physical and Life Sciences abroad?",
        "Desc": "Once you receive an acceptance letter from a university, you'll need to apply for a student visa. Requirements vary by country, but typically include proof of acceptance, financial stability, health insurance, and a clean criminal record."
    },
    {
        "title": "Are there any cultural challenges I should be aware of as an Indian student studying abroad?",
        "Desc": "Cultural adaptation is part of the international student experience. It's essential to keep an open mind, respect local customs, and actively engage with the diverse community on campus."
    },
    {
        "title": "What are the living expenses like for Indian students studying Physical and Life Sciences abroad?",
        "Desc": "Living expenses vary depending on the country and city. It's important to budget for accommodation, food, transportation, healthcare, and other miscellaneous costs."
    },
    {
        "title": "How can I stay connected with my family and friends back in India while studying abroad?",
        "Desc": "Stay connected through various means such as video calls, social media, messaging apps, and email. Many universities also offer international student support services to help you adjust to life abroad."
    }
]
    ;
const FaaFaq = [
    {
        "title": "Can Indian students pursue Fine and Applied Arts abroad?",
        "Desc": "Yes, Indian students can definitely pursue Fine and Applied Arts abroad. Many countries offer excellent art programs that welcome international students."
    },
    {
        "title": "What are the popular destinations for studying Fine and Applied Arts overseas?",
        "Desc": "Popular destinations for studying Fine and Applied Arts include the United States, the United Kingdom, Canada, Australia, Germany, France, Italy, and the Netherlands."
    },
    {
        "title": "Are there language requirements for studying Fine and Applied Arts abroad?",
        "Desc": "Language requirements vary depending on the country and institution. Some programs may require proficiency in the local language, while others offer courses in English, which is beneficial for Indian students."
    },
    {
        "title": "What are the typical entry requirements for Fine and Applied Arts programs?",
        "Desc": "Entry requirements typically include a portfolio showcasing the applicant's artistic abilities, academic transcripts, letters of recommendation, and sometimes an interview or audition."
    },
    {
        "title": "Are there scholarships available for Indian students pursuing Fine and Applied Arts abroad?",
        "Desc": "Yes, there are scholarships specifically designed for international students, including Indian students, who wish to study Fine and Applied Arts abroad. These scholarships may be offered by universities, governments, or private organizations."
    },
    {
        "title": "What are the career prospects after completing a degree in Fine and Applied Arts abroad?",
        "Desc": "Graduates can pursue careers as visual artists, designers, illustrators, animators, art directors, curators, educators, and more. They may work in various industries such as advertising, publishing, film, television, museums, galleries, and education."
    },
    {
        "title": "Can international students work part-time while studying Fine and Applied Arts abroad?",
        "Desc": "Regulations regarding part-time work for international students vary by country. Many countries allow international students to work part-time during the academic year and full-time during scheduled breaks."
    },
    {
        "title": "How can Indian students adjust to the cultural differences while studying Fine and Applied Arts abroad?",
        "Desc": "Indian students can participate in orientation programs, join cultural clubs, interact with local students and faculty, explore the local community, and embrace the opportunity to learn from diverse perspectives."
    },
    {
        "title": "Are there opportunities for internships or practical experience during Fine and Applied Arts programs abroad?",
        "Desc": "Yes, many Fine and Applied Arts programs offer opportunities for internships, work placements, or collaborations with industry professionals, providing valuable hands-on experience and networking opportunities."
    },
    {
        "title": "How can Indian students find accommodation while studying Fine and Applied Arts abroad?",
        "Desc": "Universities often provide assistance with finding accommodation, including on-campus housing or recommendations for off-campus options. Indian students can also explore rental websites, student housing agencies, and local classifieds for housing options."
    }
]
    ;
const CJFaq = [
    {
        "title": "What are the popular destinations for studying Communication and Journalism abroad?",
        "Desc": "Popular destinations for studying Communication and Journalism abroad include the USA, UK, Canada, Australia, Germany, France, Singapore, and the Netherlands."
    },
    {
        "title": "What are the entry requirements for Communication and Journalism programs abroad?",
        "Desc": "Entry requirements vary by country and university but typically include academic transcripts, English language proficiency tests (such as IELTS or TOEFL), letters of recommendation, and a statement of purpose."
    },
    {
        "title": "Are there scholarships available for Indian students studying Communication and Journalism abroad?",
        "Desc": "Yes, many universities offer scholarships specifically for international students, including those from India. Additionally, there are government scholarships and external funding opportunities available."
    },
    {
        "title": "What is the duration of Communication and Journalism programs abroad?",
        "Desc": "Bachelor's programs typically last 3-4 years, while Master's programs usually last 1-2 years. However, program duration may vary depending on the country and university."
    },
    {
        "title": "What are the career prospects after completing a Communication and Journalism program abroad?",
        "Desc": "Graduates can pursue careers in various fields such as journalism, public relations, advertising, media production, digital media, corporate communications, and more."
    },
    {
        "title": "Is work experience required to apply for Communication and Journalism programs abroad?",
        "Desc": "Work experience may not be mandatory for admission, but relevant experience can enhance your application and provide valuable insights into the industry."
    },
    {
        "title": "Are internships available as part of Communication and Journalism programs abroad?",
        "Desc": "Many programs offer internship opportunities as part of the curriculum, allowing students to gain practical experience and network with industry professionals."
    },
    {
        "title": "What is the cost of studying Communication and Journalism abroad?",
        "Desc": "Tuition fees and living expenses vary depending on the country and university. Generally, studying abroad can be expensive, but scholarships, grants, and part-time work opportunities can help offset costs."
    },
    {
        "title": "Is knowledge of the local language necessary for studying Communication and Journalism abroad?",
        "Desc": "While some countries may offer programs in English, it can be beneficial to have basic knowledge of the local language, especially for immersion and networking purposes."
    },
    {
        "title": "What support services are available for international students studying Communication and Journalism abroad?",
        "Desc": "Universities typically offer support services such as orientation programs, academic advising, career counseling, student clubs, and international student offices to help students adjust to their new environment and succeed academically."
    }
]
    ;
const DesigningFaq = [
    {
        "title": "Can I pursue a degree in Designing abroad as an Indian student?",
        "Desc": "Absolutely! Many countries welcome international students, including Indians, to pursue degrees in Designing at their universities."
    },
    {
        "title": "What are the popular destinations for studying Designing abroad?",
        "Desc": "Popular destinations for Designing studies include the USA, UK, Canada, Australia, Germany, France, Netherlands, and Singapore."
    },
    {
        "title": "Are there scholarships available for Indian students studying Designing abroad?",
        "Desc": "Yes, many universities and governments offer scholarships and financial aid programs specifically for international students, including Indians, to support their studies in Designing abroad."
    },
    {
        "title": "Are English proficiency tests like IELTS or TOEFL required for admission to Designing programs abroad?",
        "Desc": "Yes, most universities require proof of English proficiency through tests like IELTS or TOEFL. However, some institutions may accept alternative qualifications or offer English language preparation courses."
    },
    {
        "title": "What is the typical duration of a Designing program abroad?",
        "Desc": "The duration varies depending on the level of the program. Bachelor's degrees usually take 3-4 years, while Master's degrees can range from 1-2 years."
    },
    {
        "title": "Will my Indian qualifications be recognized abroad?",
        "Desc": "Many universities abroad recognize Indian qualifications, but it's essential to check the specific admission requirements and equivalencies for the country and institution you're interested in."
    },
    {
        "title": "What are the career prospects after completing a Designing degree abroad as an Indian student?",
        "Desc": "Graduates of Designing programs abroad have diverse career opportunities, including graphic design, fashion design, industrial design, interior design, and more, both in India and internationally."
    },
    {
        "title": "How much does it cost to study Designing abroad for Indian students?",
        "Desc": "The cost varies depending on the country, university, and level of the program. Tuition fees, living expenses, and other costs should be considered when budgeting for studying abroad."
    },
    {
        "title": "Can I work part-time while studying Designing abroad as an Indian student?",
        "Desc": "Many countries allow international students to work part-time during their studies to supplement their income. However, there may be restrictions on the number of hours you can work, so it's essential to check the regulations of the country you're studying in."
    },
    {
        "title": "How can I apply for a Designing program abroad as an Indian student?",
        "Desc": "The application process typically involves submitting an online application form, academic transcripts, English proficiency test scores, letters of recommendation, a statement of purpose, and possibly a portfolio of your work for Designing programs. Each university may have specific requirements, so be sure to check their website for detailed instructions."
    }
]
    ;


export { EngineeringFaq, CSITFaq, HsmFaq, SocialFaq, BusinessFaq, PlsFaq, FaaFaq, CJFaq, DesigningFaq }