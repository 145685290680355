// data.js

export const viewmoredata = {
    personalized: [
        {
            id: 1,
            title: 'COUNSELLING',
            about: 'Experience world-class education counseling that helps you navigate your academic journey with confidence'
        },
        {
            id: 2,
            title: 'PYSCHOMETRIC TEST',
            about: 'Receive expert assistance in navigating psychometric tests, helping you choose the perfect career pathway'
        },
        {
            id: 3,
            title: 'PROFILE BUILDING',
            about: 'Forge a compelling academic profile with our expert guidance, as we assist you in crafting a well-rounded and impactful representation of your achievements'
        }
    ],
    career: [
        {
            id: 1,
            title: 'COUNTRIES',
            about: 'Our experienced advisors will assist you in the intricate process of shortlisting countries for your education'
        },
        {
            id: 2,
            title: 'COURSES',
            about: 'Providing tailored assistance to match your academic interests, career goals, and desired learning outcomes'
        },
        {
            id: 3,
            title: 'UNIVERSITIES',
            about: 'Get personalized assistance in shortlisting universities that align with your academic and career goals'
        }
    ],
    admission: [
        {
            id: 1,
            title: 'EXAM PREPARATION',
            about: 'Gain a competitive edge with our comprehensive exam preparation assistance'
        },
        // {
        //     id: 2,
        //     title: 'PROFICIENCY TESTS',
        //     about: 'Our dedicated resources and expert guidance empower you to excel in language and proficiency exams, paving the way for your academic success'
        // },
        {
            id: 3,
            title: 'LOR/ESSAYs/SOPs',
            about: 'Elevate your application with our expert assistance in crafting compelling letters of recommendation'
        },
        // {
        //     id: 4,
        //     title: 'ESSAYs/SOPs',
        //     about: 'Craft persuasive statements of purpose and impactful essays with our dedicated team'
        // },
        {
            id: 5,
            title: 'SCHOLARSHIPS',
            about: 'We recognize your academic brilliance by helping you secure the funding you need to pursue your academic dreams'
        }
    ],
    post: [
        {
            id: 1,
            title: 'EDUCATION LOAN',
            about: 'Ensuring personalized guidance to secure the financial support you need for your academic aspirations'
        },
        {
            id: 2,
            title: 'VISA/IMMIGRATION/TRAVEL & FOREX',
            about: 'Facilitating a smooth and expertly-guided process to navigate the complexities of visa applications and immigration requirements'
        },
        {
            id: 3,
            title: 'ACCOMMODATION',
            about: 'Simplify your transition to studying abroad with our specialized accommodation assistance'
        },
        // {
        //     id: 4,
        //     title: 'TRAVEL & FOREX',
        //     about: 'Personalised support support in travel planning and foreign exchange arrangements, allowing you to embark on your academic adventure with ease'
        // }
    ]
};
