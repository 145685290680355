const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const  Aspect1 =imageBaseUrl + "image/Duration.png";
const  Aspect2 =imageBaseUrl + "image/Eligibility.png";
const  Aspect3 =imageBaseUrl + "image/Eligibility.png";
const  Aspect4 =imageBaseUrl + "image/CostofLiving.png";
const  Aspect5 =imageBaseUrl + "image/TuitionFees.png";
const  Aspect6 =imageBaseUrl + "image/Average Salary.png";
const  Aspect7 =imageBaseUrl + "image/Cost of Living.png";
const NepalAspectData = [
  {
    title: "Duration",
    items: [
      "Duration: Typically 4.5 to 5.5 years, including 1 year of internship",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of high school education (equivalent to 10+2) with Science stream (Physics, Chemistry, Biology)",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      " Various universities may conduct their own entrance exams, while others may admit based on high school performance",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Tuition fees vary by university and can range from NPR 3,00,000 to NPR 25,00,000 for the entire program (Write in INR)",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Average salary for a medical practitioner in Nepal is around NPR 40,000 to NPR 60,000 per month",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      " Cost of living varies depending on the city and lifestyle, estimated at around NPR 20,000 to NPR 40,000 per month for students",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Various scholarships and financial aid options are available for Nepalese students, offered by universities, government, and private organisations",
    ],
    Image: Aspect7,
  },
];
const BangladeshAspectData = [
  {
    title: "Duration",
    items: [
      "Duration: Typically 5 years, including 1 year of internship",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of high school education (equivalent to 10+2) with Science stream (Physics, Chemistry, Biology)",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "Various universities may conduct their own entrance exams, while others may admit based on high school performance",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Tuition fees vary by university and can range from BDT 20,00,000 to BDT 45,00,000 for the entire program",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Average salary for a medical practitioner in Bangladesh is around BDT 50,000 to BDT 1,00,000 per month",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Cost of living varies depending on the city and lifestyle, estimated at around BDT 20,000 to BDT 30,000 per month for students",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Various scholarships and financial aid options are available for Bangladeshi students, offered by universities, government, and private organisations",
    ],
    Image: Aspect7,
  },
];
const CanadaAspectData = [
  {
    title: "Duration",
    items: [
      "Duration: Typically 3 to 4 years for undergraduate medical education, followed by residency training",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of high school education (equivalent to 10+2) with Science stream (Physics, Chemistry, Biology)",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "MCAT (Medical College Admission Test) is commonly accepted for admission to medical schools in Canada",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Tuition fees vary by university and can range from CAD 20,000 to CAD 80,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      " Average salary for a medical practitioner in Canada is around CAD 200,000 to CAD 300,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Cost of living varies depending on the city and lifestyle, estimated at around CAD 15,000 to CAD 30,000 per year for students",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Various scholarships and financial aid options are available for both Canadian and international students, offered by universities, government, and private organizations",
    ],
    Image: Aspect7,
  },
];
const GermanyAspectData = [
  {
    title: "Duration",
    items: [
      "Duration: Typically 6 years, including pre-clinical and clinical phases",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of high school education (equivalent to 10+2) with Science stream (Physics, Chemistry, Biology)",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "Some universities may require Test for Medical Studies (TMS) or SAT Subject Tests, while others may admit based on high school performance",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Tuition fees are relatively low or even free at public universities, but administrative fees may apply. Private universities may have higher tuition fees, typically ranging from EUR 10,000 to EUR 20,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Average salary for a medical practitioner in Germany is around EUR 60,000 to EUR 80,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Cost of living varies depending on the city and lifestyle, estimated at around EUR 700 to EUR 1,000 per month for students",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      " Various scholarships and financial aid options are available for both German and international students, offered by universities, government, and private organizations",
    ],
    Image: Aspect7,
  },
];
const PhilippinesAspectData = [
  {
    title: "Duration",
    items: [
      "Duration: Typically 5.5 to 6 years, including 1 year of internship",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      " Completion of high school education (equivalent to 10+2) with Science stream (Physics, Chemistry, Biology)",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "Some universities may require their own entrance exams, while others may admit based on high school performance",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Tuition fees vary by university and can range from PHP 1,20,000 to PHP 3,00,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Average salary for a medical practitioner in the Philippines is around PHP 600,000 to PHP 1,000,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Cost of living varies depending on the city and lifestyle, estimated at around PHP 20,000 to PHP 40,000 per month for students",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Various scholarships and financial aid options are available for Filipino and international students, offered by universities, government, and private organizations",
    ],
    Image: Aspect7,
  },
];
const AustraliaAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 4 to 6 years, including clinical rotations and internship",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of high school education (equivalent to 10+2) with Science stream /UMAT/UCAT",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      " Medical Examination- UMAT/UCAT English language proficiency tests such as IELTS or TOEFL ",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "May vary by university and can range from AUD 70,000 to AUD 101,000 ",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "For a medical practitioner in Australia is around AUD 100,000 to AUD 210,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "It varies depending on the city and lifestyle, estimated at around AUD 20,000 to AUD 30,000 per year for international students",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Various scholarships and financial aid options are available for international students, offered by universities, government, and private organisations",
    ],
    Image: Aspect7,
  },
];
const RussiaAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 6 years, including 1 year of internship",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of high school education (equivalent to 10+2) with Science stream (Physics, Chemistry, Biology)/ NEET",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "Some universities may require entrance exams such as NEET, others may have their own entrance exams",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Tuition fees vary by university and can range from 1.8 lakh to 8.5 lakh a year",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Average salary for a medical practitioner in Russia is approximately $20,000 to $30,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Cost of living varies depending on the city and lifestyle, estimated at around $5,000 to $10,000 per year",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Some universities offer scholarships and financial aid options for international students",
    ],
    Image: Aspect7,
  },
];
const UKAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 5 to 6 years of undergraduate medical education",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of pre-medical undergraduate coursework and UKCAT/BMAT exam",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "UK Clinical Aptitude Test (UKCAT) or BioMedical Admissions Test (BMAT)",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Varies by institution and domicile status, ranging from £9,250 to £38,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Varies based on specialization and location, but can range from £25,000 to £100,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Depending on the city, can range from £10,000 to £15,000 per year",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Available at some institutions and through external organizations",
    ],
    Image: Aspect7,
  },
];
const KazakhstanAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 5 to 6 years of undergraduate medical education",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of pre-medical undergraduate coursework and university-specific entrance exams",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "University-specific entrance exams or other standardized tests as determined by the institution",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Varies by institution, ranging from $3,000 to $7,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Varies based on specialization and location, but can range from $15,000 to $30,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Depending on the city, can range from $3,000 to $5,000 per year",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Some institutions may offer scholarships based on academic merit or financial need",
    ],
    Image: Aspect7,
  },
];
const KyrgyzstanAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 5 to 6 years of undergraduate medical education",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of pre-medical undergraduate coursework and university-specific entrance exams",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "University-specific entrance exams or other standardized tests as determined by the institution",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Varies by institution, ranging from $2,500 to $5,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Varies based on specialization and location, but can range from $15,000 to $30,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Depending on the city, can range from $3,000 to $5,000 per year",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Depending on the city, can range from $3,000 to $5,000 per year",
    ],
    Image: Aspect7,
  },
];
const UzbekistanAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 5 to 6 years of undergraduate medical education",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of pre-medical undergraduate coursework and university-specific entrance exams",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "University-specific entrance exams or other standardized tests as determined by the institution",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Varies by institution, ranging from $2,000 to $5,000 per year for international students",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Varies based on specialization and location, but can range from $10,000 to $20,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Depending on the city, can range from $2,000 to $5,000 per year",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Some institutions may offer scholarships based on academic merit or financial need",
    ],
    Image: Aspect7,
  },
];
const USAAspectData = [
  {
    title: "Duration",
    items: [
      "Typically 4 years of undergraduate study followed by 4 years of medical school",
    ],
    Image: Aspect1,
  },
  {
    title: "Eligibility",
    items: [
      "Completion of pre-medical undergraduate coursework and MCAT exam",
    ],
    Image: Aspect2,
  },
  {
    title: "Test Accepted",
    items: [
      "Medical College Admission Test (MCAT)",
    ],
    Image: Aspect3,
  },
  {
    title: "Tuition Fees",
    items: [
      "Varies widely by institution, ranging from $30,000 to over $60,000 per year",
    ],
    Image: Aspect4,
  },
  {
    title: "Average Salary",
    items: [
      "Varies based on specialization and location, but can range from $200,000 to $500,000 per year",
    ],
    Image: Aspect5,
  },
  {
    title: "Cost of Living",
    items: [
      "Depending on the city, can range from $15,000 to $30,000 per year",
    ],
    Image: Aspect6,
  },
  {
    title: "Scholarships",
    items: [
      "Available at some institutions and through external organizations",
    ],
    Image: Aspect7,
  },
];

export  {NepalAspectData,BangladeshAspectData,CanadaAspectData,GermanyAspectData,PhilippinesAspectData,AustraliaAspectData,RussiaAspectData,UKAspectData,KazakhstanAspectData,KyrgyzstanAspectData,UzbekistanAspectData,USAAspectData};
