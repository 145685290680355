import { Box, Container, Grid } from "@mui/material";
import Card from "src/Component/card-components/Card";
import Header from "src/Component/content-component/Header";
const MBBSCollege = ({ data }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#effdff",
          padding: { xs: "10px 0", md: "50px 0" },
        }}
        id="colleges"
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0",
            }}
          >
            <Header content={data?.heading} textAlign={"center"} />
          </Box>

          <Grid
            container
            spacing={2}
            columns={{ xs: 12, sm: 12, md: 12, lg: 15 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {data?.content?.map((college, index) => {
              return (
                <>
                  <Grid
                    item
                    xs={15}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    key={index}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card
                      data={college}
                      borderRadius="10%"
                      width="150px"
                      height="150px"
                      listStyle={"none"}
                      fontSize={"16px"}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default MBBSCollege;
