import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import axios from "axios";
import back from "../../asset/universityfinder/back.png";
import front from "../../asset/universityfinder/front.png";

// Constants
const ITEMS_PER_PAGE = 12;
const STATES_API_URL = `${process.env.REACT_APP_BASE_URL}master/states`;

const StateSelect = ({ countryId, onSelectState, setFilters }) => {
  const [page, setPage] = useState(0);
  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState(null); // Track selected state

  // Fetch states data based on the selected country
  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await axios.post(
          STATES_API_URL,
          { countryId },
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.data.success && response.data.status === 200) {
          setStateData(response.data.data); // Assuming the API returns an array of states
        } else {
          console.error("Failed to fetch states:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    if (countryId) {
      fetchStatesData();
    }
  }, [countryId]);

  // Function to handle page navigation
  const handlePageChange = (direction) => {
    setPage((prevPage) => {
      if (
        direction === "next" &&
        (prevPage + 1) * ITEMS_PER_PAGE < stateData.length
      ) {
        return prevPage + 1;
      } else if (direction === "prev" && prevPage > 0) {
        return prevPage - 1;
      }
      return prevPage; // Return the current page if no change
    });
  };

  // Function to handle state selection
  const handleStateClick = (state) => {
    setSelectedState(state); // Update the selected state
    onSelectState && onSelectState(state);
    setFilters((prev) => ({
      ...prev,
      state: state._id
    })); // Notify parent component if callback is provided
  };

  // Get the items for the current page
  const itemsToDisplay = stateData.slice(
    page * ITEMS_PER_PAGE,
    (page + 1) * ITEMS_PER_PAGE
  );

  return (
    <Box>
      {stateData.length > 0 && (
        <>
          <Typography
            variant="h6"
            sx={{ marginTop: "20px", textAlign: "start" }}
          >
            Select a State:
          </Typography>
          <Grid
            container
            spacing={1}
            sx={{
              height: {
                sm: "400px",
                xs: "auto",
                display: "flex",
                justifyContent: "space-around"
              }
            }}
          >
            {itemsToDisplay.map((state) => (
              <Grid item xs={6} md={3} key={state._id}>
                <Box
                  sx={{
                    border: "2px solid #c1c1c1",
                    borderRadius: "10px",
                    height: "110px !important",
                    width: "120px !important",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    overflow: "hidden",
                    cursor: "pointer",
                    textAlign: "center",
                    backgroundColor:
                      selectedState === state ? "#00999E" : "#Effdff" // Highlight selected state
                  }}
                  onClick={() => handleStateClick(state)} // Handle state click
                >
                  <Box
                    sx={{ width: "80px", height: "65px", padding: "10px 0" }}
                  >
                    <img
                      src={state.image}
                      alt=""
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      color: selectedState === state ? "#fff" : "#000"
                    }}
                  >
                    {state.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* Page navigation for states */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                width: "80px",
                cursor: page === 0 ? "default" : "pointer",
                opacity: page === 0 ? 0.5 : 1
              }}
              onClick={() => handlePageChange("prev")}
            >
              <img src={back} alt="Previous" width={"100%"} />
            </Box>
            <Box
              sx={{
                width: "80px",
                cursor:
                  (page + 1) * ITEMS_PER_PAGE >= stateData.length
                    ? "default"
                    : "pointer",
                opacity:
                  (page + 1) * ITEMS_PER_PAGE >= stateData.length ? 0.5 : 1
              }}
              onClick={() => handlePageChange("next")}
            >
              <img src={front} alt="Next" width={"100%"} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default StateSelect;
