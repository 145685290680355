import { Box, Card, CardMedia, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "../content-component/ButtonComponent";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import MainHeader from "../content-component/MainHeader";
import "../../Dashboard/slider.css";

const BestSellerCard = ({ data, imageheight, imagewidth }) => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(",", ""); // Replace comma for desired format
  };

  const handleExploreClick = () => {
    let route = "";
    switch (data.courseName) {
      case "IELTS ULTIMATE":
      case "IELTS BASICS":
        route = "ielts";
        break;
      case "GMAT BASICS":
      case "GMAT ULTIMATE":
        route = "gmat";
        break;
      case "GRE BASICS":
      case "GRE ULTIMATE":
        route = "gre";
        break;
      case "PTE BASICS":
      case "PTE ULTIMATE":
        route = "pte";
        break;
      case "TOEFL BASICS":
      case "TOEFL ULTIMATE":
        route = "toefl";
        break;
      case "SAT BASICS":
      case "SAT ULTIMATE":
        route = "sat";
        break;
      default:
        route = ""; // fallback route if needed
    }
    if (route) {
      navigate(`/test/${route}`);
    }
  };

  return (
    <Card
      sx={{
        width: { xs: "320px", md: "360px" },
        padding: "0 !important",
        height: "auto",
        marginBottom: "25px"
      }}
    >
      <Box>
        <CardMedia
          component="img"
          width={imagewidth ?? "100%"}
          height={imageheight ?? "200px"}
          image={data.image}
          alt="news image"
        />
        <Box
          sx={{
            padding: "15px",
            marginTop: "7px"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Typography
              variant="caption"
              sx={{
                color: "#00999E",
                fontSize: "13px",
                fontWeight: "500",
                textAlign: "left"
              }}
            >
              {formatDate(data.date)}
            </Typography>
          </Box>
          <MainHeader content={data.courseName} fontSize={"24px"} />

          <Typography
            variant="h5"
            sx={{
              fontSize: "16px",
              textAlign: "justify",
              fontWeight: "600",
              marginBottom: "10px"
            }}
          >
            {data.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "13px",
              margin: "5px 0",
              textAlign: "justify"
            }}
          >
            {data.description}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <ButtonComponent
              fontSize="14px"
              padding="10px 20px"
              icon={<Icon icon="fluent:arrow-right-28-regular" />}
              text="Explore"
              onClick={handleExploreClick}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default BestSellerCard;
