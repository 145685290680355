import img1 from "../../asset/ChooseUsImg1.svg";
import img2 from "../../asset/ChooseUsImg2.svg";
import img3 from "../../asset/ChooseUsImg3.svg";
import img4 from "../../asset/ChooseUsImg4.svg";

const CaliforniaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores, math and science prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree, GMAT/GRE scores, work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹60,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in computer science, GRE scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree, GRE scores, Work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's or master's degree in engineering, GRE scores, strong research"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹35,00,000"
      }
    ]
  }
];
const WisconsinFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade, SAT/ACT scores, math and science prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree, GMAT/GRE scores, work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹60,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in computer science, GRE scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree, GRE scores, work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's or master's degree in engineering, GRE scores, strong research"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 90+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹35,00,000"
      }
    ]
  }
];
const YeshivaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma, SAT/ACT scores, strong academic record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma, SAT/ACT scores, strong academic record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma, SAT/ACT scores, strong academic record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "High school diploma, SAT/ACT scores, strong academic record, math and science prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 80+, IELTS: 6.5+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's degree, GMAT/GRE scores, work experience, strong academic and professional record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 100+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹60,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's degree in computer science or related field, GRE scores, strong academic record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 100+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's degree, GRE scores, relevant work or volunteer experience, strong academic record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 100+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's or master's degree in engineering or related field, GRE scores, strong research and academic record"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 100+, IELTS: 7.0+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,00,000 - ₹45,00,000"
      }
    ]
  }
];
const UtahFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 71, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000 - ₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 71, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000 - ₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 71, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000 - ₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science and maths"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 71, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,000,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,000,000 - ₹2,500,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,000,000 - ₹2,500,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,000,000 - ₹2,500,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000 - ₹3,000,000"
      }
    ]
  }
];
const ColoradoFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 79, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS, GMAT"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 80, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS, GRE"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 80, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 80, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img3,
        title: "Test Score Requirement",
        value: "TOEFL: 80, IELTS: 6.5"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  }
];

const IllinoisFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Math/Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree + work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  }
];

const StanfordFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Math/Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree + work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹55,00,000 - ₹60,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹55,00,000 - ₹60,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹55,00,000 - ₹60,00,000" // Updated fees
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL, IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹55,00,000 - ₹60,00,000" // Updated fees
      }
    ]
  }
];

const PennsylvaniaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with math and science"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,00,000 - ₹50,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree, work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹60,00,000 - ₹70,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹60,00,000" // Updated fees
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree, relevant experience"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹60,00,000" // Updated fees
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "TOEFL: 100+ / IELTS: 7.0+"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹55,00,000" // Updated fees
      }
    ]
  }
];

const ChicagoFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Math/Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree + work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹65,00,000 - ₹70,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹65,00,000 - ₹70,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹65,00,000 - ₹70,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹65,00,000 - ₹70,00,000"
      }
    ]
  }
];

const MITFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },  
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Math/Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,00,000 - ₹55,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree + work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹70,00,000 - ₹75,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹70,00,000 - ₹75,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹70,00,000 - ₹75,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS"
      },
      {
        image: img4,
        title: "Approximate Annual Fees (INR)",
        value: "₹70,00,000 - ₹75,00,000"
      }
    ]
  }
];








//UK

const BristolFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,000,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,200,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,200,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,000,000 - ₹2,400,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree, relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 95"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹3,000,000 - ₹3,500,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000 - ₹3,000,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,000,000 - ₹2,500,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000 - ₹3,000,000"
      }
    ]
  }
];
const LeedsFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹14,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor's degree in any discipline, work experience may be required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹22,00,000"
      }
    ]
  }
];
const GlasgowFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with a minimum of 60% marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with a strong background in Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with a minimum of 60% in Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "10+2 with a strong background in Mathematics and Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor’s degree with a minimum of 60% marks, relevant work experience may be required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor’s degree in Computer Science or related field with a minimum of 60% marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Bachelor’s degree in Public Health or related field with a minimum of 60% marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5+ / TOEFL: 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Master’s degree in Engineering with a strong academic record and research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0+ / TOEFL: 100+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹30,00,000"
      }
    ]
  }
];
const BirminghamFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary education with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary education with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary education with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Completion of secondary education with science and maths subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹24,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree and work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Relevant undergraduate degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Relevant undergraduate degree and relevant work experience or background"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant master's degree and research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  }
];
const ImperialFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "High school diploma or equivalent, specific subject requirements vary"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "High school diploma or equivalent, specific subject requirements vary"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "High school diploma or equivalent, specific subject requirements vary"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Physics, Chemistry, and Math"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹40,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree, work experience required (2-5 years)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹45,00,000 - ₹60,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field, relevant experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹40,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Master’s degree in Engineering or related field, research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹50,00,000"
      }
    ]
  }
];

const QueenMaryFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with good academic standing (specific grade requirements depend on the program)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92, PTE: 62+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard in relevant subjects (e.g., Science or Math for certain courses)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92, PTE: 62+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Similar to BSc (with a focus on Business/Commerce in 12th standard)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92, PTE: 62+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Physics, Chemistry, and Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92, PTE: 62+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 28 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree, 2-3 years of work experience (for MSc Management courses)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0, TOEFL: 100, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22 - 25 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92, PTE: 62+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22 - 25 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Medicine, Nursing, or a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 92, PTE: 62+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22 - 24 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a related field, research proposal, and academic references"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0, TOEFL: 100, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 30 Lakhs"
      }
    ]
  }
];


const LiverpoolFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 - 6.5 (minimum 5.5 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with specific subjects (Math, Science)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 - 6.5 (minimum 5.5 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with specific subjects (Math, English)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 - 6.5 (minimum 5.5 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Math and Physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 - 6.5 (minimum 5.5 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree, work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 - 7.0 (minimum 6.0 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹28,00,000 - ₹32,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 - 7.0 (minimum 6.0 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹28,00,000 - ₹32,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree, relevant experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 - 7.0 (minimum 6.0 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹28,00,000 - ₹32,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in relevant field, research proposal required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 - 7.0 (minimum 6.0 in each)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  }
];

const EdinburghFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with mathematics & science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in computer science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant master's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  }
];

const SouthamptonFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with mathematics & science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in computer science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant master's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  }
];


const DurhamFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "A-levels or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "A-levels or equivalent in science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "A-levels or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "A-levels in Maths and Physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree and work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (7.0 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹24,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹24,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5 overall)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  }
];




const UNSWFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Completion of secondary education; specific course prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000 - ₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Completion of secondary education; specific science prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000 - ₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Completion of secondary education; specific commerce prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000 - ₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value:
          "Completion of secondary education; specific engineering prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,000,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree; work experience may be required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: Minimum 7.0 overall; TOEFL: Minimum 94 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,000,000 - ₹2,400,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in a related field; specific prerequisites"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,000,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree; relevant work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,000,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a related field; research proposal required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value:
          "IELTS: Minimum 6.5 overall (no band < 6.0); TOEFL: Minimum 85 overall"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000 - ₹2,000,000 (varies; may have funding options)"
      }
    ]
  }
];
const MonashFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with Mathematics and Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree from a recognized institution"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (with no band < 6)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  }
];
const UQFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; math and science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree; work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree; programming knowledge"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree; health-related background"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in relevant field; research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 87"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹3,000,000"
      }
    ]
  }
];
const WesternAustraliaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion; math and science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree; work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree; programming knowledge"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree; health-related background"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,500,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in relevant field; research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 82"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹3,000,000"
      }
    ]
  }
];
const AdelaideFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary education (12 years)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 / TOEFL 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 / TOEFL 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with math and science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree and relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 79"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 79"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 79"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant master's degree and research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 79"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  }
];
const MelbourneFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,000 - ₹40,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,000 - ₹50,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,000 - ₹50,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Mathematics and Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,000 - ₹60,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹60,000 - ₹90,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹80,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹80,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant Master's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹80,000"
      }
    ]
  }
];

  const TrentFeeStructure = [
    {
      course: "Bachelor of Arts",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "12th grade or equivalent"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.0 / TOEFL: 60"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹25,000 - ₹40,000"
        }
      ]
    },
    {
      course: "Bachelor of Science",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "12th grade with Science stream"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.0 / TOEFL: 60"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹30,000 - ₹50,000"
        }
      ]
    },
    {
      course: "Bachelor of Commerce",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "12th grade with Commerce stream"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.0 / TOEFL: 60"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹30,000 - ₹50,000"
        }
      ]
    },
    {
      course: "Bachelor of Engineering",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "12th grade with Mathematics and Science"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.5 / TOEFL: 70"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹40,000 - ₹60,000"
        }
      ]
    },
    {
      course: "Master of Business Administration (MBA)",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "Bachelor's degree in any field"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.5 / TOEFL: 80"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹60,000 - ₹90,000"
        }
      ]
    },
    {
      course: "Master of Computer Science",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "Bachelor's degree in Computer Science or related field"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.5 / TOEFL: 80"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹50,000 - ₹80,000"
        }
      ]
    },
    {
      course: "Master of Public Health",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "Bachelor's degree in health sciences or related field"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.5 / TOEFL: 80"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹50,000 - ₹80,000"
        }
      ]
    },
    {
      course: "PhD in Engineering",
      details: [
        {
          image: img1,
          title: "Eligibility Criteria",
          value: "Relevant Master's degree"
        },
        {
          image: img2,
          title: "English Proficiency Test",
          value: "IELTS: 6.5 / TOEFL: 80"
        },
        {
          image: img3,
          title: "Approximate Annual Fees (INR)",
          value: "₹50,000 - ₹80,000"
        }
      ]
    }
  ];

const TrinityFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with good academic standing (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹17 - 21 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with Science subjects, Mathematics (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹18 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with Commerce or Business background (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹17 - 21 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with Physics, Chemistry, and Mathematics (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹18 - 23 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with a minimum 70%, work experience is preferred"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 24 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field, minimum 70%"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹19 - 23 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a health-related field, minimum 70%"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 24 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Master’s degree in a related field, research proposal, academic references required"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹22 - 25 Lakhs"
      }
    ]
  }
];

const RRUFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with good academic standing (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹17 - 21 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with Science subjects, Mathematics (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹18 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with Commerce or Business background (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹17 - 21 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "12th standard with Physics, Chemistry, and Mathematics (minimum 65%)"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹18 - 23 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with a minimum 70%, work experience is preferred"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 24 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field, minimum 70%"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 58+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹19 - 23 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a health-related field, minimum 70%"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 24 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        title: "Eligibility Criteria",
        value: "Master’s degree in a related field, research proposal, academic references required"
      },
      {
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        title: "Approximate Annual Fees (INR)",
        value: "₹22 - 25 Lakhs"
      }
    ]
  }
];

const WaterlooFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with good academic standing (specific grade requirements depend on the program)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Science background, good academic standing"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Commerce/Business focus"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 22 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Physics, Chemistry, and Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 28 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with 2-3 years of work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 100, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 30 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 30 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Medicine, Nursing, or a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 30 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a related field with research experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 30 Lakhs"
      }
    ]
  }
];

const CalgeryFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with good academic standing (specific grade requirements depend on the program)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 86+, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15 - 18 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with a Science background, specific requirements based on program"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 86+, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15 - 18 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Commerce/Business focus"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 86+, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16 - 18 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Physics, Chemistry, Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 86+, PTE: 60+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 25 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree, minimum 2-3 years of work experience (GMAT/GRE score may be required)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25 - 30 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or a related field (some programs may require GRE scores)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 25 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a health-related field such as Medicine, Nursing, or a related discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 25 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a related engineering discipline, prior research experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 30 Lakhs"
      }
    ]
  }
];

const DalhousieFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with good academic standing, minimum 75% (depending on the program)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90+, PTE: 61+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12 - 15 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Science stream, minimum 75% (program-specific requirements may apply)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90+, PTE: 61+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12 - 16 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Commerce background, minimum 75%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90+, PTE: 61+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹13 - 16 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th standard with Physics, Chemistry, and Mathematics, minimum 75%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90+, PTE: 61+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16 - 20 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with minimum 3.0 GPA, work experience (GMAT/GRE may be required)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20 - 25 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field, minimum GPA of 3.0"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15 - 20 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a related field such as Medicine, Nursing, or Health Sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15 - 20 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a related engineering discipline, research experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+, PTE: 68+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18 - 25 Lakhs"
      }
    ]
  }
];

const BrockFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with specific course requirements"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with science courses"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with business-related courses"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with maths and science courses"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree with relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree, preferably in health or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  }
];

const WindsorFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 83+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹19,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 83+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹21,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 83+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹19,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with maths and science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 83+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree, GMAT score, work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in computer science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 83+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 83+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field, research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹30,00,000"
      }
    ]
  }
];

const VCCFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "12th grade with relevant subjects" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹10,00,000 - ₹12,00,000" }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "12th grade with science subjects" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹10,00,000 - ₹12,00,000" }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "12th grade with commerce subjects" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹10,00,000 - ₹12,00,000" }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "12th grade with mathematics & science" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹12,00,000 - ₹15,00,000" }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Bachelor's degree in any discipline" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹15,00,000 - ₹20,00,000" }
    ]
  },
  {
    course: "Master of Computer Science (MSc)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Bachelor's degree in computer science or related" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹15,00,000 - ₹20,00,000" }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Bachelor's degree in health or related field" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹15,00,000 - ₹20,00,000" }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Relevant master's degree" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 / TOEFL: 90" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹20,00,000 - ₹25,00,000" }
    ]
  }
];

const TorontoFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      
      { image: img1, title: "Eligibility Criteria", value: "High school diploma or equivalent" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 (minimum 6.0 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹2,500,000 - ₹3,000,000" }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "High school diploma with Math and Science" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 (minimum 6.0 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹2,500,000 - ₹3,000,000" }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "High school diploma with Math and English" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 (minimum 6.0 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹2,500,000 - ₹3,000,000" }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "High school diploma with Math and Physics" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 6.5 (minimum 6.0 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹2,500,000 - ₹3,000,000" }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Undergraduate degree, work experience recommended" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 7.0 (minimum 6.5 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹3,500,000 - ₹4,000,000" }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Undergraduate degree in Computer Science or related field" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 7.0 (minimum 6.5 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹3,000,000 - ₹3,500,000" }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Undergraduate degree, relevant experience preferred" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 7.0 (minimum 6.5 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹3,000,000 - ₹3,500,000" }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      { image: img1, title: "Eligibility Criteria", value: "Master’s degree in relevant field, research proposal required" },
      { image: img2, title: "English Proficiency Test", value: "IELTS: 7.0 (minimum 6.5 in each)" },
      { image: img3, title: "Approximate Annual Fees (INR)", value: "₹3,500,000 - ₹4,000,000" }
    ]
  }
];





const SydneyFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,000 - ₹45,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,000 - ₹55,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,000 - ₹55,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Mathematics and Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹65,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0 / TOEFL: 94"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹70,000 - ₹100,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹60,000 - ₹80,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹60,000 - ₹80,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant Master's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹80,000"
      }
    ]
  }
];
const ANUFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,000 - ₹45,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,000 - ₹55,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹40,000 - ₹55,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Mathematics and Science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹65,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0 / TOEFL: 94"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹70,000 - ₹100,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹60,000 - ₹80,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹60,000 - ₹80,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant Master's degree"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹50,000 - ₹80,000"
      }
    ]
  }
];
const UTSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,400,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Physics, Chemistry, and Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,700,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  }
];
const MacquarieFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img1,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,400,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 60"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Physics, Chemistry, and Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,500,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,800,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,700,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 70"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  }
];

//Germany

const LMUFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];


const TUMFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];

const HeidelbergUniversityFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹3,50,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];

const HumboldtUniversityFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹3,50,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];


const RWTHFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹2,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹3,50,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];

const FUBFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree and relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "Generally no tuition fees; ₹1,000 - ₹3,000 (admin fees)"
      }
    ]
  }
];

const UOFFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree and relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,000 - ₹15,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "Generally no tuition fees; ₹1,000 - ₹3,000 (admin fees)"
      }
    ]
  }
];

const UOTFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree and relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,000 - ₹15,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "Generally no tuition fees; ₹1,000 - ₹3,000 (admin fees)"
      }
    ]
  }
];

const UOMFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree and relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,000 - ₹15,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "Generally no tuition fees; ₹1,000 - ₹3,000 (admin fees)"
      }
    ]
  }
];

const UOGFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,000 - ₹3,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree and relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,000 - ₹15,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,000 - ₹15,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80-90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "Generally no tuition fees; ₹1,000 - ₹3,000 (admin fees)"
      }
    ]
  }
];

//France

const UPSLFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on arts"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field, work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  }
];

const UPSFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on arts"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field, work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  }
];

const SUFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on arts"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.0+) or TOEFL (80+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field, work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in computer science or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (6.5+) or TOEFL (90+)"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  }
];


const UGAFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with relevant subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹3,60,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with strong results in science subjects (Math, Physics, Chemistry)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹3,60,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with a background in commerce/business studies."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹3,60,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with strong background in mathematics and science (Physics, Chemistry)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹4,50,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree + 2-3 years of professional work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 90, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,50,000 – ₹16,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree in Computer Science, Engineering, or related fields."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹4,50,000 – ₹9,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree (medicine, life sciences, public health, or related fields)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,50,000 – ₹11,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a relevant field, research proposal, and/or prior research experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,000 – ₹2,70,000"
      }
    ]
  }
];

const UDSFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with relevant subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹3,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with strong results in science subjects (Math, Physics, Chemistry)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹3,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with a background in commerce/business studies."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹3,50,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with strong background in mathematics and science (Physics, Chemistry)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,000 – ₹4,50,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree + 2-3 years of professional work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 90, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 – ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree in Computer Science, Engineering, or related fields."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹4,50,000 – ₹9,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant undergraduate degree (medicine, life sciences, public health, or related fields)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.0"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,50,000 – ₹10,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in relevant field, research proposal, and/or prior research experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL iBT: 80, IELTS: 6.5"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹35,000 – ₹2,50,000"
      }
    ]
  }
];

const UDMFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Physics, Chemistry, Math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,50,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Maths, Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with relevant field + work experience (optional)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (preferably in health-related field)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000 (varies per project)"
      }
    ]
  }
];

const UDLFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹6,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Physics, Chemistry, Math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹6,50,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹6,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Maths, Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with relevant field + work experience (optional)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (preferably in health-related field)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000 (varies per project)"
      }
    ]
  }
];


const UDNFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹6,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Physics, Chemistry, Math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹6,50,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹6,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Maths, Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with relevant field + work experience (optional)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (preferably in health-related field)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000 (varies per project)"
      }
    ]
  }
];

const UDBFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Physics, Chemistry, Math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,50,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Maths, Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,50,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with relevant field + work experience (optional)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (preferably in health-related field)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000 (varies per project)"
      }
    ]
  }
];

const UPPSFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Physics, Chemistry, Math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,50,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects (Maths, Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or TOEFL 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,50,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with relevant field + work experience (optional)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (preferably in health-related field)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹9,00,000 - ₹11,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or TOEFL 90+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000 (varies per project)"
      }
    ]
  }
];

//Italy

const PDMilanoFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; Mathematics and Physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  }
];

const UOMilanFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; Mathematics and Physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  }
];

const UOBolognaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma; Mathematics and Physics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 85"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  }
];

const UOFlorenceFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade, with math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  }
];

const UOPaduaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade, with math)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  }
];

const UOTurinFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0+ / TOEFL: 78+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹7,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade) with science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0+ / TOEFL: 78+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹7,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0+ / TOEFL: 78+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹5,00,000 - ₹7,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed secondary education (12th grade) with math/science"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0+ / TOEFL: 78+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹7,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5+ / TOEFL: 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5+ / TOEFL: 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health-related field or social sciences"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5+ / TOEFL: 80+"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  }
];

const RLSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];

const UONaplesFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Score",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];

const UOPisaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test Scores",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];


const IEDFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹1,50,000 - ₹3,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 78"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,00,000 - ₹4,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹2,50,000 - ₹5,00,000"
      }
    ]
  }
];

//Ireland

const TCDublinFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education, typically with a focus on Arts or Humanities."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education with strong background in Science and Mathematics."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education with a focus on Commerce, Mathematics, and English."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education with strong background in Mathematics, Physics, and Chemistry."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹24,00,000 - ₹28,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with a strong academic record and at least 2-3 years of work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹38,00,000 - ₹42,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in Computer Science, Engineering, or related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹27,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in Health-related fields, Social Sciences, or Science."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field with a strong research background."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000 (varies by program)"
      }
    ]
  }
];

const UCDublinFeeStructure = [
  {
    course: "Bachelor of Arts (BA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education, typically with a focus on Arts or Humanities."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science (BSc)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education with strong background in Science and Mathematics."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce (BCom)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education with a focus on Commerce, Mathematics, and English."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹19,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering (BEng)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education with strong background in Mathematics, Physics, and Chemistry."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹24,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with strong academic record and at least 2-3 years of work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0, TOEFL: 100, PTE: 68"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹32,00,000 - ₹36,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in Computer Science, Engineering, or related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹24,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health (MPH)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in Health-related fields, Social Sciences, or Science."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹24,00,000 - ₹28,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field with a strong research background."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 88, PTE: 63"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹30,00,000 (varies by program)"
      }
    ]
  }
];

const UCCorkFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹17,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce/Business subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Mathematics and Science (Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹21,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹28,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Computer Science/Engineering"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹23,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s Degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000 (approx.)"
      }
    ]
  }
];

const UOGalwayFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1, // Replace with actual image import or URL
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects",
      },
      {
        image: img2, // Replace with actual image import or URL
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent",
      },
      {
        image: img3, // Replace with actual image import or URL
        title: "Approximate Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science subjects",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce/Business subjects",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹17,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Mathematics and Science (Physics, Chemistry)",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹20,00,000",
      },
    ],
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in any discipline",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000",
      },
    ],
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Computer Science/Engineering",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹23,00,000",
      },
    ],
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Health-related field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹21,00,000",
      },
    ],
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s Degree in relevant field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹28,00,000 (approx.)",
      },
    ],
  },
];


const DCUFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1, // Replace with actual image import or URL
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects",
      },
      {
        image: img2, // Replace with actual image import or URL
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent",
      },
      {
        image: img3, // Replace with actual image import or URL
        title: "Approximate Annual Fees (INR)",
        value: "₹13,00,000 - ₹15,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science subjects",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹17,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce/Business subjects",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Mathematics and Science (Physics, Chemistry)",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹19,00,000",
      },
    ],
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in any discipline",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000",
      },
    ],
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Computer Science/Engineering",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹23,00,000",
      },
    ],
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Health-related field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹21,00,000",
      },
    ],
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s Degree in relevant field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent",
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000 (approx.)",
      },
    ],
  },
];


const UOLimerickFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹13,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹17,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce/Business subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Mathematics and Science (Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹19,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Computer Science/Engineering"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹23,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹21,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s Degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹28,00,000 (approx.)"
      }
    ]
  }
];

const MUFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹13,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce/Business subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Mathematics and Science (Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹15,00,000 - ₹17,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Computer Science/Engineering"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s Degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000 (approx.)"
      }
    ]
  }
];

const TUDublinFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹13,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce/Business subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹13,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Mathematics and Science (Physics, Chemistry)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹16,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹20,00,000 - ₹23,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Computer Science/Engineering"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹18,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s Degree in Health-related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹17,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s Degree in relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+ or equivalent"
      },
      {
        image: img3,
        title: "Approximate Annual Fees (INR)",
        value: "₹22,00,000 - ₹26,00,000 (approx.)"
      }
    ]
  }
];

const DBSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 or equivalent with minimum required marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 – ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with Science stream; minimum required marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 – ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with Commerce/related subjects; minimum marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 – ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with Physics, Chemistry, Math; strong scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 – ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum 50%); work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 88"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in IT/related field; minimum required marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 88"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹16,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in health sciences/related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 88"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s in Engineering/related field; research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0 / TOEFL: 100"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 – ₹20,00,000"
      }
    ]
  }
];

const RCSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 or equivalent with minimum required marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 – ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with Science stream; minimum required marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 – ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with Commerce/related subjects; minimum marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 – ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "10+2 with Physics, Chemistry, Math; strong scores"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 / TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 – ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum 50%); work experience preferred"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 88"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in IT/related field; minimum required marks"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 88"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹16,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in health sciences/related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 / TOEFL: 88"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s in Engineering/related field; research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 7.0 / TOEFL: 100"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 – ₹20,00,000"
      }
    ]
  }
];

//New-Zealand


const UOAFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Completion (12th Grade); Relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0-6.5 / TOEFL: 79-90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Completion (12th Grade); Relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0-6.5 / TOEFL: 79-90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Completion (12th Grade); Commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0-6.5 / TOEFL: 79-90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Completion (12th Grade); Mathematics, Physics, and Chemistry"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any discipline; Minimum 3 years of work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 – ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science/IT or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 – ₹24,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Health Sciences, Medicine, or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 – ₹24,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in Engineering or related field; Research proposal required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5-7.0 / TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 – ₹30,00,000 (varies by research)"
      }
    ]
  }
];


const MUniversityFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 70-75%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹17,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 – ₹28,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹26,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering/related field, research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  }
];

const UOWFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 70-75%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹24,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering/related field, research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹20,00,000"
      }
    ]
  }
];

const VUOWFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1, // Replace with the actual image
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2, // Replace with the actual image
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3, // Replace with the actual image
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 70-75%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹24,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering/related field, research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹20,00,000"
      }
    ]
  }
];

const UCanterburyFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 70-75%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences/related field, 60%+"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹24,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering/related field, research proposal"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹20,00,000"
      }
    ]
  }
];

const UOtagoFeeStructure =
[
  {
    "course": "Bachelor of Arts",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Science",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Completion of 12th standard with science subjects, 70-75%"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹16,00,000 – ₹20,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Commerce",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Completion of 12th standard with minimum 65-75% (varies)"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹14,00,000 – ₹18,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Engineering",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.0 (no band < 5.5), TOEFL: 80"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    "course": "Master of Business Administration (MBA)",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹20,00,000 – ₹25,00,000"
      }
    ]
  },
  {
    "course": "Master of Computer Science",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor’s degree in Computer Science/related field, 60%+"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹18,00,000 – ₹22,00,000"
      }
    ]
  },
  {
    "course": "Master of Public Health",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor’s degree in Health Sciences/related field, 60%+"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹18,00,000 – ₹24,00,000"
      }
    ]
  },
  {
    "course": "PhD in Engineering",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Master’s degree in Engineering/related field, research proposal"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS: 6.5 (no band < 6.0), TOEFL: 90"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹16,00,000 – ₹20,00,000"
      }
    ]
  }
];


const AUOTFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 70-75%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹18,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75% (varies)",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹22,00,000",
      },
    ],
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 – ₹25,00,000",
      },
    ],
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/related field, 60%+",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹20,00,000",
      },
    ],
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences/related field, 60%+",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹20,00,000",
      },
    ],
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering/related field, research proposal",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
];

const LUniversityFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹14,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 70-75%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 65-75%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹14,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 70-80%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 – ₹18,00,000",
      },
    ],
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (minimum second class or equivalent), work experience (preferred)",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 – ₹22,00,000",
      },
    ],
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/related field, 60%+",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹20,00,000",
      },
    ],
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Health Sciences/related field, 60%+",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 – ₹20,00,000",
      },
    ],
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering/related field, research proposal",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
];

const EIOTFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 60%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 – ₹12,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with science subjects, 65-70%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹14,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with minimum 60-70%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 – ₹12,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th standard with Physics, Chemistry, Maths, 65-70%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (minimum second class or equivalent), work experience",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Health Sciences/related field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
];

const UCICFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school (12th grade) with minimum 60-65%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 – ₹12,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school (12th grade) with science subjects, 65-70%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹14,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school (12th grade) with minimum 60-70%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 – ₹12,00,000",
      },
    ],
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school (12th grade) with Physics, Chemistry, Maths, 65-70%",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 (no band < 5.5), TOEFL: 80",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree (minimum second class or equivalent), work experience",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Health Sciences or related field",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 – ₹16,00,000",
      },
    ],
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in Engineering or related field, research proposal",
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 (no band < 6.0), TOEFL: 90",
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 – ₹14,00,000",
      },
    ],
  },
];

//Singapore 

const NUSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Graduation (12th Grade) with relevant subject prerequisites (e.g., Humanities, Social Sciences)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Graduation (12th Grade) with relevant science subjects (Math, Physics, Chemistry, Biology, etc.)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000 - ₹28,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Graduation (12th Grade) with Mathematics/Commerce subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School Graduation (12th Grade) with Mathematics, Physics (Engineering specializations)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree with minimum 2-3 years of work experience; GMAT or GRE score (waivers possible for some specializations)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+), PTE (60+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹40,00,000 - ₹45,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Undergraduate degree in Computer Science/Engineering with relevant academic background in programming and mathematics."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health-related field or social sciences; relevant work experience (optional but beneficial)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field with research proposal or interest in specific areas of research."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90+), IELTS (6.5+)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹30,00,000 - ₹35,00,000"
      }
    ]
  }
];

const NTUFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subject-specific qualifications (e.g., Humanities, Social Sciences)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 85+, IELTS 6.0+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12-15 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with science subjects (Physics, Chemistry, Maths)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 85+, IELTS 6.0+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12-16 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Commerce stream or equivalent qualifications."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 85+, IELTS 6.0+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12-15 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Physics, Chemistry, and Mathematics."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 85+, IELTS 6.0+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15-18 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with 2-3 years of work experience (varies by program)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 90+, IELTS 6.5+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25-30 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 90+, IELTS 6.5+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18-22 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Health Sciences, Medicine, or related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 90+, IELTS 6.5+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18-22 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in relevant field with research proposal."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL 90+, IELTS 6.5+."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20-25 Lakhs"
      }
    ]
  }
];


const SUTDFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (A-levels/IB/equivalent)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 20–25 lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion with science focus."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 20–25 lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (business/math recommended)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 20–25 lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school with physics, math, and chemistry."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 20–25 lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with relevant work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 100)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 25–35 lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in computer science or related fields."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 100)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 25–30 lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in a health-related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 20–30 lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s in engineering or a related field; research proposal."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 100)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 12–18 lakhs (with scholarships)"
      }
    ]
  }
];

const SMFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion with relevant subjects (A-levels/IB or equivalent)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.0) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 15–20 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion in science subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.0) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 15–20 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (preferably with business or maths focus)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.0) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 15–20 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion with physics, math, and chemistry."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.0) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 15–20 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree with 3-5 years of work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 100)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 25–35 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in computer science or related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 100)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 25–30 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s in health-related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 90)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 20–30 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s in engineering or related field; research proposal required."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS (min. 6.5) or TOEFL (min. 100)."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "INR 12–18 Lakhs (with scholarships)"
      }
    ]
  }
];

const JCFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school with a minimum of 60% or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 74, PTE: 50."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹19,37,024 - ₹36,54,637"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school with science background and a minimum of 60% or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 74, PTE: 50."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹19,37,024 - ₹36,54,637"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school with a focus on commerce or related subjects and a minimum of 60% or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 74, PTE: 50."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹19,37,024 - ₹36,54,637"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of high school with physics, chemistry, and mathematics and a minimum of 65% or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 74, PTE: 50."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 - ₹36,54,637"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a relevant field with minimum 55% or equivalent. Work experience preferred."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 79, PTE: 58."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 - ₹36,54,637"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science/IT or related field with a minimum of 60% or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 79, PTE: 58."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 - ₹36,54,637"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field with a minimum of 55% or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 79, PTE: 58."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 - ₹36,54,637"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a relevant field, research proposal, and prior academic publications preferred."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 79, PTE: 58."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹28,00,000 - ₹36,54,637"
      }
    ]
  }
];


const MDISFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with relevant subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with science subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with commerce background."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹7,00,000 - ₹9,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th Grade with Maths and Physics."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹9,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree or equivalent with relevant work experience."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in relevant field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 - ₹16,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Relevant Master's degree or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 - ₹20,00,000"
      }
    ]
  }
];


const SIMGEFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma in relevant science subjects."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with a focus on commerce/economics."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹7,50,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma in Science with Physics, Chemistry, and Math."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹9,00,000 - ₹13,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in relevant field, work experience may be required."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related fields."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a relevant field (e.g., healthcare, social sciences)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a related engineering discipline."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS, TOEFL, or equivalent."
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  }
];


const RCHEFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (12th grade)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+, TOEFL 80+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹5,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (12th grade) with science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+, TOEFL 80+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹6,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (12th grade) with commerce stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+, TOEFL 80+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹4,00,000 - ₹8,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school completion (12th grade) with mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0+, TOEFL 80+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+, TOEFL 90+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+, TOEFL 90+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree (medical or related)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+, TOEFL 90+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5+, TOEFL 90+"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹5,00,000 - ₹15,00,000"
      }
    ]
  }
];

const ERAFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (80), IELTS (6.0), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade with science stream"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (80), IELTS (6.0), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of 12th grade in commerce or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (80), IELTS (6.0), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "12th grade with Physics, Chemistry, and Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (80), IELTS (6.5), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree and relevant work experience (varies)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90), IELTS (6.5), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90), IELTS (6.5), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in relevant health sciences field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (90), IELTS (6.5), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL (95), IELTS (7.0), or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹25,00,000"
      }
    ]
  }
];


const CSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade or equivalent with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12-14 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with science subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12-14 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with commerce subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12-14 Lakhs"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completed 12th grade with Mathematics, Physics, and Chemistry"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14-16 Lakhs"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree or equivalent, with relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20-22 Lakhs"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18-20 Lakhs"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18-20 Lakhs"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20-25 Lakhs"
      }
    ]
  }
];

//UAE

const CUDFeeStructure = [
  {
    "course": "Bachelor of Arts",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High School Certificate with relevant subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.0 or TOEFL 61+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Science",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High School Certificate with relevant subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.0 or TOEFL 61+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Commerce",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High School Certificate with relevant subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.0 or TOEFL 61+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Engineering",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High School Certificate with relevant subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.0 or TOEFL 61+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    "course": "Master of Business Administration (MBA)",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor's Degree in any discipline"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.5 or TOEFL 80+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    "course": "Master of Computer Science",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor's in Computer Science or related field"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.5 or TOEFL 80+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    "course": "Master of Public Health",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor's degree in health or related fields"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.5 or TOEFL 80+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    "course": "PhD in Engineering",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Master's in Engineering or related field"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS 6.5 or TOEFL 80+"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹30,00,000 - ₹35,00,000"
      }
    ]
  }
];

const AjmanFeeStructure = [
  {
    "course": "Bachelor of Arts",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High school diploma or equivalent"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹7,00,000 to ₹10,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Science",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High school diploma with science subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹8,00,000 to ₹12,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Commerce",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High school diploma with commerce subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹6,50,000 to ₹9,00,000"
      }
    ]
  },
  {
    "course": "Bachelor of Engineering",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "High school diploma with mathematics and science subjects"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹10,00,000 to ₹15,00,000"
      }
    ]
  },
  {
    "course": "Master of Business Administration (MBA)",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor's degree in any field, relevant work experience"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹18,00,000 to ₹22,00,000"
      }
    ]
  },
  {
    "course": "Master of Computer Science",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor's degree in Computer Science or related field"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹10,00,000 to ₹14,00,000"
      }
    ]
  },
  {
    "course": "Master of Public Health",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Bachelor's degree in health or related field"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹12,00,000 to ₹18,00,000"
      }
    ]
  },
  {
    "course": "PhD in Engineering",
    "details": [
      {
        "image": img1,
        "title": "Eligibility Criteria",
        "value": "Master's degree in Engineering or related field"
      },
      {
        "image": img2,
        "title": "English Proficiency Test",
        "value": "IELTS/TOEFL"
      },
      {
        "image": img3,
        "title": "Approx. Annual Fees (INR)",
        "value": "₹15,00,000 to ₹20,00,000"
      }
    ]
  }
];


const AbutDhabiFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school graduation or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 5.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school graduation or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 5.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school graduation or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 5.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school graduation with science background"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹17,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000 - ₹30,00,000"
      }
    ]
  }
];


const AURAKFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 5.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with science background"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 5.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with commerce background"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 5.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹8,00,000 - ₹10,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with mathematics and science"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹9,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any discipline"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 - ₹18,00,000"
      }
    ]
  }
];


const UAEUFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education, Minimum 60% in relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 5.5 IELTS/ 61 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education, Minimum 60% in relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 5.5 IELTS/ 61 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education, Minimum 60% in relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 5.5 IELTS/ 61 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High School/Secondary Education, Minimum 60% in relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 5.5 IELTS/ 61 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree from a recognized institution, 2 years of work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 6.0 IELTS/ 78 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 - ₹22,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 6.0 IELTS/ 78 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in related field, work experience may be required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 6.0 IELTS/ 78 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 - ₹20,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "TOEFL/IELTS, Minimum 6.0 IELTS/ 78 TOEFL"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 - ₹25,00,000"
      }
    ]
  }
];


const KhalifaFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma (12th grade); subjects may vary based on the program"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 80 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,000,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma (12th grade); subject requirements based on specific program"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 80 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,000,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma (12th grade); subjects in commerce or equivalent qualification"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 80 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,000,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma (12th grade); subject requirements based on specific program (Math and Physics)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 80 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,200,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in any field; professional work experience may be required"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 85 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,500,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Computer Science or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 90 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,500,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in Health Sciences or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 85 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹1,500,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 90 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹2,000,000"
      }
    ]
  }
];


const AUSFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 61"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 approx."
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects (Math/Science)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 61"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 approx."
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with relevant subjects"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 61"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 approx."
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Physics, Chemistry, and Mathematics"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0, TOEFL: 61"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 approx."
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in any discipline with a minimum CGPA of 2.5/4.0"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 approx."
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in Computer Science, IT, or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 approx."
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health sciences or relevant field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 approx."
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5, TOEFL: 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 approx."
      }
    ]
  }
];

const AlAinFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary school, meeting the required score"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 70"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary school, meeting the required score"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 70"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary school, meeting the required score"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 70"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹10,00,000 - ₹12,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Completion of secondary school, meeting the required score"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.0 or TOEFL: 70"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹14,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree from an accredited institution, relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 - ₹15,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in health-related fields"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000 - ₹18,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS: 6.5 or TOEFL: 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 - ₹25,00,000"
      }
    ]
  }
];

const ZayedUniversityFeeStructure = [
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or TOEFL 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or TOEFL 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or TOEFL 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹15,00,000"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent (Math & Science focus)"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or TOEFL 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹17,00,000"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree or equivalent, relevant work experience"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 79"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree or equivalent in computer science/related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor’s degree in a related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹25,00,000"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master’s degree in Engineering or related field"
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or TOEFL 80"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹30,00,000"
      }
    ]
  }
];


const UniversityOfDubaiFeeStructure = [
  
  {
    course: "Bachelor of Arts",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹12,00,000 (approx.)"
      }
    ]
  },
  {
    course: "Bachelor of Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹13,00,000 (approx.)"
      }
    ]
  },
  {
    course: "Bachelor of Commerce",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma or equivalent."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹14,00,000 (approx.)"
      }
    ]
  },
  {
    course: "Bachelor of Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "High school diploma with Mathematics & Physics, relevant scores."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹16,00,000 (approx.)"
      }
    ]
  },
  {
    course: "Master of Business Administration (MBA)",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree with relevant work experience (2-3 years)."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 (approx.)"
      }
    ]
  },
  {
    course: "Master of Computer Science",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in a related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹20,00,000 (approx.)"
      }
    ]
  },
  {
    course: "Master of Public Health",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Bachelor's degree in health, medical, or related field."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 6.5 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹18,00,000 (approx.)"
      }
    ]
  },
  {
    course: "PhD in Engineering",
    details: [
      {
        image: img1,
        title: "Eligibility Criteria",
        value: "Master's degree in a related field with research proposal."
      },
      {
        image: img2,
        title: "English Proficiency Test",
        value: "IELTS 7.0 or equivalent"
      },
      {
        image: img3,
        title: "Approx. Annual Fees (INR)",
        value: "₹22,00,000 (approx.)"
      }
    ]
  }


]



export {
  MelbourneFeeStructure,
  CaliforniaFeeStructure,
  WisconsinFeeStructure,
  YeshivaFeeStructure,
  UtahFeeStructure,
  ColoradoFeeStructure,
  IllinoisFeeStructure,
  StanfordFeeStructure,
  PennsylvaniaFeeStructure,
  ChicagoFeeStructure,
  MITFeeStructure,
  BristolFeeStructure,
  BirminghamFeeStructure,
  GlasgowFeeStructure,
  ImperialFeeStructure,
  QueenMaryFeeStructure,
  LiverpoolFeeStructure,
  EdinburghFeeStructure,
  SouthamptonFeeStructure,
  DurhamFeeStructure,

  LeedsFeeStructure,
  MonashFeeStructure,
  UNSWFeeStructure,
  UQFeeStructure,
  SydneyFeeStructure,
  AdelaideFeeStructure,
  MacquarieFeeStructure,
  TrentFeeStructure,
  TrinityFeeStructure,
  RRUFeeStructure,
  WaterlooFeeStructure,
  CalgeryFeeStructure,
  DalhousieFeeStructure,
  BrockFeeStructure,
  WindsorFeeStructure,
  VCCFeeStructure,
  TorontoFeeStructure,
  ANUFeeStructure,
  UTSFeeStructure,
  WesternAustraliaFeeStructure,

  //Germany

  LMUFeeStructure,
  TUMFeeStructure,
  HeidelbergUniversityFeeStructure,
  HumboldtUniversityFeeStructure,
  FUBFeeStructure,
  RWTHFeeStructure,
  UOFFeeStructure,
  UOTFeeStructure,
  UOMFeeStructure,
  UOGFeeStructure,

  //France

  UPSLFeeStructure,
  UPSFeeStructure,
  SUFeeStructure,
  UGAFeeStructure,
  UDSFeeStructure,
  UDMFeeStructure,
  UDLFeeStructure,
  UDNFeeStructure,
  UDBFeeStructure,
  UPPSFeeStructure,

  //Italy
  PDMilanoFeeStructure,
  UOMilanFeeStructure,
  UOBolognaFeeStructure,
  UOFlorenceFeeStructure,
  UOPaduaFeeStructure,
  UOTurinFeeStructure,
  RLSFeeStructure,
  UONaplesFeeStructure,
  UOPisaFeeStructure,
  IEDFeeStructure,

  //Ireland
  TCDublinFeeStructure,
  UCDublinFeeStructure,
  UCCorkFeeStructure,
  UOGalwayFeeStructure,
  DCUFeeStructure,
  UOLimerickFeeStructure,
  MUFeeStructure,
  TUDublinFeeStructure,
  DBSFeeStructure,
  RCSFeeStructure,

  //New-Zealand
  UOAFeeStructure,
  MUniversityFeeStructure,
  UOWFeeStructure,
  VUOWFeeStructure,
  UCanterburyFeeStructure,
  UOtagoFeeStructure,
  AUOTFeeStructure,
  LUniversityFeeStructure,
  EIOTFeeStructure,
  UCICFeeStructure,

 
  // SINGAPOR
  NUSFeeStructure,
  NTUFeeStructure,
  SUTDFeeStructure,
  SMFeeStructure,
  JCFeeStructure,
  MDISFeeStructure,
  SIMGEFeeStructure,
  RCHEFeeStructure,
  ERAFeeStructure,
  CSFeeStructure,

    //UAE
    CUDFeeStructure,
    AjmanFeeStructure,
    AbutDhabiFeeStructure,
    AURAKFeeStructure,
    UAEUFeeStructure,
    KhalifaFeeStructure,
    AUSFeeStructure,
    AlAinFeeStructure,
    ZayedUniversityFeeStructure,
    UniversityOfDubaiFeeStructure,

};
