import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Card,
  Container,
  createTheme,
  Hidden,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import dottedline from "../../../asset/dottedline.svg";
import service1 from "../../../asset/service1.svg";
import service11 from "../../../asset/service11.svg";
import service2 from "../../../asset/service2.svg";
import service22 from "../../../asset/service22.svg";
import service3 from "../../../asset/service3.svg";
import service33 from "../../../asset/service33.svg";
import service4 from "../../../asset/service4.svg";
import service44 from "../../../asset/service44.svg";
import Header from "../../../Component/content-component/Header";
import "../../../globle.css";
import SecondCard from "./SecondCard";
import Second from "./Second";

const Abroad = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [hoveredStep, setHoveredStep] = useState(null);
  const [popup, setPopup] = useState(false);
  const [categoryAbout, setCategoryAbout] = useState({
    title: "",
    actionType: "",
    category: "",
  });

  const FirstCardData = [
    {
      title: "PERSONALIZED MENTORING",
      text: 1,
      icon: service11,
      defaultIcon: service1,
      category: "personalized",
      hoveredStep: 1,
    },
    {
      title: "Career Mapping",
      text: 2,
      icon: service22,
      defaultIcon: service2,
      category: "career",
      hoveredStep: 2,
    },
    {
      title: "Admission Service",
      text: 3,
      icon: service33,
      defaultIcon: service3,
      category: "admission",
      hoveredStep: 3,
    },
    {
      title: "Post Enrollment Support",
      text: 4,
      icon: service44,
      defaultIcon: service4,
      category: "post",
      hoveredStep: 4,
    },
  ];

  const handlepopup = () => {
    setPopup(!popup);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleButtonClick = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };

  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920,
      },
    },
  });

  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));

  const ScreenFor = createTheme({
    breakpoints: {
      values : {
        xs: 0,
      sm: 600,
      md: 959,
      lg: 1200,
      xl: 1600,
      s1 : 960,
      s2 : 1136
      }
    },
  });
  const specialScreenFor = useMediaQuery(ScreenFor.breakpoints.between("s1","s2"))
  const xsFor = useMediaQuery(ScreenFor.breakpoints.between("xs", "sm"));
  const smFor = useMediaQuery(ScreenFor.breakpoints.between("sm", "md"));
  const mdFor = useMediaQuery(ScreenFor.breakpoints.between("md", "lg"));
  const lgFor = useMediaQuery(ScreenFor.breakpoints.between("lg", "xl"));
  const xlFor = useMediaQuery(ScreenFor.breakpoints.up("xl"));
 

  return (
    <div>
      <Stack
        gap={3}
        direction="column"
        sx={{
          backgroundColor: "#FfffFF",
          textAlign: "center",
          padding: "60px 0",
        }}
      >
        {" "}
        <Header
          color="#00999e"
          textAlign="center"
          content="Track Your Study Abroad Journey"
        />
        <Container maxWidth={isLargeScreen ? "xl" : "lg"}>
          <Box
            rowGap={3}
            paddingTop={6}
            columnGap={2}
            // display="grid"
            // alignItems="flex-start"
            // gridTemplateColumns={{
            //   xs: "repeat(1, 1fr)",
            //   sm: "repeat(2, 1fr)",
            //   md: "repeat(4, 1fr)",
            // }}
            flexWrap={"wrap"}
            sx={{
              flexDirection : {
                xs : "column",
                sm : 'row'
              },
              display:"flex", 
              alignItems:"center",
              justifyContent:"center"
            }}
          >
            {FirstCardData?.map((item, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  sx={{
                    position: "relative",
                    zIndex: 2,
                    marginX :{
                      xs : 'auto',
                      sm : 'auto',
                      md : 'auto',
                      lg : 'auto'
                    }
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      color: "#00999e",
                      right: { xs: "10%", sm: "20%", md: "10%", lg: "10%" },
                      top: {
                        xs: "-6%",
                        sm: "" - (6 % ""),
                        md: "-5%",
                        lg: "-5%",
                      },
                      zIndex: 9,
                    }}
                  ></Box>

                  <Card
                    sx={{
                      width: 200,
                      height: 200,
                      borderTopWidth: "50%",
                      paddingBottom: 2,
                      backgroundColor:
                        selectedCategory === item?.category ||
                        hoveredStep === item?.hoveredStep
                          ? "#00999E"
                          : "transparent",
                      color:
                        selectedCategory === item?.category ||
                        hoveredStep === item?.hoveredStep
                          ? "white"
                          : "inherit",
                      border: "",
                      zIndex: 1,
                      position: "relative",
                      overflow: "visible",
                      "& .css-1rq26sm-MuiTypography-root": {
                        color:
                          selectedCategory === item?.category ||
                          hoveredStep === item?.hoveredStep
                            ? "black"
                            : "inherit",
                      },
                      "&:hover": {
                        backgroundColor: "#00999E",
                        color: "white",
                        zIndex: 1,
                        "& .css-1rq26sm-MuiTypography-root": {
                          color: "black",
                        },
                      },
                    }}
                    onMouseEnter={() => setHoveredStep(item.text)}
                    onMouseLeave={() => setHoveredStep(null)}
                    className="pseudo-partial-border"
                  >
                    <Paper
                      sx={{
                        height: "31px",
                        width: "31px",
                        borderRadius: "50%",
                        marginLeft: "190px",
                        backgroundColor:
                          selectedCategory === item?.category ||
                          hoveredStep === item?.hoveredStep
                            ? "#00999E"
                            : "white",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        marginTop: -1.5,
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignContent: "center",
                          marginTop: 0.5,
                          color:
                            selectedCategory === item?.category ||
                            hoveredStep === item?.hoveredStep
                              ? "white"
                              : "#00999E",
                        }}
                      >
                        {item?.text}
                      </Typography>
                    </Paper>
                    <Box padding={2}>
                      <img
                        alt="Hexagon Image"
                        src={
                          selectedCategory === item?.category ||
                          hoveredStep === item?.hoveredStep
                            ? item?.icon
                            : item.defaultIcon
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: 3,
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={{ fontWeight: 700, paddingBottom: 1 }}>
                        {item?.title}
                      </Typography>
                    </Box>
                  </Card>

                  <Box>
                    <Button
                      disableElevation
                      disableRipple
                      variant="contained"
                      sx={{
                        backgroundColor: "#00999E",
                        mt: 2,
                        "&:hover": {
                          backgroundColor: "#007f80",
                          color: "#fff",
                        },
                      }}
                      endIcon={
                        selectedCategory === item?.category ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                      onClick={() => handleButtonClick(item?.category)}
                    >
                      View More
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      height: xsFor ? "auto" :  smFor ? "auto" :  specialScreenFor ? "auto" : "100px",
                    }}
                  >
                    {selectedCategory === item.category && (
                      <div>
                        
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItem: "center",
                            flexDirection: "column",
                            paddingTop: "20px",
                          }}
                        >
                          <img
                            src={dottedline}
                            alt="dotted-line"
                            width={5}
                            height={80}
                            style={{ margin: "auto" }}
                          />
                          <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                        </Box>

                        {xsFor ? (
                          <Second
                            theme={theme}
                            popup={popup}
                            setPopup={setPopup}
                            categoryAbout={categoryAbout}
                            setCategoryAbout={setCategoryAbout}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            isSmallScreen={xsFor}
                          />
                        ) :  specialScreenFor ? (
                          <Second
                          theme={theme}
                          popup={popup}
                          setPopup={setPopup}
                          categoryAbout={categoryAbout}
                          setCategoryAbout={setCategoryAbout}
                          selectedCategory={selectedCategory}
                          setSelectedCategory={setSelectedCategory}
                        />
                        ) :  smFor ? (
                          <Second
                            theme={theme}
                            popup={popup}
                            setPopup={setPopup}
                            categoryAbout={categoryAbout}
                            setCategoryAbout={setCategoryAbout}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Box>
                  
                </Box>
              );
            })}
          </Box>

          <Hidden smDown>
            {selectedCategory && !xsFor && !smFor && !specialScreenFor && (
              <Second
                theme={theme}
                popup={popup}
                setPopup={setPopup}
                categoryAbout={categoryAbout}
                setCategoryAbout={setCategoryAbout}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            )}
          </Hidden>
        </Container>
      </Stack>
      {/* <Second
      setCategoryAbout={setCategoryAbout}
      selectedCategory={selectedCategory}
      categoryAbout={categoryAbout}
      /> */}
    </div>
  );
};

export default Abroad;
