
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const us5 = imageBaseUrl + "CountryUniversities/us/us5.png";

const utah = imageBaseUrl + "CountryUniversities/us/utah.png";
const wisconsin = imageBaseUrl + "CountryUniversities/us/wisconsin.png";
const yeshiva = imageBaseUrl + "CountryUniversities/us/yeshiva.png";
const colorado = imageBaseUrl + "CountryUniversities/us/colorado.png";
const Illinois = imageBaseUrl + "CountryUniversities/us/Illinois.png";
const stanford = imageBaseUrl + "CountryUniversities/us/stanford.png";
const pennsylvania = imageBaseUrl + "CountryUniversities/us/pennsylvania.png";
const chicago = imageBaseUrl + "CountryUniversities/us/stanford.png";
const mit = imageBaseUrl + "CountryUniversities/us/mit.png";


const birmingham = imageBaseUrl + "CountryUniversities/uk/birmingham.png";
const bristol = imageBaseUrl + "CountryUniversities/uk/bristol.png";
const glasgow = imageBaseUrl + "CountryUniversities/uk/glasgow.png";
const imperial = imageBaseUrl + "CountryUniversities/uk/imperial.png";
const queenmary = imageBaseUrl + "CountryUniversities/uk/queenmary.png";
const liverpool = imageBaseUrl + "CountryUniversities/uk/liverpool.png";
const edinburgh = imageBaseUrl + "CountryUniversities/uk/edinburgh.png";
const southampton = imageBaseUrl + "CountryUniversities/uk/southampton.png";
const durham = imageBaseUrl + "CountryUniversities/uk/durham.png";


const leeds = imageBaseUrl + "CountryUniversities/uk/leeds.png";

const ire1 = imageBaseUrl + "CountryUniversities/ireland/ire1.png";
const ire2 = imageBaseUrl + "CountryUniversities/ireland/ire2.png";
const ire3 = imageBaseUrl + "CountryUniversities/ireland/ire3.png";
const ire4 = imageBaseUrl + "CountryUniversities/ireland/ire4.png";
const ire5 = imageBaseUrl + "CountryUniversities/ireland/ire5.png";
const ire6 = imageBaseUrl + "CountryUniversities/ireland/ire6.png";
const ire7 = imageBaseUrl + "CountryUniversities/ireland/ire7.png";
const ire8 = imageBaseUrl + "CountryUniversities/ireland/ire8.png";
const ire9 = imageBaseUrl + "CountryUniversities/ireland/ire9.png";
const ire10 = imageBaseUrl + "CountryUniversities/ireland/ire10.png";
const brock = imageBaseUrl + "CountryUniversities/canada/Brock.png";
const calgary = imageBaseUrl + "CountryUniversities/canada/calgary.png";
const Dalhousie = imageBaseUrl + "CountryUniversities/canada/Dalhousie.png";
const RRU = imageBaseUrl + "CountryUniversities/canada/RRU.png";
const toronto = imageBaseUrl + "CountryUniversities/canada/toronto.png";
const trent = imageBaseUrl + "CountryUniversities/canada/Trent.png";
const trinity = imageBaseUrl + "CountryUniversities/canada/Trinity.png";
const vcc = imageBaseUrl + "CountryUniversities/canada/vcc.png";
const Waterloo = imageBaseUrl + "CountryUniversities/canada/waterloo.png";
const windsor = imageBaseUrl + "CountryUniversities/canada/windsor.png";

const ger1 = imageBaseUrl + "CountryUniversities/germany/ger1.png";
const ger2 = imageBaseUrl + "CountryUniversities/germany/ger2.png";
const ger3 = imageBaseUrl + "CountryUniversities/germany/ger3.png";
const ger4 = imageBaseUrl + "CountryUniversities/germany/ger4.png";
const ger5 = imageBaseUrl + "CountryUniversities/germany/ger5.png";
const ger6 = imageBaseUrl + "CountryUniversities/germany/ger6.png";
const ger7 = imageBaseUrl + "CountryUniversities/germany/ger7.png";
const ger8 = imageBaseUrl + "CountryUniversities/germany/ger8.png";
const ger9 = imageBaseUrl + "CountryUniversities/germany/ger9.png";
const ger10 = imageBaseUrl + "CountryUniversities/germany/ger10.png";
const aus1 = imageBaseUrl + "CountryUniversities/australia/aus1.png";
const aus2 = imageBaseUrl + "CountryUniversities/australia/aus2.png";
const aus3 = imageBaseUrl + "CountryUniversities/australia/aus3.png";
const aus4 = imageBaseUrl + "CountryUniversities/australia/aus4.png";
const aus5 = imageBaseUrl + "CountryUniversities/australia/aus5.png";
const aus6 = imageBaseUrl + "CountryUniversities/australia/aus6.png";
const aus7 = imageBaseUrl + "CountryUniversities/australia/aus7.png";
const macquarie = imageBaseUrl + "CountryUniversities/australia/macquarie.png";
const uts = imageBaseUrl + "CountryUniversities/australia/uts.png";
const aus8 = imageBaseUrl + "CountryUniversities/australia/aus8.png";
const sin1 = imageBaseUrl + "CountryUniversities/singapore/sin1.png";
const sin2 = imageBaseUrl + "CountryUniversities/singapore/sin2.png";
const sin3 = imageBaseUrl + "CountryUniversities/singapore/sin3.png";
const sin4 = imageBaseUrl + "CountryUniversities/singapore/sin4.png";
const sin5 = imageBaseUrl + "CountryUniversities/singapore/sin5.png";
const sin6 = imageBaseUrl + "CountryUniversities/singapore/sin6.png";
const sin7 = imageBaseUrl + "CountryUniversities/singapore/sin7.png";
const sin8 = imageBaseUrl + "CountryUniversities/singapore/sin8.png";
const sin9 = imageBaseUrl + "CountryUniversities/singapore/sin9.png";
const sin10 = imageBaseUrl + "CountryUniversities/singapore/sin10.png";
const ity1 = imageBaseUrl + "CountryUniversities/italy/ity1.png";
const ity2 = imageBaseUrl + "CountryUniversities/italy/ity2.png";
const ity3 = imageBaseUrl + "CountryUniversities/italy/ity3.png";
const ity4 = imageBaseUrl + "CountryUniversities/italy/ity4.png";
const ity5 = imageBaseUrl + "CountryUniversities/italy/ity5.png";
const ity6 = imageBaseUrl + "CountryUniversities/italy/ity6.png";
const ity7 = imageBaseUrl + "CountryUniversities/italy/ity7.png";
const ity8 = imageBaseUrl + "CountryUniversities/italy/ity8.webp";
const ity9 = imageBaseUrl + "CountryUniversities/italy/ity9.jpg";
const ity10 = imageBaseUrl + "CountryUniversities/italy/ity10.png";
const frn1 = imageBaseUrl + "CountryUniversities/france/frn1.png";
const frn2 = imageBaseUrl + "CountryUniversities/france/frn2.png";
const frn3 = imageBaseUrl + "CountryUniversities/france/frn3.png";
const frn4 = imageBaseUrl + "CountryUniversities/france/frn4.png";
const frn5 = imageBaseUrl + "CountryUniversities/france/frn5.png";
const frn6 = imageBaseUrl + "CountryUniversities/france/frn6.png";
const frn7 = imageBaseUrl + "CountryUniversities/france/frn7.png";
const frn8 = imageBaseUrl + "CountryUniversities/france/frn8.png";
const frn9 = imageBaseUrl + "CountryUniversities/france/frn9.webp";
const frn10 = imageBaseUrl + "CountryUniversities/france/frn10.jpg";
const dub1 = imageBaseUrl + "CountryUniversities/dubai/dub1.png";
const dub2 = imageBaseUrl + "CountryUniversities/dubai/dub2.png";
const dub3 = imageBaseUrl + "CountryUniversities/dubai/dub3.png";
const dub4 = imageBaseUrl + "CountryUniversities/dubai/dub4.png";
const dub5 = imageBaseUrl + "CountryUniversities/dubai/dub5.png";
const dub6 = imageBaseUrl + "CountryUniversities/dubai/dub6.png";
const dub7 = imageBaseUrl + "CountryUniversities/dubai/dub7.png";
const dub8 = imageBaseUrl + "CountryUniversities/dubai/dub8.png";
const dub9 = imageBaseUrl + "CountryUniversities/dubai/dub9.png";
const dub10 = imageBaseUrl + "CountryUniversities/dubai/dub10.png";
const nz1 = imageBaseUrl + "CountryUniversities/newzealand/nz1.png";
const nz2 = imageBaseUrl + "CountryUniversities/newzealand/nz2.png";
const nz3 = imageBaseUrl + "CountryUniversities/newzealand/nz3.png";
const nz4 = imageBaseUrl + "CountryUniversities/newzealand/nz4.png";
const nz5 = imageBaseUrl + "CountryUniversities/newzealand/nz5.png";
const nz6 = imageBaseUrl + "CountryUniversities/newzealand/nz6.png";
const nz7 = imageBaseUrl + "CountryUniversities/newzealand/nz7.png";
const nz8 = imageBaseUrl + "CountryUniversities/newzealand/nz8.png";
const nz9 = imageBaseUrl + "CountryUniversities/newzealand/nz9.png";
const nz10 = imageBaseUrl + "CountryUniversities/newzealand/nz10.png";

const USAUniversities = {
  heading: "Popular Universities to Study in  the USA",
  content: [
    {
      title: "University of California",
      Image: us5,
      path: "/study-abroad/universities/california"
    },
    {
      title: "University of Wisconsin-Madison",

      Image: wisconsin,
      path: "/study-abroad/universities/wisconsin"
    },
    {
      title: "Yeshiva University",

      Image: yeshiva,
      path: "/study-abroad/universities/yeshiva"
    },
    {
      title: "Southern Utah University",

      Image: utah,
      path: "/study-abroad/universities/utah"
    },
    {
      title: "Colorado State University",
      Image: colorado,
      path: "/study-abroad/universities/colorado"
    },
    {
      title: "UIUC",
      Image: Illinois,
      path: "/study-abroad/universities/illinois"
    },
    {
      title: "Stanford University",
      Image: stanford,
      path: "/study-abroad/universities/stanford"
    },
    {
      title: "University of Pennsylvania",
      Image: pennsylvania,
      path: "/study-abroad/universities/pennsylvania"
    },
    {
      title: "The University of Chicago",
      Image: chicago,
      path: "/study-abroad/universities/chicago"
    },
    {
      title: "MIT",
      Image: mit,
      path: "/study-abroad/universities/mit"
    },
  ]
};
const UKUniversities = {
  heading: "Popular Universities to Study in  the UK",
  content: [
    {
      title: "University of Bristol",

      Image: bristol,
      path: "/study-abroad/universities/bristol"
    },
    {
      title: "University of Leeds",

      Image: leeds,
      path: "/study-abroad/universities/leeds"
    },
    {
      title: "The University of Glasgow",

      Image: glasgow,
      path: "/study-abroad/universities/glasgow"
    },
    {
      title: "The University of Birmingham",

      Image: birmingham,
      path: "/study-abroad/universities/birmingham"
    },
    {
      title: "Imperial College London",

      Image: imperial,
      path: "/study-abroad/universities/imperial"
    },
    {
      title: "Queen Mary University of London",

      Image: queenmary,
      path: "/study-abroad/universities/queenmary"
    },
    {
      title: "University of Liverpool",

      Image: liverpool,
      path: "/study-abroad/universities/liverpool"
    },
    {
      title: "University of Edinburgh",

      Image: edinburgh,
      path: "/study-abroad/universities/edinburgh"
    },
    {
      title: "University of Southampton",

      Image: southampton,
      path: "/study-abroad/universities/southampton"
    },
    {
      title: "Durham University",

      Image: durham,
      path: "/study-abroad/universities/durham"
    },
  ]
};

//Ireland

const IrelandUniversities = {
  heading: "Popular Universities to Study in  the Ireland",
  content: [
    {
      title: "Trinity College Dublin",
      path: "/study-abroad/universities/tdublin",
      Image: ire1
    },
    {
      title: "University College Dublin",
      path: "/study-abroad/universities/ucdublin",
      Image: ire2
    },
    {
      title: "University of Cork",
      path: "/study-abroad/universities/uocork",
      Image: ire4
    },

    {
      title: "University of Galway",
      path: "/study-abroad/universities/uogalway",
      Image: ire3
    },
   
    {
      title: "Dublin City University (DCU)",
      path: "/study-abroad/universities/dcu",
      Image: ire5
    },
    {
      title: "University of Limerick",
      path: "/study-abroad/universities/uolimerick",
      Image: ire6
    },
    {
      title: "Maynooth University",
      path: "/study-abroad/universities/mu",
      Image: ire7
    },
    {
      title: "Technological University Dublin",
      path: "/study-abroad/universities/tudublin",
      Image: ire8
    },
    {
      title: "Dublin Business School",
      path: "/study-abroad/universities/dbs",
      Image: ire9
    },
    {
      title: "RCSI",
      path: "/study-abroad/universities/rcsi",
      Image: ire10
    }
  ]
};
const CanadaUniversities = {
  heading: "Popular Universities to Study in  the Canada",
  content: [
    {
      title: "Trent University, Peterborough, Ontario",
      path: "/study-abroad/universities/trent",

      Image: trent
    },
    {
      title: "Trinity Western University",
      path: "/study-abroad/universities/trinity",
      Image: trinity
    },
    {
      title: "Royal Roads University",
      path: "/study-abroad/universities/royal",
      Image: RRU
    },
    {
      title: "The University of Waterloo",
      path: "/study-abroad/universities/waterloo",
      Image: Waterloo
    },
    {
      title: "University of Calgary Continuing Education",
      path: "/study-abroad/universities/calgary",
      Image: calgary
    },
    {
      title: "Dalhousie University",
      path: "/study-abroad/universities/dalhousie",
      Image: Dalhousie
    },
    {
      title: "Brock University",
      path: "/study-abroad/universities/brock",
      Image: brock
    },
    {
      title: "The University of Windsor",
      path: "/study-abroad/universities/windsor",
      Image: windsor
    },
    {
      title: "Vancouver Community College",
      path: "/study-abroad/universities/vcc",
      Image: vcc
    },
    {
      title: "University of Toronto",
      path: "/study-abroad/universities/toronto",
      Image: toronto
    }
  ]
};
const GermanyUniversities = {
  heading: "Popular Universities to Study in  the Germany",
  content: [
    {
      title: "LMUM",
      path: '/study-abroad/universities/lmum',
      Image: ger2
    },
    {
      title: "TUM",
      path: '/study-abroad/universities/tum',
      Image: ger1
    },
    {
      title: "Heidelberg University",
      path: '/study-abroad/universities/heidelberguniversity',
      Image: ger3
    },
    {
      title: "Humboldt University",
      path: '/study-abroad/universities/humboldtuniversity',
      Image: ger4
    },
    {
      title: "Freie Universität Berlin ",
      path: '/study-abroad/universities/freieuniversitätberlin',
      Image: ger5
    },
    {
      title: "RWTH",
      path: '/study-abroad/universities/rwth',
      Image: ger6
    },
    {
      title: "University of Freiburg",
      path: '/study-abroad/universities/universityoffreiburg',
      Image: ger7
    },
    {
      title: "University of Tübingen",
      path: '/study-abroad/universities/universityoftübingen',
      Image: ger8
    },
    {
      title: "University of Mannheim",
      path: '/study-abroad/universities/universityofmannheim',
      Image: ger9
    },
    {
      title: "University of Göttingen",
      path: '/study-abroad/universities/universityofgöttingen',
      Image: ger10
    }
  ]
};
const AustraliaUniversities = {
  heading: "Popular Universities to Study in  the Australia",
  content: [
    {
      title: "ANU",

      Image: aus1,
      path: "/study-abroad/universities/anu"
    },
    {
      title: "University of Melbourne",

      Image: aus2,
      path: "/study-abroad/universities/melbourne"
    },
    {
      title: "University of Sydney",

      Image: aus3,
      path: "/study-abroad/universities/sydeny"
    },
    {
      title: "UQ",

      Image: aus4,
      path: "/study-abroad/universities/uq"
    },
    {
      title: "UNSW",

      Image: aus5,
      path: "/study-abroad/universities/unsw"
    },
    {
      title: "Monash University",

      Image: aus6,
      path: "/study-abroad/universities/monash"
    },
    {
      title: "UWA",

      Image: aus7,
      path: "/study-abroad/universities/uwa"
    },
    {
      title: "University of Adelaide",

      Image: aus8,
      path: "/study-abroad/universities/adelaide"
    },
    {
      title: "UTS",

      Image: uts,
      path: "/study-abroad/universities/uts"
    },
    {
      title: "MU",

      Image: macquarie,
      path: "/study-abroad/universities/macquarie"
    }
  ]
};
const SingaporeUniversities = {
  heading: "Popular Universities to Study in  the Singapore",
  content: [
    {
      title: "National University of Singapore",
      path: "/study-abroad/universities/nuos",
      Image: sin1
    },
    {
      title: "Nanyang Technological University, Singapore",
      path: "/study-abroad/universities/ntus",
      Image: sin2
    },
    {
      title: "Singapore University of Technology and Design",
      path: "/study-abroad/universities/sutd",
      Image: sin4
    },
    {
      title: "Singapore Management University",
      path: "/study-abroad/universities/smu",
      Image: sin3
    },

    {
      title: "James Cook University",
      path: "/study-abroad/universities/jcu",
      Image: sin6
    },

    {
      title: "Management Development Institute of Singapore",
      path: "/study-abroad/universities/mdis",
      Image: sin7
    },
    {
      title: "SIM GLOBAL EDUCATION",
      path: "/study-abroad/universities/sim",
      Image: sin8
    },
    
    {
      title: "Raffles College of Higher Education",
      path: "/study-abroad/universities/rche",
      Image: sin5
    },
   
    {
      title: "Embry Riddle Aeronautical University Asia",
      path: "/study-abroad/universities/erau",
      Image: sin9
    },
    {
      title: "Curtin Singapore",
      path: "/study-abroad/universities/cs",
      Image: sin10
    },
    
  ]
};

//Italy
const ItalyUniversities = {
  heading: "Popular Universities to Study in  the Italy",
  content: [
    {
      title: "Politecnico di Milano",
      path: '/study-abroad/universities/pdmilano',
      Image: ity1
    },

    {
      title: "University of Milan",
      path: '/study-abroad/universities/uomilano',
      Image: ity2
    },
    {
      title: "University of Bologna",
      path: '/study-abroad/universities/uobologna',
      Image: ity3
    },
    
    {
      title: "University of Florence",
      path: '/study-abroad/universities/uoflorence',
      Image: ity4
    },
    {
      title: "University of Padua",
      path: '/study-abroad/universities/uopadua',
      Image: ity5
    },
    {
      title: "University of Turin",
      path: '/study-abroad/universities/uoturin',
      Image: ity6
    },
    {
      title: "University of Rome La Sapienza",
      path: '/study-abroad/universities/rome-la-sapienza',
      Image: ity7
    },
    {
      title: "University of Naples Federico II",
      path: '/study-abroad/universities/uonaples',
      Image: ity8
    },
    {
      title: "The University of Pisa",
      path: '/study-abroad/universities/uopisa',
      Image: ity9
    },
    {
      title: "Instituto Europeo di Design (IED), Milan",
      path: '/study-abroad/universities/ied',
      Image: ity10
    }
  ]
};

//France
const FranceUniversities = {
  heading: "Popular Universities to Study in  the France",
  content: [

    {
      title: "PSL",
      path: '/study-abroad/universities/upsl',
      Image: frn2
    },
    {
      title: "Paris-Saclay",
      path: '/study-abroad/universities/paris-saclay',
      Image: frn1
    },
    {
      title: "Sorbonne",
      path: '/study-abroad/universities/sorbonne',
      Image: frn4
    },
    
    {
      title: "Université Grenoble Alpes",
      path: '/study-abroad/universities/uga',
      Image: frn3
    },
    {
      title: "Strasboury",
      path: '/study-abroad/universities/strasboury',
      Image: frn8
    },    
    
    {
      title: "Université de Montpellier",
      path: '/study-abroad/universities/udm',
      Image: frn6
    },
    {
      title: "Université de Lille",
      path: '/study-abroad/universities/udl',
      Image: frn7
    },
    {
      title: "Université de Nantes",
      path: '/study-abroad/universities/udn',
      Image: frn9
    },

    {
      title: "Université de Bordeaux",
      path: '/study-abroad/universities/udb',
      Image: frn10
    },

    {
      title: "Université Paris 1 Panthéon-Sorbonne",
      path: '/study-abroad/universities/upps',
      Image: frn5
    },
  ]
};
const DubaiUniversities = {
  heading: "Popular Universities to Study in  the Dubai",
  content: [
    {
      title: "Canadian University Dubai",
      path: "/study-abroad/universities/cud",
      Image: dub1
    },
    {
      title: "Ajman University",
      path: "/study-abroad/universities/au",
      Image: dub2
    },
    {
      title: "Abu Dhabi University",
      path: "/study-abroad/universities/adu",
      Image: dub3
    },
    {
      title: "American University",
      path: "/study-abroad/universities/aurak",
      Image: dub4
    },
    {
      title: "United Arab Emirates University",
      path: "/study-abroad/universities/uaeu",
      Image: dub5
    },
    {
      title: "Khalifa University of Science and Technology",
      path: "/study-abroad/universities/khalifa",
      Image: dub6
    },
    {
      title: "American University of Sharjah",
      path: "/study-abroad/universities/auos",
      Image: dub7
    },
    {
      title: "Al Ain University",
      path: "/study-abroad/universities/aau",
      Image: dub8
    },
    {
      title: "Zayed University",
      path: "/study-abroad/universities/zu",
      Image: dub9
    },
    {
      title: "University of Dubai",
      path: "/study-abroad/universities/uod",
      Image: dub10
    }
  ]
};

//New-Zealand
const NewZealandUniversities = {
  heading: "Popular Universities to Study in  the New-Zealand",
  content: [

    {
      title: "University of Auckland",
      path: '/study-abroad/universities/uoa',
      Image: nz1
    },
    {
      title: "Massey University",
      path: '/study-abroad/universities/muniversity',
      Image: nz2
    },
    {
      title: "University of Waikato",
      path: '/study-abroad/universities/uwaikato',
      Image: nz3
    },
    
    {
      title: "Victoria University of Wellington",
      path: '/study-abroad/universities/vuow',
      Image: nz4
    },
    {
      title: "University of Canterbury",
      path: '/study-abroad/universities/uoc',
      Image: nz5
    },    
    
    {
      title: "University of Otago",
      path: '/study-abroad/universities/uoo',
      Image: nz6
    },
    {
      title: "Auckland University of Technology",
      path: '/study-abroad/universities/auot',
      Image: nz7
    },
    {
      title: "Lincoln University",
      path: '/study-abroad/universities/luniversity',
      Image: nz8
    },

    {
      title: "Eastern Institute of Technology, Te Pūkenga",
      path: '/study-abroad/universities/eiot',
      Image: nz9
    },

    {
      title: "UC International College",
      path: '/study-abroad/universities/ucic',
      Image: nz10
    },
  ]
};






export {
  USAUniversities,
  UKUniversities,
  IrelandUniversities,
  CanadaUniversities,
  GermanyUniversities,
  AustraliaUniversities,
  SingaporeUniversities,
  ItalyUniversities,
  FranceUniversities,
  DubaiUniversities,
  NewZealandUniversities,
};
