

import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import axios from "axios";
import back from "../../asset/universityfinder/back.png";
import front from "../../asset/universityfinder/front.png";

// Constants
const ITEMS_PER_PAGE = 12;

const CountrySelect = ({ setFilters ,selectedCountries,setSelectedCountries,handleCardClick}) => {
  const [page, setPage] = useState(1); // Start from page 1
  // const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);


  console.log(selectedCountries,'selectedCountries')
  // Fetch country data from the API using axios
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/university/countryList`,
          {
            params: { page }
          }
        );
  
        if (response.data.success && response.data.status === 200) {
          setCountryData(response.data.data.formattedData);
          setTotalItems(response.data.data.count);
  
          // Retrieve selected countries from localStorage
          const storedSelectedCountries = JSON.parse(
            localStorage.getItem("selectedCountries")
          );
          if (storedSelectedCountries) {
            setSelectedCountries(storedSelectedCountries);
            setFilters((prev) => ({
              ...prev,
              country: storedSelectedCountries,
            }));
          }
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchCountryData();
  }, [page, setFilters]);
  


  return (
    <Box  >
      {/* Country selection grid */}
      <Grid
        container
        spacing={2}
        sx={{
          height: { 
            // sm: "400px",
             xs: "auto" },
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {countryData.map((item) => (
          <Grid item xs={6} md={3} value={setSelectedCountries} key={item._id}>
            <Box
              onClick={() => handleCardClick(item._id)}
              sx={{
                border: "2px solid #c1c1c1",
                borderRadius: "10px",
                height: "110px",
                width: "120px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                overflow: "hidden",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: selectedCountries.includes(item._id)
                  ? "#00999E"
                  : "#Effdff"
              }}
            >
              <Box sx={{ width: "80px", height: "65px", padding: "8px 0" }}>
                <img src={item.icon} alt="" width={"100%"} height={"100%"} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  pt: "2px",
                  color: selectedCountries.includes(item._id) ? "#fff" : "#000"
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      </Box>    

  );
};

export default CountrySelect;
