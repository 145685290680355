import React, { useState } from "react";
import { Box, Typography, Button, Dialog, DialogContent } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import CareerDialogPage from "./CareerDialogPage";

export default function CareerSelectedCard() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([
    { name: "India", flagUrl: "https://flagcdn.com/w320/in.png" },
    { name: "United Kingdom", flagUrl: "https://flagcdn.com/w320/gb.png" },
    { name: "United States", flagUrl: "https://flagcdn.com/w320/us.png" },
  ]);

  const handleSelectCountry = (country) => {
    if (!selectedCountries.find((c) => c.name === country.name)) {
      setSelectedCountries((prev) => [...prev, country]);
    }
    handleCloseModal();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleRemoveCountry = (countryName) => {
    setSelectedCountries((prev) =>
      prev.filter((country) => country.name !== countryName)
    );
  };

  return (
    <Box sx={{ mt: "20px", px: { xs: "16px", sm: "24px" } }}>
      <Box
        sx={{
          marginTop: "12px",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          padding: "18px 30px",
          gap: { xs: "12px", sm: "16px" },
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "150px" },
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#00999E",
              color: "white",
              borderRadius: "12px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={handleOpenModal}
          >
            Countries
          </Box>
          <ArrowForwardIosIcon
            sx={{
              color: "#00999E",
              fontSize: "20px",
              mx: 2,
              display: { xs: "none", sm: "block" },
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                padding: "20px 0px",
                fontSize: "15px",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              Selected Countries
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 12,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {selectedCountries.map((country, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  padding: "12px",
                  width: { xs: "100%", sm: "150px" },
                  height: "120px",
                  backgroundColor: "#EFFDFF",
                  borderRadius: "12px",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleRemoveCountry(country.name)}
                >
                  <CloseIcon sx={{ fontSize: "18px", color: "#606060E5" }} />
                </Box>

                <img
                  src={country.flagUrl}
                  alt={country.name}
                  style={{
                    width: "80px",
                    height: "60px",
                    borderRadius: "10%",
                  }}
                />
                <Typography
                  sx={{ fontSize: "15px", fontWeight: "600", color: "#595959" }}
                >
                  {country.name}
                </Typography>
              </Box>
            ))}
            <Button
              variant="outlined"
              onClick={handleOpenModal}
              sx={{
                width: { xs: "100%", sm: "150px" },
                height: "120px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "12px",
                borderColor: "#00999E",
                backgroundColor: "#EFFDFF",
                color: "#00999E",
                textTransform: "none",
              }}
            >
              <Typography variant="body1" fontWeight="bold" color="#595959">
                + <br /> Add One More Country
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md">
        <DialogContent sx={{ position: "relative" }}>
          <CloseIcon
            onClick={handleCloseModal}
            sx={{
              fontSize: "25px",
              color: "#606060",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          />
          <CareerDialogPage onSelectCountry={handleSelectCountry} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
