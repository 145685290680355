import eligibility1 from "../asset/eligibility1.png";
import eligibility2 from "../asset/eligibility2.png";
import eligibility3 from "../asset/eligibility3.png";
import eligibility4 from "../asset/eligibility4.png";
const eligibilityData = [
  {
    title: "Age",
    items: [
      "Candidates must be at least 17 years old at the time of admission.",
    ],
    Image: eligibility1,
  },
  {
    title: "Educational Qualifications",
    items: [
      "Candidates must have completed 10+2 or equivalent examinations with Physics, Chemistry, Biology.",
    ],
    Image: eligibility2,
  },
  {
    title: "Minimum Marks",
    items: [
      "Candidates must have scored the minimum aggregate marks required in the qualifying examination which is 50% for the general category, 45% for OBC/SC/ST, and 40% for PWD.",
    ],
    Image: eligibility3,
  },
  {
    title: "Entrance Exams",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility4,
  },
];

export default eligibilityData;
