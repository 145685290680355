import ielts from "../asset/ielts.svg";
import toefl from "../asset/toefl.svg";
import gre from "../asset/gre.svg";
import gmat from "../asset/gmat.svg";
import pte from "../asset/pte.svg";
import sat from "../asset/sat.svg";

const OurTestData = [
    {
        title: "ILETS",
        path: "/test/ielts",
        image: ielts,
    },
    {
        title: "TOEFL",
        path: "/test/toefl",
        image: toefl,
    },
    {
        title: "PTE",
        path: "/test/pte",
        image: pte,
    },
    {
        title: "GRE",
        path: "/test/gre",
        image: gre,
    },
    {
        title: "GMAT",
        path: "/test/gmat",
        image: gmat,
    },
    {
        title: "SAT",
        path: "/test/sat",
        image: sat,
    },
];

export default OurTestData;
