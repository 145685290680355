import React from "react";
import { Box, Typography } from "@mui/material";

const Bar = ({ totalClasses, completedClasses }) => {
  const steps = Array.from(
    { length: totalClasses },
    (_, i) => (i + 1) * (100 / totalClasses)
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="flex-end">
      {steps.map((step, index) => (
        <Box
          key={index}
          position="relative"
          width={40}
          height={500 / (totalClasses - index + 1)} // Adjusting the height dynamically based on the total classes
          marginX={0.5}
          bgcolor={index < completedClasses ? "#008080" : "transparent"}
          border={index < completedClasses ? "none" : "2px solid #008080"}
          borderRadius="4px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="caption"
            color={index < completedClasses ? "white" : "black"}
          >
            {step.toFixed(1)}%
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Bar;
