import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { useLocation, useParams } from "react-router";
import axios from "axios";
import moment from "moment";
import parser from "react-html-parser";

const BlogDetails = () => {
  const location = useLocation();
  const params = useParams();

  const [blogData, setBlogData] = useState(location.state?.data || null); // Check location.state

  const fetchBlogData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blogs/by-slugurl/${params?.slugUrl}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("tokenx")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        setBlogData(response.data.data);
      } else {
        console.log("Failed to fetch blog data.");
      }
    } catch (error) {
      console.error(error.response?.data || error.message);
    }
  };

  useEffect(() => {
    if (!blogData) {
      // Fetch blog data if not available in location.state
      fetchBlogData();
    }
  }, [blogData]);

  const dateLocal = moment(blogData?.date).format("DD MMM YYYY");

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          bgcolor: "background.default",
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            p: 0.5,
            bgcolor: "white",
          }}
        >
          {/* Title Section */}
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {blogData?.title}
          </Typography>

          {/* Image Section */}
          <Box
            component="img"
            src={blogData?.image}
            alt="Blog Image"
            sx={{
              width: "100%",
              height: "400px",
              objectFit: "cover",
              borderRadius: 2,
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          />

          {/* Date Section */}
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            Date: {dateLocal}
          </Typography>
        </Box>
      </Box>

      <Box
        maxWidth="lg"
        sx={{
          fontFamily: "poppins",
          paddingX: { xs: 1, sm: 3, md: 5 },
          "& img": {
            maxWidth: "100%",
            height: "auto",
            marginBottom: "16px",
          },
          "& p": {
            marginBottom: "16px",
            fontSize: { xs: "16px", sm: "16px", md: "18px" },
          },
        }}
      >
        {blogData?.description ? (
          <Box>
            <div className="table-container">
              {parser(blogData.description)}
            </div>
          </Box>
        ) : (
          "No content available."
        )}
      </Box>

      <Footer />
    </>
  );
};

export default BlogDetails;
