import img from "../../../asset/philadelphia.svg";


const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const usa2 =  imageBaseUrl + "CountryPlaces/us/boston.png";
const usa3 =  imageBaseUrl + "CountryPlaces/us/newyork.png";
const usa4 =  imageBaseUrl + "CountryPlaces/us/losangeles.png";
const usa5 =  imageBaseUrl + "CountryPlaces/us/sanfrancisco.png";
const usa6 =  imageBaseUrl + "CountryPlaces/us/washington.png";
const usa7 =  imageBaseUrl + "CountryPlaces/us/chicago.png";
const uk1 =  imageBaseUrl + "CountryPlaces/uk/uk1.png";
const uk2 =  imageBaseUrl + "CountryPlaces/uk/uk2.png";
const uk3 =  imageBaseUrl + "CountryPlaces/uk/uk3.png";
const uk4 =  imageBaseUrl + "CountryPlaces/uk/uk4.png";
const uk5 =  imageBaseUrl + "CountryPlaces/uk/uk5.png";
const uk6 =  imageBaseUrl + "CountryPlaces/uk/uk6.png";
const uk7 =  imageBaseUrl + "CountryPlaces/uk/uk7.png";
const uk8 =  imageBaseUrl + "CountryPlaces/uk/uk8.png";
const ire1 =  imageBaseUrl + "CountryPlaces/ireland/ireland1.png";
const ire2 =  imageBaseUrl + "CountryPlaces/ireland/ireland2.png";
const ire3 =  imageBaseUrl + "CountryPlaces/ireland/ireland3.png";
const ire4 =  imageBaseUrl + "CountryPlaces/ireland/ireland4.png";
const ire5 =  imageBaseUrl + "CountryPlaces/ireland/ireland5.png";
const ire6 =  imageBaseUrl + "CountryPlaces/ireland/ireland6.png";
const ire7 =  imageBaseUrl + "CountryPlaces/ireland/ireland7.png";
const ire8 =  imageBaseUrl + "CountryPlaces/ireland/ireland8.png";
const can1 =  imageBaseUrl + "CountryPlaces/canada/canada1.png";
const can2 =  imageBaseUrl + "CountryPlaces/canada/canada2.png";
const can3 =  imageBaseUrl + "CountryPlaces/canada/canada3.png";
const can4 =  imageBaseUrl + "CountryPlaces/canada/canada4.png";
const can5 =  imageBaseUrl + "CountryPlaces/canada/canada5.png";
const can6 =  imageBaseUrl + "CountryPlaces/canada/canada6.png";
const can7 =  imageBaseUrl + "CountryPlaces/canada/canada7.png";
const can8 =  imageBaseUrl + "CountryPlaces/canada/canada8.png";
const ger1 =  imageBaseUrl + "CountryPlaces/germany/germany1.png";
const ger2 =  imageBaseUrl + "CountryPlaces/germany/germany2.png";
const ger3 =  imageBaseUrl + "CountryPlaces/germany/germany3.png";
const ger4 =  imageBaseUrl + "CountryPlaces/germany/germany4.png";
const ger5 =  imageBaseUrl + "CountryPlaces/germany/germany5.png";
const ger6 =  imageBaseUrl + "CountryPlaces/germany/germany6.png";
const ger7 =  imageBaseUrl + "CountryPlaces/germany/germany7.png";
const ger8 =  imageBaseUrl + "CountryPlaces/germany/germany8.png";
const aus1 =  imageBaseUrl + "CountryPlaces/australia/australia1.png";
const aus2 =  imageBaseUrl + "CountryPlaces/australia/australia2.png";
const aus3 =  imageBaseUrl + "CountryPlaces/australia/australia3.png";
const aus4 =  imageBaseUrl + "CountryPlaces/australia/australia4.png";
const aus5 =  imageBaseUrl + "CountryPlaces/australia/australia5.png";
const aus6 =  imageBaseUrl + "CountryPlaces/australia/australia6.png";
const aus7 =  imageBaseUrl + "CountryPlaces/australia/australia7.png";
const aus8 =  imageBaseUrl + "CountryPlaces/australia/australia8.png";
const sin1 =  imageBaseUrl + "CountryPlaces/singapore/singapore1.png";
const sin2 =  imageBaseUrl + "CountryPlaces/singapore/singapore2.png";
const sin3 =  imageBaseUrl + "CountryPlaces/singapore/singapore3.png";
const sin4 =  imageBaseUrl + "CountryPlaces/singapore/singapore4.png";
const sin5 =  imageBaseUrl + "CountryPlaces/singapore/singapore5.png";
const sin6 =  imageBaseUrl + "CountryPlaces/singapore/singapore6.png";
const sin7 =  imageBaseUrl + "CountryPlaces/singapore/singapore7.png";
const sin8 =  imageBaseUrl + "CountryPlaces/singapore/singapore8.png";
const ita1 =  imageBaseUrl + "CountryPlaces/Italy/italy1.png";
const ita2 =  imageBaseUrl + "CountryPlaces/Italy/italy2.png";
const ita3 =  imageBaseUrl + "CountryPlaces/Italy/italy3.png";
const ita4 =  imageBaseUrl + "CountryPlaces/Italy/italy4.png";
const ita5 =  imageBaseUrl + "CountryPlaces/Italy/italy5.png";
const ita6 =  imageBaseUrl + "CountryPlaces/Italy/italy6.png";
const ita7 =  imageBaseUrl + "CountryPlaces/Italy/italy7.png";
const ita8 =  imageBaseUrl + "CountryPlaces/Italy/italy8.png";
const fra1 =  imageBaseUrl + "CountryPlaces/france/france1.png";
const fra2 =  imageBaseUrl + "CountryPlaces/france/france2.png";
const fra3 =  imageBaseUrl + "CountryPlaces/france/france3.png";
const fra4 =  imageBaseUrl + "CountryPlaces/france/france4.png";
const fra5 =  imageBaseUrl + "CountryPlaces/france/france5.png";
const fra6 =  imageBaseUrl + "CountryPlaces/france/france6.png";
const fra7 =  imageBaseUrl + "CountryPlaces/france/france7.png";
const fra8 =  imageBaseUrl + "CountryPlaces/france/france8.png";
const dub1 =  imageBaseUrl + "CountryPlaces/dubai/dubai1.png";
const dub2 =  imageBaseUrl + "CountryPlaces/dubai/dubai2.png";
const dub3 =  imageBaseUrl + "CountryPlaces/dubai/dubai3.png";
const dub4 =  imageBaseUrl + "CountryPlaces/dubai/dubai4.png";
const dub5 =  imageBaseUrl + "CountryPlaces/dubai/dubai5.png";
const dub6 =  imageBaseUrl + "CountryPlaces/dubai/dubai6.png";
const dub7 =  imageBaseUrl + "CountryPlaces/dubai/dubai7.png";
const dub8 =  imageBaseUrl + "CountryPlaces/dubai/dubai8.png";
const NZ1 =  imageBaseUrl + "CountryPlaces/newzealand/NZ1.png";
const NZ2 =  imageBaseUrl + "CountryPlaces/newzealand/NZ2.png";
const NZ3 =  imageBaseUrl + "CountryPlaces/newzealand/NZ3.png";
const NZ4 =  imageBaseUrl + "CountryPlaces/newzealand/NZ4.png";
const NZ5 =  imageBaseUrl + "CountryPlaces/newzealand/NZ5.png";
const NZ6 =  imageBaseUrl + "CountryPlaces/newzealand/NZ6.png";
const NZ7 =  imageBaseUrl + "CountryPlaces/newzealand/NZ7.png";
const NZ8 =  imageBaseUrl + "CountryPlaces/newzealand/NZ8.png";
const NZ9 =  imageBaseUrl + "CountryPlaces/newzealand/NZ9.png";
const NZ10 =  imageBaseUrl + "CountryPlaces/newzealand/NZ10.png";

const USAPlace = {
  title: "Popular Places to Study in the USA",
  data: [
    {
      city: "Boston",
      img: usa2
    },
    {
      city: "New York City",
      img: usa3
    },
    {
      city: "Los Angeles",
      img: usa4
    },
    {
      city: "San Francisco Bay Area",
      img: usa5
    },
    {
      city: "Washington",
      img: usa6
    },
    {
      city: "Chicago",
      img: usa7
    },
    {
      city: "Philadelphia",
      img: img
    }
  ]
};
const UKPlace = {
  title: "Popular Places to Study in the United Kingdom",

  data: [
    {
      city: "Oxford",
      img: uk1
    },
    {
      city: "London",
      img: uk2
    },
    {
      city: "Edinburgh",
      img: uk3
    },
    {
      city: "Manchester",
      img: uk4
    },
    {
      city: "Bristol",
      img: uk5
    },
    {
      city: "Birmingham",
      img: uk6
    },
    {
      city: "Glasgow",
      img: uk7
    },
    {
      city: "Nottingham",
      img: uk8
    }
  ]
};
const IrelandPlace = {
  title: "Popular Places to Study in the Ireland",

  data: [
    {
      city: "Dublin",
      img: ire1
    },
    {
      city: "Cork",
      img: ire2
    },
    {
      city: "Galway",
      img: ire3
    },
    {
      city: "Limerick",
      img: ire4
    },
    {
      city: "Waterford",
      img: ire5
    },
    {
      city: "Maynooth",
      img: ire6
    },
    {
      city: "Dundalk",
      img: ire7
    },
    {
      city: "Athlone",
      img: ire8
    }
  ]
};
const CanadaPlace = {
  title: "Popular Places to Study in the Canada",

  data: [
    {
      city: "Toronto",
      img: can1
    },
    {
      city: "Vancouver",
      img: can2
    },
    {
      city: "Montreal",
      img: can3
    },
    {
      city: "Ottawa",
      img: can4
    },
    {
      city: "Calgary",
      img: can5
    },
    {
      city: "Edmonton",
      img: can6
    },
    {
      city: "Quebec City",
      img: can7
    },
    {
      city: "Halifax",
      img: can8
    }
  ]
};
const GermanyPlace = {
  title: "Popular Places to Study in the Germany",

  data: [
    {
      city: "Berlin",
      img: ger1
    },
    {
      city: "Munich",
      img: ger2
    },
    {
      city: "Hamburg",
      img: ger3
    },
    {
      city: "Frankfurt",
      img: ger4
    },
    {
      city: "Cologne",
      img: ger5
    },
    {
      city: "Stuttgart",
      img: ger6
    },
    {
      city: "Dusseldorf",
      img: ger7
    },
    {
      city: "Leipzig",
      img: ger8
    }
  ]
};
const AustraliaPlace = {
  title: "Popular Places to Study in the Australia",

  data: [
    {
      city: "Sydney",
      img: aus1
    },
    {
      city: "Melbourne",
      img: aus2
    },
    {
      city: "Brisbane",
      img: aus3
    },
    {
      city: "Perth",
      img: aus4
    },
    {
      city: "Adelaide",
      img: aus5
    },
    {
      city: "Canberra",
      img: aus6
    },
    {
      city: "Gold Coast",
      img: aus7
    },
    {
      city: "Hobart",
      img: aus8
    }
  ]
};
const SingaporePlace = {
  title: "Popular Places to Study in the Singapore",

  data: [
    {
      city: "Singapore City",
      img: sin1
    },
    {
      city: "Jurong",
      img: sin2
    },
    {
      city: "Tampines",
      img: sin3
    },
    {
      city: "Hougang",
      img: sin4
    },
    {
      city: "Woodlands",
      img: sin5
    },
    {
      city: "Bukit Batok",
      img: sin6
    },
    {
      city: "Bedok",
      img: sin7
    },
    {
      city: "Pasir Ris",
      img: sin8
    }
  ]
};
const ItalyPlace = {
  title: "Popular Places to Study in the Italy",

  data: [
    {
      city: "Rome",
      img: ita1
    },
    {
      city: "Milan",
      img: ita2
    },
    {
      city: "Florence",
      img: ita3
    },
    {
      city: "Venice",
      img: ita4
    },
    {
      city: "Bologna",
      img: ita5
    },
    {
      city: "Naples",
      img: ita6
    },
    {
      city: "Turin",
      img: ita7
    },
    {
      city: "Pisa",
      img: ita8
    }
  ]
};
const FrancePlace = {
  title: "Popular Places to Study in the France",

  data: [
    {
      city: "Paris",
      img: fra1
    },
    {
      city: "Lyon",
      img: fra2
    },
    {
      city: "Marseille",
      img: fra3
    },
    {
      city: "Toulouse",
      img: fra4
    },
    {
      city: "Bordeaux",
      img: fra5
    },
    {
      city: "Nice",
      img: fra6
    },
    {
      city: "Lille",
      img: fra7
    },
    {
      city: "Strasbourg",
      img: fra8
    }
  ]
};
const DubaiPlace = {
  title: "Popular Places to Study in the Dubai",

  data: [
    {
      city: "Dubai City",
      img: dub1
    },
    {
      city: "Abu Dhabi",
      img: dub2
    },
    {
      city: "Sharjah",
      img: dub3
    },
    {
      city: "Al Ain",
      img: dub4
    },
    {
      city: "Ajman",
      img: dub5
    },
    {
      city: "Ras Al Khaimah",
      img: dub6
    },
    {
      city: "Fujairah",
      img: dub7
    },
    {
      city: "Umm Al Quwain",
      img: dub8
    }
  ]
};

//country place 

const NewZealandPlace = {
  title: "Popular Places to study in New Zealand: ",

  data: [
    {
      city: "Auckland",
      img: NZ1
    },
    {
      city: "Wellington",
      img: NZ2
    },
    {
      city: "Christchurch",
      img: NZ3
    },
    {
      city: "Dunedin",
      img: NZ4
    },
    {
      city: "Hamilton",
      img: NZ5
    },
    {
      city: "Queenstown",
      img: NZ6
    },
    {
      city: "Rotorua",
      img: NZ7
    },
    {
      city: "Napier-Hastings",
      img: NZ8
    },
    {
      city: "Taupo",
      img: NZ9
    },
    {
      city: "Whangarei",
      img: NZ10
    }
  ]
};

export {
  USAPlace,
  UKPlace,
  IrelandPlace,
  CanadaPlace,
  GermanyPlace,
  AustraliaPlace,
  SingaporePlace,
  ItalyPlace,
  FrancePlace,
  DubaiPlace,
  NewZealandPlace,
};
