import Image1 from '../../../asset/popular1.png'
import Image2 from '../../../asset/popular2.png'
import Image3 from '../../../asset/popular3.png'
import Image4 from '../../../asset/popular4.png'

const USAPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management",
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
        ],
    },
    {
        id: 6,
        Image: Image2,
        title: "Law",
        items: ["JD", "LLM ", "Legal Studies"],
    },
    {
        id: 7,
        Image: Image3,
        title: "Creative Arts and Design",
        items: ["Fine Arts", "Graphic Design", "Architecture", "Film Studies"],
    },

];
const UKPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management",
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
        ],
    },
    {
        id: 6,
        Image: Image2,
        title: "Law",
        items: ["JD", "LLM ", "Legal Studies"],
    },
    {
        id: 7,
        Image: Image3,
        title: "Creative Arts and Design",
        items: ["Fine Arts", "Graphic Design", "Architecture", "Film Studies"],
    },
    {
        id: 8,
        Image: Image4,
        title: "Literature",
        items: ["English Literature"],
    },
];
const IrelandPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Biotechnology",
            "Medicine",
            "Pharmaceuticals",
            
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "International Relations"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
        ],
    },


];
const CanadaPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management",
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
        ],
    },

];
const GermanyPopular =
    [
        {
            id: 1,
            Image: Image1,
            title: "Computer Science and Engineering",
            items: ["Computer Science", "Software Engineering", "Data Science"],
        },
        {
            id: 2,
            Image: Image2,
            title: "Business Administration and Management",
            items: ["MBA", "Business Analytics", "Marketing", "Human Resource Management"],
        },
        {
            id: 3,
            Image: Image3,
            title: "Health Sciences and Medicine",
            items: [
                "Psychology",
                "Medicine",
                "Nursing",
                "Public Health",
                "Healthcare Management", "Dentistry", "Pharmacy", "Physical Therapy"
            ],
        },
        {
            id: 4,
            Image: Image4,
            title: "Economics and Finance",
            items: ["Economics", "Finance", "Accounting", "Business Economics", "Financial Mathematics", "Actuarial Science"],
        },
        {
            id: 5,
            Image: Image1,
            title: "STEM",
            items: [
                "Biology",
                "Chemistry",
                "Physics",
                "Mathematics",
                "Engineering",
                "Astrophysics",
                "Civil Engineering"
            ],
        },

    ];
const AustraliaPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics", "Marketing", "Human Resource Management"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management", "Dentistry", "Pharmacy", "Physical Therapy"
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics", "Investment Banking", "Risk Management"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
            "Astrophysics",
            "Civil Engineering"
        ],
    },

];
const SingaporePopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics", "Marketing", "Human Resource Management"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management", "Dentistry", "Pharmacy", "Physical Therapy"
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics", "Investment Banking", "Risk Management"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
            "Astrophysics",
            "Civil Engineering"
        ],
    },
];
const ItalyPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics", "Marketing", "Human Resource Management"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management", "Dentistry", "Pharmacy", "Physical Therapy"
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics", "Investment Banking", "Risk Management"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
            "Astrophysics",
            "Civil Engineering"
        ],
    },

];
const FrancePopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Information Technology"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics", "Management"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Medicine",
            "Nursing",
            "Public Health",

        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "International Business"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering",
        ],
    },
    {
        id: 6,
        Image: Image2,
        title: "Law",
        items: ["Law", "International Law ", "Legal Studies"],
    },
    {
        id: 7,
        Image: Image3,
        title: "Creative Arts and Design",
        items: ["Fine Arts", "Graphic Design", "Design", "Fashion Design"],
    },
    {
        id: 8,
        Image: Image4,
        title: "Literature",
        items: ["Literature", "French Studies", "Comparative Literature"],
    },
];
const DubaiPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics", "Marketing", "Human Resource Management"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management",
            "Dentistry",
            "Pharmacy",
            "Physical Therapy"
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics", "Investment Banking", "Risk Management"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering", "Engineering", "Astrophysics", "Civil Engineering"
        ],
    },

];

const NewZealandPopular = [
    {
        id: 1,
        Image: Image1,
        title: "Computer Science and Engineering",
        items: ["Computer Science", "Software Engineering", "Data Science"],
    },
    {
        id: 2,
        Image: Image2,
        title: "Business Administration and Management",
        items: ["MBA", "Business Analytics", "Marketing", "Human Resource Management"],
    },
    {
        id: 3,
        Image: Image3,
        title: "Health Sciences and Medicine",
        items: [
            "Psychology",
            "Medicine",
            "Nursing",
            "Public Health",
            "Healthcare Management",
            "Dentistry",
            "Pharmacy",
            "Physical Therapy"
        ],
    },
    {
        id: 4,
        Image: Image4,
        title: "Economics and Finance",
        items: ["Economics", "Finance", "Accounting", "Business Economics", "Investment Banking", "Risk Management"],
    },
    {
        id: 5,
        Image: Image1,
        title: "STEM",
        items: [
            "Biology",
            "Chemistry",
            "Physics",
            "Mathematics",
            "Engineering", "Engineering", "Astrophysics", "Civil Engineering"
        ],
    },

];
export {
    USAPopular,
    UKPopular,
    IrelandPopular,
    CanadaPopular,
    GermanyPopular,
    AustraliaPopular,
    SingaporePopular,
    ItalyPopular,
    FrancePopular,
    DubaiPopular,
    NewZealandPopular,
};
