import immigrationOfferImg1 from "../asset/immigrationOfferImg-1.svg";
import immigrationOfferImg2 from "../asset/immigrationoffer3.svg";
import immigrationOfferImg3 from "../asset/immigrationOfferImg-2.svg";

const immigrationOfferData = [
  {
    title: "Permanent Residency for Canada & Australia",
    description:
      "Receive personalised consultation and assistance with preparing and submitting your PR application including document preparation, eligibility assessment and application tracking for programs such as Express Entry, Provincial Nominee Programs (PNPs), General Skilled Migration (GSM) program (GSMs), Employer sponsored, Family Sponsorship, etc..",
    Image: immigrationOfferImg1,
  },
  {
    title: "Work Permit for UK",
    description:
      "Get guidance on eligibility requirements, applications, and compliances with the country’s immigration laws for work permits through programs such as the Skilled Worker Visa (formerly Tier 2 General), Intra-Company Transfer Visa (ICT) and more.",

    Image: immigrationOfferImg2,
  },
  {
    title: "Job Search Services",
    description:
      "Our job search services are tailored to assist immigrants in finding rewarding career opportunities in a vibrant job market. You will benefit from resume and cover letter writing assistance, interview preparation, job search strategies, networking opportunities, and access to job listings and recruitment resources.",
    Image: immigrationOfferImg3,
  },
];

export default immigrationOfferData;
