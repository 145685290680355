import { useTheme } from "@emotion/react";
import { Box, Button, createTheme, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, } from "@mui/material";
import {useState,useEffect} from "react";

export default function AdmissionUniversityActionTypeList() {
 
    const [width,setWidth] = useState(320);

    useEffect(()=>{
    
    const handleWidth = () => {
        setWidth(window.innerWidth - 100);
    }    

    window.addEventListener('resize' , handleWidth);

    return () => {
        window.removeEventListener('resize', handleWidth);
    }

    },[])

    const titleData = [
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
  ];

  const lorsData = [
    "Completed",
    "In Progress",
    "Not Required",
    "Completed",
    "In Progress",
  ];

  const sopsData = [
    "Not Required",
    "Yet To Start",
    "Completed",
    "Not Required",
    "Yet To Start",
  ];

  const essaysData = [
    "Yet To Start",
    "In Progress",
    "Not Required",
    "Completed",
    "Not Required",
  ];

  const theme = useTheme();
  const ScreenFor = createTheme({
    breakpoints : {
        values : {
            xs : 0,
            sm : 600,
            md : 960,
            lg : 1200,
            xl : 1600
        }
    }
  })
  const isXsScreen = useMediaQuery(ScreenFor.breakpoints.between("xs","sm"));
  const isSmScreen = useMediaQuery(ScreenFor.breakpoints.between("sm","md"));

  return (
    <Paper elevation={1} 
    sx={{
        width : isXsScreen || isSmScreen ? "95%" : "92%",
        margin  : "auto",
        marginRight : isXsScreen || isSmScreen ? 0 : 12,
        paddingX  : 2,
        paddingRight : 3,
        paddingY  : 3
    }}
    >
      {/* Close Button */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 1,
          position: "relative",
          top: -12,
          right: -20,
          mt: 1,
          mr: 1,
        }}
        onClick= {()=>handleClose()}
      >
        <img src={Button_icon_wrong_abroad} alt="wrong Btn" />
      </Box> */}

      {/* Main Content */}
      {
        isXsScreen || isSmScreen ? <>



<TableContainer
  sx={{
    overflow: "auto",  
    maxWidth : width
  }}>
        <Table>
          {/* Table Header */}
          <TableHead>
            <TableRow>
              <TableCell>University</TableCell>
              <TableCell>LORs</TableCell>
              <TableCell>SOPs</TableCell>
              <TableCell>Essays</TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {titleData.map((university, index) => (
              <TableRow key={index}>
                {/* University Column */}
                <TableCell>
                  <Typography sx={{ fontSize: "14px" }}>{university}</Typography>
                </TableCell>

                {/* LORs Column */}
                <TableCell>
                  <Box
                    sx={{
                      bgcolor: "#B1FCFF",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "6px 12px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: `4px solid ${
                          lorsData[index] === "Completed"
                            ? "#20CF10"
                            : lorsData[index] === "In Progress"
                            ? "orange"
                            : lorsData[index] === "Not Required"
                            ? "red"
                            : "black"
                        }`,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        mr: 1,
                      }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>{lorsData[index]}</Typography>
                  </Box>
                </TableCell>

                {/* SOPs Column */}
                <TableCell>
                  <Box
                    sx={{
                      bgcolor: "#B1FCFF",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "6px 12px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: `4px solid ${
                          sopsData[index] === "Completed"
                            ? "#20CF10"
                            : sopsData[index] === "In Progress"
                            ? "orange"
                            : sopsData[index] === "Not Required"
                            ? "red"
                            : "black"
                        }`,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        mr: 1,
                      }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>{sopsData[index]}</Typography>
                  </Box>
                </TableCell>

                {/* Essays Column */}
                <TableCell>
                  <Box
                    sx={{
                      bgcolor: "#B1FCFF",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "6px 12px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: `4px solid ${
                          essaysData[index] === "Completed"
                            ? "#20CF10"
                            : essaysData[index] === "In Progress"
                            ? "orange"
                            : essaysData[index] === "Not Required"
                            ? "red"
                            : "black"
                        }`,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        mr: 1,
                      }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>{essaysData[index]}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


        </>  :  <>
        

        <Grid container spacing={2}>
        {/* Headers */}
        <Grid
          item
          container
          spacing={2}
          sx={{ marginLeft: 3, alignItems: "center" }}
        >
          <Grid item xs={3}>
            <Typography variant="h4">University</Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{
                backgroundColor: "#00999E",
                color: "white",
                textTransform: "capitalize",
                width: "70%",
                height: "55px",
                 "&:hover" : {
                  backgroundColor : "#00999E",
                  color : "white",}, 
                  borderRadius : '10px'
              }}
              size="large"
            >
              LORs
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{
                backgroundColor: "#00999E",
                color: "white",
                textTransform: "capitalize",
                width: "70%",
                height: "55px",
                 "&:hover" : {
                  backgroundColor : "#00999E",
                  color : "white",}, 
                  borderRadius : '10px'
              }}
              size="large"
            >
              SORs
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{
                backgroundColor: "#00999E",
                color: "white",
                textTransform: "capitalize",
                width: "70%",
                height: "55px",
                "&:hover" : {
                  backgroundColor : "#00999E",
                  color : "white"
                } ,
                borderRadius : '10px'
              }}
              size="large"
            >
              Essays
            </Button>
          </Grid>
        </Grid>

        {/* Content Rows */}
        {titleData.map((university, index) => (
          <Grid
            item
            container
            spacing={2}
            key={index}
            alignItems="center"
            sx={{ mt: 1, ml: 1.4 }}
          >
            {/* University Column */}
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  padding: "6px 12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "black", fontSize: "18px" , fontWeight : '600' , display : "flex" , flexWrap : "wrap" }}>
                  {university}
                </Typography>
              </Box>
            </Grid>

            {/* LORs Column */}
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  bgcolor: "#B1FCFF",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "6px 12px",
                  width: "160px",
                }}
              >
                <Box
                  sx={{
                    height: "12px",
                    width: "12px",
                    border: `4px solid ${lorsData[index] === "Completed"
                        ? "#20CF10"
                        : lorsData[index] === "In Progress"
                        ? "orange"
                        : lorsData[index] === "Not Required"
                        ? "red"
                        : "black"
                    }`,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    mr: 1,
                  }}
                />
                <Typography sx={{ color: "black", fontSize: "14px" }}>
                  {lorsData[index]}
                </Typography>
              </Box>
            </Grid>

            {/* SORs Column */}
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  bgcolor: "#B1FCFF",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "6px 12px",
                  width: "160px",
                }}
              >
                <Box
                  sx={{
                    height: "12px",
                    width: "12px",
                    border: `4px solid ${
                      sopsData[index] === "Completed"
                        ? "#20CF10"
                        : sopsData[index] === "In Progress"
                        ? "orange"
                        : sopsData[index] === "Not Required"
                        ? "red"
                        : "black"
                    }`,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    mr: 1,
                  }}
                />
                <Typography sx={{ color: "black", fontSize: "14px" }}>
                  {sopsData[index]}
                </Typography>
              </Box>
            </Grid>

            {/* Essays Column */}
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  bgcolor: "#B1FCFF",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "6px 12px",
                  width: "160px",
                }}
              >
                <Box
                  sx={{
                    height: "12px",
                    width: "12px",
                    border: `4px solid ${
                      essaysData[index] === "Completed"
                        ? "#20CF10"
                        : essaysData[index] === "In Progress"
                        ? "orange"
                        : essaysData[index] === "Not Required"
                        ? "red"
                        : "black"
                    }`,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    mr: 1,
                  }}
                />
                <Typography sx={{ color: "black", fontSize: "14px" }}>
                  {essaysData[index]}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>






        </>
      }
      
    </Paper>
  );
} 