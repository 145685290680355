import { Box, Card, Typography, Grid, Container } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";


const Intakes = ({ data }) => {
   

    return (
        <>
            <Container
                sx={{
                    // padding: {
                    //     xs: "0px 20px",
                    //     md: "0px 40px",
                    //     xl: "0px 80px"
                    // }`
                }}
            >
            
                <Grid container spacing={4}>
                    {data?.map((item) => (
                        <Grid item xl={4} md={4} sm=
                            {12} xs={12} key={item.id} display={"flex"} justifyContent={"center"}>
                            <Card
                                sx={{
                                    width: "300px",
                                    height: "auto",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "30px 20px",
                                    borderTop: `3px solid ${item?.topColor}`
                                }}
                            >
                                <Box sx={{ padding: "20px 0" }}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            color: "#00999E",
                                            fontFamily: "poppins",
                                            fontSize: "22px",
                                            fontWeight: "600",
                                            textAlign: "center", mb: "20px"
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    {item.listitem.map((listItem) => (
                                        <ul>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start", mb: "10px"

                                                }}
                                            >
                                                <li
                                                    style={{
                                                        listStyleType: "none",
                                                        fontFamily: "poppins",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: "16px",
                                                        color: "black",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    <CheckIcon
                                                        style={{
                                                            color: "#00999E",
                                                            fontSize: "28px",
                                                            paddingRight: "5px"
                                                        }}
                                                    />
                                                    {listItem}
                                                </li>
                                            </Box>
                                        </ul>
                                    ))}
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default Intakes;
