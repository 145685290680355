import { Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import React from "react";
import Button_icon_wrong_abroad from "../../../../asset/Button_icon_abroad.svg";
import { useLocation } from "react-router";

export default function CheckAllAppStatus({ handleClose }) {


  


  const titleData = [
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
    "University of Alabama Birmingham",
  ];

  const lorsData = [
    "Completed",
    "In Progress",
    "Not Required",
    "Completed",
    "In Progress",
  ];

  const sopsData = [
    "Not Required",
    "Yet To Start",
    "Completed",
    "Not Required",
    "Yet To Start",
  ];

  const essaysData = [
    "Yet To Start",
    "In Progress",
    "Not Required",
    "Completed",
    "Not Required",
  ];

  return (
    <Box>
      {/* Close Button */}
 <Box
  sx={{
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    position: "relative",
    top: -12,
    right: -20,
    mt: 1,
    mr: 1,
  }}
  onClick={() => handleClose()}
>
  <img src={Button_icon_wrong_abroad} alt="wrong Btn" />
</Box> 

      {/* Table Content */}
      <TableContainer component={Paper}>
        <Table>
          {/* Table Header */}
          <TableHead>
            <TableRow>
              <TableCell>University</TableCell>
              <TableCell>LORs</TableCell>
              <TableCell>SOPs</TableCell>
              <TableCell>Essays</TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {titleData.map((university, index) => (
              <TableRow key={index}>
                {/* University Column */}
                <TableCell>
                  <Typography sx={{ fontSize: "14px" }}>{university}</Typography>
                </TableCell>

                {/* LORs Column */}
                <TableCell>
                  <Box
                    sx={{
                      bgcolor: "#B1FCFF",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "6px 12px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: `4px solid ${
                          lorsData[index] === "Completed"
                            ? "#20CF10"
                            : lorsData[index] === "In Progress"
                            ? "orange"
                            : lorsData[index] === "Not Required"
                            ? "red"
                            : "black"
                        }`,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        mr: 1,
                      }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>{lorsData[index]}</Typography>
                  </Box>
                </TableCell>

                {/* SOPs Column */}
                <TableCell>
                  <Box
                    sx={{
                      bgcolor: "#B1FCFF",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "6px 12px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: `4px solid ${
                          sopsData[index] === "Completed"
                            ? "#20CF10"
                            : sopsData[index] === "In Progress"
                            ? "orange"
                            : sopsData[index] === "Not Required"
                            ? "red"
                            : "black"
                        }`,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        mr: 1,
                      }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>{sopsData[index]}</Typography>
                  </Box>
                </TableCell>

                {/* Essays Column */}
                <TableCell>
                  <Box
                    sx={{
                      bgcolor: "#B1FCFF",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "6px 12px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: `4px solid ${
                          essaysData[index] === "Completed"
                            ? "#20CF10"
                            : essaysData[index] === "In Progress"
                            ? "orange"
                            : essaysData[index] === "Not Required"
                            ? "red"
                            : "black"
                        }`,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        mr: 1,
                      }}
                    />
                    <Typography sx={{ fontSize: "14px" }}>{essaysData[index]}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
