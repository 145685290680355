/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import MainHeader from "src/Component/content-component/MainHeader";
import { Icon } from "@iconify/react";
import MarkObtainedcard from "./Components/MarkObtained";

import AnswerTable from "./Components/ScoreTable";
import FacultyFeedback from "./Components/FacultyFeedback";
import { useLocation, useParams } from "react-router";

const MockScoreBoardtest = ({ completed }) => {
  const theme = useTheme();
  const location = useLocation();
  const { id } = useParams();
  console.log("test id :>> ", id);

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  useEffect(() => {}, [status]);
  // Use useMediaQuery to check if the screen width is less than the theme's breakpoint (e.g., 'sm')

  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: "30px",
          paddingTop: "20px"
        }}
      >
        {/* <Icon icon={"medical-icon:i-interpreter-services"} /> */}
        <Icon
          icon="heroicons:light-bulb"
          style={{ color: "#00999E", fontSize: "28px" }}
        />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Mock Score Board "}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Box sx={{ marginTop: 3 }}>
          <Box>
            <AnswerTable />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MockScoreBoardtest;
