import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ContactMain from "./contact/ContactMain";
import {
  Box,
  Container,
  Grid,
  createTheme,
  useMediaQuery
} from "@mui/material";
import { useEffect } from "react";
import Header from "./content-component/Header";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920
      }
    }
  });
  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Best Study Abroad & Test Prep Institute | Taksheela - Delhi, Kolkata,
          Jaipur
        </title>
        <meta
          name="description"
          content="Taksheela Institute offers top-notch study abroad, immigration, MBBS abroad, and test prep services. Based in Delhi, Kolkata, and Jaipur, we are the best institute for your global education needs. Discover our expert guidance and comprehensive support today!"
        />
        <meta
          name="keywords"
          content="best institute for your global education, Best study abroad institute in kolkata, study abroad in Delhi,"
        />
      </Helmet>
      <Navbar />
      <ContactMain />
      <Container maxWidth={isLargeScreen ? "xl" : "lg"}>
        <Grid container spacing={4} sx={{ paddingTop: "20px" }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Header color="#00999e" content="Head Office" />
            {/* <Typography
              variant='body2'
              sx={{
                color: '#00999E',
                fontSize: ["18px", "20px", "32px", "36px"],
                fontWeight: 600,
              }}
            >
              ~Head Office~
            </Typography> */}
            <Box sx={{ marginBottom: "20px" }}>
              <iframe
                title="Unique Title"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.464703215515!2d88.3672814!3d22.5330314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0276db766a48df%3A0xf49d4b95cbe8bcec!2sTaksheela%20Institute%20of%20Education!5e0!3m2!1sen!2sin!4v1697453294193!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0, marginTop: "20px" }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Header color="#00999e" content="Branch Office" />
            {/* <Typography
              variant='body2'
              sx={{
                color: '#00999E',
                fontSize: ["18px", "20px", "32px", "36px"],
                fontWeight: 600,
              }}
            >~Branch Office~</Typography> */}
            <Box sx={{ marginBottom: "20px" }}>
              <iframe
                title="Unique Title"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14022.967744570511!2d77.1981384!3d28.517411!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1172bab4731%3A0x33bd9636218081fa!2sHub%20Hive%2011%20Coworking%20Space%20Saket!5e0!3m2!1sen!2sin!4v1711447102515!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0, marginTop: "20px" }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer paddingTop="70px" />
    </>
  );
};

export default ContactUs;
