import stbg from '../../asset/homestudybg.svg'
// import imbg from '../asset/homeimmigrationbg.svg'
// import mbbg from '../asset/homembbsbg.svg'
// import tpbg from '../asset/hometestprepbg.svg'
import tpbg from '../../asset/hometestprepbg.svg'
import st from '../../asset/homestudyabroad.svg'
// import im from '../asset/homeimmigration.svg'
// import mb from '../asset/homembbs.png'
import tp from '../../asset/hometest.svg'

const   servicesdata = [
    {
        background: stbg,
        image: st,
        title: "STUDY ABROAD",
        title2: "Lorem ipsum dolor sit amet consectetur.",
        path: "/dashboard/admission"
    },
    // {
    //     background: imbg,
    //     image: im,
    //     title: "IMMIGRATION",
    //     path: "/immigration"
    // },
    // {
    //     background: mbbg,
    //     image: mb,
    //     title: "MBBS",
    //     path: "/mbbs"
    // },
    {
        background: tpbg,
        image: tp,
        title: "TEST PREP",
        title2: "Lorem ipsum dolor sit amet consectetur.",
        path: "/dashboard/enrollment"
    },
];
export default servicesdata;
