

import React, { useEffect, useState } from "react";
import { Box, TextField } from "@material-ui/core";
import { Typography, useMediaQuery } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

function FormComponent({
  handleCloseDialog,
  setOpenDialog,
  buttonText = "Schedule A Meeting",
  filters,
  // setFilters,
  setFilters = () => {},
  isFormValid,
}) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
 

  const [isErrorShow, setIsErrorShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [formErrorMsgShow,setFormErrorMsg] = useState({}); 

  useEffect(()=>{  
  },[isFormValid])

  // Handle form submission
  const handleSubmit = (values) => {
    setFilters(values); // Update the parent state with form values
    if (isFormValid) {
      setIsErrorShow(!values.name || !values.phone || !values.email);
      if (!values.name) errors.name = "Name is required";
      if (!values.phone) errors.phone = "Phone is required";
      if (!values.email) errors.email = "email is required";
      if (isErrorShow) return;
    }
    toast.success("Form submitted successfully!");
    if (handleCloseDialog) handleCloseDialog(); // Close dialog if needed
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Formik
        initialValues={{
          name: filters?.name || "",
          phone: filters?.phone || "",
          email: filters?.email || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, handleChange, handleBlur }) => (
          <Form
            style={{
              padding: "20px",
              display: "grid",
              alignContent: "space-between",
            }}
          >
            <Field
              as={TextField}
              id="name"
              label="Name"
              variant="outlined"
              color="#eeeeee"
              name="name"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              onChange={(e) => {
                handleChange(e); // Formik's change handler
                setFilters((prev) => ({
                  ...prev,
                  name: e.target.value,
                })); // Update filters
              }}
              onBlur={handleBlur}
              //Required
              // helperText={
              //   <ErrorMessage
              //     name="name"
              //     component="div"
              //     style={{ color: "red" }}
              //   />
              // }
            />
            {/* {errors && errors.name && <Typography>{errors.name}</Typography>} */}
            {isFormValid && !values.name &&<Typography sx={{color:"red"}}>Name is required</Typography>} 

            <Field
              as={TextField}
              id="phone"
              label="Phone"
              type="text"
              variant="outlined"
              color="#eeeeee"
              name="phone"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              onChange={(e) => {
                handleChange(e); // Formik's change handler
                setFilters((prev) => ({
                  ...prev,
                  phone: e.target.value,
                })); // Update filters
              }}
              onBlur={handleBlur}
              // helperText={
              //   <ErrorMessage
              //     name="phone"
              //     component="div"
              //     style={{ color: "red" }}
              //   />
              // }
              inputProps={{ maxLength: 10 }}
            />
            {/* {errors && errors.phone && <Typography>{errors.phone}</Typography>} */}
            {isFormValid && !values.phone &&<Typography sx={{color:"red"}}>Phone Number is required</Typography>} 

            <Field
              as={TextField}
              id="email"
              label="Email"
              variant="outlined"
              color="#eeeeee"
              type="email"
              name="email"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              onChange={(e) => {
                handleChange(e); // Formik's change handler
                setFilters((prev) => ({
                  ...prev,
                  email: e.target.value,
                })); // Update filters
              }}
              onBlur={handleBlur}
              //Required
              // helperText={
              //   <ErrorMessage
              //     name="email"
              //     component="div"
              //     style={{ color: "red" }}
              //   />
              // }
            />
            {/* {errors && errors.email && <Typography>{errors.email}</Typography>} */}
            {isFormValid && !values.email &&<Typography sx={{color:"red"}}>Email is required</Typography>}
            {/* //Required  */}
            {/* <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                color="primary"
              >
                {buttonText}
              </LoadingButton>
            </Box> */}
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
}

export default FormComponent;
