import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HeroComponet from "./header-component/HeroComponet";
import WhatisMbbs from "src/mbbs/WhatisMbbs";
import MbbsinIndia from "src/mbbs/MbbsinIndia";
import WhyStudyMbbsAbroad from "src/mbbs/WhyStudyMbbsAbroad";
import MbbsFaq from "../mbbs/MbbsFaq";
import CountriesList from "src/mbbs/Countries";
import LetsGetStarted from "./LetsGetStarted";
// import NewsComponent from "src/mbbs/News";
import { Box } from "@mui/material";

import { Helmet } from "react-helmet";
import Cta from "./card-components/Cta";
const MBBS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {" "}
      <Helmet>
        <title>Taksheela Institute: MBBS Abroad Expert</title>
        <meta
          name="description"
          content="Secure your MBBS admission abroad with Taksheela Institute. Get personalized support, find top medical universities, and achieve your medical career goals."
        />
        <meta
          name="keywords"
          content="mbbs in russia for indian students, lowest fee structure for mbbs in russia, Study MBBS Abroad"
        />
      </Helmet>
      <Navbar />
      <HeroComponet />
      <WhatisMbbs />
      <MbbsinIndia />
      <WhyStudyMbbsAbroad />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0"
        }}
      >
        <Cta title={"BOOK YOUR FREE DEMO SESSION"} />
      </Box>
      <CountriesList />
      <MbbsFaq />
      {/* <NewsComponent /> */}
      <LetsGetStarted top="20px" mb="70px" />
      <Footer />
    </>
  );
};

export default MBBS;
