import { Box, Container, Grid } from '@mui/material';
import React from 'react'
import FlipCard from 'src/Component/card-components/FlipCard';
import Header from 'src/Component/content-component/Header';

const CourseWhyChoose = ({ data }) => {
    return (
        <Box pt={6} pb={8} sx={{ bgcolor: "#effdff" }}>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={data?.heading}
                        textAlign={"center"}
                    />
                </Box>

                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {data?.item?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={12} sm={6} md={4} lg={4} key={index} sx={{ textAlign: "-webkit-center" }}>
                                    <FlipCard data={item} cardheight="250px" cardwidth="250px" fontSize='13px' isFlip={false} />
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
            </Container>
        </Box>
    )
}

export default CourseWhyChoose
