import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IELTS from "src/asset/IELTS_abroad.svg";
import Edit from "src/asset/Edit_abroad.svg";

export default function CustomSelectedPage({ SelectedData }) {
  console.log(SelectedData);

  const [openModal, setOpenModal] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState(SelectedData.data);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleRemoveCourse = (courseName) => {
    setSelectedCourses((prev) =>
      prev.filter((course) => course.name !== courseName)
    );
  };

  return (
    <Box
      sx={{
        mt: "20px",
        width: { xs: "90%", sm: "85%" },
        margin: "auto",
      }}
    >
      <Paper sx={{ p: 2, borderRadius: "12px", mt: "30px" }}>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            // justifyContent: "space-between",
            gap: { xs: 2, sm: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "250px", sm: "150px" },
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00999E",
                color: "white",
                borderRadius: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={handleOpenModal}
            >
              Tests
            </Box>
            <ArrowForwardIosIcon
              sx={{
                color: "#00999E",
                fontSize: "20px",
                mx: 2,
                display: { xs: "none", sm: "block" },
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{ padding: "20px 0px", fontSize: "15px", fontWeight: 600 }}
              >
                Selected EXAM
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 10,
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              {selectedCourses.map((course, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    padding: "12px",
                    width: { xs: "250px", sm: "150px" },
                    height: "120px",
                    backgroundColor: "#EFFDFF",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #B7B7B7",
                  }}
                >
                  <img
                    src={course.flagUrl}
                    alt={course.name}
                    style={{
                      width: "80px",
                      height: "60px",
                      borderRadius: "10%",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "#595959",
                      textAlign: "center",
                    }}
                  >
                    {course.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
