import React, { Component } from "react";
import { MapContainer, GeoJSON } from "react-leaflet";
import mapData from "./countries.json";
import "leaflet/dist/leaflet.css";
import "./WorldMap.css";
import { Box } from "@mui/material";

class WorldMap extends Component {
    state = { color: "#2b7171" };

    colors = ["#43b9ba"];

    componentDidMount() {
        console.log(mapData);
    }

    countryStyle = {
        fillColor: "#44b9bc",
        fillOpacity: 1,
        color: "#000",
        weight: 0.3,
    };

    printMesssageToConsole = (event) => {
        console.log("Clicked");
    };

    changeCountryColor = (event) => {
        event.target.setStyle({
            color: "#000",
            fillColor: this.state.color,
            fillOpacity: 1,
        });
    };

    getFlagUrl = (isoCode) => `https://flagsapi.com/${isoCode.toUpperCase()}/shiny/24.png`;

    onEachCountry = (country, layer) => {
        const { ADMIN: countryName, ISO_A3: isoCode, path } = country.properties;
        console.log(countryName);

        const flagUrl = this.getFlagUrl(isoCode);
        const popupContent = `<div style="display: flex; align-items: center; color: #00999E; ">
                                <img src="${flagUrl}" alt="${countryName} flag" style="margin-right: 8px;" />
                                <span ><strong>${countryName}</strong></span>
                              </div>`;

        

        if (path) {
            layer.options.fillColor = this.state.color;

            layer.on({
                click: () => this.handleCountryClick(path),
                mouseover: (event) => {
                    layer.bindPopup(popupContent, {
                        autoPan: true,
                        keepInView: true,
                    }).openPopup(event.latlng);
                },
                mouseout: () => {
                    layer.closePopup();
                }
            });
        } else {
            const colorIndex = Math.floor(Math.random() * this.colors.length);
            layer.options.fillColor = this.colors[colorIndex];
        }
    };

    handleCountryClick = (path) => {
        if (path) {
            window.location.href = path;
        } else {
            console.log("No path defined for this country");
        }
    };

    colorChange = (event) => {
        this.setState({ color: event.target.value });
    };

    render() {
        return (
            <Box sx={{ height: { md: "100vh", sm: "60vh", xs: "40vh" } }}>
                <MapContainer style={{ height: "100%" }} zoom={2} center={[30, 10]} scrollWheelZoom={false}>
                    <GeoJSON
                        style={this.countryStyle}
                        data={mapData.features}
                        onEachFeature={this.onEachCountry}
                    />
                </MapContainer>
            </Box>
        );
    }
}

export default WorldMap;
