import img1 from "../../asset/ChooseUsImg1.svg";
import img2 from "../../asset/ChooseUsImg2.svg";
import img3 from "../../asset/ChooseUsImg3.svg";
import img4 from "../../asset/ChooseUsImg4.svg";
import img5 from "../../asset/ChooseUsImg5.svg";

const EngineeringSynopsis =
    [
        {
            "course": "USA",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 5000
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3000000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 6000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 50000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 200000
                }
            ]
        },
        {
            "course": "UK",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 3000
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2500000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 5000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 30000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 150000
                }
            ]
        },
        {
            "course": "Canada",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 2500
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2000000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 4500000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 40000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 175000
                }
            ]
        },
        {
            "course": "Australia",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 2000
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2200000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 5500000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 35000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 180000
                }
            ]
        },
        {
            "course": "Germany",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 2200
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 1500000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 4000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 20000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 100000
                }
            ]
        },
        {
            "course": "France",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 1800
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 1800000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 4200000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 15000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 90000
                }
            ]
        },
        {
            "course": "Singapore",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 1500
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2800000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 6500000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 25000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 120000
                }
            ]
        },
        {
            "course": "Ireland",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 1200
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2600000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 6000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 10000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 60000
                }
            ]
        },
        {
            "course": "Italy",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 1000
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2000000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 4800000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 12000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 70000
                }
            ]
        },
        {
            "course": "Dubai",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Engineering Programs",
                    "value": 800
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3500000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 7000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 8000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 40000
                }
            ]
        }
    ]

const CSITSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 3500
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 3500000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 7000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 40000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 250000
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 2000
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 3000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 6500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 25000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 150000
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 2200
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2500000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 6000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 30000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 175000
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 1800
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2800000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 6200000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 20000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 100000
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 1500
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 5000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 15000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 90000
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 1200
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2200000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 5500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 10000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 60000
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 1000
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 3200000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 7000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 12000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 70000
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 900
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 3000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 6500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 8000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 40000
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 700
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2600000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 6000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 6000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 35000
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of CSIT Programs",
                "value": 600
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 4000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 7500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 5000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 25000
            }
        ]
    }
]
    ;
const HsmSynopsis =
    [
        {
            "course": "USA",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 2500
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 4000000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 8000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 30000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 150000
                }
            ]
        },
        {
            "course": "UK",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 1800
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3500000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 7500000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 25000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 120000
                }
            ]
        },
        {
            "course": "Canada",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 2000
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3000000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 7000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 35000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 180000
                }
            ]
        },
        {
            "course": "Australia",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 1500
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3200000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 7000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 20000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 100000
                }
            ]
        },
        {
            "course": "Germany",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 1000
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2500000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 6000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 15000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 90000
                }
            ]
        },
        {
            "course": "France",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 1200
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 2800000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 6500000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 18000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 110000
                }
            ]
        },
        {
            "course": "Singapore",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 800
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3800000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 8000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 10000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 50000
                }
            ]
        },
        {
            "course": "Ireland",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 600
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3600000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 7500000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 8000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 40000
                }
            ]
        },
        {
            "course": "Italy",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 700
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 3400000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 7000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 9000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 45000
                }
            ]
        },
        {
            "course": "Dubai",
            "details": [
                {
                    "image": img1,
                    "title": "Number of Health Sciences and Medicine Programs",
                    "value": 400
                },
                {
                    "image": img2,
                    "title": "Annual Fees & Living Costs (INR)",
                    "value": 4500000
                },
                {
                    "image": img3,
                    "title": "Post-education Average Starting/Base Salary (INR)",
                    "value": 9000000
                },
                {
                    "image": img4,
                    "title": "Indian Student Population",
                    "value": 5000
                },
                {
                    "image": img5,
                    "title": "Total International Student Population",
                    "value": 25000
                }
            ]
        }
    ]
    ;
const SocialSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 3500
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2500000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 4000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 40000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 150000
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 2800
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2200000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 3500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 35000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 120000
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 2000
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 3000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 30000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 100000
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 1500
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 1800000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 3200000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 25000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 80000
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 1200
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 1500000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 2800000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 20000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 70000
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 1000
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 1600000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 3000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 15000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 60000
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 800
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2800000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 4500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 10000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 50000
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 600
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2500000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 4000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 8000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 40000
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 500
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 2000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 3500000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 7000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 30000
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of Social Science Programs",
                "value": 400
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": 3000000
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": 5000000
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": 5000
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": 20000
            }
        ]
    }
]
    ;
const BusinessSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "200+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000 - 40,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "60,00,000 - 1,20,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "10,000 - 15,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "100,000 - 150,000"
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "150+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "15,00,000 - 35,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "50,00,000 - 1,00,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "8,000 - 12,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "80,000 - 120,000"
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "100+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "10,00,000 - 30,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "40,00,000 - 80,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "5,000 - 10,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "50,000 - 100,000"
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "80+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "12,00,000 - 25,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "45,00,000 - 90,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "6,000 - 9,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "60,000 - 90,000"
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "70+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "8,00,000 - 20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "35,00,000 - 70,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,000 - 7,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "40,000 - 70,000"
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "60+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "10,00,000 - 25,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "40,00,000 - 80,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,500 - 8,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "45,000 - 80,000"
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "50+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000 - 35,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "55,00,000 - 1,00,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "7,000 - 10,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "70,000 - 100,000"
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "40+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "14,00,000 - 30,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "50,00,000 - 95,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "5,500 - 8,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "55,000 - 95,000"
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "30+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "12,00,000 - 22,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "42,00,000 - 75,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,000 - 6,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "40,000 - 75,000"
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of MBA Programs",
                "value": "20+"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "25,00,000 - 45,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "70,00,000 - 1,20,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "8,000 - 12,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "80,000 - 120,000"
            }
        ]
    }
]
    ;
const PlsSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "3000"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "25,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "50,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "40,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,80,000"
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "2500"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "22,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "48,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "30,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,50,000"
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "2000"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "45,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "35,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,75,000"
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "1800"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "23,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "52,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "25,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,60,000"
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "1500"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "40,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "20,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,00,000"
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "1300"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "42,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "15,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "90,000"
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "1200"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "26,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "60,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "10,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "60,000"
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "1000"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "24,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "55,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "8,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "40,000"
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "800"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "21,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "46,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "5,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "30,000"
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of Physical and Life Sciences Programs",
                "value": "600"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "30,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "65,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "25,000"
            }
        ]
    }
]
    ;
const FaaSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "500"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "25,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "40,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "10,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "50,000"
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "400"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "35,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "8,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "45,000"
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "350"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "32,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "7,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "40,000"
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "300"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "22,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "38,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "6,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "35,000"
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "250"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "15,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "28,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "5,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "30,000"
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "200"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "30,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "25,000"
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "180"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "28,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "45,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "3,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "20,000"
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "150"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "26,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "42,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "2,500"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "15,000"
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "120"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "36,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "2,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "12,000"
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of Fine and Applied Art Programs",
                "value": "100"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "35,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "50,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "1,500"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "10,000"
            }
        ]
    }
]
    ;
const CJSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "1200"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "25,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "40,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "15,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,00,000"
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "800"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "22,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "35,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "10,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "75,000"
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "600"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "32,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "12,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "80,000"
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "500"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "24,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "38,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "8,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "60,000"
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "400"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "28,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "6,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "40,000"
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "300"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "30,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "5,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "35,000"
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "250"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "28,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "45,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "7,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "50,000"
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "200"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "26,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "42,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "30,000"
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "150"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "22,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "36,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "3,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "25,000"
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of Communication and Journalism Programs",
                "value": "100"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "30,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "48,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "2,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "15,000"
            }
        ]
    }
]
    ;
const DesigningSynopsis = [
    {
        "course": "USA",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "800"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "25,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "50,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "30,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,50,000"
            }
        ]
    },
    {
        "course": "UK",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "600"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "45,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "25,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,20,000"
            }
        ]
    },
    {
        "course": "Canada",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "500"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "40,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "20,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "1,00,000"
            }
        ]
    },
    {
        "course": "Australia",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "400"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "22,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "48,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "15,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "90,000"
            }
        ]
    },
    {
        "course": "Germany",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "350"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "15,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "35,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "10,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "60,000"
            }
        ]
    },
    {
        "course": "France",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "300"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "18,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "42,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "12,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "70,000"
            }
        ]
    },
    {
        "course": "Singapore",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "250"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "28,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "60,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "8,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "40,000"
            }
        ]
    },
    {
        "course": "Ireland",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "200"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "26,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "55,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "6,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "35,000"
            }
        ]
    },
    {
        "course": "Italy",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "150"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "20,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "45,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "5,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "30,000"
            }
        ]
    },
    {
        "course": "Dubai",
        "details": [
            {
                "image": img1,
                "title": "Number of Designing Programs",
                "value": "100"
            },
            {
                "image": img2,
                "title": "Annual Fees & Living Costs (INR)",
                "value": "35,00,000"
            },
            {
                "image": img3,
                "title": "Post-education Average Starting/Base Salary (INR)",
                "value": "70,00,000"
            },
            {
                "image": img4,
                "title": "Indian Student Population",
                "value": "4,000"
            },
            {
                "image": img5,
                "title": "Total International Student Population",
                "value": "25,000"
            }
        ]
    }
]
    ;


export { EngineeringSynopsis, CSITSynopsis, HsmSynopsis, SocialSynopsis, BusinessSynopsis, PlsSynopsis, FaaSynopsis, CJSynopsis, DesigningSynopsis }