import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Rating,
  Typography
} from "@mui/material";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Icon } from "@iconify/react";

const UniversityItem = ({
  university,
  onCompare,
  isCompared,
  onShortlist,
  onDownload,
  isShortlisted
}) => {
  const handleMoreDetailsClick = () => {
    if (university.webLink) {
      window.open(university.webLink, "_blank");
    }
  };

  const handleDownloadClick = () => {
    if (isShortlisted) {
      onDownload(university._id);
    } else {
      alert("Please shortlist this university before downloading.");
    }
  };
  return (
    <Box
      sx={{
        padding: "20px",
        marginBottom: "20px",
        width: "100%",

        bgcolor: "#effdff",
        border: "1px solid #00999E",
        borderRadius: "20px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: "20px"
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="fa:university"
                style={{ color: "white", fontSize: "24px" }}
              />{" "}
            </Box>
            <Typography variant="h6" sx={{ padding: "0 10px" }} gutterBottom>
              {university?.universityName}
            </Typography>
          </Box>
          <Rating
            name="read-only"
            value={university?.rating || 0} // Default to 0 if rating is undefined
            precision={0.1} // Adjust precision to show fractional stars
            readOnly
          />
        </Box>
        <Box sx={{ paddingLeft: { sm: "50px" } }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "20px",
                width: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="carbon:course"
                style={{ color: "white", fontSize: "16px" }}
              />{" "}
            </Box>
            <Typography
              variant="h6"
              color="#000"
              sx={{ p: "0 10px", fontSize: { sm: "18px", xs: "16px" } }}
            >
              Courses:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {university.courses.map((course) => course.name).join(", ")}{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 1
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="subway:world"
                style={{ color: "white", fontSize: "16px" }}
              />
            </Box>
            <Typography
              variant="h6"
              color="#000"
              sx={{
                px: 1,
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: 500
              }}
            >
              Country:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {university.countryName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 1
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="material-symbols:editor-choice-rounded"
                style={{ color: "white", fontSize: "16px" }}
              />
            </Box>
            <Typography
              variant="h6"
              color="#000"
              sx={{
                px: 1,
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: 500
              }}
            >
              Intakes:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {university.intake}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 1
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="mdi:currency-usd"
                style={{ color: "white", fontSize: "16px" }}
              />
            </Box>
            <Typography
              variant="h6"
              color="#000"
              sx={{
                px: 1,
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: 500
              }}
            >
              Yearly Tuition Fees:
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontWeight: "500", fontSize: { sm: "18px", xs: "16px" } }}
            >
              $ {university.tutionFee}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 1
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="hugeicons:global-education"
                style={{ color: "white", fontSize: "16px" }}
              />
            </Box>
            <Typography
              variant="h6"
              color="#000"
              sx={{
                px: 1,
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: 500
              }}
            >
              Higher Education:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {university.highestQualification}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 1
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "#00999E",
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 5
              }}
            >
              <Icon
                icon="material-symbols:editor-choice-rounded"
                style={{ color: "white", fontSize: "16px" }}
              />
            </Box>
            <Typography
              variant="h6"
              color="#000"
              sx={{
                px: 1,
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: 500
              }}
            >
              QS Ranking:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {university.qsRanking}
            </Typography>
          </Box>
          <Grid container spacing={1} style={{ marginTop: "10px" }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isShortlisted}
                    onChange={onShortlist}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={
                      <Icon
                        icon="lets-icons:check-fill"
                        style={{ color: "#00999E", fontSize: "24px" }}
                      />
                    }
                  />
                }
                label="Shortlist"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCompared}
                    onChange={onCompare}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={
                      <Icon
                        icon="lets-icons:check-fill"
                        style={{ color: "#00999E", fontSize: "24px" }}
                      />
                    }
                  />
                }
                label="Compare"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center"
            }}
          >
            {university.webLink && (
              <Typography
                variant="body2"
                style={{
                  color: "#00999E",
                  marginTop: "10px",
                  cursor: "pointer"
                }}
                onClick={handleMoreDetailsClick}
              >
                More Details
              </Typography>
            )}

            <ButtonComponent
              text={"Download"}
              onClick={handleDownloadClick} // Use the conditional download handler
            />
          </Box>{" "}
        </Box>
      </Box>
    </Box>
  );
};

export default UniversityItem;
