import React from "react";
import {
  Card,
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  createTheme,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";

function CustomCard({ setOpenDialog }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const theme = useTheme();
  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xl: 1920,
        screenFor1: 960,
        md: 960,
        mds: 959,
      },
    },
  });
  const isMediumToLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    phone: yup
      .string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      console.log("Form Data:", values);
      handleSubmit(values);
    },
  });

  const currentPathname = window.location.pathname;

  const handleSubmit = async (payload) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}student/schedule-meeting`,
        payload,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        console.log("okay");
        toast.success("SuccessFully created Unlock Your Dreams");
        if (setOpenDialog) setOpenDialog(false);
        else scrollToTop();
        formik.resetForm();
      } else {
        console.log("no");
        toast.error("Unlock Your Dreams Not Created");
        if (setOpenDialog) setOpenDialog(false);
        else scrollToTop();
        formik.resetForm();
      }
    } catch (error) {
      console.error(error.response?.data || error.message);
      toast.error("Unlock Your Dreams Not Created");
      if (setOpenDialog) setOpenDialog(false);
      else scrollToTop();
      formik.resetForm();
    }
  };

  return (
    <Card
      sx={{
        padding: "0px 12px 20px 12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 2,
        width: "100%", // Ensure it takes full width for small screens
        maxWidth:
          currentPathname === "/" ? { xs: "100%", md: "300px" } : "400px", // Add a max width for larger screens
        boxSizing: "border-box",
        boxShadow: "none",
        backgroundColor: currentPathname === "/" ? "none" : "transparent",
        // Set right margin to 10% when the card takes 100% width
        // marginRight: isSmallScreen ? '0' : '10%',
        ...(isSmallScreen
          ? {
              position: "relative", // No specific positioning for small screens
              maxWidth: "100%", // Allow full width on small screens
              marginRight: "0", // Ensure no right margin on small screens
            }
          : {
              position: currentPathname === "/" ? "absolute" : "relative",
              top:
                currentPathname === "/"
                  ? isMediumToLargeScreen
                    ? "14%"
                    : "20%"
                  : "0",
              left: currentPathname === "/" ? "56%" : "0",
              //   right: '10%', // Keep 10% right margin on larger screens
              bottom: "auto", // Adjust as needed,
            }),
        marginTop: currentPathname !== "/" ? "15px" : 0,
        // backgroundColor : "green",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          backgroundColor: "none",
          width: currentPathname === "/" && isSmallScreen ? "100%" : "auto",
          marginTop:
            currentPathname === "/" && isSmallScreen ? 2 : "transparent",
          // border : isSmallScreen && currentPathname === "/" ? '.1px solid black'  : "none"
        }}
      >
        <Typography
          variant={currentPathname === "/" ? "h6" : "h5"}
          sx={{
            textAlign: "center",
            fontWeight: 500,
            backgroundColor: "#00999E",
            color: "white",
            padding: isSmallScreen ? "8px" : "12px",
            minWidth: currentPathname === "/" ? "300px" : "transparent",
            borderRadius: currentPathname !== "/" ? 2 : 0,
          }}
        >
          {currentPathname === "/" ? "Unlock Your Dreams" : "Register Now"}
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "grid",
            gap: "10px",
            width: "100%",
            padding: currentPathname === "/" ? "0 20px" : "transparent",
            backgroundColor: "none",
            boxShadow: "none",
          }}
        >
          <TextField
            id="name"
            name="name"
            label="Name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            required
          />
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            variant="outlined"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            required
            inputProps={{
              maxLength: 10,
            }}
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            required
          />

          <Box sx={{ marginTop: "3px" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#00999E",
                color: "white",
                padding: "10px 20px",
                fontSize: "16px",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#00999E",
                  color: "white",
                  padding: "10px 20px",
                  fontSize: "16px",
                  width: "100%",
                },
              }}
            >
              Schedule A Meeting
            </Button>
          </Box>
        </form>
      </Stack>
    </Card>
  );
}

export default CustomCard;
