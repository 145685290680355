import image from '../../../asset/visa-img.png'



const USAVisa =
{
    image: image,
    data: ["Apply for a Student Visa (F-1 or M-1)",
        "Receive Form I-20 from a SEVIS-approved School",
        "Complete the Online Visa Application (DS-160)",
        "Schedule and Attend a Visa Interview",

    ]
};
const UKVisa =
{
    image: image,
    data: ["Apply for a Tier 4 (General) student visa",
        "Provide a Confirmation of Acceptance for Studies (CAS) from a licensed Tier 4 sponsor",
        "Show proof of English language proficiency and sufficient funds", "Attend an interview, if required"


    ]
};
const IrelandVisa =
{
    image: image,
    data: ["Apply for a student visa (study visa) through the Irish Naturalisation and Immigration Service (INIS)",
        "Provide a letter of acceptance from an Irish educational institution",
        "Show proof of sufficient funds to cover tuition and living expenses",
        "Demonstrate proficiency in the English language"


    ]
};
const CanadaVisa =
{
    image: image,
    data: ["Apply for a study permit through the Immigration, Refugees, and Citizenship Canada (IRCC)",
        "Provide a letter of acceptance from a designated learning institution",
        "Show proof of financial support and medical clearance",
        "Submit biometrics and attend an interview, if required"


    ]
};
const GermanyVisa =
{
    image: image,
    data: [
        "Apply for a student visa (National Visa) at the German Embassy or Consulate",
        "Provide a letter of admission from a recognized German university",
        "Show proof of sufficient funds for living expenses",
        "Obtain health insurance coverage"


    ]
};
const AustraliaVisa =
{
    image: image,
    data: ["Apply for a student visa (subclass 500) through the Department of Home Affairs",
        "Provide Confirmation of Enrolment (COE) from an Australian educational institution",
        "Show proof of English language proficiency and financial capacity",
        "Undergo a health examination and obtain Overseas Student Health Cover (OSHC)"


    ]
};
const SingaporeVisa =
{
    image: image,
    data: ["Apply for a Student's Pass through the Singapore Immigration and Checkpoints Authority (ICA)",
        "Obtain a letter of acceptance from an educational institution in Singapore",
        "Provide proof of financial ability to support studies and living expenses",
        "Attend an interview at the discretion of the authorities"

    ]
};
const ItalyVisa =
{
    image: image,
    data: [
        "Apply for a student visa (type D) through the Italian consulate or embassy",
        "Provide proof of acceptance to an Italian educational institution",
        "Show proof of financial means and accommodation arrangements",
        "Undergo a medical examination, if required"



    ]
};
const FranceVisa =
{
    image: image,
    data: ["Apply for a student visa through the French Embassy or Consulate.",
        "Provide proof of acceptance to a recognized educational institution in France.",
        "Demonstrate financial stability and medical fitness.",
        "Attend a visa interview, if necessary"


    ]
};
const DubaiVisa =
{
    image: image,
    data: ["Apply for a student visa through the UAE Embassy or Consulate.",
        "Provide proof of acceptance to a recognized educational institution in Dubai.",
        "Show proof of financial means and medical fitness.",
        "Attend a visa interview, if required."


    ]
};
const NewZealandVisa =
{
    image: image,
    data: ["Apply for a student visa through the UAE Embassy or Consulate.",
        "Provide proof of acceptance to a recognized educational institution in Dubai.",
        "Show proof of financial means and medical fitness.",
        "Attend a visa interview, if required."
    ]
};





export { USAVisa, UKVisa, IrelandVisa, CanadaVisa, GermanyVisa, AustraliaVisa, SingaporeVisa, ItalyVisa, FranceVisa, DubaiVisa,NewZealandVisa};