const GmatPackage = [
  {
    id: 1,
    title: "GMAT BASICS",
    items: [
      "Batch Size 2-3 students",
      "Personalised Feedback",
      "Doubt solving session",
      "5 Simulation Test",
      "Online/Offline Classes"
    ],
    topColor: "#F59E0B",
    packagePrice: "35000"
  },
  {
    id: 2,
    title: "GMAT ULTIMATE",
    items: [
      "1:1 Classes",
      "Flexible Timing",
      "Personalised Feedback",
      "Doubt solving session",
      "10 Simulation Test",
      "Hybrid Mode Classes"
    ],
    topColor: "#10B981",
    packagePrice: "45000"
  }
];
const IeltsPackage = [
  {
    id: 3,
    title: "IELTS BASICS",
    items: [
      "Batch Size 2-3 students",
      "Personalised Feedback",
      "Doubt solving session",
      "5 Simulation Test",
      "Online/Offline Classes"
    ],
    topColor: "#F59E0B",

    packagePrice: "10000",
    mostpopular: true
  },
  {
    id: 4,
    title: "IELTS ULTIMATE",
    items: [
      "1:1 Classes",
      "Flexible Timing",
      "Personalised Feedback",
      "Doubt solving session",
      "10 Simulation Test",
      "Hybrid Mode Classes"
    ],
    topColor: "#10B981",

    packagePrice: "12000"
  }
];
const PtePackage = [
  {
    id: 5,
    title: "PTE BASICS",
    items: [
      "Batch Size 2-3 students",
      "Personalised Feedback",
      "Doubt solving session",
      "5 Simulation Test",
      "Online/Offline Classes"
    ],
    topColor: "#F59E0B",

    packagePrice: "12000",
    mostpopular: true
  },
  {
    id: 6,
    title: "PTE ULTIMATE",
    items: [
      "1:1 Classes",
      "Flexible Timing",
      "Personalised Feedback",
      "Doubt solving session",
      "10 Simulation Test",
      "Hybrid Mode Classes"
    ],
    topColor: "#10B981",

    packagePrice: "15000"
  }
];
const GrePackage = [
  {
    id: 7,
    title: "GRE BASICS",
    items: [
      "Batch Size 2-3 students",
      "Personalised Feedback",
      "Doubt solving session",
      "5 Simulation Test",
      "Online/Offline Classes"
    ],
    topColor: "#F59E0B",
    packagePrice: "35000",
    mostpopular: true
  },
  {
    id: 8,
    title: "GRE ULTIMATE",
    items: [
      "1:1 Classes",
      "Flexible Timing",
      "Personalised Feedback",
      "Doubt solving session",
      "10 Simulation Test",
      "Hybrid Mode Classes",
      "online: Yes"
    ],
    topColor: "#10B981",

    packagePrice: "45000"
  }
];
const ToeflPackage = [
  {
    id: 9,
    title: "TOEFL BASICS",
    items: [
      "Batch Size 2-3 students",
      "Personalised Feedback",
      "Doubt solving session",
      "5 Simulation Test",
      "Online/Offline Classes"
    ],
    topColor: "#F59E0B",

    packagePrice: "12000",
    mostpopular: true
  },
  {
    id: 10,
    title: "TOEFL ULTIMATE",
    items: [
      "1:1 Classes",
      "Flexible Timing",
      "Personalised Feedback",
      "Doubt solving session",
      "10 Simulation Test",
      "Hybrid Mode Classes"
    ],
    topColor: "#10B981",

    packagePrice: "15000"
  }
];
const SatPackage = [
  {
    id: 11,
    title: "SAT BASICS",
    items: [
      "Batch Size 2-3 students",
      "Personalised Feedback",
      "Doubt solving session",
      "5 Simulation Test",
      "Online/Offline Classes"
    ],
    topColor: "#F59E0B",
    packagePrice: "50000",
    mostpopular: true
  },
  {
    id: 12,
    title: "SAT ULTIMATE",
    items: [
      "1:1 Classes",
      "Flexible Timing",
      "Personalised Feedback",
      "Doubt solving session",
      "10 Simulation Test",
      "Hybrid Mode Classes"
    ],
    topColor: "#10B981",
    packagePrice: "60000"
  }
];

export {
  IeltsPackage,
  GmatPackage,
  PtePackage,
  GrePackage,
  ToeflPackage,
  SatPackage
};
