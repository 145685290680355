import React from "react";
import FlipCard from "src/Component/card-components/FlipCard";
import Header from "src/Component/content-component/Header";
import { Box, Container } from "@mui/material";
import testChooseUsData from "src/json/testChooseUsData";
import Slider from "react-slick";

const WhyChoose = () => {
    var settings = {
        dots: true,
        infinite: true,
        arrow: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 800,
                settings: {

                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    speed: 2000,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 3000,
                    autoplaySpeed: 2000,
                }
            },

        ]

    };
    return (
        <Box pt={6} pb={8} sx={{ backgroundColor: "#effdff" }}>

            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Header
                        content={"Why Choose Us"}
                        textAlign={{ md: "center", sm: "left", xs: "left" }}

                    />
                </Box>
                <Slider {...settings} >
                    {testChooseUsData?.map((eligibility, index) => {
                        return (
                            <>
                                <Box sx={{ margin: "30px 0" }}>

                                    <FlipCard
                                        sx={{ display: "flex", justifyContent: "center" }}
                                        key={index}
                                        data={eligibility}
                                        height="270px"
                                        width="220px"
                                        fontSize="13px"
                                        titlefontsize={"14px"}
                                        padding="5px"
                                        pb={"5px"}
                                        isFlip={false}
                                    />
                                </Box>

                            </>
                        );
                    })}
                </Slider>
            </Container>
        </Box>
    );
};

export default WhyChoose;
