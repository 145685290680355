import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../asset/TIE_LOGO.png';
import LoginImg from '../../asset/loginImg.svg';
import Login from './Login';
import { Grid } from '@material-ui/core';

const LoginSignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getPathType = (pathname) => pathname === '/signup' ? 'register' : 'login';

  const [type, setType] = useState(getPathType(location.pathname));

  useEffect(() => {
    setType(getPathType(location.pathname));
  }, [location.pathname]);

  const handleLoginClick = () => {
    setType('login');
    navigate('/login', { state: location.state });
  };

  const handleRegisterClick = () => {
    setType('register');
    navigate('/signup', { state: location.state });
  };

  return (
    <Container py={3}>
      {isSmallScreen && (
        <Box display='flex' justifyContent='center' >
          <Box sx={{ width: "150px", cursor: "pointer" }} onClick={() => navigate("/")}>

            <img src={Logo} alt='tie-logo' width={"100%"} />
          </Box>
        </Box>
      )}
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "100%", padding: "40px 0" }}
        rowGap={3}
        columnGap={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box display='flex' justifyContent='center' mt={1} sx={{ position: 'relative' }}>
            <img src={LoginImg} alt='tied-login' style={{ width: "100%" }} />
            <Box sx={{ position: 'absolute', bottom: '10%', left: '10%' }}>
              <Typography variant='h5' color='white' sx={{ fontWeight: 600 }}>
                We are happy to be a part of your journey
              </Typography>
              {/* <Typography color='white'>Lorem Ipsum is simply</Typography> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
          {!isSmallScreen && (
            <Box display='flex' justifyContent='center'>
              <Box sx={{ width: "150px", cursor: "pointer" }} onClick={() => navigate("/")}>

                <img src={Logo} alt='tie-logo' width={"100%"} />
              </Box>
            </Box>
          )}
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{ padding: { xs: 2, sm: 4, md: 4, lg: 8 } }}>
            <Typography variant='h6' sx={{ fontWeight: "550" }}>Welcome to TIE</Typography>
            <Box
              gap={1}
              display='flex'
              justifyContent='space-around'
              mt={3}
              sx={{
                backgroundColor: '#D9FEFF',
                width: '250px',
                borderRadius: '7px',
                p: 1,
              }}
            >
              <Button
                variant={type === 'login' ? 'contained' : 'text'}
                fullWidth
                sx={{
                  backgroundColor: type === 'login' ? '#00999E' : '',
                  color: type === 'login' ? '#fff' : '#00999E',
                  '&:hover': {
                    backgroundColor: type === 'login' ? '#007f80' : '',
                    color: type === 'login' ? '#fff' : '#007f80',
                  },
                  '&:focus': {
                    outline: 'none',
                    backgroundColor: type === 'login' ? '#007f80' : '',
                    color: type === 'login' ? '#fff' : '#007f80',
                  },
                  '&:active': {
                    backgroundColor: type === 'login' ? '#005d5e' : '',
                    color: type === 'login' ? '#fff' : '#005d5e',
                  },
                }}
                onClick={handleLoginClick}
              >
                Login
              </Button>

              <Button
                variant={type === 'register' ? 'contained' : 'text'}
                fullWidth
                sx={{
                  backgroundColor: type === 'register' ? '#00999E' : '',
                  color: type === 'register' ? '#fff' : '#00999E',
                  '&:hover': {
                    backgroundColor: type === 'register' ? '#007f80' : '',
                    color: type === 'register' ? '#fff' : '#007f80',
                  },
                  '&:focus': {
                    outline: 'none',
                    backgroundColor: type === 'register' ? '#007f80' : '',
                    color: type === 'register' ? '#fff' : '#007f80',
                  },
                  '&:active': {
                    backgroundColor: type === 'register' ? '#005d5e' : '',
                    color: type === 'register' ? '#fff' : '#005d5e',
                  },
                }}
                onClick={handleRegisterClick}
              >
                Register
              </Button>
            </Box>
            <Box display='flex' sx={{ width: "100%" }}>
              <Typography mt={4}>
                Login to empower your journey with taksheela.
              </Typography>
            </Box>
            <Login type={type} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginSignUp;
