import { Box, Card, MenuList, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import MainHeader from "../content-component/MainHeader";
import { LoadingButton } from "@mui/lab";

const PackageCard = ({ data, setItemUpdate, itemupdate }) => {
    console.log('data :>> ', data);
    const navigate = useNavigate();
    const [status, setStatus] = useState(false);
    const [isInCart, setIsInCart] = useState(false);
    const [loading, setLoading] = React.useState(false);


    const fetchCartData = async () => {
        setLoading(true);
        const token = sessionStorage.getItem("token");
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}order/get-cart`, {
                    headers: {
                        Authorization: token,
                    },
                });
                const data1 = await response.json();
                if (data1.success) {
                    const apiCartList = data1.data.flatMap(order => order.items.map(item => ({
                        id: Number(item.planId),
                        title: item.planName,
                        packagePrice: item.amount,
                    })));
                    console.log('ata.id :>> ', data.id);
                    const isInCart = apiCartList.some(item => item.id === data.id);
                    setIsInCart(isInCart);
                }
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        } else {
            const localCart = JSON.parse(localStorage.getItem("cart")) || [];
            const isInCart = localCart.some(item => item.title === data.title);
            setIsInCart(isInCart);

        }
        setLoading(false);
    };

    useEffect(() => {
        fetchCartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const addToCart = async (packageData) => {
        setLoading(true);

        const token = sessionStorage.getItem("token");
        if (token) {
            try {
                const userId = JSON.parse(atob(token.split('.')[1])).id; // Decode JWT to get user ID
                const transformedData = {
                    userId: userId,
                    items: [{
                        planName: packageData.title,
                        amount: packageData.packagePrice,
                        planId: packageData.id,
                    }],
                };
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}order/add-cart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token,
                    },
                    body: JSON.stringify(transformedData),
                });
                const data = await response.json();
                if (data.success) {
                    setStatus(!status);
                    setItemUpdate(!itemupdate)
                }
            } catch (error) {
                console.error("Error adding to cart:", error);
            }
        } else {
            let cart = JSON.parse(localStorage.getItem("cart")) || [];
            cart.push(packageData);
            localStorage.setItem("cart", JSON.stringify(cart));
            setItemUpdate(!itemupdate)

            setStatus(!status);
        } setLoading(false);
    };

    return (
        <Card
            sx={{
                width: "300px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "10px 20px",
                borderTop: `3px solid ${data?.topColor}`,
            }}
        >
            <Box sx={{ marginTop: "auto" }}>
                <MainHeader
                    color={"#00999E"}
                    fontSize="24px"
                    fontWeight={"600"}
                    textAlign="center"
                    content={data?.title}
                />
            </Box>

            <Typography
                sx={{
                    color: "#000",
                    fontSize: "30px",
                    fontWeight: "600",
                    margin: "20px 0",
                    textAlign: "center",
                }}
            >
                ₹{data?.packagePrice}
                <sub style={{ verticalAlign: "sub", fontSize: "12px", fontWeight: "400" }}>(INR)</sub>
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
                <MenuList>
                    {data.items?.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                pr: 2,
                                display: "flex",
                                justifyContent: "left",
                                margin: "10px 20px",
                            }}
                        >
                            <Box sx={{ marginRight: "5px" }}>
                                <Icon icon="iconamoon:check-bold" style={{ fontSize: "25px", color: "#00999E" }} />
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: "13px",
                                    wordBreak: "break-word",
                                    wordWrap: "wrap",
                                    mr: 2,
                                    textTransform: "capitalize"
                                }}
                            >
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </MenuList>
            </Box>

            <Box sx={{ marginTop: "auto", marginBottom: "5px" }}>
                <LoadingButton
                    onClick={() => {
                        if (!isInCart) {
                            addToCart(data);
                        } else {
                            navigate("/addCart");
                        }
                    }}
                    loading={loading}
                    sx={{
                        fontSize: "14px",
                        width: "100%",
                        height: "40px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        textAlign: "center",
                        color: "#fff",
                        borderRadius: "8px",
                        backgroundColor: "#00999E",

                        transition: "all 0.3s linear",
                        border: "2px solid #00999E",
                        "&:hover": {
                            color: "#000",
                            border: "2px solid #000000",
                        }, "& .MuiLoadingButton-loadingIndicator": {
                            color: "#000000",
                            fontSize: "50px",

                        }
                    }}
                >
                    {isInCart ? !loading && "Go To Cart" : !loading && "Add To Cart"}
                </LoadingButton>
            </Box>
        </Card>
    );
};

export default PackageCard;
