const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

//wisconsin
const w1 = imageBaseUrl + "wisconsin/1.png";
const w2 = imageBaseUrl + "wisconsin/2.png";
const w3 = imageBaseUrl + "wisconsin/3.png";
const w4 = imageBaseUrl + "wisconsin/4.png";
const w5 = imageBaseUrl + "wisconsin/5.png";
const w6 = imageBaseUrl + "wisconsin/6.png";
const w7 = imageBaseUrl + "wisconsin/7.png";

const w11 = imageBaseUrl + "wisconsin/11.png";
const w12 = imageBaseUrl + "wisconsin/12.png";
const w13 = imageBaseUrl + "wisconsin/13.png";

//yeshivaimages
const y1 = imageBaseUrl + "asset/yeshiva/1.png";
const y2 = imageBaseUrl + "asset/yeshiva/2.png";
const y3 = imageBaseUrl + "asset/yeshiva/3.png";
const y4 = imageBaseUrl + "asset/yeshiva/4.png";
const y5 = imageBaseUrl + "asset/yeshiva/5.png";
const y6 = imageBaseUrl + "asset/yeshiva/6.png";
const y7 = imageBaseUrl + "asset/yeshiva/7.png";
const y8 = imageBaseUrl + "asset/yeshiva/8.png";
const y9 = imageBaseUrl + "asset/yeshiva/9.png";
const y10 = imageBaseUrl + "asset/yeshiva/10.png";

//UtahImages
const u1 = imageBaseUrl + "asset/utah/1.png";
const u2 = imageBaseUrl + "asset/utah/2.png";
const u3 = imageBaseUrl + "asset/utah/3.png";
const u4 = imageBaseUrl + "asset/utah/4.png";
const u5 = imageBaseUrl + "asset/utah/5.png";
const u6 = imageBaseUrl + "asset/utah/6.png";
const u7 = imageBaseUrl + "asset/utah/7.png";
const u8 = imageBaseUrl + "asset/utah/8.png";
const u9 = imageBaseUrl + "asset/utah/9.png";
const u10 = imageBaseUrl + "asset/utah/10.png";

//recruiters
const accenture = imageBaseUrl +  "recruiters/accenture.png";
const amazon = imageBaseUrl +  "recruiters/amazon.png";
const apple = imageBaseUrl +  "recruiters/apple.png";
const bbc = imageBaseUrl +  "recruiters/bbc.png";
const britishairways = imageBaseUrl +  "recruiters/britishairways.png";
const deloitte = imageBaseUrl +  "recruiters/deloitte.png";
const ey = imageBaseUrl +  "recruiters/ey.png";
const goldmansachs = imageBaseUrl +  "recruiters/goldmansachs.png";
const google = imageBaseUrl +  "recruiters/google.png";
const gsk = imageBaseUrl +  "recruiters/gsk.png";
const hsbc = imageBaseUrl +  "recruiters/hsbc.png";
const ibm = imageBaseUrl +  "recruiters/ibm.png";
const jaguar = imageBaseUrl +  "recruiters/jaguar.png";
const jpmorgan = imageBaseUrl +  "recruiters/jpmorgan.png";
const kpmg = imageBaseUrl +  "recruiters/kpmg.png";
const lloyds = imageBaseUrl +  "recruiters/lloyds.png";
const mckinsey = imageBaseUrl +  "recruiters/mckinsey.png";
const microsoft = imageBaseUrl +  "recruiters/microsoft.png";
const pwc = imageBaseUrl +  "recruiters/pwc.png";
const rollsroyce = imageBaseUrl +  "recruiters/rollsroyce.png";
const unilever = imageBaseUrl +  "recruiters/unilever.png";
const abc = imageBaseUrl +  "recruiters/abc.png";
const anz = imageBaseUrl +  "recruiters/anz.png";
const bhpbilliton = imageBaseUrl +  "recruiters/bhpbilliton.png";
const civica = imageBaseUrl +  "recruiters/civica.png";
const commonwealthbank = imageBaseUrl +  "recruiters/commonwealthBank.png";
const csl = imageBaseUrl +  "recruiters/csl.png";
const flinders = imageBaseUrl +  "recruiters/flinders.png";
const hp = imageBaseUrl +  "recruiters/hp.png";
const macquarie = imageBaseUrl +  "recruiters/macquarie.png";
const nationalaustraliabank = imageBaseUrl +  "recruiters/nationalausstraliabank.png";
const queenslandgovernment = imageBaseUrl +  "recruiters/queenslandgovernment.png";
const riotinto = imageBaseUrl +  "recruiters/riotinto.png";
const santos = imageBaseUrl +  "recruiters/santos.png";
const telstra = imageBaseUrl +  "recruiters/telstra.png";
const westpac = imageBaseUrl +  "recruiters/westpac.png";
const woodside = imageBaseUrl +  "recruiters/woodside.png";
const Dominion = imageBaseUrl +  "recruiters/dominion.png";
const RBC = imageBaseUrl +  "recruiters/RBC.png";
const siemens = imageBaseUrl +  "recruiters/siemens.png";
const CIBC = imageBaseUrl +  "recruiters/CIBC.png";
const GM = imageBaseUrl +  "recruiters/GM.png";
const Hatch = imageBaseUrl +  "recruiters/Hatch.png";
const Telus = imageBaseUrl +  "recruiters/Telus.png";
const Fraser = imageBaseUrl +  "recruiters/Fraser.png";
const Vancouver =imageBaseUrl + "recruiters/Vancouver.png";
const CityofVancouver =imageBaseUrl + "recruiters/CityofVancouver.png";
const Suncor =imageBaseUrl + "recruiters/Suncor.png";
const World =imageBaseUrl + "recruiters/World.png";
const Island =imageBaseUrl + "recruiters/Island.png";
const Victoria =imageBaseUrl + "recruiters/Victoria.png";
const Environmental =imageBaseUrl + "recruiters/Environmental.png";
const WWF =imageBaseUrl + "recruiters/WWF.png";
const sap =imageBaseUrl + "recruiters/sap.png";
const Tesla =imageBaseUrl + "recruiters/Tesla.png";
const Shopify =imageBaseUrl + "recruiters/Shopify.png";
const Calgary =imageBaseUrl + "recruiters/Calgary.png";
const enbridge =imageBaseUrl + "recruiters/enbridge.png";
const atco =imageBaseUrl + "recruiters/atco.png";
const canadian =imageBaseUrl + "recruiters/canadian.png";
const pcl =imageBaseUrl + "recruiters/pcl.png";
const husky =imageBaseUrl + "recruiters/husky.png";
const scotiabank =imageBaseUrl + "recruiters/scotiabank.png";
const bell =imageBaseUrl + "recruiters/bell.png";
const snc =imageBaseUrl + "recruiters/snc.png";
const nova =imageBaseUrl + "recruiters/nova.png";
const cbc =imageBaseUrl + "recruiters/cbc.png";
const mnp =imageBaseUrl + "recruiters/mnp.png";
const Imperial =imageBaseUrl + "recruiters/Imperial.png";
const ford =imageBaseUrl + "recruiters/ford.png";
const magna =imageBaseUrl + "recruiters/magna.png";
const walmart =imageBaseUrl + "recruiters/walmart.png";
const ctc =imageBaseUrl + "recruiters/ctc.png";
const nh =imageBaseUrl + "recruiters/nh.png";
const eu =imageBaseUrl + "recruiters/eu.png";
const wrh =imageBaseUrl + "recruiters/wrh.png";
const bchydro =imageBaseUrl + "recruiters/bchydro.png";
const ld =imageBaseUrl + "recruiters/ld.png";
const loblaws =imageBaseUrl + "recruiters/loblaws.png";
const bt =imageBaseUrl + "recruiters/bt.png";
const bos =imageBaseUrl + "recruiters/bos.png";
const sg =imageBaseUrl + "recruiters/sg.png";
const ms =imageBaseUrl + "recruiters/ms.png";
const shell = imageBaseUrl + "recruiters/shell.png";
const nestlé = imageBaseUrl + "recruiters/nestlé.png";
const intel = imageBaseUrl + "recruiters/intel.png";
const boeing = imageBaseUrl + "recruiters/boeing.png";
const caterpillar = imageBaseUrl + "recruiters/caterpillar.png";
const facebook = imageBaseUrl + "recruiters/facebook.png";
const salesforce = imageBaseUrl + "recruiters/salesforce.png";
const bcg = imageBaseUrl + "recruiters/bcg.png";
const boa = imageBaseUrl + "recruiters/boa.png";
const bmw = imageBaseUrl + "recruiters/bmw.png";
const allianz = imageBaseUrl + "recruiters/allianz.png";
const bayer = imageBaseUrl + "recruiters/bayer.png";
const deutscheBank = imageBaseUrl + "recruiters/deutscheBank.png";
const bosch = imageBaseUrl + "recruiters/bosch.png";
const daimler = imageBaseUrl + "recruiters/daimler.png";
const heidelbergcement = imageBaseUrl + "recruiters/heidelbergcement.png";
const roche = imageBaseUrl + "recruiters/roche.png";
const volkswagen = imageBaseUrl + "recruiters/volkswagen.png";
const basf = imageBaseUrl + "recruiters/basf.png";
const deutscheTelekom = imageBaseUrl + "recruiters/deutscheTelekom.png";
const loreal = imageBaseUrl + "recruiters/loreal.png";
const bnpParibas = imageBaseUrl + "recruiters/bnpParibas.png";
const capgemini = imageBaseUrl + "recruiters/capgemini.png";
const airbus = imageBaseUrl + "recruiters/airbus.png";
const edf = imageBaseUrl + "recruiters/edf.png";
const renault = imageBaseUrl + "recruiters/renault.png";
const orange = imageBaseUrl + "recruiters/orange.png";
const thales = imageBaseUrl + "recruiters/thales.png";
const dassaultSystemes = imageBaseUrl + "recruiters/dassaultSystemes.png";
const safran = imageBaseUrl + "recruiters/safran.png";
const schneiderElectric = imageBaseUrl + "recruiters/schneiderElectric.png";
const stMicroelectronics = imageBaseUrl + "recruiters/stMicroelectronics.png";
const valeo = imageBaseUrl + "recruiters/valeo.png";
const sanofi = imageBaseUrl + "recruiters/sanofi.png";
const societeGenerale = imageBaseUrl + "recruiters/societeGenerale.png";
const axa = imageBaseUrl + "recruiters/axa.png";
const airFranceKLM = imageBaseUrl + "recruiters/airFranceKLM.png";
const fiatChrysler = imageBaseUrl + "recruiters/fiatChrysler.png";
const pirelli = imageBaseUrl + "recruiters/pirelli.png";
const bulgari = imageBaseUrl + "recruiters/bulgari.png";
const tcs = imageBaseUrl + "recruiters/tcs.png";
const infosys = imageBaseUrl + "recruiters/infosys.png";
const wipro = imageBaseUrl + "recruiters/wipro.png";
const cognizant = imageBaseUrl + "recruiters/cognizant.png";
const hcl = imageBaseUrl + "recruiters/hcl.png";
const luxottica = imageBaseUrl + "recruiters/luxottica.png";
const eni = imageBaseUrl + "recruiters/eni.png";
const unicredit = imageBaseUrl + "recruiters/unicredit.png";
const ferragamo = imageBaseUrl + "recruiters/ferragamo.png";
const prada = imageBaseUrl + "recruiters/prada.png";
const intesaSanpaolo = imageBaseUrl + "recruiters/intesaSanpaolo.png";
const ferrari = imageBaseUrl + "recruiters/ferrari.jpg";
const lavazza = imageBaseUrl + "recruiters/lavazza.png";
const leonardo = imageBaseUrl + "recruiters/leonardo.png";
const gucci = imageBaseUrl + "recruiters/gucci.png";
const fendi = imageBaseUrl + "recruiters/fendi.png";
const diesel = imageBaseUrl + "recruiters/diesel.png";
const ikea = imageBaseUrl + "recruiters/ikea.png";
const stJamesHospital = imageBaseUrl + "recruiters/stJamesHospital.png";
const materMisericordiae = imageBaseUrl + "recruiters/materMisericordiae.png";
const beaumontHospital = imageBaseUrl + "recruiters/beaumontHospital.png";
const corkUniversityHospital = imageBaseUrl + "recruiters/corkUniversityHospital.png";
const hse = imageBaseUrl + "recruiters/hse.png";
const abbVie = imageBaseUrl + "recruiters/abbVie.png";
const medtronic = imageBaseUrl + "recruiters/medtronic.png";
const bostonScientific = imageBaseUrl + "recruiters/bostonScientific.png";
const pfizer = imageBaseUrl + "recruiters/pfizer.png";
const linkedin = imageBaseUrl + "recruiters/linkedin.png";
const meta = imageBaseUrl + "recruiters/meta.png";
const fonterra = imageBaseUrl + "recruiters/fonterra.png";
const adhb = imageBaseUrl + "recruiters/adhb.png";
const airNewZealand = imageBaseUrl + "recruiters/airNewZealand.png";
const xero = imageBaseUrl + "recruiters/xero.png";
const anzBank = imageBaseUrl + "recruiters/anzBank.png";
const fisherPaykel = imageBaseUrl + "recruiters/fisherPaykel.png";
const genesisEnergy = imageBaseUrl + "recruiters/genesisEnergy.png";
const sparkNewZealand = imageBaseUrl + "recruiters/sparkNewZealand.png";
const taitCommunications = imageBaseUrl + "recruiters/taitCommunications.png";
const christchurchCityCouncil = imageBaseUrl + "recruiters/christchurchCityCouncil.png";
const doc = imageBaseUrl + "recruiters/doc.png";
const fisherPaykelHealthcare = imageBaseUrl + "recruiters/fisherPaykelHealthcare.jpg";
const newZealandPolice = imageBaseUrl + "recruiters/newZealandPolice.jpg";
const agResearch = imageBaseUrl + "recruiters/agResearch.png";
const landcorpFarming = imageBaseUrl + "recruiters/landcorpFarming.png";
const aftPharmaceuticals = imageBaseUrl + "recruiters/aftPharmaceuticals.png";
const tgGlobal = imageBaseUrl + "recruiters/tgGlobal.png";
const horticultureNewZealand = imageBaseUrl + "recruiters/horticultureNewZealand.png";
const lic = imageBaseUrl + "recruiters/lic.png";
const nzTransportAgency = imageBaseUrl + "recruiters/nzTransportAgency.png";
const fletcherBuilding = imageBaseUrl + "recruiters/fletcherBuilding.jpg";
const emiratesGroup = imageBaseUrl + "recruiters/emiratesGroup.png";
const dewa = imageBaseUrl + "recruiters/dewa.png";
const alFuttaim = imageBaseUrl + "recruiters/alFuttaim.png";
const dubaiWorldTradeCentre = imageBaseUrl + "recruiters/dubaiWorldTradeCentre.png";
const etisalat = imageBaseUrl + "recruiters/etisalat.png";
const samsung = imageBaseUrl + "recruiters/samsung.png";
const jumeirahGroup = imageBaseUrl + "recruiters/jumeirahGroup.png";
const etihadAirways = imageBaseUrl + "recruiters/etihadAirways.png";
const adnoc = imageBaseUrl + "recruiters/adnoc.png";
const cnsMiddleEast = imageBaseUrl + "recruiters/cnsMiddleEast.png";
const rakta = imageBaseUrl + "recruiters/rakta.png";
const maab = imageBaseUrl + "recruiters/maab.png";
const almarwan = imageBaseUrl + "recruiters/almarwan.png";
const rakBank = imageBaseUrl + "recruiters/rakBank.png";
const dhl = imageBaseUrl + "recruiters/dhl.png";
const burjKhalifa = imageBaseUrl + "recruiters/burjKhalifa.png";
const careem = imageBaseUrl + "recruiters/careem.png";
const emaar = imageBaseUrl + "recruiters/emaar.png";
const fedex = imageBaseUrl + "recruiters/fedex.png";
const schlumberger = imageBaseUrl + "recruiters/schlumberger.png";
const halliburton = imageBaseUrl + "recruiters/halliburton.png";
const ge = imageBaseUrl + "recruiters/ge.png";
const crescentEnterprises = imageBaseUrl + "recruiters/crescentEnterprises.png";
const snoc = imageBaseUrl + "recruiters/snoc.png";
const beeah = imageBaseUrl + "recruiters/beeah.png";
const mastercard = imageBaseUrl + "recruiters/mastercard.png";
const chalhoub = imageBaseUrl + "recruiters/chalhoub.png";
const abuDhabi = imageBaseUrl + "recruiters/abuDhabi.png";
const alAinMunicipality = imageBaseUrl + "recruiters/alAinMunicipality.jpg";
const saudiAramco = imageBaseUrl + "recruiters/saudiAramco.png";
const oracle = imageBaseUrl + "recruiters/oracle.png";
const dge = imageBaseUrl + "recruiters/dge.png";
const expo2020Dubai = imageBaseUrl + "recruiters/expo2020Dubai.png";
const damac = imageBaseUrl + "recruiters/damac.png";
const abudhabiCommercialBank = imageBaseUrl + "recruiters/abudhabiCommercialBank.png";
const ghantoot = imageBaseUrl + "recruiters/ghantoot.png";
const apparelLLC = imageBaseUrl + "recruiters/apparelLLC.png";
const bayt = imageBaseUrl + "recruiters/bayt.png";
const landmarkGroup = imageBaseUrl + "recruiters/landmarkGroup.png";
const dbsBank = imageBaseUrl + "recruiters/dbsBank.png";
const bloomberg = imageBaseUrl + "recruiters/bloomberg.png";
const standardChartered = imageBaseUrl + "recruiters/standardChartered.png";
const morganstanley = imageBaseUrl + "recruiters/morganstanley.png";
const singaporeairlines = imageBaseUrl + "recruiters/singaporeairlines.png";
const bhp = imageBaseUrl + "recruiters/bhp.png";
const queenslandHealth = imageBaseUrl + "recruiters/queenslandHealth.png";
const commonwealthBank = imageBaseUrl + "recruiters/commonwealthBank.png";
const ocbcBank = imageBaseUrl + "recruiters/ocbcBank.png";
const marriottInternational = imageBaseUrl + "recruiters/marriottInternational.png";
const hm = imageBaseUrl + "recruiters/hm.png";
const sony = imageBaseUrl + "recruiters/sony.png";
const marriott = imageBaseUrl + "recruiters/marriott.png";
const havasGroup = imageBaseUrl + "recruiters/havasGroup.png";
const shangriLa = imageBaseUrl + "recruiters/shangriLa.png";
const ritzCarlton = imageBaseUrl + "recruiters/ritzCarlton.png";
const louisVuitton = imageBaseUrl + "recruiters/louisVuitton.png";
const dior = imageBaseUrl + "recruiters/dior.png";
const adidas = imageBaseUrl + "recruiters/adidas.png";
const prudential = imageBaseUrl + "recruiters/prudential.png";
const singtel = imageBaseUrl + "recruiters/singtel.png";
const mubadalaInvestment = imageBaseUrl + "recruiters/mubadalaInvestment.png";
const uaeUniversity = imageBaseUrl + "recruiters/uaeUniversity.png";



//californiaImages
const c1 = imageBaseUrl + "california/1.png";
const c2 = imageBaseUrl + "california/2.png";
const c3 = imageBaseUrl + "california/3.png";
const c4 = imageBaseUrl + "california/4.png";
const c5 = imageBaseUrl + "california/5.png";
const c6 = imageBaseUrl + "california/6.png";
const c7 = imageBaseUrl + "california/7.png";
const c8 = imageBaseUrl + "california/8.png";
const c9 = imageBaseUrl + "california/9.png";
const c10 = imageBaseUrl + "california/10.png";


//ColoradoImages
const co1 = imageBaseUrl + "colorado/1.png";
const co2 = imageBaseUrl + "colorado/2.png";
const co3 = imageBaseUrl + "colorado/3.png";
const co4 = imageBaseUrl + "colorado/4.png";
const co5 = imageBaseUrl + "colorado/5.png";
const co6 = imageBaseUrl + "colorado/6.png";
const co7 = imageBaseUrl + "colorado/7.png";
const co8 = imageBaseUrl + "colorado/8.png";
const co9 = imageBaseUrl + "colorado/9.png";
const co10 = imageBaseUrl + "colorado/10.png";



const CaliforniaRecruiter = [
  {
    id: 1,
    Image: c1,
    title: ["Google"]
  },
  {
    id: 2,
    Image: c2,
    title: ["Apple"]
  },
  {
    id: 3,
    Image: c3,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: c4,
    title: ["Amazon"]
  },
  {
    id: 5,
    Image: c5,
    title: ["Facebook (Meta)"]
  },
  {
    id: 6,
    Image: c6,
    title: ["Tesla"]
  },
  {
    id: 7,
    Image: c7,
    title: ["Deloitte"]
  },
  {
    id: 8,
    Image: c8,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 9,
    Image: c9,
    title: ["Intel"]
  },
  {
    id: 10,
    Image: c10,
    title: ["LinkedIn"]
  }
];
const wisconsinRecruiter = [
  {
    id: 1,
    Image: w1,
    title: ["Google"]
  },
  {
    id: 2,
    Image: w2,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: w3,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: w4,
    title: ["Apple"]
  },
  {
    id: 5,
    Image: w5,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: w6,
    title: ["Goldman Sachs"]
  },
  {
    id: 7,
    Image: w7,
    title: ["Deloitte"]
  },

  {
    id: 8,
    Image: w11,
    title: ["Facebook (Meta)"]
  },
  {
    id: 9,
    Image: w12,
    title: ["Oracle"]
  },
  {
    id: 10,
    Image: w13,
    title: ["Johnson & Johnson"]
  }
];
const YeshivaRecruiter = [
  {
    id: 1,
    Image: y1,
    title: ["Google"]
  },
  {
    id: 2,
    Image: y2,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: y3,
    title: ["Goldman Sachs"]
  },
  {
    id: 4,
    Image: y4,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: y5,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 6,
    Image: y6,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 7,
    Image: y7,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: y8,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: y9,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: y10,
    title: ["JPMorgan Chase"]
  }
];
const UtahRecruiter = [
  {
    id: 1,
    Image: u1,
    title: ["Intermountain Healthcare"]
  },
  {
    id: 2,
    Image: u2,
    title: ["SkyWest Airlines"]
  },
  {
    id: 3,
    Image: u3,
    title: ["Utah State Government"]
  },
  {
    id: 4,
    Image: u4,
    title: ["Zions Bank"]
  },
  {
    id: 5,
    Image: u5,
    title: ["Southern Utah University"]
  },
  {
    id: 6,
    Image: u6,
    title: ["Wells Fargo"]
  },
  {
    id: 7,
    Image: u7,
    title: ["Adobe"]
  },
  {
    id: 8,
    Image: u8,
    title: ["Utah Shakespeare Festival"]
  },
  {
    id: 9,
    Image: u9,
    title: ["Southwest Technical College"]
  },
  {
    id: 10,
    Image: u10,
    title: ["Iron County School District"]
  }
];
const ColoradoRecruiter = [
  {
    id: 1,
    Image: co1,
    title: ["Lockheed Martin"]
  },
  {
    id: 2,
    Image: co2,
    title: ["Intel"]
  },
  {
    id: 3,
    Image: co3,
    title: ["IBM"]
  },
  {
    id: 4,
    Image: co4,
    title: ["Hewlett-Packard (HP)"]
  },
  {
    id: 5,
    Image: co5,
    title: ["Agilent Technologies"]
  },
  {
    id: 6,
    Image: co6,
    title: ["Ball Aerospace & Technologies Corp."]
  },
  {
    id: 7,
    Image: co7,
    title: ["Arrow Electronics"]
  },
  {
    id: 8,
    Image: co8,
    title: ["Woodward, Inc."]
  },
  {
    id: 9,
    Image: co9,
    title: ["Charles Schwab"]
  },
  {
    id: 10,
    Image: co10,
    title: ["Kaiser Permanente"]
  }
];

const IllinoisRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 4,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: intel,
    title: ["Intel"]
  },
  {
    id: 7,
    Image: caterpillar,
    title: ["Caterpillar"]
  },
  {
    id: 8,
    Image: boeing,
    title: ["Boeing"]
  },
  {
    id: 9,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  },
];

const StanfordRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 3,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 5,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 6,
    Image: Tesla,
    title: ["Tesla"]
  },

  {
    id: 7,
    Image: intel,
    title: ["Intel"]
  },

  {
    id: 8,
    Image: salesforce,
    title: ["Salesforce"]
  },

  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
];

const PennsylvaniaRecruiter = [

  {
    id: 1,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 2,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: mckinsey,
    title: ["McKinsey & Company"]
  },
  {
    id: 5,
    Image: apple,
    title: ["Apple"]
  },

  {
    id: 6,
    Image: google,
    title: ["Google"]
  },

  {
    id: 7,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },

  {
    id: 8,
    Image: bcg,
    title: ["Boston Consulting Group (BCG)IBM "]
  },

  {
    id: 9,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 10,
    Image: Tesla,
    title: ["Tesla"]
  },

];

const ChicagoRecruiter = [

  {
    id: 1,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 2,
    Image: mckinsey,
    title: ["McKinsey & Company"]
  },
  {
    id: 3,
    Image: bcg,
    title: ["Boston Consulting Group (BCG)IBM "]
  },
  {
    id: 4,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 7,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 8,
    Image: google,
    title: ["Google"]
  },

  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: boa,
    title: ["Bank of America"]
  },

];

const MITRecruiter = [

  {
    id: 1,
    Image: google,
    title: ["Google"]
  },

  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 3,
    Image: apple,
    title: ["Apple"]
  },

  {
    id: 4,
    Image: amazon,
    title: ["Amazon"]
  },

  {
    id: 5,
    Image: Tesla,
    title: ["Tesla"]
  },

  {
    id: 6,
    Image: bcg,
    title: ["Boston Consulting Group (BCG)IBM "]
  },
  {
    id: 7,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },

  {
    id: 8,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },

];


const BristolRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: google,
    title: ["Google"]
  },
  {
    id: 7,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 8,
    Image: lloyds,
    title: ["LLOYDS Banking Group"]
  },
  {
    id: 9,
    Image: bbc,
    title: ["BBC"]
  },
  {
    id: 10,
    Image: rollsroyce,
    title: ["Rolls- Royce"]
  }
];
const LeedsRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: gsk,
    title: ["GSK"]
  },
  {
    id: 10,
    Image: britishairways,
    title: ["British Airways"]
  }
];
const GlasgowRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 5,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 7,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 9,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];
const BirminghamRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: rollsroyce,
    title: ["Rolls- Royce"]
  },
  {
    id: 9,
    Image: jaguar,
    title: ["JAGUAR"]
  },
  {
    id: 10,
    Image: unilever,
    title: ["Unilever"]
  }
];
const imperialRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 4,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 5,
    Image: mckinsey,
    title: ["McKinsey & Company"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 7,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 8,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 9,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 10,
    Image: hsbc,
    title: ["HSBC"]
  }
];

const QueenMaryRecruiter = [
  {
    id: 1,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 2,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 3,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 4,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 7,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: google,
    title: ["Google"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  },

];

const LiverpoolRecruiter = [

  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: hsbc,
    title: ["HSBC"]
  },


  {
    id: 7,
    Image: bt,
    title: ["BT Group"]
  },



  {
    id: 8,
    Image: unilever,
    title: ["Unilever"]
  },
  {
    id: 9,
    Image: bbc,
    title: ["BBC"]
  },
  {
    id: 10,
    Image: lloyds,
    title: ["Lloyds Banking Group"]
  },

];

const EdinburghRecruiter = [

  {
    id: 1,
    Image: bos,
    title: ["Bank of Scotland"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },


  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },



  {
    id: 7,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },



  {
    id: 8,
    Image: sg,
    title: ["Scottish Government"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  },

];

const SouthamptonRecruiter = [


  {
    id: 1,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },


  {
    id: 4,
    Image: ms,
    title: ["Morgan Stanley"]
  },

  {
    id: 5,
    Image: google,
    title: ["Google"]
  },


  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 7,
    Image: bt,
    title: ["BT Group"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: shell,
    title: ["Shell"]
  },

];

const DurhamRecruiter = [




  {
    id: 1,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 3,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },

  {
    id: 1,
    Image: kpmg,
    title: ["KPMG"]
  },


  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 5,
    Image: jpmorgan,
    title: ["JP Morgan"]
  },


  {
    id: 6,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },

  {
    id: 7,
    Image: bbc,
    title: ["BBC"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: nestlé,
    title: ["Nestlé"]
  },

];

const UNSWRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 9,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 10,
    Image: telstra,
    title: ["Telstra"]
  }
];
const MonashRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 7,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 8,
    Image: westpac,
    title: ["Westpac"]
  },
  {
    id: 9,
    Image: telstra,
    title: ["Telstra"]
  },
  {
    id: 10,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  }
];
const QueenslandRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: abc,
    title: ["ABC"]
  },
  {
    id: 7,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 8,
    Image: riotinto,
    title: ["Rio Tinto"]
  },
  {
    id: 9,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 10,
    Image: queenslandgovernment,
    title: ["Queensland Government"]
  }
];
const WesternAustraliaRecruiter = [
  {
    id: 1,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 2,
    Image: riotinto,
    title: ["Rio Tinto"]
  },
  {
    id: 3,
    Image: woodside,
    title: ["Woodside Petroleum"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: abc,
    title: ["ABC"]
  },
  {
    id: 9,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 10,
    Image: civica,
    title: ["Civica"]
  }
];
const AdelaideRecruiter = [
  {
    id: 1,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 2,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 8,
    Image: santos,
    title: ["Santos"]
  },
  {
    id: 9,
    Image: hp,
    title: ["HP"]
  },
  {
    id: 10,
    Image: flinders,
    title: ["Flinders"]
  }
];
const MelbourneRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 4,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: macquarie,
    title: ["Macquarie"]
  },
  {
    id: 7,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 8,
    Image: nationalaustraliabank,
    title: ["NAB"]
  },
  {
    id: 9,
    Image: csl,
    title: ["CSL"]
  },
  {
    id: 10,
    Image: telstra,
    title: ["Telstra"]
  }
];
const SydneyRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 4,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: macquarie,
    title: ["Macquarie"]
  },
  {
    id: 8,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 9,
    Image: google,
    title: ["Google"]
  },
  {
    id: 10,
    Image: telstra,
    title: ["Telstra"]
  }
];
const AnuRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: westpac,
    title: ["Westpac"]
  },
  {
    id: 6,
    Image: anz,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 8,
    Image: telstra,
    title: ["Telstra"]
  },
  {
    id: 9,
    Image: nationalaustraliabank,
    title: ["NAB"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];
const UtsRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 8,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 9,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 10,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  }
];
const MacquarieRecruiter = [
  {
    id: 1,
    Image: macquarie,
    title: ["Macquarie"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 7,
    Image: westpac,
    title: ["Westpac"]
  },
  {
    id: 8,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 9,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];


const TrentRecruiter = [
  {
    id: 1,
    Image: Dominion,
    title: ["Toronto Dominion Bank (TD)"]
  },
  {
    id: 2,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 7,
    Image: CIBC,
    title: ["CIBC (Canadian Imperial Bank of Commerce)"]
  },
  {
    id: 8,
    Image: unilever,
    title: ["Unilever"]
  },
  {
    id: 9,
    Image: GM,
    title: ["General Motors"]
  },
  {
    id: 10,
    Image: Hatch,
    title: ["Hatch Ltd"]
  }
];

const TrinityRecruiter = [

  {
    id: 1,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },

  {
    id: 2,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 3,
    Image: Fraser,
    title: ["Fraser Health Authority"]
  },
  {
    id: 4,
    Image: Vancouver,
    title: ["Vancouver Coastal Health"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },



  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },


  {
    id: 8,
    Image: CityofVancouver,
    title: ["City of Vancouver"]
  },
  {
    id: 9,
    Image: World,
    title: ["World Vision Canada"]
  },
  {
    id: 10,
    Image: Suncor,
    title: ["Suncor Energy"]
  }
];

const RRURecruiter = [

  {
    id: 1,
    Image: World,
    title: ["BC Public Service"]
  },

  {
    id: 2,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 3,
    Image: Island,
    title: ["Vancouver   Health Authority"]
  },
  {
    id: 4,
    Image: Victoria,
    title: ["City of Victoria"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },



  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },


  {
    id: 8,
    Image: Environmental,
    title: ["Environmental Protection Agency (EPA)"]
  },

  {
    id: 9,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },

  {
    id: 10,
    Image: WWF,
    title: ["World Wildlife Fund (WWF)"]
  }
];

const WaterlooRecruiter = [

  {
    id: 1,
    Image: google,
    title: ["Google"]
  },

  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 3,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 4,
    Image: sap,
    title: ["SAP"]
  },
  {
    id: 5,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },





  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },

  {
    id: 8,
    Image: Tesla,
    title: ["Tesla"]
  },



  {
    id: 9,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 10,
    Image: Shopify,
    title: ["Shopify"]
  }
];

const CalgeryRecruiter = [

  {
    id: 1,
    Image: Calgary,
    title: ["Calgary Health Region"]
  },

  {
    id: 2,
    Image: Suncor,
    title: ["Suncor Energy"]
  },

  {
    id: 3,
    Image: enbridge,
    title: ["Enbridge"]
  },
  {
    id: 4,
    Image: atco,
    title: ["ATCO"]
  },
  {
    id: 5,
    Image: canadian,
    title: ["Canadian Natural Resources Limited (CNRL)"]
  },
  {
    id: 6,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 7,
    Image: pcl,
    title: ["PCL Construction"]
  },

  {
    id: 8,
    Image: husky,
    title: ["Husky Energy"]
  },



  {
    id: 9,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 10,
    Image: CIBC,
    title: ["CIBC"]
  }
];

const DalhousieRecruiter = [

  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 2,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 3,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 4,
    Image: scotiabank,
    title: ["Scotiabank"]
  },
  {
    id: 5,
    Image: bell,
    title: ["Bell Canada)"]
  },
  {
    id: 6,
    Image: snc,
    title: ["SNC-Lavalin"]
  },

  {
    id: 7,
    Image: nova,
    title: ["Nova Scotia Health Authority"]
  },

  {
    id: 8,
    Image: cbc,
    title: ["CBC (Canadian Broadcasting Corporation)"]
  },



  {
    id: 9,
    Image: mnp,
    title: ["MNP LLP"]
  },

  {
    id: 10,
    Image: Imperial,
    title: ["Imperial Oil"]
  }
];

const BrockRecruiter = [

  {
    id: 1,
    Image: ford,
    title: ["Ford Motor Company"]
  },

  {
    id: 2,
    Image: GM,
    title: ["General Motors"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 5,
    Image: magna,
    title: ["Magna International"]
  },

  {
    id: 6,
    Image: walmart,
    title: ["Walmart Canada"]
  },


  {
    id: 7,
    Image: bell,
    title: ["Bell Canada)"]
  },

  {
    id: 8,
    Image: ctc,
    title: ["Canadian Tire Corporation"]
  },



  {
    id: 9,
    Image: nh,
    title: ["Niagara Health"]
  },

  {
    id: 10,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  }
];

const WindsorRecruiter = [

  {
    id: 1,
    Image: ford,
    title: ["Ford Motor Company"]
  },

  {
    id: 2,
    Image: GM,
    title: ["General Motors"]
  },

  {
    id: 3,
    Image: magna,
    title: ["Magna International"]
  },

  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },



  {
    id: 6,
    Image: wrh,
    title: ["Windsor Regional Hospital"]
  },


  {
    id: 7,
    Image: eu,
    title: ["Enwin Utilities"]
  },
  {
    id: 8,
    Image: bell,
    title: ["Bell Canada)"]
  },




  {
    id: 9,
    Image: snc,
    title: ["SNC-Lavalin"]
  },

  {
    id: 10,
    Image: hp,
    title: ["Hewlett-Packard (HP)"]
  }
];

const VCCRecruiter = [

  {
    id: 1,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 2,
    Image: bchydro,
    title: ["BC Hydro"]
  },

  {
    id: 3,
    Image: ld,
    title: ["London Drugs"]
  },

  {
    id: 4,
    Image: Vancouver,
    title: ["Vancouver Coastal Health"]
  },
  {
    id: 5,
    Image: loblaws,
    title: ["Loblaws"]
  },



  {
    id: 6,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },


  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },




  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },

  {
    id: 10,
    Image: sap,
    title: ["SAP"]
  }
];

const TorontoRecruiter = [

  {
    id: 1,
    Image: google,
    title: ["Google"]
  },

  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 4,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 5,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },



  {
    id: 6,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },


  {
    id: 7,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },




  {
    id: 9,
    Image: Dominion,
    title: ["Toronto-Dominion Bank (TD)"]
  },

  {
    id: 10,
    Image: Tesla,
    title: ["Tesla"]
  }
];

//Germany

const LMURecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 3,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 4,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 9,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 10,
    Image: roche,
    title: ["Roche"]
  }
];

const TUMRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 3,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 5,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 6,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 7,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 10,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  }
];

const HeidelbergUniversityRecruiter = [
  {
    id: 1,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: heidelbergcement,
    title: ["HeidelbergCement"]
  },

  {
    id: 6,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 7,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 8,
    Image: roche,
    title: ["Roche"]
  },

  {
    id: 9,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 10,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  }
];


const HumboldtUniversityRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 3,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 5,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 6,
    Image: basf,
    title: ["BASF"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 9,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];

const RWTHRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 4,
    Image: ford,
    title: ["Ford"]
  },

  {
    id: 5,
    Image: deutscheTelekom,
    title: ["Deutsche Telekom"]
  },

  {
    id: 6,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 8,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 9,
    Image: basf,
    title: ["BASF"]
  },

  {
    id: 10,
    Image: volkswagen,
    title: ["Volkswagen"]
  }
];

const FUBRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 4,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 5,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 6,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 7,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 8,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 9,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];

const UOFRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 6,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 10,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  }
];

const UOTRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 6,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 10,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  }
];

const UOMRecruiter = [
  {
    id: 1,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 2,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 3,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 5,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 6,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 8,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 9,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },

  {
    id: 10,
    Image: allianz,
    title: ["Allianz"]
  }
];

const UOGRecruiter = [
  {
    id: 1,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 2,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 3,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 5,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 10,
    Image: siemens,
    title: ["Siemens"]
  }
];


//France

const UPSLRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 2,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 3,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 5,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 6,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 7,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 8,
    Image: renault,
    title: ["Renault"]
  },

  {
    id: 9,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 10,
    Image: orange,
    title: ["Orange"]
  }
];


const UPSRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 2,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 3,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 4,
    Image: thales,
    title: ["Thales"]
  },

  {
    id: 5,
    Image: dassaultSystemes,
    title: ["Dassault Systèmes"]
  },

  {
    id: 6,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 7,
    Image: safran,
    title: ["Safran"]
  },

  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 9,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 10,
    Image: renault,
    title: ["Renault"]
  }
];

const SURecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 2,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 3,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 4,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 6,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 7,
    Image: dassaultSystemes,
    title: ["Dassault Systèmes"]
  },

  {
    id: 8,
    Image: orange,
    title: ["Orange"]
  },

  {
    id: 9,
    Image: safran,
    title: ["Safran"]
  },

  {
    id: 10,
    Image: renault,
    title: ["Renault"]
  }
];

const UGARecruiter = [
  {
    id: 1,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 2,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 3,
    Image: stMicroelectronics,
    title: ["STMicroelectronics"]
  },

  {
    id: 4,
    Image: valeo,
    title: ["Valeo"]
  },

  {
    id: 5,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 6,
    Image: renault,
    title: ["Renault"]
  },

  {
    id: 7,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 8,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },

  {
    id: 10,
    Image: dassaultSystemes,
    title: ["Dassault Systèmes"]
  }
];

const UDSRecruiter = [
  {
    id: 1,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 2,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 6,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 7,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 8,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 9,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 10,
    Image: siemens,
    title: ["Siemens"]
  }
];

const UDMRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: sanofi,
    title: ["Sanofi"]
  },

  {
    id: 3,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 4,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 7,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },

  {
    id: 9,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 10,
    Image: boeing,
    title: ["Boeing"]
  }
];


const UDLRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 3,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 5,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 6,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 7,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  }
];


const UDNRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 4,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 6,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 8,
    Image: orange,
    title: ["Orange"]
  },

  {
    id: 9,
    Image: thales,
    title: ["Thales"]
  },

  {
    id: 10,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  }
];

const UDBRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 5,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 8,
    Image: orange,
    title: ["Orange"]
  },

  {
    id: 9,
    Image: thales,
    title: ["Thales"]
  },

  {
    id: 10,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  }
];

const UPPSRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 4,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },

  {
    id: 6,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 7,
    Image: societeGenerale,
    title: ["Société Générale"]
  },

  {
    id: 8,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 9,
    Image: axa,
    title: ["AXA"]
  },

  {
    id: 10,
    Image: airFranceKLM,
    title: ["Air France-KLM"]
  }
];

//Italy

const PDMilanoRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },
  {
    id: 3,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 4,
    Image: google,
    title: ["Google"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles (Stellantis)"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const UOMilanRecruiter = [
  {
    id: 1,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },
  {
    id: 5,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 6,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles (Stellantis)"]
  },
  {
    id: 9,
    Image: bulgari,
    title: ["Bulgari"]
  },
  {
    id: 10,
    Image: pirelli,
    title: ["Pirelli"]
  }
];

const UOBolognaRecruiter = [
  {
    id: 1,
    Image: tcs,
    title: ["Tata Consultancy Services (TCS)"]
  },
  {
    id: 2,
    Image: infosys,
    title: ["Infosys"]
  },
  {
    id: 3,
    Image: wipro,
    title: ["Wipro"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: cognizant,
    title: ["Cognizant"]
  },
  {
    id: 7,
    Image: capgemini,
    title: ["Capgemini"]
  },
  {
    id: 8,
    Image: hcl,
    title: ["HCL Technologies"]
  },
  {
    id: 9,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 10,
    Image: amazon,
    title: ["Amazon"]
  }
];

const UOFlorenceRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: luxottica,
    title: ["Luxottica"]
  },
  {
    id: 5,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 6,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 7,
    Image: unicredit,
    title: ["Unicredit"]
  },
  {
    id: 8,
    Image: ferragamo,
    title: ["Ferragamo"]
  },
  {
    id: 9,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 10,
    Image: prada,
    title: ["Prada"]
  }
];

const UOPaduaRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: luxottica,
    title: ["Luxottica"]
  },
  {
    id: 5,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 6,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 7,
    Image: unicredit,
    title: ["Unicredit"]
  },
  {
    id: 8,
    Image: prada,
    title: ["Prada"]
  },
  {
    id: 9,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 10,
    Image: ferragamo,
    title: ["Ferragamo"]
  }
];

const UOTurinRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: unicredit,
    title: ["Unicredit"]
  },
  {
    id: 6,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 7,
    Image: intesaSanpaolo,
    title: ["Intesa Sanpaolo"]
  },
  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 9,
    Image: ferrari,
    title: ["Ferrari"]
  },
  {
    id: 10,
    Image: lavazza,
    title: ["Lavazza"]
  }
];

const RLSRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: unicredit,
    title: ["UniCredit"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: ferrari,
    title: ["Ferrari"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: intesaSanpaolo,
    title: ["Intesa Sanpaolo"]
  }
];

const UONaplesRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: unicredit,
    title: ["UniCredit"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 10,
    Image: leonardo,
    title: ["Leonardo S.p.A."]
  }
];

const UOPisaRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: unicredit,
    title: ["UniCredit"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 10,
    Image: leonardo,
    title: ["Leonardo S.p.A."]
  }
];

const IEDRecruiter = [
  {
    id: 1,
    Image: gucci,
    title: ["Gucci"]
  },
  {
    id: 2,
    Image: prada,
    title: ["Prada"]
  },
  {
    id: 3,
    Image: fendi,
    title: ["Fendi"]
  },
  {
    id: 4,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 7,
    Image: ferragamo,
    title: ["Ferragamo"]
  },
  {
    id: 8,
    Image: diesel,
    title: ["Diesel"]
  },
  {
    id: 9,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 10,
    Image: ikea,
    title: ["Ikea"]
  }
];

//Ireland

const TCDublinRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 7,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 9,
    Image: linkedin,
    title: ["LinkedIn"]
  },
  {
    id: 10,
    Image: amazon,
    title: ["Amazon"]
  }
];

const UCDublinRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 6,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 7,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: meta,
    title: ["Meta (formerly Facebook)"]
  },
  {
    id: 9,
    Image: intel,
    title: ["Intel"]
  },
  {
    id: 10,
    Image: amazon,
    title: ["Amazon"]
  }
];

const UOCorkRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const UOGalwayRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const DCURecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const UOLimerickRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: google,
    title: ["Google"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 8,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: apple,
    title: ["Apple"]
  }
];

const MURecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 7,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 8,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  }
];


const TUDublinRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 7,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 8,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 9,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];

const DBSRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];

const RCSIRecruiter = [
  {
    id: 1,
    Image: stJamesHospital,
    title: ["St. James’s Hospital"]
  },
  {
    id: 2,
    Image: materMisericordiae,
    title: ["Mater Misericordiae University Hospital"]
  },
  {
    id: 3,
    Image: beaumontHospital,
    title: ["Beaumont Hospital"]
  },
  {
    id: 4,
    Image: corkUniversityHospital,
    title: ["Cork University Hospital"]
  },
  {
    id: 5,
    Image: hse,
    title: ["Health Service Executive (HSE)"]
  },
  {
    id: 6,
    Image: abbVie,
    title: ["AbbVie"]
  },
  {
    id: 7,
    Image: medtronic,
    title: ["Medtronic"]
  },
  {
    id: 8,
    Image: bostonScientific,
    title: ["Boston Scientific"]
  },
  {
    id: 9,
    Image: pfizer,
    title: ["Pfizer"]
  },
  {
    id: 10,
    Image: gsk,
    title: ["GlaxoSmithKline (GSK)"]
  }
];



// Singapor

const NUSRecruiters = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 4,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 7,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 9,
    Image: dbsBank,
    title: ["DBS Bank"]
  },
  {
    id: 10,
    Image: jpmorgan,
    title: ["JPMorgan"]
  }
];


const NTURecruiters = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 8,
    Image: bloomberg,
    title: ["Bloomberg"]
  },
  {
    id: 9,
    Image: samsung,
    title: ["Samsung"]
  },
  {
    id: 10,
    Image: dbsBank,
    title: ["DBS Bank"]
  }
];

const SUTDRecruiters = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 3,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 7,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  }
];


const SMRecruiters = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PricewaterhouseCoopers (PwC)"]
  },
  {
    id: 4,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: dbsBank,
    title: ["DBS Bank"]
  },
  {
    id: 7,
    Image: standardChartered,
    title: ["Standard Chartered Bank"]
  },
  {
    id: 8,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 9,
    Image: morganstanley,
    title: ["Morgan Stanley"]
  },
  {
    id: 10,
    Image: singaporeairlines,
    title: ["Singapore Airlines"]
  }
];


const JCRecruiters = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: riotinto,
    title: ["Rio Tinto"]
  },
  {
    id: 8,
    Image: bhp,
    title: ["BHP"]
  },
  {
    id: 9,
    Image: queenslandHealth,
    title: ["Queensland Health"]
  },
  {
    id: 10,
    Image: commonwealthBank,
    title: ["Commonwealth Bank of Australia"]
  }
];

const MDISRecruiters = [
  {
    id: 1,
    Image: dbsBank,
    title: ["DBS Bank"]
  },
  {
    id: 2,
    Image: ocbcBank,
    title: ["OCBC Bank"]
  },
  {
    id: 3,
    Image: standardChartered,
    title: ["Standard Chartered Bank"]
  },
  {
    id: 4,
    Image: singaporeairlines,
    title: ["Singapore Airlines"]
  },
  {
    id: 5,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 8,
    Image: marriottInternational,
    title: ["Marriott International"]
  },
  {
    id: 9,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];

const SIMGERecruiters = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: google,
    title: ["Google"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 9,
    Image: dbsBank,
    title: ["DBS Bank"]
  },
  {
    id: 10,
    Image: standardChartered,
    title: ["Standard Chartered"]
  }
];

const RCHERecruiters = [
  {
    id: 1,
    Image: hm,
    title: ["H&M"]
  },
  {
    id: 2,
    Image: gucci,
    title: ["Gucci"]
  },
  {
    id: 3,
    Image: sony,
    title: ["Sony"]
  },
  {
    id: 4,
    Image: dhl,
    title: ["DHL"]
  },
  {
    id: 5,
    Image: marriott,
    title: ["Marriott"]
  },
  {
    id: 6,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 7,
    Image: havasGroup,
    title: ["Havas Group"]
  },
  {
    id: 8,
    Image: shangriLa,
    title: ["Shangri-La"]
  },
  {
    id: 9,
    Image: ritzCarlton,
    title: ["The Ritz-Carlton"]
  },
  {
    id: 10,
    Image: samsung,
    title: ["Samsung"]
  }
];

const ERARecruiters = [
  {
    id: 1,
    Image: louisVuitton,
    title: ["Louis Vuitton"]
  },
  {
    id: 2,
    Image: dior,
    title: ["Dior"]
  },
  {
    id: 3,
    Image: ikea,
    title: ["IKEA"]
  },
  {
    id: 4,
    Image: unilever,
    title: ["Unilever"]
  },
  {
    id: 5,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 6,
    Image: singaporeairlines,
    title: ["Singapore Airlines"]
  },
  {
    id: 7,
    Image: gucci,
    title: ["Gucci"]
  },
  {
    id: 8,
    Image: adidas,
    title: ["Adidas"]
  },
  {
    id: 9,
    Image: prudential,
    title: ["Prudential"]
  },
  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];

const CSRecruiters = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: dbsBank,
    title: ["DBS Bank"]
  },
  {
    id: 7,
    Image: standardChartered,
    title: ["Standard Chartered"]
  },
  {
    id: 8,
    Image: google,
    title: ["Google"]
  },
  {
    id: 9,
    Image: shell,
    title: ["Shell"]
  },
  {
    id: 10,
    Image: singtel,
    title: ["SingTel"]
  }
];







// New Zealand
const AucklandRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: fonterra,
    title: ["Fonterra"]
  },
  {
    id: 7,
    Image: adhb,
    title: ["Auckland District Health Board (ADHB)"]
  },
  {
    id: 8,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: xero,
    title: ["Xero"]
  }
];

const MasseyRecruiter = [
  {
    id: 1,
    Image: fonterra,
    title: ["Fonterra"]
  },
  {
    id: 2,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 3,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 8,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 9,
    Image: genesisEnergy,
    title: ["Genesis Energy"]
  },
  {
    id: 10,
    Image: sparkNewZealand,
    title: ["Spark New Zealand"]
  }
];

const WaikatoRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: taitCommunications,
    title: ["Tait Communications"]
  }
];

const VictoriaRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: sparkNewZealand,
    title: ["Spark New Zealand"]
  }
];

const CanterburyRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: christchurchCityCouncil,
    title: ["Christchurch City Council"]
  }
]

const OtagoRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: doc,
    title: ["Department of Conservation"]
  }
];

const AUTRecruiter = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: newZealandPolice,
    title: ["New Zealand Police"]
  }
];

const LincolnRecruiter = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: agResearch,
    title: ["AgResearch"]
  },
  {
    id: 4,
    Image: landcorpFarming,
    title: ["Landcorp Farming"]
  },
  {
    id: 5,
    Image: aftPharmaceuticals,
    title: ["AFT Pharmaceuticals"]
  },
  {
    id: 6,
    Image: tgGlobal,
    title: ["T&G Global"]
  },
  {
    id: 7,
    Image: horticultureNewZealand,
    title: ["Horticulture New Zealand"]
  },
  {
    id: 8,
    Image: lic,
    title: ["LIC (Livestock Improvement Corporation)"]
  },
  {
    id: 9,
    Image: doc,
    title: ["Department of Conservation (DOC)"]
  },
  {
    id: 10,
    Image: nzTransportAgency,
    title: ["NZ Transport Agency (NZTA)"]
  }
];

const EITRecruiter = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: agResearch,
    title: ["AgResearch"]
  },
  {
    id: 4,
    Image: landcorpFarming,
    title: ["Landcorp Farming"]
  },
  {
    id: 5,
    Image: aftPharmaceuticals,
    title: ["AFT Pharmaceuticals"]
  },
  {
    id: 6,
    Image: tgGlobal,
    title: ["T&G Global"]
  },
  {
    id: 7,
    Image: horticultureNewZealand,
    title: ["Horticulture New Zealand"]
  },
  {
    id: 8,
    Image: lic,
    title: ["LIC (Livestock Improvement Corporation)"]
  },
  {
    id: 9,
    Image: doc,
    title: ["Department of Conservation (DOC)"]
  },
  {
    id: 10,
    Image: nzTransportAgency,
    title: ["NZ Transport Agency (NZTA)"]
  },
  {
    id: 11,
    Image: fletcherBuilding,
    title: ["Fletcher Building"]
  }
];

const UCRecruiters = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: agResearch,
    title: ["AgResearch"]
  },
  {
    id: 4,
    Image: landcorpFarming,
    title: ["Landcorp Farming"]
  },
  {
    id: 5,
    Image: tgGlobal,
    title: ["T&G Global"]
  },
  {
    id: 6,
    Image: horticultureNewZealand,
    title: ["Horticulture New Zealand"]
  },
  {
    id: 7,
    Image: lic,
    title: ["LIC (Livestock Improvement Corporation)"]
  },
  {
    id: 8,
    Image: doc,
    title: ["Department of Conservation (DOC)"]
  },
  {
    id: 9,
    Image: nzTransportAgency,
    title: ["NZ Transport Agency (NZTA)"]
  },
  {
    id: 10,
    Image: fletcherBuilding,
    title: ["Fletcher Building"]
  }
];



// UAE Recuriter 
const CUDRecruiter = [
  {
    id: 1,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 2,
    Image: google,
    title: ["Google"]
  },
  {
    id: 3,
    Image: emiratesGroup,
    title: ["Emirates Group"]
  },
  {
    id: 4,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 5,
    Image: dewa,
    title: ["Dubai Electricity and Water Authority (DEWA)"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 8,
    Image: alFuttaim,
    title: ["Al-Futtaim"]
  },
  {
    id: 9,
    Image: boeing,
    title: ["Boeing"]
  },
  {
    id: 10,
    Image: dubaiWorldTradeCentre,
    title: ["Dubai World Trade Centre"]
  }
];

const AjmanRecruiter = [
  {
    id: 1,
    Image: emiratesGroup,
    title: ["Emirates Group"]
  },
  {
    id: 2,
    Image: etisalat,
    title: ["Etisalat"]
  },
  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 7,
    Image: google,
    title: ["Google"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: samsung,
    title: ["Samsung"]
  },
  {
    id: 10,
    Image: jumeirahGroup,
    title: ["Jumeirah Group"]
  }
];

const AbutDhabiRecruiter = [
  {
    id: 1,
    Image: etihadAirways,
    title: ["Etihad Airways"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: alFuttaim,
    title: ["Al-Futtaim Group"]
  },
  {
    id: 6,
    Image: adnoc,
    title: ["ADNOC (Abu Dhabi National Oil Company)"]
  },
  {
    id: 7,
    Image: emiratesGroup,
    title: ["Emirates Group"]
  },
  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 9,
    Image: samsung,
    title: ["Samsung Electronics"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const AURAKRecruiter = [
  {
    id: 1,
    Image: cnsMiddleEast,
    title: ["CNS Middle East"]
  },
  {
    id: 2,
    Image: rakta,
    title: ["RAKTA (Ras Al Khaimah Transport Authority)"]
  },
  {
    id: 3,
    Image: maab,
    title: ["Mohamed Abdulrahman Al-Bahar"]
  },
  {
    id: 4,
    Image: almarwan,
    title: ["Al Marwan Group"]
  },
  {
    id: 5,
    Image: caterpillar,
    title: ["Caterpillar"]
  },
  {
    id: 6,
    Image: emiratesGroup,
    title: ["Emirates Group"]
  },
  {
    id: 7,
    Image: rakBank,
    title: ["RAK Bank"]
  },
  {
    id: 8,
    Image: alFuttaim,
    title: ["Al Futtaim Group"]
  },
  {
    id: 9,
    Image: dhl,
    title: ["DHL"]
  },
  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];

const UAEURecruiter = [
  {
    id: 1,
    Image: burjKhalifa,
    title: ["Burj Khalifa"]
  },
  {
    id: 2,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 3,
    Image: careem,
    title: ["Careem"]
  },
  {
    id: 4,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 5,
    Image: dewa,
    title: ["DEWA (Dubai Electricity and Water Authority)"]
  },
  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 7,
    Image: emaar,
    title: ["Emaar"]
  },
  {
    id: 8,
    Image: fedex,
    title: ["FedEx"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: etihadAirways,
    title: ["Etihad Airways"]
  }
];

const KhalifaRecruiter = [
  {
    id: 1,
    Image: adnoc,
    title: ["ADNOC (Abu Dhabi National Oil Company)"]
  },
  {
    id: 2,
    Image: schlumberger,
    title: ["Schlumberger"]
  },
  {
    id: 3,
    Image: halliburton,
    title: ["Halliburton"]
  },
  {
    id: 4,
    Image: boeing,
    title: ["Boeing"]
  },
  {
    id: 5,
    Image: ge,
    title: ["General Electric (GE)"]
  },
  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 9,
    Image: etihadAirways,
    title: ["Etihad Airways"]
  },
  {
    id: 10,
    Image: emiratesGroup,
    title: ["Emirates Group"]
  }
];

const AUSRecruiter = [
  {
    id: 1,
    Image: crescentEnterprises,
    title: ["Crescent Enterprises"]
  },
  {
    id: 2,
    Image: snoc,
    title: ["Sharjah National Oil Corporation (SNOC)"]
  },
  {
    id: 3,
    Image: beeah,
    title: ["Bee'ah"]
  },
  {
    id: 4,
    Image: mastercard,
    title: ["Mastercard"]
  },
  {
    id: 5,
    Image: chalhoub,
    title: ["Chalhoub Group"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 8,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: etihadAirways,
    title: ["Etihad Airways"]
  }
];

const AlAinFRecruiter = [
  {
    id: 1,
    Image: adnoc,
    title: ["Abu Dhabi National Oil Company (ADNOC)"]
  },
  {
    id: 2,
    Image: emiratesGroup,
    title: ["Emirates Group"]
  },
  {
    id: 3,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 4,
    Image: abuDhabi,
    title: ["Abu Dhabi University"]
  },
  {
    id: 5,
    Image: alAinMunicipality,
    title: ["Al Ain Municipality"]
  },
  {
    id: 6,
    Image: mubadalaInvestment,
    title: ["Mubadala Investment Company"]
  },
  {
    id: 7,
    Image: uaeUniversity,
    title: ["United Arab Emirates University"]
  },
  {
    id: 8,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },
  {
    id: 9,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 10,
    Image: saudiAramco,
    title: ["Saudi Aramco"]
  }
];

const ZayedUniversityRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 5,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 6,
    Image: oracle,
    title: ["Oracle"]
  },
  {
    id: 7,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 8,
    Image: dge,
    title: ["Dubai Government Entities"]
  },
  {
    id: 9,
    Image: etisalat,
    title: ["Etisalat"]
  },
  {
    id: 10,
    Image: expo2020Dubai,
    title: ["Expo 2020 Dubai"]
  }
];

const UniversityOfDubaiRecruiter = [
  {
    id: 1,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 2,
    Image: damac,
    title: ["Damac Group"]
  },
  {
    id: 3,
    Image: etisalat,
    title: ["Etisalat"]
  },
  {
    id: 4,
    Image: abudhabiCommercialBank,
    title: ["Abu Dhabi Commercial Bank"]
  },
  {
    id: 5,
    Image: alFuttaim,
    title: ["Al Futtaim"]
  },
  {
    id: 6,
    Image: ghantoot,
    title: ["Ghantoot Group"]
  },
  {
    id: 7,
    Image: apparelLLC,
    title: ["Apparel LLC"]
  },
  {
    id: 8,
    Image: bayt,
    title: ["BAYT"]
  },
  {
    id: 9,
    Image: emiratesGroup,
    title: ["Emirates Airlines"]
  },
  {
    id: 10,
    Image: landmarkGroup,
    title: ["Landmark Group"]
  }
];



export {
  CaliforniaRecruiter,
  wisconsinRecruiter,
  YeshivaRecruiter,
  UtahRecruiter,
  ColoradoRecruiter,
  IllinoisRecruiter,
  StanfordRecruiter,
  PennsylvaniaRecruiter,
  ChicagoRecruiter,
  MITRecruiter,
  BristolRecruiter,
  LeedsRecruiter,
  GlasgowRecruiter,
  imperialRecruiter,
  QueenMaryRecruiter,
  LiverpoolRecruiter,
  EdinburghRecruiter,
  SouthamptonRecruiter,
  DurhamRecruiter,
  BirminghamRecruiter,
  UNSWRecruiter,
  MonashRecruiter,
  QueenslandRecruiter,
  WesternAustraliaRecruiter,
  AdelaideRecruiter,
  MelbourneRecruiter,
  SydneyRecruiter,
  AnuRecruiter,
  UtsRecruiter,
  MacquarieRecruiter,
  TrentRecruiter,
  TrinityRecruiter,
  RRURecruiter,
  WaterlooRecruiter,
  CalgeryRecruiter,
  DalhousieRecruiter,
  BrockRecruiter,
  WindsorRecruiter,
  VCCRecruiter,
  TorontoRecruiter,

  //Germany

  LMURecruiter,
  TUMRecruiter,
  HeidelbergUniversityRecruiter,
  HumboldtUniversityRecruiter,
  FUBRecruiter,
  RWTHRecruiter,
  UOFRecruiter,
  UOTRecruiter,
  UOMRecruiter,
  UOGRecruiter,

  //France

  UPSLRecruiter,
  UPSRecruiter,
  SURecruiter,
  UGARecruiter,
  UDSRecruiter,
  UDMRecruiter,
  UDLRecruiter,
  UDNRecruiter,
  UDBRecruiter,
  UPPSRecruiter,

  //Italy

  PDMilanoRecruiter,
  UOMilanRecruiter,
  UOBolognaRecruiter,
  UOFlorenceRecruiter,
  UOPaduaRecruiter,
  UOTurinRecruiter,
  RLSRecruiter,
  UONaplesRecruiter,
  UOPisaRecruiter,
  IEDRecruiter,

  //Ireland

  TCDublinRecruiter,
  UCDublinRecruiter,
  UOCorkRecruiter,
  UOGalwayRecruiter,
  DCURecruiter,
  UOLimerickRecruiter,
  MURecruiter,
  TUDublinRecruiter,
  DBSRecruiter,
  RCSIRecruiter,

  //NewZealand

  AucklandRecruiter,
  MasseyRecruiter,
  WaikatoRecruiter,
  VictoriaRecruiter,
  CanterburyRecruiter,
  OtagoRecruiter,
  AUTRecruiter,
  LincolnRecruiter,
  EITRecruiter,
  UCRecruiters,


  // SINGAPOR
  NUSRecruiters,
  NTURecruiters,
  SUTDRecruiters,
  SMRecruiters,
  JCRecruiters,
  MDISRecruiters,
  SIMGERecruiters,
  RCHERecruiters,
  ERARecruiters,
  CSRecruiters,



  // UAE
  CUDRecruiter,
  AjmanRecruiter,
  AbutDhabiRecruiter,
  AURAKRecruiter,
  UAEURecruiter,
  KhalifaRecruiter,
  AUSRecruiter,
  AlAinFRecruiter,
  ZayedUniversityRecruiter,
  UniversityOfDubaiRecruiter,






};
