
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const us1 =imageBaseUrl + "CountryRecruiter/us/google.png";
const us2 =imageBaseUrl + "CountryRecruiter/us/amazon.png";
const us3 =imageBaseUrl + "CountryRecruiter/us/microspft.png";
const us4 =imageBaseUrl + "CountryRecruiter/us/apple.png";
const us5 =imageBaseUrl + "CountryRecruiter/us/facebook.png";
const us6 =imageBaseUrl + "CountryRecruiter/us/ibm.png";
const us7 =imageBaseUrl + "CountryRecruiter/us/goldman.png";
const us8 =imageBaseUrl + "CountryRecruiter/us/jp.png";
const us9 =imageBaseUrl + "CountryRecruiter/us/deloitte.png";
const us10 =imageBaseUrl + "CountryRecruiter/us/pwc.png";
const uk1 =imageBaseUrl + "CountryRecruiter/uk/bp.png";
const uk2 =imageBaseUrl + "CountryRecruiter/uk/unilever.png";
const uk3 =imageBaseUrl + "CountryRecruiter/uk/pwc.png";
const uk4 =imageBaseUrl + "CountryRecruiter/uk/deloitte.png";
const uk5 =imageBaseUrl + "CountryRecruiter/uk/kpmg.png";
const uk6 =imageBaseUrl + "CountryRecruiter/uk/ey.png";
const uk7 =imageBaseUrl + "CountryRecruiter/uk/barclays.png";
const uk8 =imageBaseUrl + "CountryRecruiter/uk/hsbc.png";
const uk9 =imageBaseUrl + "CountryRecruiter/uk/gsk.png";
const uk10 =imageBaseUrl + "CountryRecruiter/uk/rr.png";
const ire1 =imageBaseUrl + "CountryRecruiter/ireland/accenture.png";
const ire2 =imageBaseUrl + "CountryRecruiter/ireland/google.png";
const ire3 =imageBaseUrl + "CountryRecruiter/ireland/facebook.png";
const ire4 =imageBaseUrl + "CountryRecruiter/ireland/deloitte.png";
const ire5 =imageBaseUrl + "CountryRecruiter/ireland/kpmg.png";
const ire6 =imageBaseUrl + "CountryRecruiter/ireland/pwc.png";
const ire7 =imageBaseUrl + "CountryRecruiter/ireland/ey.png";
const ire8 =imageBaseUrl + "CountryRecruiter/ireland/Ellipse 30.png";
const ire9 =imageBaseUrl + "CountryRecruiter/ireland/intel.png";
const ire10 =imageBaseUrl + "CountryRecruiter/ireland/amazon.png";
const can1 =imageBaseUrl + "CountryRecruiter/canada/shopify.png";
const can2 =imageBaseUrl + "CountryRecruiter/canada/google.png";
const can3 =imageBaseUrl + "CountryRecruiter/canada/microspft.png";
const can4 =imageBaseUrl + "CountryRecruiter/canada/ibm.png";
const can5 =imageBaseUrl + "CountryRecruiter/canada/amazon.png";
const can6 =imageBaseUrl + "CountryRecruiter/canada/deloitte.png";
const can7 =imageBaseUrl + "CountryRecruiter/canada/pwc.png";
const can8 =imageBaseUrl + "CountryRecruiter/canada/ey.png";
const can9 =imageBaseUrl + "CountryRecruiter/canada/kpmg.png";
const can10 =imageBaseUrl + "CountryRecruiter/canada/rbc.png";
const ger1 =imageBaseUrl + "CountryRecruiter/germany/wv.png";
const ger2 =imageBaseUrl + "CountryRecruiter/germany/bmw.png";
const ger3 =imageBaseUrl + "CountryRecruiter/germany/simens.png";
const ger4 =imageBaseUrl + "CountryRecruiter/germany/daimler.png";
const ger5 =imageBaseUrl + "CountryRecruiter/germany/bosch.png";
const ger6 =imageBaseUrl + "CountryRecruiter/germany/sap.png";
const ger7 =imageBaseUrl + "CountryRecruiter/germany/deutschetelekom.png";
const ger8 =imageBaseUrl + "CountryRecruiter/germany/bayer.png";
const ger9 =imageBaseUrl + "CountryRecruiter/germany/deutschebank.png";
const ger10 =imageBaseUrl + "CountryRecruiter/germany/basf.png";
const aus1 =imageBaseUrl + "CountryRecruiter/australia/commbank.png";
const aus2 =imageBaseUrl + "CountryRecruiter/australia/deloitte.png";
const aus3 =imageBaseUrl + "CountryRecruiter/australia/pwc.png";
const aus4 =imageBaseUrl + "CountryRecruiter/australia/kpmg.png";
const aus5 =imageBaseUrl + "CountryRecruiter/australia/ey.png";
const aus6 =imageBaseUrl + "CountryRecruiter/australia/westpac.png";
const aus7 =imageBaseUrl + "CountryRecruiter/australia/anz.png";
const aus8 =imageBaseUrl + "CountryRecruiter/australia/telstra.png";
const aus9 =imageBaseUrl + "CountryRecruiter/australia/nab.png";
const aus10 =imageBaseUrl + "CountryRecruiter/australia/bhp.png";
const sin1 =imageBaseUrl + "CountryRecruiter/singapore/dbs.png";
const sin2 =imageBaseUrl + "CountryRecruiter/singapore/ocbc.png";
const sin3 =imageBaseUrl + "CountryRecruiter/singapore/uob.png";
const sin4 =imageBaseUrl + "CountryRecruiter/singapore/singtel.png";
const sin5 =imageBaseUrl + "CountryRecruiter/singapore/Deloitte.png";
const sin6 =imageBaseUrl + "CountryRecruiter/singapore/pwc.png";
const sin7 =imageBaseUrl + "CountryRecruiter/singapore/ey.png";
const sin8 =imageBaseUrl + "CountryRecruiter/singapore/kpmg.png";
const sin9 =imageBaseUrl + "CountryRecruiter/singapore/Gic.png";
const sin10 =imageBaseUrl + "CountryRecruiter/singapore/capitaland.png";
const ity1 =imageBaseUrl + "CountryRecruiter/italy/eni.png";
const ity2 =imageBaseUrl + "CountryRecruiter/italy/unicredit.png";
const ity3 =imageBaseUrl + "CountryRecruiter/italy/intesa.png";
const ity4 =imageBaseUrl + "CountryRecruiter/italy/enel.png";
const ity5 =imageBaseUrl + "CountryRecruiter/italy/fca.png";
const ity6 =imageBaseUrl + "CountryRecruiter/italy/telecom.png";
const ity7 =imageBaseUrl + "CountryRecruiter/italy/pt.png";
const ity8 =imageBaseUrl + "CountryRecruiter/italy/luxottica.png";
const ity9 =imageBaseUrl + "CountryRecruiter/italy/generali.png";
const ity10 =imageBaseUrl + "CountryRecruiter/italy/leonardo.png";
const frn1 =imageBaseUrl + "CountryRecruiter/france/loreal.png";
const frn2 =imageBaseUrl + "CountryRecruiter/france/te.png";
const frn3 =imageBaseUrl + "CountryRecruiter/france/axa.png";
const frn4 =imageBaseUrl + "CountryRecruiter/france/bnp.png";
const frn5 =imageBaseUrl + "CountryRecruiter/france/sg.png";
const frn6 =imageBaseUrl + "CountryRecruiter/france/airbus.png";
const frn7 =imageBaseUrl + "CountryRecruiter/france/ft.png";
const frn8 =imageBaseUrl + "CountryRecruiter/france/renault.png";
const frn9 =imageBaseUrl + "CountryRecruiter/france/danone.png";
const frn10 =imageBaseUrl + "CountryRecruiter/france/capgemini.png";
const dub1 =imageBaseUrl + "CountryRecruiter/dubai/emirates.png";
const dub2 =imageBaseUrl + "CountryRecruiter/dubai/emaar.png";
const dub3 =imageBaseUrl + "CountryRecruiter/dubai/dubai.png";
const dub4 =imageBaseUrl + "CountryRecruiter/dubai/dpworld.png";
const dub5 =imageBaseUrl + "CountryRecruiter/dubai/jumeirah.png";
const dub6 =imageBaseUrl + "CountryRecruiter/dubai/dubaielectricity.png";
const dub7 =imageBaseUrl + "CountryRecruiter/dubai/etisalat.png";
const dub8 =imageBaseUrl + "CountryRecruiter/dubai/alfuttaim.png";
const dub9 =imageBaseUrl + "CountryRecruiter/dubai/futtaim.png";
const dub10 =imageBaseUrl + "CountryRecruiter/dubai/emiratesnbd.png";
const nz1 =imageBaseUrl + "CountryRecruiter/newzealand/nz1.png";
const nz2 =imageBaseUrl + "CountryRecruiter/newzealand/nz2.png";
const nz3 =imageBaseUrl + "CountryRecruiter/newzealand/nz3.png";
const nz4 =imageBaseUrl + "CountryRecruiter/newzealand/nz4.png";
const nz5 =imageBaseUrl + "CountryRecruiter/newzealand/nz5.png";
const nz6 =imageBaseUrl + "CountryRecruiter/newzealand/nz6.png";
const nz7 =imageBaseUrl + "CountryRecruiter/newzealand/nz7.png";
const nz8 =imageBaseUrl + "CountryRecruiter/newzealand/nz8.png";
const nz9 =imageBaseUrl + "CountryRecruiter/newzealand/nz9.png";
const nz10 =imageBaseUrl + "CountryRecruiter/newzealand/nz10.png";

const USARecruiter = [
    {
        id: 1,
        Image: us1,
        title: ["Google"],

        // title: "Mountain View"
    },
    {
        id: 2,
        Image: us2,
        title: ["Amazon"],

        // title: "Seattle"
    },
    {
        id: 3,
        Image: us3,
        title: ["Microsoft"],

        // title: "Redmond"
    },
    {
        id: 4,
        Image: us4,
        title: ["Apple"],

        // title: "Cupertino"
    },
    {
        id: 5,
        Image: us5,
        title: ["Facebook"],

        // title: "Menlo Park"
    },
    {
        id: 6,
        Image: us6,
        title: ["IBM"],

        // title: "Armonk"
    },
    {
        id: 7,
        Image: us7,
        title: ["Goldman Sachs"],

        // title: "New York"
    },
    {
        id: 8,
        Image: us8,
        title: ["JPMorgan Chase"],

        // title: "New York"
    },
    {
        id: 9,
        Image: us9,
        title: ["Deloitte"],

        // title: "New York"
    },
    {
        id: 10,
        Image: us10,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "London"
    }
];

const UKRecruiter = [
    {
        id: 1,
        Image: uk1,
        title: ["BP (British Petroleum)"],

        // title: "London"
    },
    {
        id: 2,
        Image: uk2,
        title: ["Unilever"],

        // title: "London"
    },
    {
        id: 3,
        Image: uk3,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "London"
    },
    {
        id: 4,
        Image: uk4,
        title: ["Deloitte"],

        // title: "London"
    },
    {
        id: 5,
        Image: uk5,
        title: ["KPMG"],

        // title: "London"
    },
    {
        id: 6,
        Image: uk6,
        title: ["Ernst & Young (EY)"],

        // title: "London"
    },
    {
        id: 7,
        Image: uk7,
        title: ["Barclays"],

        // title: "London"
    },
    {
        id: 8,
        Image: uk8,
        title: ["HSBC"],

        // title: "London"
    },
    {
        id: 9,
        Image: uk9,
        title: ["GlaxoSmithKline (GSK)"],

        // title: "Brentford"
    },
    {
        id: 10,
        Image: uk10,
        title: ["Rolls-Royce Holdings"],

        // title: "London"
    }
];
const IrelandRecruiter = [
    {
        id: 1,
        Image: ire1,
        title: ["Accenture"],

        // title: "Dublin"
    },
    {
        id: 2,
        Image: ire2,
        title: ["Google"],

        // title: "Dublin"
    },
    {
        id: 3,
        Image: ire3,
        title: ["Facebook"],

        // title: "Dublin"
    },
    {
        id: 4,
        Image: ire4,
        title: ["Deloitte"],

        // title: "Dublin"
    },
    {
        id: 5,
        Image: ire5,
        title: ["KPMG"],

        // title: "Dublin"
    },
    {
        id: 6,
        Image: ire6,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Dublin"
    },
    {
        id: 7,
        Image: ire7,
        title: ["EY Ireland (Ernst & Young)"],

        // title: "Dublin"
    },
    {
        id: 8,
        Image: ire8,
        title: ["Microsoft"],

        // title: "Dublin"
    },
    {
        id: 9,
        Image: ire9,
        title: ["Intel"],

        // title: "Leixlip"
    },
    {
        id: 10,
        Image: ire10,
        title: ["Amazon"],

        // title: "Dublin"
    }
];
const CanadaRecruiter = [
    {
        id: 1,
        Image: can1,
        title: ["Shopify"],

        // title: "Ottawa"
    },
    {
        id: 2,
        Image: can2,
        title: ["Google"],

        // title: "Toronto"
    },
    {
        id: 3,
        Image: can3,
        title: ["Microsoft"],

        // title: "Mississauga"
    },
    {
        id: 4,
        Image: can4,
        title: ["IBM"],

        // title: "Markham"
    },
    {
        id: 5,
        Image: can5,
        title: ["Amazon"],

        // title: "Toronto"
    },
    {
        id: 6,
        Image: can6,
        title: ["Deloitte"],

        // title: "Toronto"
    },
    {
        id: 7,
        Image: can7,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Toronto"
    },
    {
        id: 8,
        Image: can8,
        title: ["Ernst & Young (EY)"],

        // title: "Toronto"
    },
    {
        id: 9,
        Image: can9,
        title: ["KPMG"],

        // title: "Toronto"
    },
    {
        id: 10,
        Image: can10,
        title: ["RBC (Royal Bank of Canada)"],

        // title: "Toronto"
    }
];
const GermanyRecruiter = [
    {
        id: 1,
        Image: ger1,
        title: ["Volkswagen Group"],
        // title: "Wolfsburg"
    },
    {
        id: 2,
        Image: ger2,
        title: ["BMW Group"],

        // title: "Munich"
    },
    {
        id: 3,
        Image: ger3,
        title: ["Siemens"],

        // title: "Munich"
    },
    {
        id: 4,
        Image: ger4,
        title: ["Daimler AG"],

        // title: "Stuttgart"
    },
    {
        id: 5,
        Image: ger5,
        title: ["Bosch"],

        // title: "Gerlingen"
    },
    {
        id: 6,
        Image: ger6,
        title: ["SAP SE"],

        // title: "Walldorf"
    },
    {
        id: 7,
        Image: ger7,
        title: ["Deutsche Telekom AG"],

        // title: "Bonn"
    },
    {
        id: 8,
        Image: ger8,
        title: ["Bayer AG"],

        // title: "Leverkusen"
    },
    {
        id: 9,
        Image: ger9,
        title: ["Deutsche Bank AG"],

        // title: "Frankfurt"
    },
    {
        id: 10,
        Image: ger10,
        title: ["BASF SE"],

        // title: "Ludwigshafen"
    }
];
const AustraliaRecruiter = [
    {
        id: 1,
        Image: aus1,
        title: ["Commonwealth Bank"],

        // title: "Sydney"
    },
    {
        id: 2,
        Image: aus2,
        title: ["Deloitte"],

        // title: "Sydney"
    },
    {
        id: 3,
        Image: aus3,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Sydney"
    },
    {
        id: 4,
        Image: aus4,
        title: ["KPMG Australia"],

        // title: "Sydney"
    },
    {
        id: 5,
        Image: aus5,
        title: ["Ernst & Young (EY)"],

        // title: "Sydney"
    },
    {
        id: 6,
        Image: aus6,
        title: ["Westpac Banking Corporation"],

        // title: "Sydney"
    },
    {
        id: 7,
        Image: aus7,
        title: ["ANZ Banking Group"],

        // title: "Melbourne"
    },
    {
        id: 8,
        Image: aus8,
        title: ["Telstra Corporation"],

        // title: "Melbourne"
    },
    {
        id: 9,
        Image: aus9,
        title: ["National Australia Bank (NAB)"],

        // title: "Melbourne"
    },
    {
        id: 10,
        Image: aus10,
        title: ["BHP (BHP Group Limited)"],

        // title: "Melbourne"
    }
];
const SingaporeRecruiter = [
    {
        id: 1,
        Image: sin1,
        title: ["DBS Bank"],

        // title: "Singapore"
    },
    {
        id: 2,
        Image: sin2,
        title: ["OCBC Bank"],

        // title: "Singapore"
    },
    {
        id: 3,
        Image: sin3,
        title: ["United Overseas Bank (UOB)"],

        // title: "Singapore"
    },
    {
        id: 4,
        Image: sin4,
        title: ["Singtel"],

        // title: "Singapore"
    },
    {
        id: 5,
        Image: sin5,
        title: ["Deloitte"],

        // title: "Singapore"
    },
    {
        id: 6,
        Image: sin6,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Singapore"
    },
    {
        id: 7,
        Image: sin7,
        title: ["Ernst & Young (EY)"],

        // title: "Singapore"
    },
    {
        id: 8,

        Image: sin8,
        title: ["KPMG"],

        // title: "Singapore"
    },
    {
        id: 9,
        Image: sin9,
        title: ["GIC Private Limited"],

        // title: "Singapore"
    },
    {
        id: 10,
        Image: sin10,
        title: ["CapitaLand"],

        // title: "Singapore"
    }
];
const ItalyRecruiter = [
    {
        id: 1,
        Image: ity1,
        title: ["Eni"],

        // title: "Rome"
    },
    {
        id: 2,
        Image: ity2,
        title: ["UniCredit"],

        // title: "Milan"
    },
    {
        id: 3,
        Image: ity3,
        title: ["Intesa Sanpaolo"],

        // title: "Turin"
    },
    {
        id: 4,
        Image: ity4,
        title: ["Enel"],

        // title: "Rome"
    },
    {
        id: 5,
        Image: ity5,
        title: ["Fiat Chrysler Automobiles (FCA)"],

        // title: "Turin"
    },
    {
        id: 6,
        Image: ity6,
        title: ["Telecom Italia"],

        // title: "Milan"
    },
    {
        id: 7,
        Image: ity7,
        title: ["Poste Italiane"],

        // title: "Rome"
    },
    {
        id: 8,
        Image: ity8,
        title: ["Luxottica Group"],

        // title: "Milan"
    },
    {
        id: 9,
        Image: ity9,
        title: ["Generali Group"],

        // title: "Trieste"
    },
    {
        id: 10,
        Image: ity10,
        title: ["Leonardo S.p.A."],

        // title: "Rome"
    }
];
const FranceRecruiter = [
    {
        id: 1,
        Image: frn1,
        title: ["L'Oréal"],

        // title: "Clichy"
    },
    {
        id: 2,
        Image: frn2,
        title: ["TotalEnergies"],

        // title: "Paris"
    },
    {
        id: 3,
        Image: frn3,
        title: ["AXA Group"],

        // title: "Paris"
    },
    {
        id: 4,
        Image: frn4,
        title: ["BNP Paribas"],

        // title: "Paris"
    },
    {
        id: 5,
        title: ["Société Générale"],

        Image: frn5,
        // title: "Paris"
    },
    {
        id: 6,
        Image: frn6,
        title: ["Airbus"],

        // title: "Toulouse"
    },
    {
        id: 7,
        Image: frn7,
        title: ["Orange S.A. (formerly France Télécom)"],

        // title: "Paris"
    },
    {
        id: 8,
        Image: frn8,
        title: ["Renault Group"],

        // title: "Boulogne-Billancourt"
    },
    {
        id: 9,
        Image: frn9,
        title: ["Danone"],

        // title: "Paris"
    },
    {
        id: 10,
        Image: frn10,
        title: ["Capgemini"],

        // title: "Paris"
    }
];
const DubaiRecruiter = [
    {
        id: 1,
        Image: dub1,
        title: ["Emirates Group"],
        // title: "Dubai"
    },
    {
        id: 2,
        Image: dub2,
        title: ["Emaar Properties"],

        // title: "Dubai"
    },
    {
        id: 3,
        Image: dub3,
        title: ["Dubai Holding"],

        // title: "Dubai"
    },
    {
        id: 4,
        Image: dub4,
        title: ["DP World"],

        // title: "Dubai"
    },
    {
        id: 5,
        Image: dub5,
        title: ["Jumeirah Group"],

        // title: "Dubai"
    },
    {
        id: 6,
        Image: dub6,
        title: ["Dubai Electricity and Water Authority (DEWA)"],

        // title: "Dubai"
    },
    {
        id: 7,
        Image: dub7,
        title: ["Etisalat"],

        // title: "Abu Dhabi"
    },
    {
        id: 8,
        Image: dub8,
        title: ["Majid Al Futtaim Group"],

        // title: "Dubai"
    },
    {
        id: 9,
        Image: dub9,
        title: ["Al-Futtaim Group"],

        // title: "Dubai"
    },
    {
        id: 10,
        Image: nz1,
        title: ["Emirates NBD"],

        // title: "Dubai"
    }
];

//country Recruiter

const NewZealandRecruiter = [
    {
        id: 1,
        Image: nz1,
        title: ["Randstad"],
        // title: "Dubai"
    },
    {
        id: 2,
        Image: nz2,
        title: ["Hays Recruitment"],

        // title: "Dubai"
    },
    {
        id: 3,
        Image: nz3,
        title: ["Apex Group"],

        // title: "Dubai"
    },
    {
        id: 4,
        Image: nz4,
        title: ["Michael Page"],

        // title: "Dubai"
    },
    {
        id: 5,
        Image: nz5,
        title: ["Robert Walters"],

        // title: "Dubai"
    },
    {
        id: 6,
        Image: nz6,
        title: ["Hudson"],

        // title: "Dubai"
    },
    {
        id: 7,
        Image: nz7,
        title: ["ManpowerGroup"],

        // title: "Abu Dhabi"
    },
    {
        id: 8,
        Image: nz8,
        title: ["Seek Recruitment"],

        // title: "Dubai"
    },
    {
        id: 9,
        Image: nz9,
        title: ["Recruitment Solutions"],

        // title: "Dubai"
    },
    {
        id: 10,
        Image: nz10,
        title: ["Kelly Services"],

        // title: "Dubai"
    }
];


export {
    USARecruiter,
    UKRecruiter,
    IrelandRecruiter,
    CanadaRecruiter,
    GermanyRecruiter,
    AustraliaRecruiter,
    SingaporeRecruiter,
    ItalyRecruiter,
    FranceRecruiter,
    DubaiRecruiter,
    NewZealandRecruiter,
};
