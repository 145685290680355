const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const u2 = imageBaseUrl + "universitybanner/u2.png";
const u3 = imageBaseUrl + "universitybanner/u3.png";
const u4 = imageBaseUrl + "universitybanner/u4.png";
const u5 = imageBaseUrl + "universitybanner/u5.png";
const u6 = imageBaseUrl + "universitybanner/u6.png";
const bristol = imageBaseUrl + "universitybanner/bristol.png";
const birmingham = imageBaseUrl + "universitybanner/birmingham.png";
const glasgow = imageBaseUrl + "universitybanner/glasgow.png";
const imperial = imageBaseUrl + "universitybanner/imperial.png";
const queenmary = imageBaseUrl + "universitybanner/queenmary.png";
const liverpool = imageBaseUrl + "universitybanner/liverpool.png";
const edinburgh = imageBaseUrl + "universitybanner/edinburgh.png";
const southampton = imageBaseUrl + "universitybanner/southampton.png";
const durham = imageBaseUrl + "universitybanner/durham.png";

const leeds = imageBaseUrl + "universitybanner/leeds.png";
const adelaide = imageBaseUrl + "universitybanner/adelaide.png";
const anu = imageBaseUrl + "universitybanner/anu.png";
const macquarie = imageBaseUrl + "universitybanner/macquarie.png";
const melbourne = imageBaseUrl + "universitybanner/melbourne.png";
const monash = imageBaseUrl + "universitybanner/monashuniversity.png";
const queensland = imageBaseUrl + "universitybanner/queensland.png";
const sydney = imageBaseUrl + "universitybanner/sydney.png";
const technologysydney = imageBaseUrl + "universitybanner/technologysydney.png";
const unsw = imageBaseUrl + "universitybanner/unsw.png";
const westernaustralia = imageBaseUrl + "universitybanner/westernaustralia.png";
const brock = imageBaseUrl + "universitybanner/Brock.png";
const calgary = imageBaseUrl + "universitybanner/Calgary.png";
const dalhousie = imageBaseUrl + "universitybanner/Dalhousie.png";
const rru = imageBaseUrl + "universitybanner/RRU.png";
const toronto = imageBaseUrl + "universitybanner/Toronto.png";
const trent = imageBaseUrl + "universitybanner/Trinity.png";
const trinity = imageBaseUrl + "universitybanner/Trinity.png";
const vcc = imageBaseUrl + "universitybanner/VCC.png";
const waterloo = imageBaseUrl + "universitybanner/Waterloo.png";
const windsor = imageBaseUrl + "universitybanner/windsor.png";
const illinois = imageBaseUrl + "universitybanner/illinois.png";
const stanford = imageBaseUrl + "universitybanner/stanford.png";
const pennsylvania = imageBaseUrl + "universitybanner/pennsylvania.png";
const chicago = imageBaseUrl + "universitybanner/chicago.png";
const mit = imageBaseUrl + "universitybanner/mit.png";
const LMU = imageBaseUrl + "universitybanner/LMU.png";
const TMU = imageBaseUrl + "universitybanner/TUM.jpg";
const HBU = imageBaseUrl + "universitybanner/HBU.png";
const HU = imageBaseUrl + "universitybanner/HU.png";
const RWTH = imageBaseUrl + "universitybanner/RWTH.png";
const FUB = imageBaseUrl + "universitybanner/FUB.png";
const UOF = imageBaseUrl + "universitybanner/UOF.png";
const UOT = imageBaseUrl + "universitybanner/UOT.png";
const UOM = imageBaseUrl + "universitybanner/UOM.png";
const UOG = imageBaseUrl + "universitybanner/UOG.png";

const UPSL = imageBaseUrl + "universitybanner/UPSL.png";
const UPS = imageBaseUrl + "universitybanner/UPS.png";
const SU = imageBaseUrl + "universitybanner/SU.png";
const UGA = imageBaseUrl + "universitybanner/UGA.png";
const UDS = imageBaseUrl + "universitybanner/UDS.png";
// const UDM = imageBaseUrl + "universitybanner/UDM.png";
const UDM = imageBaseUrl + "universitybanner/UDM.jpg";
const UDL = imageBaseUrl + "universitybanner/UDL.png";
const UDN = imageBaseUrl + "universitybanner/UDN.png";
const UDB = imageBaseUrl + "universitybanner/UDB.png";
const UPPS = imageBaseUrl + "universitybanner/UPPS.png";
const PDMilano = imageBaseUrl + "universitybanner/PDMilano.png";
const UOMilan = imageBaseUrl + "universitybanner/UOMilan.png";
const UOBologna = imageBaseUrl + "universitybanner/UOBologna.png";
const UOFlorence = imageBaseUrl + "universitybanner/UOFlorence.png";
const UOPadua = imageBaseUrl + "universitybanner/UOPadua.png";
const UOTurin = imageBaseUrl + "universitybanner/UOTurin.png";
const RLS = imageBaseUrl + "universitybanner/RLS.png";
const UONaples = imageBaseUrl + "universitybanner/UONaples.png";
const UOPisa = imageBaseUrl + "universitybanner/UOPisa.png";
const IED = imageBaseUrl + "universitybanner/IED.png";
const TDublin = imageBaseUrl + "universitybanner/TDublin.png";
const UCDublin = imageBaseUrl + "universitybanner/UCDublin.png";
const UOCork = imageBaseUrl + "universitybanner/UOCork.png";
const UOGalway = imageBaseUrl + "universitybanner/UOGalway.png";
const DCU = imageBaseUrl + "universitybanner/DCU.png";
const UOLimerick = imageBaseUrl + "universitybanner/UOLimerick.png";
const MU = imageBaseUrl + "universitybanner/MU.png";
const TUDublin = imageBaseUrl + "universitybanner/TUDublin.png";
const DBS = imageBaseUrl + "universitybanner/DBS.png";
const RCS = imageBaseUrl + "universitybanner/RCS.png";
const UOA = imageBaseUrl + "universitybanner/UOA.png";
const MUniversity = imageBaseUrl + "universitybanner/MUniversity.png";
const UOW = imageBaseUrl + "universitybanner/UOW.png";
const VUOW = imageBaseUrl + "universitybanner/VUOW.png";
const UCanterbury = imageBaseUrl + "universitybanner/UCanterbury.png";
const UOtago = imageBaseUrl + "universitybanner/UOtago.png";
const AUOT = imageBaseUrl + "universitybanner/AUOT.png";
const LUniversity = imageBaseUrl + "universitybanner/LUniversity.png";
const EIOT = imageBaseUrl + "universitybanner/EIOT.png";
const UCIC = imageBaseUrl + "universitybanner/UCIC.png";
const CUD = imageBaseUrl + "universitybanner/CUD.png";
const Ajman = imageBaseUrl + "universitybanner/Ajman.png";
const AbuDhabi = imageBaseUrl + "universitybanner/AbuDhabi.png";
const AURAK = imageBaseUrl + "universitybanner/AURAK.png";
const UAEU = imageBaseUrl + "universitybanner/UAEU.png";
const khalifa = imageBaseUrl + "universitybanner/khalifa.png";
const AUS = imageBaseUrl + "universitybanner/AUS.png";
const ALAin = imageBaseUrl + "universitybanner/ALAin.png";
const ZayedUniversity = imageBaseUrl + "universitybanner/ZayedUniversity.png";
const UniversityOfDubai = imageBaseUrl + "universitybanner/UniversityOfDubai.png";
const nus = imageBaseUrl + "universitybanner/nus.png";
const ntu = imageBaseUrl + "universitybanner/ntu.png";
const sutd = imageBaseUrl + "universitybanner/sutd.png";
const smu = imageBaseUrl + "universitybanner/smu.png";
const jcu = imageBaseUrl + "universitybanner/jcu.png";
const mdis = imageBaseUrl + "universitybanner/mdis.png";
const sge = imageBaseUrl + "universitybanner/sge.png";
const rche = imageBaseUrl + "universitybanner/rche.png";
const eraua = imageBaseUrl + "universitybanner/eraua.png";
const cs = imageBaseUrl + "universitybanner/cs.jpg";

const CaliforniaBanner = {
  title: "University of California",
  image: u2
};
const WisconsinBanner = {
  title: "University of Wisconsin-Madison",
  image: u3
};
const YeshivaBanner = {
  title: "Yeshiva University",
  image: u4
};
const UtahBanner = {
  title: "Southern Utah University",
  image: u5
};
const ColoradoBanner = {
  title: "Colorado State University",
  image: u6
};
const IllinoisBanner = {
  title: "The University of Illinois at Urbana-Champaign",
  image: illinois
};
const StanfordBanner = {
  title: "Stanford University",
  image: stanford
};
const PennsylvaniaBanner = {
  title: "University of Pennsylvania",
  image: pennsylvania
};
const ChicagoBanner = {
  title: "The University of Chicago",
  image: chicago
};
const MITBanner = {
  title: "The Massachusetts Institute of Technology (MIT)",
  image: mit
};




const BristolBanner = {
  title: "University of Bristol",
  image: bristol
};
const BirminghamBanner = {
  title: "The University of Birmingham",
  image: birmingham
};
const GlasgowBanner = {
  title: "The University of Glasgow",
  image: glasgow
};
const ImperialBanner = {
  title: "Imperial College London",
  image: imperial
};
const QueenMaryBanner = {
  title: "Queen Mary University of London",
  image: queenmary
};
const LiverpoolBanner = {
  title: "University of Liverpool",
  image: liverpool
};
const EdinburghBanner = {
  title: " University of Edinburgh",
  image: edinburgh
};
const SouthamptonBanner = {
  title: " University of Southampton",
  image: southampton
};
const DurhamBanner = {
  title: " Durham University",
  image: durham
};
const LeedsBanner = {
  title: "University of Leeds",
  image: leeds
};
// Australia University
const UNSWBanner = {
  title: "The University of New South Australia",
  image: unsw
};

const MonashBanner = {
  title: "Monash University",
  image: monash
};

const QueenslandBanner = {
  title: "University of Queensland",
  image: queensland
};

const WesternaustraliaBanner = {
  title: "University of Western Australia",
  image: westernaustralia
};
const AdelaideBanner = {
  title: "University of Adelaide",
  image: adelaide
};
const MelbourneBanner = {
  title: "University of Melbourne",
  image: melbourne
};
const SydneyBanner = {
  title: "University of Sydney",
  image: sydney
};
const AnuBanner = {
  title: "The Australian National University",
  image: anu
};
const TechnologySydneyBanner = {
  title: "The University of Technology Sydney",
  image: technologysydney
};
const MacquarieBanner = {
  title: "Macquarie University",
  image: macquarie
};
const TrentBanner = {
  title: "Trent University",
  image: trent
};
const TrinityBanner = {
  title: "Trinity Western University",
  image: trinity
};
const RRUBanner = {
  title: "Royal Roads University (RRU)",
  image: rru
};
const WaterlooBanner = {
  title: "The University of Waterloo",
  image: waterloo
};
const CalgeryBanner = {
  title: "University of Calgary Continuing Education",
  image: calgary
};
const DalhousieBanner = {
  title: "Dalhousie University",
  image: dalhousie
};
const BrockBanner = {
  title: "Brock University",
  image: brock
};
const WindsorBanner = {
  title: "The University of Windsor",
  image: windsor
};
const VCCBanner = {
  title: "Vancouver Community College, Vancouver, British Columbia",
  image: vcc
};
const TorontoBanner = {
  title: "University of Toronto",
  image: toronto
};

//Germany

const LMUBanner = {
  title: "Ludwig Maximilian University of Munich (LMU)",
  image: LMU
};

const TMUBanner = {
  title: "Technical University of Munich (TUM)",
  image: TMU
};

const HeidelbergUniversityBanner = {
  title: "Heidelberg University",
  image: HBU
};

const HumboldtUniversityBanner = {
  title: "Humboldt University of Berlin",
  image: HU
};

const RWTHBanner = {
  title: "RWTH Aachen University",
  image: RWTH
};

const FUBBanner = {
  title: "Freie Universität Berlin",
  image: FUB
};

const UOFBanner = {
  title: "University of Freiburg",
  image: UOF
};

const UOTBanner = {
  title: "University of Tübingen",
  image: UOT
};

const UOMBanner = {
  title: "University of Mannheim",
  image: UOM
};

const UOGBanner = {
  title: "University of Göttingen",
  image: UOG
};

//France

const UPSLBanner = {
  title: "Université PSL (Paris Sciences et Lettres)",
  image: UPSL
};

const UPSBanner = {
  title: "Université Paris-Saclay",
  image: UPS
};

const SUBanner = {
  title: "Sorbonne University",
  image: SU
};

const UGABanner = {
  title: "Université Grenoble Alpes",
  image: UGA
};
const UDSBanner = {
  title: "Université de Strasbourg",
  image: UDS
};
const UDMBanner = {
  title: "Université de Montpellier",
  image: UDM
};
const UDLBanner = {
  title: "Université de Lille",
  image: UDL
};
const UDNBanner = {
  title: "Université de Nantes",
  image: UDN
};
const UDBBanner = {
  title: "Université de Bordeaux",
  image: UDB
};
const UPPSBanner = {
  title: "Université Paris 1 Panthéon-Sorbonne",
  image: UPPS
};

//italy

const PDMilanoBanner = {
  title: "Politecnico di Milano",
  image: PDMilano
};

const UOMilanBanner = {
  title: "University of Milan",
  image: UOMilan
};

const UOBolognaBanner = {
  title: "University of Bologna",
  image: UOBologna
};

const UOFlorenceBanner = {
  title: "University of Florence",
  image: UOFlorence
};
const UOPaduaBanner = {
  title: "University of Padua",
  image: UOPadua
};
const UOTurinBanner = {
  title: "University of Turin",
  image: UOTurin
};
const RLSBanner = {
  title: "University of Rome La Sapienza",
  image: RLS
};
const UONaplesBanner = {
  title: "University of Naples Federico II",
  image: UONaples
};
const UOPisaBanner = {
  title: "The University of Pisa",
  image: UOPisa
};
const IEDBanner = {
  title: "Instituto Europeo di Design (IED), Milan",
  image: IED
};

//Ireland

const TCDublinBanner = {
  title: "Trinity College Dublin",
  image: TDublin
};
const UCDublinBanner = {
  title: "University College Dublin",
  image: UCDublin
};
const UOCorkBanner = {
  title: " University of Cork",
  image: UOCork
};
const UOGalwayBanner = {
  title: "University of Galway",
  image: UOGalway
};
const DCUBanner = {
  title: "Dublin City University (DCU)",
  image: DCU
};
const UOLimerickBanner = {
  title: "University of Limerick",
  image: UOLimerick
};
const MUBanner = {
  title: "Maynooth University",
  image: MU
};
const TUDublinBanner = {
  title: "Technological University Dublin",
  image: TUDublin
};
const DBSBanner = {
  title: "Dublin Business School",
  image: DBS
};
const RCSIBanner = {
  title: "Royal College of Surgeons in Ireland (RCSI)",
  image: RCS
};

//New-Zealand

const UOABanner = {
  title: "University of Auckland",
  image: UOA
};
const MUniversityBanner = {
  title: "Massey University",
  image: MUniversity
};
const UOWBanner = {
  title: "University of Waikato",
  image: UOW
};
const VUOWBanner = {
  title: "Victoria University of Wellington",
  image: VUOW
};
const UCanterburyBanner = {
  title: "University of Canterbury",
  image: UCanterbury
};
const UOtagoBanner = {
  title: "University of Otago",
  image: UOtago
};
const AUOTBanner = {
  title: "Auckland University of Technology",
  image: AUOT
};
const LUniversityBanner = {
  title: "Lincoln University",
  image: LUniversity
};
const EIOTBanner = {
  title: "Eastern Institute of Technology, Te Pūkenga",
  image: EIOT
};
const UCICBanner = {
  title: "UC International College",
  image: UCIC
};


// Singapor

const NUSBanner = {
  title: "National University of Singapore",
  image: nus,
 };
 const NTUBanner = {
  title: "Nanyang Technological University, Singapore",
  image: ntu,
 };
 const SUTDBanner = {
  title: "Singapore University of Technology and Design",
  image: sutd,
 };
 const SMBanner = {
  title: "Singapore Management University",
  image: smu,
 };
 const JCBanner = {
  title: "James Cook University",
  image: jcu,
 };
 const MDISBanner = {
  title: "Management Development Institute of Singapore ",
  image: mdis,
 };
 const SIMGEBanner = {
  title: "SIM GLOBAL EDUCATION",
  image: sge,
 };
 const RCHEBanner = {
  title: "Raffles College of Higher Education",
  image: rche,
 };
 const ERABanner = {
  title: "Embry Riddle Aeronautical University Asia",
  image: eraua,
 };
 const CSBanner = {
  title: "Curtin Singapore",
  image: cs,
 };
 




// UAE
const CUDBanner = {
  title :"Canadian University Dubai",
  image : CUD
}
const AjmanBanner = {
  title :"Ajman University",
  image : Ajman
}
const AbuDhabiBanner = {
  title :"Abu Dhabi University",
  image : AbuDhabi
}
const AURAKBanner = {
  title :"American University of Ras Al Khaimah ( AURAK )",
  image : AURAK
}
const UAEUBanner = {
  title :"United Arab Emirates University",
  image : UAEU
}
const khalifaBanner = {
 title : "Khalifa University of Science and Technology",
 image : khalifa 
}
const AUSBanner = {
  title :"American University of Sharjah",
  image : AUS
}
const ALAinBanner = {
  title :"Al Ain University",
  image : ALAin
}
const ZayedUniversityBanner = {
  title :"Zayed University",
  image : ZayedUniversity
}
const UniversityOfDubaiBanner = {
  title :"University of Dubai",
  image : UniversityOfDubai
}








export {
  CaliforniaBanner,
  WisconsinBanner,
  YeshivaBanner,
  UtahBanner,
  ColoradoBanner,
  IllinoisBanner,
  StanfordBanner,
  PennsylvaniaBanner,
  ChicagoBanner,
  MITBanner,
  BristolBanner,
  BirminghamBanner,
  ImperialBanner,
  QueenMaryBanner,
  LiverpoolBanner,
  EdinburghBanner,
  SouthamptonBanner,
  DurhamBanner,
  GlasgowBanner,
  LeedsBanner,
  UNSWBanner,
  MonashBanner,
  QueenslandBanner,
  WesternaustraliaBanner,
  AdelaideBanner,
  MelbourneBanner,
  SydneyBanner,
  AnuBanner,
  TechnologySydneyBanner,
  MacquarieBanner,
  TrentBanner,
  TrinityBanner,
  RRUBanner,
  WaterlooBanner,
  CalgeryBanner,
  DalhousieBanner,
  BrockBanner,
  WindsorBanner,
  VCCBanner,
  TorontoBanner,

  //Germany
  LMUBanner,
  TMUBanner,
  HeidelbergUniversityBanner,
  HumboldtUniversityBanner,
  FUBBanner,
  RWTHBanner,
  UOFBanner,
  UOTBanner,
  UOMBanner,
  UOGBanner,

  //France

  UPSLBanner,
  UPSBanner,
  SUBanner,
  UGABanner,
  UDSBanner,
  UDMBanner,
  UDLBanner,
  UDNBanner,
  UDBBanner,
  UPPSBanner,

  //Italy

  PDMilanoBanner,
  UOMilanBanner,
  UOBolognaBanner,
  UOFlorenceBanner,
  UOPaduaBanner,
  UOTurinBanner,
  RLSBanner,
  UONaplesBanner,
  UOPisaBanner,
  IEDBanner,

  //Ireland
  TCDublinBanner,
  UCDublinBanner,
  UOCorkBanner,
  UOGalwayBanner,
  DCUBanner,
  UOLimerickBanner,
  MUBanner,
  TUDublinBanner,
  DBSBanner,
  RCSIBanner,

  //New-Zealand
  UOABanner,
  MUniversityBanner,
  UOWBanner,
  VUOWBanner,
  UCanterburyBanner,
  UOtagoBanner,
  AUOTBanner,
  LUniversityBanner,
  EIOTBanner,
  UCICBanner,

 //SINGAPOR

 NUSBanner,
 NTUBanner,
 SUTDBanner,
 SMBanner,
 JCBanner,
 MDISBanner,
 SIMGEBanner,
 RCHEBanner,
 ERABanner,
 CSBanner,
  

  
    //UAE
    CUDBanner,
    AjmanBanner,
    AbuDhabiBanner,
    AURAKBanner,
    UAEUBanner,
    khalifaBanner,
    AUSBanner,
    ALAinBanner,
    ZayedUniversityBanner,
    UniversityOfDubaiBanner,
 

};
