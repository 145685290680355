import eligibility1 from "../asset/eligibility1.png";
import eligibility2 from "../asset/eligibilityImg_2.png";
import eligibility3 from "../asset/eligibilityImg_3.png";
import eligibility4 from "../asset/eligibilityImg_4.png";
import eligibility5 from "../asset/eligibilityImg_5.png";
import eligibility6 from "../asset/eligibilityImg_6.png";
import eligibility7 from "../asset/eligibilityImg_7.png";
import eligibility8 from "../asset/eligibilityImg_8.png";
import eligibility9 from "../asset/eligibilityImg9.png";
import eligibility10 from "../asset/eligibilityImg10.png";

const immigrationEligibilityData = [
  {
    title: "Age",
    items: [
      "Candidates must be at least 17 years old at the time of admission.",
    ],
    Image: eligibility1,
  },
  {
    title: "Visa Category",
    items: [
      "Candidates must have completed 10+2 or equivalent examinations with Physics, Chemistry, Biology.",
    ],
    Image: eligibility2,
  },
  {
    title: "English Language Proficiency",
    items: [
      "Candidates must have completed 10+2 or equivalent examinations with Physics, Chemistry, Biology.",
    ],
    Image: eligibility3,
  },
  {
    title: "Skill Assessment",
    items: [
      "Candidates must have scored the minimum aggregate marks required in the qualifying examination which is 50% for the general category, 45% for OBC/SC/ST, and 40% for PWD.",
    ],
    Image: eligibility4,
  },
  {
    title: "Education Qualification",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility5,
  },
  {
    title: "Work Experience",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility6,
  },
  {
    title: "Occupation In Demand",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility7,
  },
  {
    title: "Financial Requirement",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility8,
  },
  {
    title: "Health and Character Checks",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility9,
  },
  {
    title: "Support or Sponsorship",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility10,
  },
  
];

export default immigrationEligibilityData;
