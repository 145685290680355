import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import University_abroad from "../../../../asset/University_abroad.svg";
import OppositeSidekeyArrow_abroad from "../../../../asset/OppositeSidekeyArrow_abroad.svg";
import rightIcon from "../../../../asset/Right_abroad.svg";

export default function UniversityDetailsDialog() {
  const alldata = ["SOP", "LOR", "ESSAYS"];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            gap: 4,
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              padding: "auto",
              width: { xs: "100%", sm: "150px" },
              height: "auto",
              backgroundColor: "#EFFDFF",
              borderRadius: "12px",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              border: "1px solid #B7B7B7",
            }}
          >
            <img
              src={University_abroad}
              alt="University"
              style={{
                width: "80px",
                height: "auto",
                borderRadius: "10%",
              }}
            />
          </Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "#595959",
              fontSize: 35,
            }}
          >
            Harvard University
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            disableElevation
            disableRipple
            disa
            sx={{
              backgroundColor: "#00999E",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#00999E",
                color: "#fff",
              },
              textTransform: "capitalize",
            }}
            size="large"
          >
            Visit Site
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3} margin="auto">
        <Grid item xs={1} sm={4}>
          <Box
            sx={{
              height: "auto",
              width: "367px",
              borderRadius: "8.59px",
              border: "0.86px solid #9f9f9f",
              backgroundColor: "#E2FEFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                SOP/ LOR / Essays
              </Typography>
              <Typography variant="body1">
                Sample, Draft and Final Copy
              </Typography>
            </Box>

            <Grid container>
              <Grid
                item
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // flexDirection  : "column",
                  gap: 2,
                  marginTop: "auto",
                }}
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography color={"orange"}>*</Typography>
                  <img src={OppositeSidekeyArrow_abroad} alt="opp" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={10}>
                    Select to upload your Document via browse file
                  </Typography>
                  <Typography fontSize={7}>
                    JPG, PNG or PDF, file size no more than 10MB
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                height: "auto",
                width: "323px",
                padding: "14px 24px",
                borderRadius: "10px",
                border: "1px solid #E4E4E4",
                margin: "auto",
                backgroundColor: "#FFF",
                marginTop: "auto",
              }}
            >
              <Stack
                variant="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  SOR
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  LOR
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  ESSAYS
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "auto",
                }}
              >
                <Box>
                  <img src={OppositeSidekeyArrow_abroad} alt="opp" />
                </Box>
                <Typography variant="body2">
                  Select to upload your SOP
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                  }}
                >
                  JPG, PNG or PDF, file size no more than 10MB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "auto",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "#00999E",
                    border: "1px solid #00999E",
                    "&:hover": {
                      color: "#00999E",
                      border: "1px solid #00999E",
                    },
                    textTransform: "capitalize",
                  }}
                  size="large"
                >
                  Browse File
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1} sm={4}>
          <Box
            sx={{
              height: "auto",
              width: "367px",
              borderRadius: "8.59px",
              border: "0.86px solid #9f9f9f",
              backgroundColor: "#E2FEFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Filling Application
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                }}
              >
                Status: Yes/NO, Date, if application fees Paid: Yes/No (Date)
              </Typography>
            </Box>

            <Box
              sx={{
                height: "auto",
                width: "323px",
                borderRadius: "10px",
                border: "1px solid #E4E4E4",
                margin: "auto",
                backgroundColor: "#FFF",
                marginTop: "auto",
                padding: 2, // Adds consistent padding inside the box
                display: "flex",
                flexDirection: "column",
                gap: 2, // Uniform gap between child boxes
              }}
            >
              {/* Application Header */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Application applied</Typography>
                <img
                  src={rightIcon}
                  alt="Right Icon"
                  style={{ width: "24px", height: "24px" }}
                />
              </Box>

              {/* Applied Date */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Applied Date</Typography>
                <Typography>13 Aug 2024</Typography>
              </Box>

              {/* Applied Time */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Applied Time</Typography>
                <Typography>10:00 AM</Typography>
              </Box>

              {/* Application Amount */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Application Amount</Typography>
                <Typography>Rs. 5000/-</Typography>
              </Box>

              {/* Applied Via */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Applied via</Typography>
                <Typography>UPI</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1} sm={4}>
          <Box
            sx={{
              height: "auto",
              width: "367px",
              borderRadius: "8.59px",
              border: "0.86px solid #9f9f9f",
              backgroundColor: "#E2FEFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Conditional Offer Letter
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                }}
              >
                Received : Yes/ No, If Received conditional{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                height: "auto",
                width: "323px",
                borderRadius: "10px",
                border: "1px solid #E4E4E4",
                margin: "auto",
                backgroundColor: "#FFF",
                marginTop: "auto",
                padding: 2, // Adds consistent padding inside the box
                display: "flex",
                flexDirection: "column",
                gap: 2, // Uniform gap between child boxes
              }}
            >
              {/* Application Header */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Offer Letter Received</Typography>
                <img
                  src={rightIcon}
                  alt="Right Icon"
                  style={{ width: "24px", height: "24px" }}
                />
              </Box>

              {/* Applied Date */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Received Date</Typography>
                <Typography>13 Aug 2024</Typography>
              </Box>

              {/* Applied Time */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Received Time</Typography>
                <Typography>10:00 AM</Typography>
              </Box>

              {/* Application Amount */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2">
                  Download Your Conditional Offer letter
                </Typography>
              </Box>

              {/* Applied Via */}
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#00999E",
                    "&:hover": {
                      backgroundColor: "#007f80",
                      color: "#fff",
                    },
                    width: "100%",
                    fontSize: 13,
                    padding: "7px 4px",
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1} sm={4}>
          <Box
            sx={{
              height: "auto",
              width: "367px",
              borderRadius: "8.59px",
              border: "0.86px solid #9f9f9f",
              backgroundColor: "#E2FEFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                UnConditional Offer Letter
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                }}
              >
                Received : Yes/ No, If Received conditional{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                height: "auto",
                width: "323px",
                borderRadius: "10px",
                border: "1px solid #E4E4E4",
                margin: "auto",
                backgroundColor: "#FFF",
                marginTop: "auto",
                padding: 2, // Adds consistent padding inside the box
                display: "flex",
                flexDirection: "column",
                gap: 2, // Uniform gap between child boxes
              }}
            >
              {/* Application Header */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Offer Letter Received</Typography>
                <img
                  src={rightIcon}
                  alt="Right Icon"
                  style={{ width: "24px", height: "auto" }}
                />
              </Box>

              {/* Applied Date */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Received Date</Typography>
                <Typography>13 Aug 2024</Typography>
              </Box>

              {/* Applied Time */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Received Time</Typography>
                <Typography>10:00 AM</Typography>
              </Box>

              {/* Application Amount */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: 13,
                  }}
                >
                  Download Your unconditional Offer letter
                </Typography>
              </Box>

              {/* Applied Via */}
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#00999E",
                    "&:hover": {
                      backgroundColor: "#007f80",
                      color: "#fff",
                    },
                    width: "100%",
                    fontSize: 13,
                    padding: "7px 4px",
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1} sm={4}>
          <Box
            sx={{
              height: "auto",
              width: "367px",
              borderRadius: "8.59px",
              border: "0.86px solid #9f9f9f",
              backgroundColor: "#E2FEFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Others
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Urna mi ipsum sapien
                lectus consequa.{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                height: "auto",
                width: "323px",
                borderRadius: "10px",
                border: "1px solid #E4E4E4",
                margin: "auto",
                backgroundColor: "#FFF",
                marginTop: "auto",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <img src={OppositeSidekeyArrow_abroad} alt="opp" />
                </Box>
                <Typography
                  variant="body2"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexWrap={"noWrap"}
                >
                  Lorem, ipsum dolor. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Nulla.
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                  }}
                >
                  JPG, PNG or PDF, file size no more than 10MB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "auto",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "#00999E", // Text color
                    border: "1px solid #00999E", // Corrected border color
                    "&:hover": {
                      color: "#00999E", // Keeps text color same on hover
                      border: "1px solid #00999E", // Keeps border color same on hover
                    },
                    textTransform: "capitalize", // Ensures text is not uppercase
                  }}
                  size="large"
                >
                  Browse File
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
