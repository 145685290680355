import { Box, Grid, } from '@mui/material'
import React from 'react'
import Cta from 'src/Component/card-components/Cta'
// import { useNavigate } from 'react-router'
import HexagonalCard from 'src/Component/card-components/HexagonalCard'
import Header from 'src/Component/content-component/Header'
import globalCombinationData from 'src/json/StudyAbroadJson/globalCombinationData'

const StudyAbroadGlobalCombination = () => {

    return (
        <Box sx={{ backgroundColor: "#effdff", padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0", display: 'flex', justifyContent: "center", flexDirection: 'column' } }}>
            <Box sx={{ mb: "40px", }}>
                <Header
                    color="#00999e"
                    textAlign="center"
                    content="Find Your Perfect Global Combination"
                />

            </Box>
            <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {globalCombinationData.map(
                    (item, index) => (
                        <>
                            <Grid
                                item md={4} sm={12} xs={12}

                            > <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", margin: "20px 0", padding: "10px 0" }}>
                                    <HexagonalCard data={item} />

                                </Box>

                            </Grid>

                        </>
                    )
                )}
            </Grid>
            <Box sx={{display:"flex", justifyContent:"center", }}>

                <Cta />
            </Box>
        </Box>
    )
}

export default StudyAbroadGlobalCombination
