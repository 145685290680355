import Header from "src/Component/content-component/Header";
import ParaData from "src/Component/content-component/ParaData";
import ContentContainer from "src/Component/grid-component/ContentContainer";
import ImageContainer from "src/Component/grid-component/ImageContainer";
import MainContainer from "src/Component/grid-component/MainContainer";
// import mbbsImg1 from "../asset/mbbs3.png";
import mbbsImg1 from "../asset/mbbsinindia.png";
import mbbsicon1 from "../asset/mbbsIcon1.svg";
import mbbsicon2 from "../asset/mbbsIcon2.svg";
import { Icon } from "@iconify/react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
const images = [
  {
    image: mbbsicon1,
    title: "Eligibility Criteria",
    page: "/mbbs/india",
    section: "#indiaCriteria",
  },
  {
    image: mbbsicon2,
    title: "Top 10 Colleges",
    page: "/mbbs/india",
    section: "#colleges",
  },
];
const MbbsinIndia = () => {
  return (
    <>
      <MainContainer
        dir="column-reverse"
        color={"#EFFDFF"}
        com1={<ImageContainer img={mbbsImg1} />}
        com2={
          <ContentContainer
            Header={<Header content={"MBBS in INDIA"} />}
            Content={
              <ParaData
                color={"#525560"}
                fontSize={"16px"}
                content={
                  "MBBS in India is a great choice given its world-class curriculum, seasoned faculty, diverse patient population, and global recognition. The overall duration of MBBS in India is about 5.5 years where the first 4.5 years is allotted for the classroom training, and the rest is for the internship course. In India we have three types of colleges, Government College, Private College and Deemed University. At present, there are 1,06,333 total medical seats in India out of which 55,468 seats are for government colleges, 50,685 for private colleges and about 8850 for deemed universities."
                }
              />
            }
            images={images}
            button={
              <ButtonComponent
                text="Read More"
                href="/mbbs/india"
                icon={<Icon icon="mynaui:arrow-long-right" />}
              />
            }
          />
        }
      />
    </>
  );
};
export default MbbsinIndia;
