import { Box, Container, Grid } from "@mui/material";
import FlipCard from "src/Component/card-components/FlipCard";
import Header from "src/Component/content-component/Header";
import eligibilityData from "src/json/eligibilityData";
const EligibilityData = () => {
    return (
        <>
            <Box sx={{ backgroundColor: "#EFFDFF", padding: { xs: "20px", md: "50px 0" }, display: 'flex', justifyContent: 'center' }} id="indiaCriteria">
                <Container maxWidth="lg">
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}>

                        <Header content={"Eligibility criteria for MBBS in India"} textAlign={"center"} />
                    </Box>

                    <Grid container spacing={3} columns={{ xs: 12, sm: 12, md: 12 }}>
                        {eligibilityData?.map((eligibility, index) => {
                            return (
                                <>
                                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index} sx={{ textAlign: "-webkit-center" }}>
                                        <FlipCard data={eligibility} />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};
export default EligibilityData;
