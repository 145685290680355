import Edit from "src/asset/Edit_abroad.svg";
import Engineering_abroad from "src/asset/Engineering&Technology_abroad.svg";
import IELTS from "src/asset/IELTS_abroad.svg";
import Location_abroad from "src/asset/Location_abroad.svg";
import Other_abroad from "src/asset/Other_abroad.svg";
import CareerSelectedCard from "./ThirdPart/CareerSelectedCard";
import CareerUniversityList from "./ThirdPart/CareerUniversity.List";
import CheckAllAppStatus from "./ThirdPart/CheckAllAppStatus";
import CustomError from "./ThirdPart/CustomError";
import CustomSelectedPage from "./ThirdPart/CustomSelectedPage";
import EducationLoanPage from "./ThirdPart/EducationLoanPage";
import PersonalizedMentoring from "./ThirdPart/PersonalizedMentoring";
import Visa from "./ThirdPart/VisaPage";
import AdmissionUniversityActionTypeList from "./ThirdPart/AdmissionUniversityActionTypeList";

const ErrorCustomData = [
  {
    title: " Sorry! You are not Eligible ",
    description:
      "Lorem ipsum dolor sit amet consectetur. Leo morbi risus morbi ullorper volutpat amet lectus vulputate pharetra. Lectus sit ultricies cras curabitur.",
  },
  {
    title: "Dear Student",
    description: "You Have not taken any courses from this website",
  },
];

const SelectedCardCustomData = [
  {
    title: "Selected Courses",
    data: [
      {
        name: "Engineering & Technology",
        flagUrl: Engineering_abroad,
      },
      {
        name: "Engineering & Technology",
        flagUrl: Engineering_abroad,
      },
      {
        name: "Edit",
        flagUrl: Edit,
      },
    ],
  },
  {
    title: "Selected  EXAM ",
    data: [
      {
        name: "IELTS",
        flagUrl: IELTS,
      },
      {
        name: "IELTS",
        flagUrl: IELTS,
      },
      {
        name: "Edit",
        flagUrl: Edit,
      },
    ],
  },
  {
    title: "Selected Accommodation Option",
    data: [
      {
        name: "University",
        flagUrl: Engineering_abroad,
      },
      {
        name: "Rent",
        flagUrl: Location_abroad,
      },
      {
        name: "Other",
        flagUrl: Other_abroad,
      },
    ],
  },
];

export default function Third({ categoryAbout }) {

  return (
    <>
      {/* PersonalizedMentoring */}
      {categoryAbout.category === "personalized" &&
        (categoryAbout.actionType === "In Progress" ? (
          <PersonalizedMentoring
            title={categoryAbout.title}
            actionType={categoryAbout.actionType}
          />
        ) : categoryAbout.actionType === "Not Taken" ? (
          <>
            <CustomError
              title={categoryAbout.title}
              Errortitle={ErrorCustomData[0].title}
              description={ErrorCustomData[0].description}
            />
          </>
        ) : (
          <></>
        ))}
      {/* CarrerMapping */}
      {categoryAbout.category === "career" &&
        (categoryAbout.title === "Countries" ? (
          categoryAbout.actionType === "In Progress" ? (
            <>
              <CareerSelectedCard />
            </>
          ) : categoryAbout.actionType === "Not Taken" ? (
            <>
              <CustomError
                title={categoryAbout.title}
                Errortitle={ErrorCustomData[0].title}
                description={ErrorCustomData[0].description}
              />
            </>
          ) : (
            <></>
          )
        ) : categoryAbout.title === "Courses" ? (
          categoryAbout.actionType === "In Progress" ? (
            <>
              <CustomSelectedPage SelectedData={SelectedCardCustomData[0]} />
            </>
          ) : categoryAbout.actionType === "Not Taken" ? (
            <>
              <CustomError
                title={categoryAbout.title}
                Errortitle={ErrorCustomData[0].title}
                description={ErrorCustomData[0].description}
              />{" "}
            </>
          ) : (
            <></>
          )
        ) : categoryAbout.title === "Universities" &&
          categoryAbout.actionType === "In Progress" ? (
          <CareerUniversityList />
        ) : categoryAbout.actionType === "Not Taken" ? (
          <>
            <CustomError
              title={categoryAbout.title}
              Errortitle={ErrorCustomData[0].title}
              description={ErrorCustomData[0].description}
            />{" "}
          </>
        ) : (
          <></>
        ))}
      {/* Admissinon Service */}
      {categoryAbout.category === "admission" &&
        (categoryAbout.title === "Test Preparation" ? (
          categoryAbout.actionType === "In Progress" ? (
            <>
              <CustomSelectedPage SelectedData={SelectedCardCustomData[1]} />
            </>
          ) : categoryAbout.actionType === "Not Taken" ? (
            <>
              <CustomError
                title={categoryAbout.title}
                Errortitle={ErrorCustomData[1].title}
                description={ErrorCustomData[1].description}
              />
            </>
          ) : (
            <></>
          )
        ) : categoryAbout.title === "LORs/ Essays/ SOPs" ? (
          categoryAbout.actionType === "In Progress" ? (
            <>
              <AdmissionUniversityActionTypeList />
            </>
          ) : categoryAbout.actionType === "Not Taken" ? (
            <>
              <CustomError
                title={categoryAbout.title}
                Errortitle={ErrorCustomData[1].title}
                description={ErrorCustomData[1].description}
              />{" "}
            </>
          ) : (
            <></>
          )
        ) : categoryAbout.title === "Scholarship" &&
          categoryAbout.actionType === "In Progress" ? (
          <AdmissionUniversityActionTypeList />
        ) : categoryAbout.actionType === "Not Taken" ? (
          <>
            <CustomError
              title={categoryAbout.title}
              Errortitle={ErrorCustomData[1].title}
              description={ErrorCustomData[1].description}
            />{" "}
          </>
        ) : (
          <></>
        ))}
      {/* Post Service */}
      {categoryAbout.category === "post" &&
        (categoryAbout.title === "Education Loan" ? (
          categoryAbout.actionType === "In Progress" ? (
            <>
              <EducationLoanPage />
            </>
          ) : categoryAbout.actionType === "Not Taken" ? (
            <>
              <CustomError
                title={categoryAbout.title}
                Errortitle={ErrorCustomData[1].title}
                description={ErrorCustomData[1].description}
              />
            </>
          ) : (
            <></>
          )
        ) : categoryAbout.title === "VISA/TRAVEL & FOREX" ? (
          categoryAbout.actionType === "In Progress" ? (
            <>
              <Visa />
            </>
          ) : categoryAbout.actionType === "Not Taken" ? (
            <>
              <CustomError
                title={categoryAbout.title}
                Errortitle={ErrorCustomData[1].title}
                description={ErrorCustomData[1].description}
              />{" "}
            </>
          ) : (
            <></>
          )
        ) : categoryAbout.title === "ACCOMMODATION" &&
          categoryAbout.actionType === "In Progress" ? (
          <>
            <CustomSelectedPage SelectedData={SelectedCardCustomData[2]} />
          </>
        ) : categoryAbout.actionType === "Not Taken" ? (
          <>
            <CustomError
              title={categoryAbout.title}
              Errortitle={ErrorCustomData[1].title}
              description={ErrorCustomData[1].description}
            />{" "}
          </>
        ) : (
          <></>
        ))}
    </>
  );
}
