import Header from "src/Component/content-component/Header";
import ParaData from "src/Component/content-component/ParaData";
import ContentContainer from "src/Component/grid-component/ContentContainer";
import ImageContainer from "src/Component/grid-component/ImageContainer";
import MainContainer from "src/Component/grid-component/MainContainer";
// import image from '../../asset/countryintro.svg'
import image from "src/asset/StudyabroadMain.svg";

import { Box } from "@mui/material";

const CountryIntro = () => {
    return (
        <>
            <MainContainer
                dir="column-reverse"
                color={"#ffffff"}
                com1={<ImageContainer img={image} />}
                com2={
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ContentContainer
                            Header={<Header content={"Global Navigation"} />}
                            Content={
                                <ParaData
                                    color={"#525560"}
                                    fontSize={"16px"}
                                    content={
                                        "From the academic powerhouses of the United States and the United Kingdom, with their Ivy League institutions and centuries-old universities, to the innovative landscapes of Australia and Canada, offering cutting-edge research facilities and multicultural environments, our platform navigates you through a plethora of study destinations. Explore the rich tapestry of educational experiences, diverse cultures, and vibrant communities awaiting you as you step into the world of international education"
                                    }
                                />
                            }


                        />

                    </Box>
                }
            />
        </>
    );
};
export default CountryIntro;
