const MbbsTabData = [
  {
    title: "MBBS India",
    items: [
      "Typically 5.5 years (including internship)",
      "NEET (National Eligibility cum Entrance Test) is the common entrance exam for MBBS admissions",
      "Follows the regulations and guidelines set by the Medical Council of India (MCI)",
      "Opportunities for clinical rotations",
    ],
    styles: {},
  },
  {
    title: "MBBS Abroad",
    items: [
      "Varies by country (usually 5-6 years)",
      "NEET (National Eligibility cum Entrance Test) is the common entrance exam for MBBS admissions",
      "Follows the standards set by respective medical councils or accreditation bodies",
      "Similar to India, with opportunities",
    ],
    styles: {},
  },
  {
    title: "MBBS India",
    items: [
      "3 years after completing MBBS",
      "NEET PG for admission to MD/MS/Diploma courses in India",
      "Specialisation in various branches of medicine/surgery as per MCI guidelines",
    ],
    styles: {},
  },
  {
    title: "MBBS Abroad",
    items: [
      "May range between 2 years to 4 years after completing MBBS.",
      "Some countries may have entrance exams for PG programs, while others may have direct admissions",
      "Specialisation options similar to India, with opportunities for research and clinical training",
    ],
    styles: {},
  },
];

export default MbbsTabData;
