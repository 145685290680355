import React from "react";
import { Box, Typography, Slider, TextField } from "@mui/material";

const RangeSelectionBox = ({ label, value, onChange }) => (
  <Box
    sx={{
      margin: "20px 0",
      bgcolor: "rgb(239, 253, 255, 0.9)",
      padding: "20px",
      borderRadius: "20px",
      border: "2px solid #00999E",
      background: `linear-gradient(to right, 
              rgba(167, 214, 215, 0.6) 10%,   
              rgba(218, 240, 241, 0.5) 60%, 
              rgba(255, 255, 255, 1) 100%)`,
      flexDirection: { xs: "column", md: "row" },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography
      variant="h6"
      gutterBottom
      sx={{ width: { sm: "300px", xs: "200px" } }}
    >
      {label}
    </Typography>

    <Box
      sx={{
        display: "flex",
        width: {
          md: "600px",
          xs: "250px",
        },
        padding: "20px",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          border: "2px solid #00999E",
          borderRadius: "20px",
          padding: "0 50px  ",
        }}
      >
        <Typography gutterBottom>Range Slider</Typography>
        <Slider
          value={value}
          onChange={onChange}
          min={0}
          max={5000000}
          valueLabelDisplay="auto"
          sx={{
            color: "#00999E",
            "& .MuiSlider-thumb": {
              color: "#00999E",
            },
            "& .MuiSlider-rail": {
              color: "#cfcfcf",
            },
            "& .MuiSlider-markLabel": {
              color: "#000",
              fontWeight: "bold",
            },
          }}
          marks={[
            { value: 0, label: "₹ 0" },
            { value: 5000000, label: "₹ 50,00,000" },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: "center",

          width: { md: "600px", xs: "250px" },
          padding: "20px",
        }}
      >
        <Box
          sx={{
            width: { md: "300px", xs: "100%" },
            bgcolor: "#00999E",
            color: "#fff",
            borderTopLeftRadius: "5px",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            borderBottomLeftRadius: "5px",
            gap: 5,
          }}
        >
          <Typography variant="body2">Tuition Fees Per Year</Typography>
        </Box>
        <Box sx={{ width: { md: "300px", xs: "100%" } }}>
          <TextField
            variant="outlined"
            value={`₹ ${value}`}
            InputProps={{
              readOnly: true,
              sx: {
                width: { md: "300px", xs: "100%" },
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
                "& fieldset": {
                  border: "2px solid",

                  borderColor: "rgb(0,0,0,0.5)",
                },
                textAlign: "center",
              },
              textAlign: "center",
            }}
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

export default RangeSelectionBox;
