import {
  Box,
  Card,
  Grid,
  Typography,
  useTheme,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState } from "react";

import dottedline from "../../../asset/dottedline.svg";
import Third from "./Third";

export default function Second({
  setCategoryAbout,
  selectedCategory,
  categoryAbout,
}) {
  const [show, setShow] = useState(false);
  const arr = [1, 2, 3];
  const ScreenFor = createTheme({
    breakpoints: {
      values: {
        s1: 806,
        s2: 956,
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
  });
  const isSmallScreen = useMediaQuery(ScreenFor.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(ScreenFor.breakpoints.down("md"));
  const handleChangeProcess = (e, title, actionType) => {
    setCategoryAbout({
      title: title,
      actionType: actionType,
      category: selectedCategory,
    });
  };
  const allData = {
    personalized: [
      {
        title: "Counselling",
        actionType: "In Progress",
      },
      {
        title: "Psychometric Test",
        actionType: "Not Taken",
      },
      {
        title: "Profile Building",
        actionType: "Completed",
      },
    ],

    career: [
      {
        title: "Countries",
        actionType: "In Progress",
      },
      {
        title: "Courses",
        actionType: "In Progress",
      },
      {
        title: "Universities",
        actionType: "In Progress",
      },
    ],

    admission: [
      {
        title: "Test Preparation",
        actionType: "In Progress",
      },
      {
        title: "LORs/ Essays/ SOPs",
        actionType: "In Progress",
      },
      {
        title: "Scholarship",
        actionType: "In Progress",
      },
    ],

    post: [
      {
        title: "Education Loan",
        actionType: "In Progress",
      },
      {
        title: "VISA/TRAVEL & FOREX",
        actionType: "In Progress",
      },
      {
        title: "ACCOMMODATION",
        actionType: "In Progress",
      },
    ],
  };

  return (
    <>
      <Box
        sx={{
          width: {
            xs: "95%",
            sm: "90%",
          },
          height: "auto",
          padding: "auto",
          margin: "auto",
          // backgroundColor : "black",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          //
          py: 4,
          // boxShadow: "2px 2px 4px #000",
        }}
        onClick={() => setShow(true)}
      >
        {allData[selectedCategory]?.map((item, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                mx: {
                  xs: 1,
                  sm: "auto",
                },
                my: {
                  xs: 1,
                  sm: "auto",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                  },
                  alignItems: "center",
                  gap: {
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                  },

                  flexWrap: "wrap",
                  flexDirection: {
                    xs: show ? "column" : "row",
                    sm: "row",
                  },
                  // backgroundColor : "green",
                  my: {
                    xs: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    width: "auto",
                    height: "125px",
                    padding: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "16px",
                    },
                    gap: {
                      xs: "5px",
                      sm: "5px",
                      md: "10px",
                      lg: "20px",
                    },
                    borderRadius: "12px",
                    backgroundColor: "#00999E",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    cursor: "pointer",
                    my: {
                      md: 3,
                      sm: 3,
                    },
                  }}
                  onClick={(e) =>
                    handleChangeProcess(e, item?.title, item?.actionType)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "150px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                     {item?.title}
                    </Typography>
                    <Box
                      sx={{
                        bgcolor: "#B1FCFF",
                        width: "fit-content",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                        padding: "3px 8px",
                      }}
                    >
                      <Box
                          sx={{
                            height: "18px",
                            width: "18px",
                            border: `5px solid ${
                              item?.actionType === "In Progress"
                                ? "white"
                                : item?.actionType === "Completed"
                                ? "white"
                                : item?.actionType === "Not Taken"
                                ? "#828282"
                                : item?.actionType === "Not Required"
                                ? "white"
                                : "white"
                            }`,
                            borderRadius: "50%",
                            backgroundColor: `${
                              item?.actionType === "In Progress"
                                ? "#F67A07"
                                : item?.actionType === "Completed"
                                ? "#20CF10"
                                : item?.actionType === "Not Taken"
                                ? "#1C1C1C"
                                : item?.actionType === "Not Required"
                                ? "red"
                                : "white"
                            }`,
                            mr: 1,
                            mb: 0.3,
                          }}
                        />
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "12px",
                        }}
                      >
                        {item?.actionType}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {isSmallScreen  ? (
                  show &&  item.title === categoryAbout.title  &&  categoryAbout.actionType !== "Completed" && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        paddingTop: "20px",
                        marginRight: {
                          xs: 0,
                          sm: 8,
                        },
                      }}
                    >
                      <img
                        src={dottedline}
                        alt="dotted-line"
                        width={5}
                        height={80}
                        style={{ margin: "auto" }}
                      />
                      <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                    </Box>
                  )
                ) : (
                  <ArrowForwardIosIcon />
                )}
                 
              </Box>
              {(isSmallScreen || isMediumScreen) && show && item.title === categoryAbout.title  &&  categoryAbout.actionType !== "Completed" && (
              <>
                {
                              show && (isSmallScreen||isMediumScreen)  && (
                                <Box>
                                 <Third
                           categoryAbout={categoryAbout}
                          />
                                </Box>
                              )
                            }
                            </>
              )}
                  {
              !isSmallScreen && show && item.title === categoryAbout.title  &&  categoryAbout.actionType !== "Completed" && <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                flexDirection: "column",
                paddingTop: "20px",
                marginRight: {
                  xs: 0,
                  sm: 8,
                },
              }}
            >
              <img
                src={dottedline}
                alt="dotted-line"
                width={5}
                height={80}
                style={{ margin: "auto" }}
              />
              <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
            </Box>
             }
        
            </Box>
          );
        })}
        
      </Box>
       {show && !isSmallScreen && (
              <Box>
                
                <Third
                 categoryAbout={categoryAbout}
                />
              </Box>
            )}
    </>
  );
}
