import React, { useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dottedline from "../../asset/dottedline.svg";
const RuleCard = ({ data, setContent, setSelectedIndex, index }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <>
      <Box
        position="relative"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "180px",
            height: "180px",
            borderRadius: "100px",
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100'  stroke='${hovered ? "%2300999E" : "%23000000"
              }' stroke-width='2' stroke-dasharray='20%25%2c 18%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
          }}
        >
          <Card
            sx={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#00999E",
                color: "#FFFFFF",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              wordWrap: "wrap",
              padding: "5px",
              textAlign: "center",
              fontWeight: "400",
              fontSize: "12px",
              cursor: "pointer"
            }}
            onMouseEnter={() => {
              setHovered(true);
              setContent(data.content);
              setSelectedIndex(index)
            }}
            onMouseLeave={() => {
              setHovered(false);
              setContent(null);
              setSelectedIndex(null)
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "600", fontFamily: "Poppins" }}>{data?.title}</Typography>
          </Card>
        </Box>
        {hovered ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              flexDirection: "column",
              paddingTop: "20px",
            }}
          >
            <img
              src={dottedline}
              alt="dotted-line"
              width={5}
              height={80}
              style={{ margin: "auto" }}
            />
            <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={5}
            height={80}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              flexDirection: "column",
              paddingTop: "20px",
              fontsize: "30px",
            }}
          >
            <Icon
              icon="lets-icons:check-ring-light"
              style={{ fontSize: "30px" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default RuleCard;
