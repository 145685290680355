import i1 from "../../asset/testchoose1.svg";
import i2 from "../../asset/testchoose2.svg";
import i3 from "../../asset/ChooseUsImg2.svg";
import i4 from "../../asset/testchoose3.svg";
import i5 from "../../asset/testchoose4.svg";
import i6 from "../../asset/ChooseUsImg05.svg";




const EngineeringChoose = {
    heading: "Why choose Engineering Abroad",
    item: [
        {
            title: "Global Perspective",
            Image: i1,
        },
        {
            title: "Advanced Facilities",
            Image: i2,
        },
        {
            title: "Specialized Programs",
            Image: i3,
        },
        {
            title: "Internship and Job Opportunities",
            Image: i4,
        },
        {
            title: "Networking",
            Image: i5,
        },
        {
            title: "Cultural Experience",
            Image: i6,
        },
    ]
};
const CSITChoose = {
    heading: "Why choose CSIT Abroad",

    item: [
        {
            title: "Specialization Options",
            Image: i1,
        },
        {
            title: "Quality Education",
            Image: i2,
        },
        {
            title: "Industry Connections",
            Image: i3,
        },
        {
            title: "Cultural Immersion",
            Image: i4,
        },
        {
            title: "Global Job Market",
            Image: i5,
        },
        {
            title: "Research Opportunities",
            Image: i6,
        },
    ]
};
const HsmChoose = {
    heading: "Why choose Health Science and Medicine Abroad",
    item: [
        {
            "title": "Impactful Contribution to Society",
            "Image": i1
        },
        {
            "title": "Diverse Career Opportunities",
            "Image": i2
        },
        {
            "title": "Personal Fulfillment and Satisfaction",
            "Image": i3
        },
        {
            "title": "Continuous Learning and Innovation",
            "Image": i4
        },
        {
            "title": "Potential for Making a Difference in People's Lives",
            "Image": i5
        },
        {
            "title": "Global Relevance and Demand",
            "Image": i6
        }
    ]
};
const SocialChoose = {
    heading: "Why choose Social Science Abroad",
    item: [
        {
            "title": "Diverse Perspectives",
            "Image": i1
        },
        {
            "title": "Global Networking",
            "Image": i2
        },
        {
            "title": "Interdisciplinary Approach",
            "Image": i3
        },
        {
            "title": "Research Opportunities",
            "Image": i4
        },
        {
            "title": "Cultural Immersion",
            "Image": i5
        },
        {
            "title": "Career Advancement",
            "Image": i6
        },
        {
            "title": "Personal Development",
            "Image": i1
        },
        {
            "title": "Unique Experiences",
            "Image": i2
        },
        {
            "title": "Cultural Exchange",
            "Image": i3
        }
    ]
}
    ;
const BusinessChoose = {
    heading: "Why choose Business Management Abroad",
    item: [
        {
            "title": "Global Perspective",
            "Image": i1
        },
        {
            "title": "Quality Education",
            "Image": i2
        },
        {
            "title": "Networking Opportunities",
            "Image": i3
        },
        {
            "title": "Cultural Immersion",
            "Image": i4
        },
        {
            "title": "Practical Experience",
            "Image": i5
        },
        {
            "title": "Career Advancement",
            "Image": i6
        },
        {
            "title": "Personal Growth",
            "Image": i1
        },
        {
            "title": "Entrepreneurial Opportunities",
            "Image": i2
        },
        {
            "title": "Cultural Diversity",
            "Image": i3
        }
    ]
}
    ;
const PlsChoose = {
    heading: "Why choose Physical & Life Science Abroad",
    item: [
        {
            "title": "Diverse Academic Opportunities",
            "Image": i1
        },
        {
            "title": "Interdisciplinary Learning",
            "Image": i2
        },
        {
            "title": "Cutting-edge Research Facilities",
            "Image": i3
        },
        {
            "title": "Global Perspective",
            "Image": i4
        },
        {
            "title": "Career Advancement",
            "Image": i5
        },
        {
            "title": "Cultural Enrichment",
            "Image": i6
        }
    ]
}
    ;
const FaaChoose = {
    heading: "Why choose Fine & Applied Art Abroad",
    item: [
        {
            "title": "Diverse Artistic Exploration",
            "Image": i1
        },
        {
            "title": "Interdisciplinary Approach",
            "Image": i2
        },
        {
            "title": "Access to Top Resources",
            "Image": i3
        },
        {
            "title": "Cultural Enrichment",
            "Image": i4
        },
        {
            "title": "Global Career Opportunities",
            "Image": i5
        }
    ]
}
    ;
const CJChoose = {
    heading: "Why choose Communication & Journalism Abroad",
    item: [
        {
            "title": "Diverse Perspectives",
            "Image": i1
        },
        {
            "title": "Top-notch Education",
            "Image": i2
        },
        {
            "title": "International Networking",
            "Image": i3
        },
        {
            "title": "Industry Exposure",
            "Image": i4
        },
        {
            "title": "Cultural Immersion",
            "Image": i5
        },
        {
            "title": "Global Career Prospects",
            "Image": i6
        }
    ]
}
    ;
const DesigningChoose = {
    heading: "Why choose Designing Abroad",
    item: [
        {
            "title": "Global Perspective",
            "Image": i1
        },
        {
            "title": "Top-tier Education",
            "Image": i2
        },
        {
            "title": "Cultural Immersion",
            "Image": i3
        },
        {
            "title": "Networking Opportunities",
            "Image": i4
        },
        {
            "title": "Career Advancement",
            "Image": i5
        }
    ]
}
    ;


export { EngineeringChoose, CSITChoose, HsmChoose, SocialChoose, BusinessChoose, PlsChoose, FaaChoose, CJChoose, DesigningChoose }
