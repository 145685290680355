
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const c1 = imageBaseUrl + 'coursebanner/1.png'
const c2 = imageBaseUrl + 'coursebanner/2.png'
const c3 = imageBaseUrl + 'coursebanner/3.png'
const c4 = imageBaseUrl + 'coursebanner/4.png'
const c5 = imageBaseUrl + 'coursebanner/5.png'
const c6 = imageBaseUrl + 'coursebanner/6.png'
const c7 = imageBaseUrl + 'coursebanner/7.png'
const c8 = imageBaseUrl + 'coursebanner/8.png'
const c9 = imageBaseUrl + 'coursebanner/9.png'

const EngineeringBanner = {
    
    image: c1
};
const CSITBanner = {
    
    image: c2
};
const HsmBanner = {
    
    image: c3
};
const SocialBanner = {
    
    image: c4
};
const BusinessBanner = {
    
    image: c5
};
const PlsBanner = {
    
    image: c6
};
const FaaBanner = {
    
    image: c7
};
const CJBanner = {
    
    image: c8
};
const DesigningBanner = {
    
    image: c9
};


export {EngineeringBanner,CSITBanner,HsmBanner,SocialBanner,BusinessBanner,PlsBanner,FaaBanner,CJBanner,DesigningBanner  }