import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";


const HexagonalCard = ({ data }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    window.scrollTo(0, 0);
    navigate(data?.path);
  };

  const [isHovered, setIsHovered] = useState(false);

  const hexagonContainerStyle = {
    position: "relative",
    width: "167px",
    height: "193px",
  };
  const hexagonContainerStyle2 = {
    position: "relative",
    width: "162px",
    height: "185px",
  };


  const hexagonStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    color: isHovered ? "#ffffff" : "#00999E",
    backgroundColor: isHovered ? "#00999E" : "#ffffff",
    cursor: "pointer",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s, color 0.3s",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
  };
  const hexagonStyle3 = {
    position: "absolute",
    width: "100%",
    height: "100%",
    clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    backgroundColor: isHovered ? "#ffffff" : "#00999e",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s, color 0.3s",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
  };
  const hexagonStyle2 = {
    position: "absolute",
    width: "100%",
    height: "100%",
    clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    backgroundColor: isHovered ? "#5cd2d6" : "#00999E",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s, color 0.3s",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
  };

  const hexagonImageContainerStyle = {
    position: "absolute",

    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    height: "40%",
  };

  const hexagonImageStyle = {
    width: "50%",

  };



  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

      <div
        style={hexagonContainerStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >

        <Paper elevation={3} style={hexagonStyle2} onClick={handleNavigation}>
          <div
            style={hexagonContainerStyle2}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Paper elevation={3} style={hexagonStyle}>
              <Typography variant="h5" sx={{ fontWeight: "550" }}>
                {data?.buttontext}

              </Typography>

              {/* <img alt="Hovered Hexagon_Image" src={data?.Image} width={"60%"} /> */}
            </Paper>
          </div>
        </Paper>
        <div style={hexagonImageContainerStyle} hoverdercolor={"#00999e"}>
          <Paper elevation={7} style={hexagonStyle3} >

            <img
              alt="Hexagon_Image"
              src={data?.icon}
              style={hexagonImageStyle}
            />
          </Paper>
        </div>
      </div>
    </div>

  );
};

export default HexagonalCard;
