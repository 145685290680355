const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const nepal = imageBaseUrl + "image/image 186.png";
const aus = imageBaseUrl + "flags/australia.png";
const bangladesh = imageBaseUrl + "flags/bangladesh.png";
const canada = imageBaseUrl + "flags/canada.png";
const germany = imageBaseUrl + "flags/germany.png";
const kazakhstan = imageBaseUrl + "flags/kazakhstan.png";
const kyrgyzstan = imageBaseUrl + "flags/kyrgyzstan.png";
const russia = imageBaseUrl + "flags/russia.png";
const uk = imageBaseUrl + "flags/uk.png";
const usa = imageBaseUrl + "flags/usa.png";
const uzbekistan = imageBaseUrl + "flags/uzbekistan.png";
const philippines = imageBaseUrl + "flags/philippines.png";



const NepalMbbsInfo =
{
    title: "Nepal",
    content: "Nepal offers one of the most affordable medical study options in comparison to India and many western medical colleges. Medical Universities in Nepal are enrolled in WDOMS, FAIMER, ECFMG, and NMC (MCI). The best medical universities of Nepal fall in the top cream of the best medical universities of the world. Nepal is a growing nation where MBBS institutions are renowned for their amenities, welcoming environments for students and other positive attributes.",
    image: nepal,
};
const BangladeshMbbsInfo =
{
    title: "Bangladesh",
    content: "The Republic of Bangladesh incorporates Top Medical Universities, which provide a-list infrastructure with top-notch medical education and well proficient staff in every Bangladesh Medical College.The MBBS program is well known in India. MBBS in Bangladesh is going into a pattern among Indian students who'd need to consider MBBS abroad. MBBS in Bangladesh is perhaps the most helpful decision for students considering beginning an overall medical vocation ahead.",
    image: bangladesh,
};
const CanadaMbbsInfo =
{
    title: "Canada",
    content: "Canada is renowned for its high-quality education system, and studying MBBS in Canada offers students a unique opportunity to receive world-class medical education.It is renowned for its high academic standards, cutting-edge research facilities, and multicultural environment, Canadian medical universities offer an unparalleled learning experience. With state-of-the-art infrastructure, supportive immigration policies, and affordable education, Canada provides a conducive environment for aspiring medical professionals to thrive",
    image: canada,
};
const GermanyMbbsInfo =
{
    title: "Germany",
    content: "Germany offers rigorous academic standards, advanced research facilities, and extensive clinical training opportunities. With a diverse and multicultural environment, studying medicine in Germany provides students with invaluable cross-cultural experiences. Graduates receive internationally recognized degrees, opening doors to global career opportunities.",
    image: germany,
};
const PhilippinesMbbsInfo =
{
    title: "Philippines",
    content: "The Philippines is known for its high-quality medical education, offering a comprehensive curriculum and extensive clinical training opportunities. Studying medicine in the Philippines provides students with a multicultural environment, fostering global perspectives and cross-cultural understanding. Graduates receive internationally recognized degrees, preparing them for a successful medical career worldwide.",
    image: philippines,
};
const AustraliaMbbsInfo =
{
    title: "Australia",
    content: "MBBS in Australia offers a comprehensive medical education experience characterised by high academic standards, modern facilities, diverse clinical exposure, and excellent career prospects.It provides students with a high-quality medical education, diverse clinical exposure, and excellent career prospects in the field of medicine. It is an attractive option for aspiring medical professionals seeking a global learning experience.",
    image: aus,
};
const RussiaMbbsInfo =
{
    title: "Russia",
    content: "Russia is known the world over for its excellence in teaching and research. It is known for providing high-quality education at a low cost, which is why it is one of the most sought-after destinations for international students desiring to study MBBS in Russia. Most of the students wish to study MBBS so as to become successful doctors in their field. Russia and India both are great destinations to study MBBS.",
    image: russia,
};
const USAMbbsInfo =
{
    title: "USA",
    content: "Pursuing MBBS in the USA offers students access to some of the world's most renowned medical institutions and innovative healthcare systems. With a combination of cutting-edge research facilities, diverse patient populations, and rigorous academic programs, MBBS students in the USA receive comprehensive medical education and training.",
    image: usa,
};
const UKMbbsInfo =
{
    title: "UNITED KINGDOM",
    content: "The UK is globally recognized for its high-quality medical education and training. MBBS programs in the UK provide students with a solid foundation in medical sciences and clinical practice, preparing them to excel in their careers as healthcare professionals. With access to state-of-the-art hospitals and renowned faculty, students benefit from a rich learning experience.",
    image: uk,
};
const KAZAKHSTANMbbsInfo =
{
    title: "KAZAKHSTAN",
    content: "Kazakhstan is emerging as a popular destination for international students seeking affordable yet quality medical education. MBBS programs in Kazakhstan are conducted in English, making it accessible to students from diverse backgrounds. With modern infrastructure, experienced faculty, and a multicultural environment, Kazakhstan offers a conducive learning environment for aspiring doctors.",
    image: kazakhstan,
};
const KYRGYZSTANMbbsInfo =
{
    title: "KYRGYZSTAN",
    content: "Kyrgyzstan is gaining prominence as a destination for MBBS education due to its affordable tuition fees, modern medical infrastructure, and English-taught programs. Students pursuing MBBS in Kyrgyzstan have the opportunity to gain clinical experience through rotations in hospitals and clinics, preparing them for successful medical careers.",
    image: kyrgyzstan,
};
const UZBEKISTANMbbsInfo =
{
    title: "UZBEKISTAN",
    content: "Uzbekistan offers international students the opportunity to pursue MBBS in a culturally rich and diverse environment. With a focus on hands-on clinical training and academic excellence, MBBS programs in Uzbekistan equip students with the knowledge and skills required to thrive in the medical field. Additionally, the lower cost of living makes it an attractive option for aspiring doctors.",
    image: uzbekistan,
};
export {NepalMbbsInfo,BangladeshMbbsInfo,CanadaMbbsInfo,GermanyMbbsInfo,PhilippinesMbbsInfo,AustraliaMbbsInfo,RussiaMbbsInfo,USAMbbsInfo,UKMbbsInfo,KAZAKHSTANMbbsInfo,KYRGYZSTANMbbsInfo,UZBEKISTANMbbsInfo};